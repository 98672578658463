import * as React from 'react'
import {createStyles, Grid, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {makeObservable, observable, when} from "mobx";
import User from "../../model/User";
import Progress from "../../components/Progress";
// import UserCard from "./UserCard";
import TitleButton from "../../components/TitleButton";
import SearchBar from 'material-ui-search-bar'
import UserCard from "./UserCard";
import ControlTower, {Routes} from "../../components/ControlTower";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(1),
  },
  controlBar: {
    display: "flex",
    flex: "none",
    flexDirection: "row",
    marginTop: theme.spacing(1),
    height: 30,
    width: "100%",
    maxWidth: 960,
    paddingRight: theme.spacing(1)
  },
  content: {
    flexGrow: 1,
    justifyContent: 'top',
    width: "100%",
    maxWidth: 960,
    marginTop: theme.spacing(0.5),
    // maxHeight: 'calc(100vh - 206px)',
    minHeight: "min-content",
    overflowY: "auto"
  },
  search: {
    flexGrow: 1,
  },
  searchBar: {
    height: 30,
  },
  actions: {
    flexGrow: 0,
    alignItems: "center",
    alignContent: "center",
    // paddingTop: 2
  },
  actionButton: {
    width: 24,
    height: 30,
    padding: 0,
    marginLeft: theme.spacing(1),
    margin: 0,
    minWidth: 64,
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.dark,
    },
    "&:active": {
      background: theme.palette.secondary.light,
    },
  },
  subtitle: {
    margin: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
  quantity: {
    margin: theme.spacing(0),
    color: theme.palette.text.secondary,
    fontWeight: 600,
    textAlign: "right",
    paddingRight: theme.spacing(1)
  },

})

interface IAccountUsersProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
}

@inject("userStore", "accountStore", "progress")
@observer
class AccountUsers extends React.Component<WithStyles<typeof styles> & IAccountUsersProps & WithTheme> {

  @observable account?: Account
  @observable allUsers: User[] = []
  @observable users: User[] = []
  @observable isLoading = true
  @observable search?: string

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    const { progress } = this.props
    this.isLoading = true
    progress!.show("AccountUsers")
    const { userStore, accountStore} = this.props
    when (
      () => !userStore!.isLoading,
      async () => {
        this.account = accountStore!.account
        let users = await accountStore!.listUsers(this.account!.id)
        this.allUsers = users.sort((a: User, b: User) => { return a.fullName.localeCompare(b.fullName)})
        this.filterUsers()
        this.isLoading = false
        progress!.hide("AccountUsers")
      }
    )
  }

  render() {
    const { classes } = this.props

    if (this.isLoading) {
      return null
    }

    return (
      <div className={classes.root}>
        <div className={classes.controlBar}>
          <div className={classes.search}>
            <SearchBar value={this.search} className={classes.searchBar}
                       onChange={this.onSearchChange} onCancelSearch={this.onCancelSearch} />
          </div>
          <div className={classes.actions}>
            <TitleButton title="+ Add" className={classes.actionButton} onClick={this.onAddUser}/>
          </div>
        </div>
        {this.allUsers &&
          <Grid container className={classes.content} direction="row" spacing={1}>
            {this.users.map((u: User) => {
              return (
                <Grid item sm={6} xs={12} className={classes.card} key={u.id}>
                  <UserCard user={u} onCardAction={this.onCardAction}/>
                </Grid>
              )
            })}
          </Grid>
        }
      </div>
    )
  }

  onSearchChange = (value: string) => {
    this.search = value
    // localStorage.setItem(ClientsPageConstants.CLIENTS_SEARCH_NAME, this.search)
    this.filterUsers()
  }

  onCancelSearch = () => {
    this.search = undefined
    // localStorage.removeItem(ClientsPageConstants.CLIENTS_SEARCH_NAME)
    this.filterUsers()
  }

  onAddUser = () => {
    ControlTower.route(`${Routes.account}/userEdit`)
  }

  onCardAction = (user: User) => {
    ControlTower.route(`${Routes.account}/userEdit/${user.id}`)
  }

  filterUsers = () => {
    let users: User[] = []

    if (this.search) {
      const search = this.search.toLowerCase()
      this.allUsers.forEach((u: User) => {
        if (u.fullName.toLowerCase().indexOf(search!) >= 0 ||
            u.email.toLowerCase().indexOf(search!) >= 0 ||
            u.role.toLowerCase().indexOf(search!) >= 0) {
          users.push(u)
        }
      })
    } else {
      users = this.allUsers
    }
    
    this.users = users
  }
}

export default withTheme((withStyles(styles)(AccountUsers)))