import * as React from "react";
import {createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  funText: {
    fontFamily: [
      'Domus',
      'sans-serif'
    ].join(','),
    fontSize: 18,
    color: theme.palette.primary.main
  },
  givesText: {
    fontFamily: [
      'Domus',
      'sans-serif'
    ].join(','),
    fontSize: 18,
    color: theme.palette.secondary.main
  }
})

interface IAppNameProps {
  className?: string
  fontSize?: number
}

class AppName extends React.Component<WithStyles<typeof styles> & IAppNameProps & WithTheme> {

  render() {
    const { classes, fontSize } = this.props

    const style = {fontSize: fontSize ?? 18}

    return (
      <React.Fragment>
        <span className={classes.funText} style={style}>fun</span><span className={classes.givesText} style={style}>gives</span>
      </React.Fragment>
    )
  }
}

export default withTheme((withStyles(styles)(AppName)))
