import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme, fade} from '@material-ui/core/styles'
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import {Button, Grid, Typography} from "@material-ui/core";
import ControlTower, {Routes} from "../../components/ControlTower";
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import CampaignStore from "../../stores/CampaignStore";
import {makeObservable, observable, when} from "mobx";
import Campaign from "../../model/Campaign";
import CampaignCard from "../account/CampaignCard";
import HeroImage from "../../images/Hero.jpg"
import AboutBINKO from "../../images/AboutBINKO.jpg"
import AboutSocial from "../../images/AboutSocial.png"
import AboutTeams from "../../images/AboutTeams.jpg"
import UserStore from "../../stores/UserStore";
import * as APITypes from "../../API";
import {getISODateTimeFromDate, getISODateToday} from "../../stores/StoreUtilities";
import {addMonths} from "date-fns";

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: 'top',
    alignItems: "center",
    width: "100%",
    margin: 0,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0.0),
      paddingRight: theme.spacing(0.0),
    },
    [theme.breakpoints.up('md')]: {
      // maxWidth: 960,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContainer: {
    width: "100%",
    minHeight: 400,
    margin: 0,
    padding: 0,
    backgroundColor: theme.palette.common.white
  },
  hero: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: 'top',
    alignItems: "center",
    width: "100%",
    minHeight: 400,
    margin: 0,
    padding: 0,
    textAlign: "center",
    backgroundImage: `url(${HeroImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 25%",
    backgroundColor: theme.palette.common.white
  },
  heroContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.black,
    padding: theme.spacing(1)
  },
  heroHeading: {
    color: theme.palette.background.default
  },
  heroText: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1)
  },
  startHeroButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 220,
    // height: 50,
    fontSize: 20,
    marginTop: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  learnHeroButton: {
    color: theme.palette.secondary.main,
    fontSize: 20,
    "&:hover": {
      color: theme.palette.secondary.dark,
    }
  },
  heroList: {
    color: fade(theme.palette.common.black, 0.7)
  },
  callToAction: {
    paddingTop: 120,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 200,
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 960,
      justifyContent: "left",
      alignItems: "left",
      textAlign: "left"
    },
    // border: "1px solid yellow"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 0,
    paddingTop: theme.spacing(1),
    color: theme.palette.text.secondary,

  },
  slogan: {
    color: theme.palette.text.primary,
    margin: 0,
    opacity: "0.8",
    textShadow: "2px 2px #000000",
  },
  sloganEm: {
    color: theme.palette.primary.main,
    margin: 0,
    textShadow: "2px 2px #000000",
  },
  sloganSub: {
    color: theme.palette.text.secondary,
    margin: 0,
    textShadow: "2px 2px #000000",
  },
  campaigns: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(1),
  },
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // backgroundColor: "#808080"
  },
  appName: {
    color: theme.palette.primary.main
  },
  startButton: {
    backgroundColor: fade(theme.palette.primary.main, 1),
    color: theme.palette.primary.contrastText,
    width: 350,
    height: 50,
    fontSize: 20,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  learnButton: {
    backgroundColor: fade(theme.palette.secondary.main, 0.8),
    color: theme.palette.secondary.contrastText,
    width: 350,
    height: 50,
    fontSize: 20,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    }
  },
  title: {
    color: theme.palette.common.white,
    textAlign: "left",
  },
  aboutPanel: {
    backgroundColor: "rgba(255, 255, 255, 0.10)",
    marginTop: 36,
    width: "100%",
    paddingBottom:  theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  aboutItem: {
    maxWidth: 250,
    "& p": {
      fontSize: 16,
      color: theme.palette.text.secondary
    },
  },
  aboutBINKOImage: {
    height: 200,
    paddingTop: theme.spacing(1)
  },
  aboutSocialImage: {
    height: 210,
    paddingTop: theme.spacing(1)
  },
  aboutTeamsImage: {
    height: 200,
    paddingTop: theme.spacing(1)
  },
  secondCall: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  },
  learnMore: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2)
  },
})

interface IHomePageProps {
  campaignStore?: CampaignStore
  userStore?: UserStore
  progress?: Progress
}

@inject("campaignStore", "userStore", "progress")
@observer
class HomePage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IHomePageProps & WithTheme> {

  @observable isLoading = true
  @observable campaigns: Campaign[] = []
  @observable pastCampaigns: Campaign[] = []

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount () {
    const { campaignStore, progress } = this.props
    this.isLoading = true
    progress!.show("HomePage")
    when (
      () => !campaignStore!.isLoading,
      async () => {
        await this.loadCampaigns()
        this.isLoading = false
        progress!.hide("HomePage")
      }
    )
  }

  render() {
    const { classes } = this.props

    const title = "Home"

    return (
      <Page title={title}>
        <div className={classes.root}>
          <Grid container className={classes.heroContainer} direction="row">
            <Grid item sm={8} className={classes.hero}>

            </Grid>
            <Grid item sm={4} className={classes.heroContent}>
              <h1 className={classes.heroHeading}>Make Giving Fun!</h1>
              <Typography variant="body1" className={classes.heroText}>
                Take your fundraising to the next level <br/>with team-oriented games to <br/>maximize fun and giving!
              </Typography>
              <Button size="small" className={classes.startHeroButton} onClick={this.onSignup}>
                Start a FUNdraiser
              </Button>
              <Button variant="text" className={classes.learnHeroButton} onClick={this.onAbout}>
                Learn More
              </Button>
              <br/>
              <Grid container direction="row" className={classes.heroList}>
                <Grid item sm={6} xs={6}>Youth Activities</Grid>
                <Grid item sm={6} xs={6}>Sports Teams</Grid>
                <Grid item sm={6} xs={6}>Schools</Grid>
                <Grid item sm={6} xs={6}>Clubs</Grid>
                <Grid item sm={6} xs={6}>Churches</Grid>
                <Grid item sm={6} xs={6}>Run/Walk/Rides</Grid>
                <Grid item sm={6} xs={6}>Non-Profits</Grid>
                <Grid item sm={6} xs={6}>Special Needs</Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*<div className={classes.hero}>*/}
          {/*</div>*/}
          <MarginRow>
            <div className={classes.content}>
              <h1 className={classes.title}>Featured Fundraisers</h1>
              <div>
                {this.campaigns &&
                <Grid container className={classes.campaigns} direction="row" spacing={2}>
                  {this.campaigns.map((c: Campaign) => {
                    return (
                      <Grid item md={6} sm={6} xs={12} className={classes.card} key={c.id}>
                        <CampaignCard campaign={c} onCardAction={this.onCardAction}/>
                      </Grid>
                    )
                  })}
                </Grid>
                }
              </div>

              <h1 className={classes.title}>Past Fundraisers</h1>
              <div>
                {this.pastCampaigns &&
                <Grid container className={classes.campaigns} direction="row" spacing={2}>
                  {this.pastCampaigns.map((c: Campaign) => {
                    return (
                      <Grid item md={6} sm={6} xs={12} className={classes.card} key={c.id}>
                        <CampaignCard campaign={c} onCardAction={this.onCardAction}/>
                      </Grid>
                    )
                  })}
                </Grid>
                }
              </div>

              <div className={classes.aboutPanel}>
                <MarginRow>
                  <div>
                    <Grid container className={classes.campaigns} direction="row" spacing={0}>
                      <Grid item md={6} sm={6} xs={12} className={classes.aboutItem}>
                        <h2>Game Motivated</h2>
                        <p>Use the power of games<br/>to make giving<br/>fun and motivating!</p>
                        <img src={AboutBINKO} className={classes.aboutBINKOImage} alt="About BINKO"/>
                      </Grid>
                      <Grid item md={3} sm={6} xs={12} className={classes.aboutItem}>
                        <h2>Team Oriented</h2>
                        <p>Work as a team<br/>or a group of teams to<br/>reach your fundraising goal!</p>
                        <img src={AboutTeams} className={classes.aboutTeamsImage} alt="About Teams"/>
                      </Grid>
                      <Grid item md={6} sm={6} xs={12} className={classes.aboutItem}>
                        <h2>Socially Connected</h2>
                        <p>Integrated Social media<br/>to share your story in a<br/>time of social distancing!</p>
                        <img src={AboutSocial} className={classes.aboutSocialImage} alt="About Social"/>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.learnMore}>
                    <Button className={classes.learnButton} onClick={this.onAbout}>
                      Learn More!
                    </Button>
                  </div>
                </MarginRow>
              </div>

            </div>
          </MarginRow>

        </div>
      </Page>
    )
  }

  onSignup = () => {
    ControlTower.route(Routes.signup)
  }

  onAbout = () => {
    ControlTower.route(Routes.about)
  }

  onCardAction = (campaign: Campaign) => {
    ControlTower.route(`${Routes.campaign}/${campaign.alias}`)
  }

  loadCampaigns = async () => {
    const { campaignStore } = this.props

    let filter: APITypes.ModelCampaignFilterInput | undefined = undefined

    // Only show started or published campaigns
    const today = getISODateToday()
    // Ended >= 6 months ago
    const ended = getISODateTimeFromDate(addMonths(new Date(), -1))
    console.log(`end filter: ${ended}`)

    filter = {
      and: [
        {
          or: [
            { startAt: { le: today } },
            { publishAt: { le: today } },
          ]
        },
        { endAt: { ge: ended }}
      ]
    }

    const campaigns = await campaignStore!.listCampaigns(filter)
    if (campaigns) {
      campaigns.sort((a: Campaign, b: Campaign) => b.startAt.localeCompare(a.startAt))
      this.campaigns = campaigns
    }

    filter = { endAt: { lt: ended } }

    const pastCampaigns = await campaignStore!.listCampaigns(filter)
    if (pastCampaigns) {
      pastCampaigns.sort((a: Campaign, b: Campaign) => b.startAt.localeCompare(a.startAt))
      this.pastCampaigns = pastCampaigns
    }

  }

}
export default withTheme((withStyles(styles)(HomePage)))