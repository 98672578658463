import React from 'react';
import './App.css';

// AWS
import {Amplify, API, Storage} from 'aws-amplify';
import aws_exports from './aws-exports'

// Config
import config from 'react-global-configuration';
import AppConfig from './AppConfig';

// Styles
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

// Routing
import { Router } from '@reach/router'
import HomePage from "./pages/home/HomePage";
import ControlTower, {Routes} from "./components/ControlTower";
import SignUpPage from "./pages/signup/SignUpPage";
import SignInPage from "./pages/signIn/SignInPage";
import CampaignEditPage from "./pages/account/CampaignEditPage";
import SignOutPage from "./pages/SignOutPage";
import AccountPage from "./pages/account/AccountPage";
import CampaignPage from "./pages/campaign/CampaignPage";
import BoardsPage from "./pages/boards/BoardsPage";
import BoardEditPage from "./pages/boards/BoardEditPage";
import BoardPage from "./pages/boards/BoardPage";
import DonationPage from "./pages/donation/DonationPage";
import CampaignSharePage from "./pages/campaign/CampaignSharePage";
import PrivacyPolicyDialog from "./components/page/PrivacyPolicyDialog";
import TermsOfServiceDialog from "./components/page/TermsOfServiceDialog";
import AccountCampaignPage from "./pages/account/AccountCampaignPage";
import NotFoundPage from "./pages/NotFoundPage";
import RegisterPage from "./pages/register/RegisterPage";
import UserEditPage from "./pages/account/UserEditPage";
import AboutPage from "./pages/about/AboutPage";
import AccountEditPage from "./pages/account/AccountEditPage";

config.set(AppConfig);

Amplify.configure(aws_exports);
API.configure(aws_exports)
Storage.configure(aws_exports)

const theme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: 'hsl(200, 100%, 18%)', //'#003D5C'  Dark blue
      paper: 'hsl(200, 100%, 24%)'
    },
    primary: {
      main:   'hsl(20, 75%, 50%)', // #DF6020  Orange
      contrastText: 'rgba(255, 255, 255, 1.0)'
    },
    secondary: {
      main: 'hsl(196, 64%, 43%)', // #278EB4  Light Blue
      contrastText: 'rgba(255, 255, 255, 1.0)' // 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      main: 'hsl(60, 85%, 50%)' // yellow
    },
    action: {
      active: 'hsl(136, 98%, 36%)',
    }
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: 'rgba(255,255,255,0.7)',
        "&$focused": { // Make less opaque than default of 0.7
          color: 'rgba(255,255,255,0.6)',
        },
      }
    },
    MuiCardContent: {
      root: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 4,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
  },
  typography: {
    fontFamily: [
      'Avenir',
      'sans-serif'
    ].join(','),
    /*
    h1: {
      fontFamily: [
        'Archivo Black',
        'sans-serif'
      ].join(','),
      fontWeight: 700
    },
    h2: {
      fontFamily: [
        'Archivo Black',
        'sans-serif'
      ].join(','),
      fontWeight: 400
    },
    h3: {
      fontFamily: [
        'Archivo Black',
        'sans-serif'
      ].join(',')
    },
    h4: {
      fontFamily: [
        'Archivo Black',
        'sans-serif'
      ].join(',')
    },
    h5: {
      fontFamily: [
        'Archivo Black',
        'sans-serif'
      ].join(',')
    },
    button: {
      textTransform: 'initial',
      fontFamily: [
        'Archivo',
        'sans-serif'
      ].join(','),
      fontWeight: 400
    },
    body1: {
      fontFamily: [
        'proxima-nova',
        'sans-serif'
      ].join(',')
    },
    body2: {
      fontFamily: [
        'proxima-nova',
        'sans-serif'
      ].join(',')
    },
  */
  }
})

interface IApp {
}

class App extends React.Component<IApp> {
  render() {

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Router>
          <ControlTower path={Routes.home}>
            <HomePage path={Routes.home}/>
            <AboutPage path={Routes.about}/>
            <SignUpPage path={Routes.signup}/>
            <SignInPage path={Routes.signin}/>
            <SignOutPage path={Routes.signout}/>
            <AccountCampaignPage path={`${Routes.account}/campaign/:campaignId`}/>
            <AccountEditPage path={`${Routes.accountEdit}/:accountId`}/>
            <AccountPage path={Routes.account}/>
            <AccountPage path={`${Routes.account}/:tab`}/>
            <AccountCampaignPage path={`${Routes.account}/campaign/:campaignId`}/>
            <AccountCampaignPage path={`${Routes.account}/campaign/:campaignId/:tab`}/>
            <CampaignEditPage path={`${Routes.campaignEdit}/:campaignId`}/>
            <CampaignEditPage path={Routes.campaignEdit}/>
            <CampaignPage path={`${Routes.campaign}/:campaignId`}/>
            <CampaignPage path={`${Routes.campaign}/:campaignId/:boardId`}/>
            <CampaignSharePage path = {`${Routes.share}/:campaignId`}/>
            <CampaignSharePage path = {`${Routes.share}/:campaignId/:boardId`}/>
            <BoardsPage path={`${Routes.campaign}/:campaignId/boards`}/>
            <BoardEditPage path={`${Routes.campaign}/:campaignId/boardEdit/:boardId`}/>
            <BoardEditPage path={`${Routes.campaign}/:campaignId/boardEdit`}/>
            <BoardEditPage path={`${Routes.account}/campaign/:campaignId/boardEdit/:boardId`}/>
            <BoardEditPage path={`${Routes.account}/campaign/:campaignId/boardEdit`}/>
            <DonationPage path={`${Routes.board}/:boardId/donate`}/>
            <DonationPage path={`${Routes.board}/:boardId/add`}/>
            <DonationPage path={`${Routes.board}/:boardId/:donationId`}/>
            <RegisterPage path={`${Routes.register}/:accountId/:userId`}/>
            <BoardPage path={`${Routes.board}/:boardId`}/>
            <UserEditPage path={`${Routes.account}/userEdit/:userId`}/>
            <UserEditPage path={`${Routes.account}/userEdit`}/>
            <NotFoundPage path={Routes.notFound}/>
          </ControlTower>
        </Router>
        <PrivacyPolicyDialog/>
        <TermsOfServiceDialog/>
      </MuiThemeProvider>
    );
  }

}

export default App;
