import * as React from "react";
import {
  Card, CardActionArea,
  CardContent, CardMedia,
  createStyles, Grid, LinearProgress,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import {makeObservable, observable} from "mobx";
import Campaign from "../../model/Campaign";
import Visible from "../../components/Visible";
import {getISODateToday, isoToLocalDate, numberToMoneyFormat} from "../../stores/StoreUtilities";
import {fade} from "@material-ui/core/styles";
import {format} from "date-fns";
import DialogButton from "../../components/form/DialogButton";

const styles = (theme: Theme) => createStyles({
  card: {
    [theme.breakpoints.down('sm')]: {
      width: 350,
      maxWidth: 350,
      // marginRight: 8
    },
    [theme.breakpoints.up('sm')]: {
      width: "100%",
      maxWidth: 350,
      padding: 0
    },
    height: 520,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    textAlign: "left"
  },
  hashtag: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.secondary.light,
    padding: "4px 8px 0 8px"
  },
  title: {
    fontSize: 18,
    fontWeight: 800,
    maxHeight: 50,
    lineHeight: 1.2,
    overflow: "hidden",
    color: theme.palette.text.primary,
    padding: "0 8px 0 8px"
  },
  titleButtons: {
    textAlign: "right"
  },
  actionArea: {
    color: theme.palette.secondary.main
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    height: 60,
    lineHeight: 1.3,
    overflow: "hidden",
    padding: theme.spacing(1)
  },
  location: {
    color: theme.palette.primary.light,
    fontSize: 16,
    fontWeight: 600,
    padding: "0 8px"
  },
  status: {
    fontSize: 16,
    fontWeight: 400,
    paddingTop: 2,
    paddingLeft: theme.spacing(1),
    textAlign: "left"
  },
  dates: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    textAlign: "center"
  },
  cover: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  mediaArea: {
    display: "flex",
    justifyContent: "center",
  },
  media: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    width: 350,
    height: 262
  },
  image: {
    width: "100%",
    height: "auto"
  },
  editButton: {
    position: "absolute",
    top: 8,
    right: 8
  },
  progressBar: {
    display: "flex",
    justifyContent: "center",
    padding: "12px 6px 2px",
  },
  linearProgressDeterminateColor: {
    backgroundColor: "rgba(255, 255, 255, 0.25)"
  },
  linearProgressColor: {
    backgroundColor: theme.palette.primary.main
  },
  linearProgress: {
    color: theme.palette.primary.main,
    width: "100%",
    height: 4,
  },
  statusBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  playButton: {
    backgroundColor: fade(theme.palette.primary.main, 0.8),
    color: theme.palette.primary.contrastText,
    top: 105,
    width: 150,
    height: 50,
    fontSize: 20,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  actionButtons: {
    padding: theme.spacing(1),
    paddingBottom: 0
  },

})

interface ICampaignCardProps {
  campaign: Campaign
  s3UrlCacheStore?: S3UrlCacheStore
  onCardAction?: any
  onEdit?: any
  previewUrl?: string
}

@inject("s3UrlCacheStore")
@observer
class CampaignCard extends React.Component<WithStyles<typeof styles> & ICampaignCardProps> {

  @observable coverImageUrl?: string

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount() {
    const { s3UrlCacheStore, campaign, previewUrl } = this.props

    if (previewUrl) {
      this.coverImageUrl = previewUrl
    } else if (campaign && campaign.coverUrl) {
      const coverUrl = campaign.coverUrl
      s3UrlCacheStore!.get(coverUrl)
        .then((url: string | undefined) => {
          this.coverImageUrl = url
        })
        .catch((err: Error) => {
          console.log("Error loading cover image")
        })
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.previewUrl !== this.props.previewUrl) {
      this.componentDidMount()
    }
  }

  render() {
    const { classes, campaign, onCardAction, onEdit } = this.props

    let alias = ""
    let title = ""
    let description = ""
    let location = ""
    let status
    let progress = campaign.goalAmount ? (campaign.totalAmount / campaign.goalAmount) * 100.0 : 0
    let dates = ""

    if (campaign) {
      title = campaign.title
      alias = '#' + campaign.alias
      description = campaign.description
      location = (campaign.city && campaign.state) ? `${campaign.city}, ${campaign.state}` : ""
      status = <span><b>{`${numberToMoneyFormat(campaign.totalAmount, 0)} raised`}</b> {` of ${numberToMoneyFormat(campaign.goalAmount, 0)} goal`}</span>

      const today = getISODateToday()
      if (campaign.startAt) {
        if (campaign.startAt > today) {
          const startAtDate = isoToLocalDate(campaign.startAt)
          dates = `Starting ${format(startAtDate, "MMMM d, yyyy")}`
        } else if (campaign.endAt) {
          const endAtDate = isoToLocalDate(campaign.endAt)
          if (campaign.endAt >= today) {
            dates = `Ending ${format(endAtDate, "MMMM d, yyyy")}`
          } else {
            dates = `Ended ${format(endAtDate, "MMMM d, yyyy")}`
          }
        }
      }
    }

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <CardActionArea onClick={this.onCardAction}>
            {this.coverImageUrl &&
              <CardMedia className={classes.media}
                         image={this.coverImageUrl}
                         title={title}>
              </CardMedia>
            }
          </CardActionArea>
          <CardActionArea onClick={this.onCardAction} className={classes.actionArea}>
            <Typography className={classes.hashtag}>
              {alias}
            </Typography>
            <Typography className={classes.title}>
              {title}
            </Typography>
          </CardActionArea>
          <Typography className={classes.location}>
            {location}
          </Typography>
          <Typography className={classes.description}>
            {description}
          </Typography>
          <div className={classes.progressBar}>
            <LinearProgress className={classes.linearProgress}
                            classes={{barColorPrimary: classes.linearProgressColor, determinate: classes.linearProgressDeterminateColor}}
                            variant="determinate" value={progress}/>
          </div>
          <div className={classes.statusBar}>
            <Typography className={classes.status}>
              {status}
            </Typography>
          </div>
          <div className={classes.actionButtons}>
            <Visible if={onEdit !== undefined}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <DialogButton variant="secondary" fullWidth onClick={this.onEdit}>
                    Manage
                  </DialogButton>
                </Grid>
                <Grid item xs={6}>
                  <DialogButton variant="primary" fullWidth onClick={this.onCardAction}>
                    Play!
                  </DialogButton>
                </Grid>
              </Grid>
            </Visible>
            <Visible if={onCardAction !== undefined && onEdit === undefined}>
              <DialogButton variant="primary" fullWidth onClick={this.onCardAction}>
                Play!
              </DialogButton>
            </Visible>
          </div>
          <Typography className={classes.dates}>
            {dates}
          </Typography>
        </CardContent>
      </Card>
    )
  }

  onCardAction = (event: any) => {
    const { onCardAction, campaign } = this.props
    if (onCardAction) {
      onCardAction(campaign)
    }
  }

  onEdit = (event: any) => {
    event.stopPropagation();

    const { onEdit, campaign } = this.props

    if (onEdit) {
      onEdit(campaign)
    }
  }

}

export default withStyles(styles)(CampaignCard)
