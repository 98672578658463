import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import {Button, Grid} from "@material-ui/core";
import ControlTower, {Routes} from "../../components/ControlTower";
import {makeObservable, observable} from "mobx";
import HeroImage from "../../images/Hero.jpg"
import AboutStory from "../../images/AboutStory.png"
import AboutBINKO from "../../images/AboutBINKO.jpg"
import AboutSocial from "../../images/AboutSocial.png"
import AboutTeams from "../../images/AboutTeams.jpg"
import AboutGiving from "../../images/AboutGiving.png"
import SharingImg from "../../images/Sharing.png"
import {observer} from "mobx-react";
import AppName from "../../components/AppName";

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: 'top',
    alignItems: "center",
    width: "100%",
    margin: 0,
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      // maxWidth: 960,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  hero: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: 'top',
    alignItems: "center",
    width: "100%",
    minHeight: 400,
    margin: 0,
    padding: 0,
    textAlign: "center",
    backgroundImage: `url(${HeroImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 25%",
  },
  callToAction: {
    paddingTop: 120,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 200,
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 960,
      justifyContent: "left",
      alignItems: "left",
      textAlign: "left",
      paddingLeft: 40,
    },
    // border: "1px solid yellow"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 0,
    paddingTop: theme.spacing(1)
  },
  slogan: {
    fontFamily: [
      'Nanum Brush Script',
      'cursive'
    ].join(','),
    color: theme.palette.primary.main,
    margin: 0,
    opacity: "0.8",
    textShadow: "2px 2px #000000",
    fontSize: 60,
  },
  sloganEm: {
    color: theme.palette.primary.main,
    margin: 0,
  },
  sloganSub: {
    color: theme.palette.text.secondary,
    margin: 0,
    textShadow: "2px 2px #000000",
  },
  appName: {
    // fontFamily: [
    //   'Nanum Brush Script',
    //   'cursive'
    // ].join(','),
    // color: theme.palette.primary.main,
    // fontSize: 48,
    // fontWeight: 800,
  },
  campaigns: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(1),
  },
  startButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 350,
    height: 50,
    fontSize: 20,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: "center",
  },
  aboutPanel: {
    backgroundColor: "rgba(255, 255, 255, 0.10)",
    marginTop: 36,
    width: "100%",
    paddingBottom:  theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  aboutItem: {
    maxWidth: 250,
    "& p": {
      fontSize: 16,
      color: theme.palette.text.secondary
    },
  },
  aboutStoryImage: {
    height: 210,
    paddingTop: theme.spacing(1)
  },
  aboutBINKOImage: {
    height: 200,
    paddingTop: theme.spacing(1)
  },
  aboutSocialImage: {
    height: 210,
    paddingTop: theme.spacing(1)
  },
  aboutTeamsImage: {
    height: 200,
    paddingTop: theme.spacing(1)
  },
  aboutGivingImage: {
    height: 200,
    paddingTop: theme.spacing(1)
  },
  aboutDetails: {
    "& p": {
      fontSize: 16,
      color: theme.palette.text.secondary,
      textAlign: "left",
      marginBottom: theme.spacing(1),
    },
  },
  secondCall: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
})

interface IAboutPageProps {
}

@observer
class AboutPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAboutPageProps & WithTheme> {

  @observable isLoading = true

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount () {
  }

  render() {
    const { classes } = this.props

    const title = "Learn More"

    return (
      <Page title={title}>
        <div className={classes.root}>
          <div className={classes.hero}>
          </div>
          <div className={classes.content}>
            <h1 className={classes.title}>About <AppName fontSize={32}/></h1>

            <div className={classes.aboutPanel}>
              <MarginRow>
                <div>
                  <Grid container className={classes.campaigns} direction="row" spacing={0}>
                    <Grid item md={3} sm={6} xs={12} className={classes.aboutItem}>
                      <h2>Game Motivated</h2>
                      <p>Use the power of games<br/>to make giving<br/>fun and motivating!</p>
                      <img src={AboutBINKO} className={classes.aboutBINKOImage} alt="About BINKO"/>
                    </Grid>
                    <Grid item md={9} sm={6} xs={12} className={classes.aboutDetails}>
                      <h2>&nbsp;</h2>
                      <p><AppName/> uses familiar games to make your fundraiser fun and unique!
                        Our first game is based on the classic BINGO game. Teams or groups compete to see who can
                        fill all the squares on their board.  You can optionally award prizes
                        to the team that completes a card, gets the most squares filled or other criteria.
                      </p>
                      <p>Each board can be customized with a color scheme and background photo that is uncovered as each
                        square is filled by a donor.  Predefined giving amounts can be configured for each square (or left open) along with
                        donation frequency (Monthly or One Time).
                      </p>
                      <p>
                        More games are in development...
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </MarginRow>
            </div>

            <div className={classes.aboutPanel}>
              <MarginRow>
                <div>
                  <Grid container className={classes.campaigns} direction="row" spacing={0}>
                    <Grid item md={3} sm={6} xs={12} className={classes.aboutItem}>
                      <h2>Team Oriented</h2>
                      <p>Work as a team<br/>or a group of teams to<br/>reach your fundraising goal!</p>
                      <img src={AboutTeams} className={classes.aboutTeamsImage} alt="About Teams"/>
                    </Grid>
                    <Grid item md={9} sm={6} xs={12} className={classes.aboutDetails}>
                      <h2>&nbsp;</h2>
                      <p>Use the power of teamwork to make your fundraiser more successful!</p>
                      <p><AppName/> makes your fundraiser fun and competitive, in a friendly way, through teams.
                        Organizers recruit sponsors (team captains) to create a board to share with their social group,
                        friends and family (team). Sponsors can optionally provide matching up to a limit amount.
                        Sponsors are updated by email when donations are added to their board.
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </MarginRow>
            </div>

            <div className={classes.aboutPanel}>
              <MarginRow>
                <div>
                  <Grid container className={classes.campaigns} direction="row" spacing={0}>
                    <Grid item md={3} sm={6} xs={12} className={classes.aboutItem}>
                      <h2>Socially Connected</h2>
                      <p>Integrated Social media<br/>to share your story in a<br/>time of social distancing!</p>
                      <img src={AboutSocial} className={classes.aboutSocialImage} alt="About Social"/>
                    </Grid>
                    <Grid item md={9} sm={6} xs={12} className={classes.aboutDetails}>
                      <h2>&nbsp;</h2>
                      <p>Sponsors and donors are encouraged to share the game with their friends to broaden your reach!</p>
                      <p>Sharing is built-in for Facebook, Twitter, Email, Text message, WhatsApp, along with a custom link or embed
                        you can use on your own web site or other sharing service.</p>
                      <img src={SharingImg} alt="About Sharing"/>
                    </Grid>
                  </Grid>
                </div>
              </MarginRow>
            </div>

            <div className={classes.aboutPanel}>
              <MarginRow>
                <div>
                  <Grid container className={classes.campaigns} direction="row" spacing={0}>
                    <Grid item md={3} sm={6} xs={12} className={classes.aboutItem}>
                      <h2>Story Focused</h2>
                      <p>Tell your story in video, <br/>pictures and words!</p>
                      <img src={AboutStory} className={classes.aboutStoryImage} alt="About Story"/>
                    </Grid>
                    <Grid item md={9} sm={6} xs={12} className={classes.aboutDetails}>
                      <h2>&nbsp;</h2>
                      <p><AppName/> spotlights your unique story throughout your customizable fundraiser pages.
                        Start with your own video and story text on your fundraiser home page.
                        Customize the game boards with your own photos.
                        Customize the messaging on all the pages.
                      </p>
                      <p><AppName/> also allows your donors to add their names and encouraging messages to your story!
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </MarginRow>
            </div>

            <div className={classes.aboutPanel}>
              <MarginRow>
                <div>
                  <Grid container className={classes.campaigns} direction="row" spacing={0}>
                    <Grid item md={3} sm={6} xs={12} className={classes.aboutItem}>
                      <h2>Giving Maximized</h2>
                      <p>Maximize giving with <br/>no or low fees!</p>
                      <img src={AboutGiving} className={classes.aboutGivingImage} alt="About Giving"/>
                    </Grid>
                    <Grid item md={9} sm={6} xs={12} className={classes.aboutDetails}>
                      <h2>&nbsp;</h2>
                      <p><AppName/> allows you minimize the overhead and maximize your donations.
                        We currently support Young Life Giving or our own Stripe integration.
                      </p>
                      <p><AppName/> is funded through optional tips from donors or 2% of donations if you prefer to not
                        ask your donors for tips. We also provide the option to have donors cover credit card transaction fees
                        (2.9% + $0.30). Contact us regarding pricing if you are using Young Life Giving.
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </MarginRow>
            </div>

            <div className={classes.secondCall}>
              <MarginRow>
                <Button className={classes.startButton} onClick={this.onSignup}>
                  Start a FUNdraiser!
                </Button>
              </MarginRow>
            </div>
          </div>
        </div>
      </Page>
    )
  }

  onSignup = () => {
    ControlTower.route(Routes.signup)
  }

}
export default withTheme((withStyles(styles)(AboutPage)))