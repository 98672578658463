import * as React from 'react'
import {createStyles, Grid, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {makeObservable, observable, when} from "mobx";
import User from "../../model/User";
import Progress from "../../components/Progress";
import AccountCard from "./AccountCard";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    justifyContent: 'top',
    alignItems: 'center',
    width: "100%",
    maxWidth: 960,
    marginTop: theme.spacing(1),
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
})

interface IAccountInfoProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
}

@inject("userStore", "accountStore", "progress")
@observer
class AccountInfo extends React.Component<WithStyles<typeof styles> & IAccountInfoProps & WithTheme> {

  @observable account?: Account
  @observable user?: User
  @observable isLoading = true

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    const { progress } = this.props
    this.isLoading = true
    progress!.show("AccountInfo")
    const { userStore, accountStore} = this.props
    when(
      () => !userStore!.isLoading && !accountStore!.isLoading,
      () => {
        this.account = accountStore!.account
        this.user = userStore!.user
        this.isLoading = false
        progress!.hide("AccountInfo")
      }
    )
  }

  render() {
    const { classes } = this.props

    if (this.isLoading || !this.account) {
      return null
    }

    return (
      <Grid container direction="row" className={classes.root}>
        <Grid item xs={12} sm={6}>
          <AccountCard account={this.account}/>
        </Grid>
      </Grid>
    )
  }

  onEdit = async (account: Account) => {
    // ControlTower.route(`${Routes.accountEdit}/${account.id}`)
  }

}

export default withTheme((withStyles(styles)(AccountInfo)))