import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText, FormControlLabel, FormGroup,
  Grid,
  Paper,
  Theme, Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {Link, RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import DialogButton from "../../components/form/DialogButton";
import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import ControlTower, {Routes} from "../../components/ControlTower";
import Notify from "../../components/notify/Notify";
import UserStore, {UserStoreConstants} from "../../stores/UserStore";
import User from "../../model/User";
import {
  CreateAccountInput,
  CreateUserInput,
  UserRole,
} from "../../API";
import Visible from "../../components/Visible";
import AccountStore from "../../stores/AccountStore";
import ProgressButton from "../../components/form/ProgressButton";
import {createUUID} from "../../stores/StoreUtilities";
import PrivacyPolicy from "../../components/page/PrivacyPolicy";
import TermsOfService from "../../components/page/TermsOfService";
import CheckboxValidator from "../../components/form/CheckboxValidator";
import Tracking from "../../components/Tracking";
import config from 'react-global-configuration';
import Confirm from "../../components/confirm/Confirm";
import StateSelector from "../../components/form/StateSelector";
import FormGroupSpacer from "../../components/form/FormGroupSpacer";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 480,
    height: 40,
    padding: theme.spacing(1),
  },
  message: {
    color: theme.palette.error.main,
    fontSize: 18,
    marginTop: 10
  },
  instructions: {
    color: theme.palette.text.primary,
    fontSize: 16,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  dialogPaper: {
    // backgroundColor: theme.palette.background.paper,
    maxWidth: 480,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dialogContent: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: 4
    }
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  label: {
    color: theme.palette.text.secondary,
    marginLeft: 0,
  },
  termsOfUseText: {
    // color: '#252525',
    color: theme.palette.text.secondary,
    fontSize: '13px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 36,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 22,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 36,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 40,
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.action.active
  },
  formGroupRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  formGroupField: {
    flexGrow: 1,
  },
  stateSelector: {
    minWidth: 60,
    paddingTop: 5,
  },
  postalCodeField: {
    flexGrow: 0,
    width: 100,
  },

})

interface ISignUpPageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  notify?: Notify
  confirm?: Confirm
  privacyPolicy?: PrivacyPolicy
  termsOfService?: TermsOfService
  // promo?: string
}

enum SignupStep {
  Organization,
  Contact,
  Verification
}

@inject("userStore", "accountStore", "notify", "confirm", "privacyPolicy", "termsOfService")
@observer
class SignUpPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ISignUpPageProps & WithTheme> {

  @observable values = {
    accountType: "Agency",
    accountName: "",
    orgType: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    webSite: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    about: "",
    userId: "",
    accountId: "",
    role: UserRole.Owner,
    confirmationCode: "",
    termsCheckbox: false,
  }
  @observable step = SignupStep.Organization
  @observable user?: User
  @observable isProcessing: boolean = false
  @observable message = ""

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    this.values.userId = createUUID()
    this.values.accountId = createUUID()
  }

  render() {
    const { classes, accountStore } = this.props

    const title = "FunGives Sign Up"

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container className={classes.root} direction="column">
            <Paper className={classes.dialogPaper}>
              <TitleBar title={title} className={classes.titleBar}>
              </TitleBar>
              <Visible if={true}>
                <p className={classes.instructions}>We are currently in beta and
                  accepting new sign ups.  If you are interested in using FunGives, please complete the form below
                  and we will notify you when your account is activated and ready to use.
                </p>
                <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="signupForm" id="signupForm">
                <DialogContent className={classes.dialogContent}>
                  <Visible if={this.step === SignupStep.Organization}>
                    <TextFieldValidator
                      autoFocus
                      margin="dense"
                      name="accountName"
                      label="Organization Name *"
                      type="text"
                      variant="standard"
                      validators={{required: true}}
                      onChange={this.onChange}
                      value={this.values.accountName}
                      fullWidth
                      placeholder=""
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="orgType"
                      label="Organization Type *"
                      variant="standard"
                      validators={{required:false}}
                      onChange={this.onChange}
                      fullWidth
                      autocompleteOptions={{
                        value: this.values.orgType,
                        options: accountStore!.getOrgTypes()
                      }}
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="address"
                      label="Address"
                      type="text"
                      validators={{required:false}}
                      onChange={this.onChange}
                      fullWidth
                      value={this.values.address}
                      helperText="Optional"
                    />
                    <FormGroup row classes={{row: classes.formGroupRow}}>
                      <TextFieldValidator
                        margin="dense"
                        name="city"
                        label="City"
                        type="text"
                        validators={{required:true}}
                        required
                        onChange={this.onChange}
                        value={this.values.city}
                        className={classes.formGroupField}
                      />
                      <FormGroupSpacer/>
                      <StateSelector
                        value={this.values.state}
                        onChange={this.onChange}
                        className={classes.stateSelector}
                      />
                      <FormGroupSpacer/>
                      <TextFieldValidator
                        margin="dense"
                        name="postalCode"
                        label="ZIP"
                        type="text"
                        validators={{required:true, isPostalCode:null}}
                        required
                        onChange={this.onChange}
                        value={this.values.postalCode}
                        className={classes.postalCodeField}
                      />
                    </FormGroup>
                    <TextFieldValidator
                      margin="dense"
                      name="webSite"
                      label="Web Site"
                      type="text"
                      variant="standard"
                      validators={{required:false}}
                      onChange={this.onChange}
                      value={this.values.webSite}
                      fullWidth
                      helperText="Optional"
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="about"
                      label="Tell us About Your Fundraiser *"
                      type="text"
                      variant="standard"
                      validators={{required: true}}
                      onChange={this.onChange}
                      value={this.values.about}
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Visible>
                  <Visible if={this.step === SignupStep.Contact}>
                  <TextFieldValidator
                      margin="dense"
                      name="firstName"
                      label="Contact First Name"
                      type="text"
                      variant="standard"
                      validators={{required:true}}
                      onChange={this.onChange}
                      value={this.values.firstName}
                      fullWidth
                      placeholder=""
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="lastName"
                      label="Contact Last Name"
                      type="text"
                      variant="standard"
                      validators={{required:true}}
                      onChange={this.onChange}
                      value={this.values.lastName}
                      fullWidth
                      placeholder=""
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="email"
                      id="EmailInput"
                      label="Email"
                      variant="standard"
                      placeholder=""
                      type="text"
                      validators={{required:true, isEmail: true}}
                      value={this.values.email}
                      onChange={this.onChange}
                      fullWidth
                      autoCorrect="off"
                      autoCapitalize="off"
                      autoComplete="on"
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="password"
                      label="Password"
                      type="password"
                      variant="standard"
                      validators={{required:true, isStrongPassword:3}}
                      value={this.values.password}
                      onChange={this.onChange}
                      fullWidth
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="phone"
                      label="Phone Number"
                      type="text"
                      variant="standard"
                      validators={{isMobilePhone:null, required:true}}
                      onChange={this.onChange}
                      value={this.values.phone}
                      fullWidth
                    />
                    <FormControlLabel style={{marginRight: 0}}
                                      control={
                                        <CheckboxValidator name="termsCheckbox" color="secondary" checked={this.values.termsCheckbox} value="checkedG" onChange={this.onChange}/>
                                      }
                                      label={
                                        <Typography className={ classes.termsOfUseText }>
                                          I agree with the <Link to="." onClick={this.onClickTermsOfService} className={classes.link}>Terms of Use</Link> and <Link to="." onClick={this.onClickPrivacyPolicy} className={classes.link}>Privacy Policy</Link>.&nbsp;&nbsp;
                                          I give permission to communicate with me via email, text or phone.&nbsp;&nbsp;
                                        </Typography>
                                      }
                    />
                  </Visible>

                  <DialogContentText className={classes.message}>
                    {this.message}
                  </DialogContentText>

                  <Visible if={this.step === SignupStep.Verification}>
                    <Grid item className={classes.message}>
                      <Typography variant="body2" className={classes.instructions}>
                        Please check your email for a verification code.
                      </Typography>
                    </Grid>
                      <TextFieldValidator
                        margin="dense"
                        name="confirmationCode"
                        label="Verification Code"
                        type="text"
                        variant="standard"
                        value={this.values.confirmationCode}
                        validators={{required:true, matches:"^\\d{6}$"}}
                        onChange={this.onChange}
                        fullWidth
                      />
                    <DialogButton variant="text" onClick={this.onResendCode}>
                      Resend verification code
                    </DialogButton>
                  </Visible>

                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12} >
                      <ProgressButton variant="contained" color="primary" fullWidth
                                      type="submit" processing={this.isProcessing}>
                        { this.step === SignupStep.Verification ? "Verify" : "Next" }
                      </ProgressButton>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <DialogButton variant="secondary" fullWidth onClick={this.onCancel}>
                        Cancel
                      </DialogButton>
                    </Grid>
                  </Grid>
                </DialogActions>
              </FormValidator>
              </Visible>
            </Paper>
          </Grid>
        </MarginRow>
      </Page>
    )
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    if (name === "termsCheckbox") {
      this.values![name] = event.target.checked
    } else  {
      this.values[name] = event.target.value
    }
  }

  onCancel() {
    ControlTower.back()
  }

  onSubmit = async () => {
    const {userStore, accountStore, notify, confirm} = this.props
    const values = this.values
    const env = config.get("env")

    if (this.step === SignupStep.Organization) {
      // TODO: Verify fields
      this.step = SignupStep.Contact
    } else if (this.step === SignupStep.Contact) {
      // Sign-up user
      this.isProcessing = true

      if (!values.termsCheckbox) {
        notify!.show("error", "You must check the Terms box to indicate you agree with the Terms of Use and Privacy Policy.")
        return
      }

      const signupResult = await userStore!.signUp(values.userId, values.password, values.email.toLowerCase(), values.phone,
        values.accountId, values.role)
        .catch((err: Error) => {
          let message
          if (err.name === UserStoreConstants.USERNAME_EXISTS_EXCEPTION || err.message === UserStoreConstants.EMAIL_EXISTS_MESSAGE) {
            message = "This email is already in use."
            Tracking.event({action: 'SignupError', label:"Email in use"})
          } else {
            message = `Unable to create user. ${err.message}`
            Tracking.event({action: 'SignupError', label: `Unable to create user: ${err.message}`})
          }
          notify!.show("error", message)
          this.isProcessing = false
        })
      if (signupResult) {
        this.step = SignupStep.Verification
      }
      this.isProcessing = false
    } else if (this.step === SignupStep.Verification) {
      this.isProcessing = true

      const confirmation = await userStore!.confirmSignUp(values.userId, values.confirmationCode)
        .catch((err: Error) => {
          notify!.show("error", "Unable to verify account")
          Tracking.event({action: 'SignupError', label: `Unable to verify account: ${err.message}`})
          this.isProcessing = false
        })

      if (confirmation === "SUCCESS") {
        // Sign in
        let user = await userStore!.signIn(values.userId, values.password)
          .catch((err: Error) => {
            if (err.message !== UserStoreConstants.USER_NOT_FOUND) {
              notify!.show("error", "Unable to log in to account")
              Tracking.event({action: 'SignupError', label: `Unable to log into account: ${err.message}`})
              this.isProcessing = false
            }
          })

        if (!user) {
          // Create user
          const createUserInput: CreateUserInput = {
            id: values.userId,
            active: true,
            accountId: values.accountId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email.toLowerCase(),
            phone: values.phone,
            role: UserRole.Owner,
          }

          user = await userStore!.createUser(createUserInput)
            .catch((err: Error) => {
              notify!.show("error", "Unable to create user")
              Tracking.event({action: 'SignupError', label: `Unable to create user: ${err.message}`})
              this.isProcessing = false
            })

          if (user) {
            // Create account
            const createAccountInput: CreateAccountInput = {
              id: values.accountId,
              ownerId: user.id,
              name: values.accountName,
              orgType: values.orgType,
              about: values.about,
              address: values.address,
              city: values.city,
              state: values.state,
              postalCode: values.postalCode,
              webSite: values.webSite,
              active: true,
            }

            const account = await accountStore!.createAccount(createAccountInput)
              .catch((err: Error) => {
                notify!.show("error", "Unable to create account")
                Tracking.event({action: 'SignupError', label: `Unable to create account: ${err.message}`})
                this.isProcessing = false
              })

            // if (env === "main") {
            //   this.isProcessing = false
            //   confirm!.show("Thank You!", "You will be notified by email when your account is activated and ready for use!" , ["Ok"],
            //     async (): Promise<any> => {
            //       this.isProcessing = false
            //       await userStore!.signOut()
            //       ControlTower.route(Routes.home) // TODO: Change to create campaign
            //       return true;
            //     });
            // } else {
              if (account) {
                if (user) {
                  // Sign in again to get updated attributes and groups
                  user = await userStore!.signIn(values.email.toLowerCase(), values.password)
                    .catch((err: Error) => {
                      notify!.show("error", "Unable to sign in new user")
                      Tracking.event({action: 'SignupError', label: `Unable to sign in new user: ${err.message}`})
                      this.isProcessing = false
                    })

                  if (user) {
                    this.user = user
                    // userStore!.createActivity(ActivityType.AccountCreate, account.id)
                    notify!.show("success", "Welcome to FunGives!")
                    Tracking.event({action: 'SignupSuccess'})
                    ControlTower.route(Routes.campaignEdit)
                  }
                }
              }
            // }

            this.isProcessing = false

            }

          }
        }
      }
    }

  onResendCode = () => {
    const { userStore, notify} = this.props

    userStore!.resendSignUp(this.values.userId)
      .then((result: any) => {
        notify!.show("success", "Verification code resent")
        Tracking.event({action: 'SignupResendCode'})
      })
      .catch((err: Error) => {
        notify!.show("error", "Unable to resend verification code")
        Tracking.event({action: 'SignupError', label: `Unable to resend verification code: ${err.message}`})
      })
  }

  onClickPrivacyPolicy = () => {
    const { privacyPolicy } = this.props
    if (privacyPolicy) {
      privacyPolicy.show()
    }
  }

  onClickTermsOfService = () => {
    const { termsOfService } = this.props
    if (termsOfService) {
      termsOfService.show()
    }
  }

  onBack = () => {
    ControlTower.route(Routes.home)
  }

}

export default withTheme((withStyles(styles)(SignUpPage)))