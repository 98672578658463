import User from "./User";
import Campaign from "./Campaign";
import {PaymentProvider} from "../API";


export class Account {
  id: string
  createdAt: string
  updatedAt: string
  ownerId: string
  name: string
  about: string
  address: string
  city: string
  state: string
  postalCode: string
  orgType: string
  webSite: string
  stripeId: string
  paymentProvider: PaymentProvider
  paymentOptions: any
  active: boolean
  users: User[]
  campaigns: Campaign[]

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.ownerId = data.ownerId
    this.name = data.name
    this.about = data.about
    this.address = data.address
    this.city = data.city
    this.state = data.state
    this.postalCode = data.postalCode
    this.orgType = data.orgType
    this.webSite = data.webSite
    this.stripeId = data.stripeId
    this.paymentProvider = data.paymentProvider ? PaymentProvider[data.paymentProvider] : undefined
    this.paymentOptions = data.paymentOptions ? JSON.parse(data.paymentOptions) : undefined
    this.active = data.active
    this.users = []
    if (data.users && data.users.items) {
      this.loadUsers(data.users.items)
    }
    this.campaigns = []
    if (data.campaigns && data.campaigns.items) {
      this.loadCampaigns(data.campaigns.items)
    }
  }

  loadUsers(items: any[]) {
    this.users = items.map((item: any) => new User(item))
  }

  loadCampaigns(items: any[]) {
    this.campaigns = items.map((item: any) => new Campaign(item))
  }
}

export default Account