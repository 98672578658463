import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles, DialogActions, DialogContent, DialogContentText, FormControlLabel,
  Grid,
  Paper, Radio, RadioGroup,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import {inject, observer} from "mobx-react";
import AccountStore from "../../stores/AccountStore";
import {makeObservable, observable, when} from "mobx";
import Progress from "../../components/Progress";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import DialogButton from "../../components/form/DialogButton";
import ControlTower, {Routes} from "../../components/ControlTower";
import {
  CreateUserInput,
  UpdateUserInput,
  UserRole,
} from "../../API";
import Notify from "../../components/notify/Notify";
import User from "../../model/User";
import UserStore from "../../stores/UserStore";
import Tracking from "../../components/Tracking";
import Visible from "../../components/Visible";
import PasswordChangeDialog from "../../components/auth/PasswordChangeDialog";
import EmailChangeDialog from "../../components/auth/EmailChangeDialog";
import Confirm from "../../components/confirm/Confirm";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  message: {
    color: theme.palette.error.main,
    marginTop: 10
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: 4
    }
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
  checkboxLabel: {
    color: theme.palette.text.secondary,
    marginLeft: 0,
  },
  resendButton: {
    color: theme.palette.secondary.light,
    fontSize: 16,
    paddingLeft: 0,
  },
  radioGroup: {
    margin: 0,
    padding: 0
  }
})

interface IUserEditPageProps {
  userId?: string
  accountStore?: AccountStore
  userStore?: UserStore
  progress?: Progress
  notify?: Notify
  confirm?: Confirm
  location?: any
}

@inject("accountStore", "userStore", "progress", "notify", "confirm")
@observer
class UserEditPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IUserEditPageProps & WithTheme> {

  @observable isLoading = true
  @observable user?: User
  @observable values = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    userRole: "Owner",
    active: false
  }
  @observable message = ""
  @observable showPasswordChange = false
  @observable showEmailChange = false
  private classId?: any

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    const { userId, userStore, accountStore, progress} = this.props
    this.isLoading = true
    when(
      () => !userStore!.isLoading,
      async () => {
        // Get classId query param
        this.classId = new URLSearchParams(this.props.location.search).get("classId")
        console.log(`classId = ${this.classId}`)

        if (userId) {
          progress!.show("UserEditPage")
          this.user = await accountStore!.getUser(userId!)
          if (this.user) {
            this.values = {
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              email: this.user.email,
              phone: this.user.phone,
              userRole: this.user.isOwner ? "Owner" : (this.user.isAdmin ? "Admin" : "Sponsor"),
              active: this.user.active
            }
          }
          this.isLoading = false
          progress!.hide("UserEditPage")
        } else {
          this.isLoading = false
          progress!.hide("UserEditPage")
        }
      }
    )

  }


  render() {
    const { classes, userStore, userId } = this.props

    const title = this.user ? "Edit Person" : "Add Person"
    const isAdmin = userStore!.isAdmin
    const isOwner = isAdmin || userStore!.isOwner
    const roleDisabled = (userId !== undefined)

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container className={classes.root} direction="column">
            <TitleBar title={title} className={classes.titleBar}>
            </TitleBar>
            <Paper className={classes.dialogPaper}>
              <FormValidator onSubmit={this.onSubmit} autoComplete="off"
                             name="UserEditForm" id="UserEditForm" className={classes.form}>
                <DialogContent className={classes.dialogContent}>
                  <TextFieldValidator
                    autoFocus
                    margin="dense"
                    name="firstName"
                    label="First Name"
                    type="text"
                    variant="standard"
                    validators={{required:true}}
                    onChange={this.onChange}
                    value={this.values.firstName}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="lastName"
                    label="Last Name"
                    type="text"
                    variant="standard"
                    validators={{required:true}}
                    onChange={this.onChange}
                    value={this.values.lastName}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="email"
                    id="EmailInput"
                    label="Email"
                    variant="standard"
                    placeholder=""
                    type="text"
                    validators={{required:true, isEmail: true}}
                    value={this.values.email}
                    onChange={this.onChange}
                    fullWidth
                    autoCorrect="off"
                    autoCapitalize="off"
                    autoComplete="on"
                    disabled={this.user !== undefined}
                  />
                  { this.user && !this.user.active &&
                    <DialogButton variant="text" onClick={this.onResendInvite} customClassName={classes.resendButton}>
                      Resend Invitation
                    </DialogButton>
                  }
                  { this.user && this.user.id === userStore!.user!.id &&
                    <DialogButton variant="text" onClick={this.onChangeEmailOpen} customClassName={classes.resendButton}>
                      Change Email &nbsp;&nbsp;&nbsp;&nbsp;
                    </DialogButton>
                  }
                  { this.user && this.user.id === userStore!.user!.id &&
                    <DialogButton variant="text" onClick={this.onChangePasswordOpen} customClassName={classes.resendButton}>
                      Change Password
                    </DialogButton>
                  }
                  <TextFieldValidator
                    margin="dense"
                    name="phone"
                    label="Phone Number"
                    type="text"
                    variant="standard"
                    validators={{isMobilePhone:null, required:true}}
                    onChange={this.onChange}
                    value={this.values.phone}
                    fullWidth
                  />
                  <Visible if={isOwner}>
                    <div className={classes.label}>Role</div>
                    <RadioGroup aria-label="userRole" name="userRole" value={this.values.userRole} onChange={this.onChange} row>
                      <FormControlLabel
                        value="Sponsor"
                        control={<Radio color="secondary" disabled={roleDisabled}/>}
                        label="Sponsor"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Owner"
                        control={<Radio color="secondary"  disabled={roleDisabled}/>}
                        label="Owner"
                        labelPlacement="end"
                      />
                      {isAdmin &&
                        <FormControlLabel
                          value="Admin"
                          control={<Radio color="secondary"  disabled={roleDisabled}/>}
                          label="Admin"
                          labelPlacement="end"
                        />
                      }
                    </RadioGroup>

                    { this.user && !this.user.active && isOwner &&
                      <DialogButton variant="text" onClick={this.onDelete} customClassName={classes.resendButton}>
                        Delete Person
                      </DialogButton>
                    }
                  </Visible>

                  <DialogContentText className={classes.message}>
                    {this.message}
                  </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <DialogButton variant="primary" fullWidth>
                    Save
                  </DialogButton>
                  <DialogButton variant="tertiary" fullWidth onClick={this.onCancel}>
                    Cancel
                  </DialogButton>
                </DialogActions>
              </FormValidator>
            </Paper>
          </Grid>

          {this.showEmailChange &&
            <EmailChangeDialog oldEmail={this.values.email} onClose={this.onEmailChangeClose} onChange={this.onEmailChange}/>
          }
          {this.showPasswordChange &&
            <PasswordChangeDialog onClose={this.onPasswordChangeClose}/>
          }
        </MarginRow>
      </Page>
    )
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    if (name === "sendInvite") {
      this.values![name] = event.target.checked
    } else {
      this.values[name] = event.target.value
    }
  }

  onResendInvite = async () => {
    const { accountStore, notify } = this.props
    if (!this.user || this.user.active) {
      return
    }

    // Set Active to true then false to cause resend
    const update: UpdateUserInput = {
      id: this.user!.id,
      active: true
    }

    let user = await accountStore!.updateUser(update)
      .catch((err: Error) => {
        notify!.show("error", err.message)
      })

    if (user) {
      update.active = false
      user = await accountStore!.updateUser(update)
        .catch((err: Error) => {
          notify!.show("error", err.message)
        })

      if (user) {
        this.user = user
        notify!.show("success", "Email invitation sent!")
      }
    }
  }

  onChangeEmailOpen = () => {
    this.showEmailChange = true
  }

  onEmailChange = (newEmail: string) => {
    this.values.email = newEmail
    this.showEmailChange = false
    this.updateEmail()
  }

  onEmailChangeClose = () => {
    this.showEmailChange = false
  }

  onChangePasswordOpen = () => {
    this.showPasswordChange = true
  }

  onPasswordChangeClose = () => {
    this.showPasswordChange = false
  }

  onDelete = async () => {
    const { accountStore, confirm, notify } = this.props

    if (this.user) {
      confirm!.show("Confirm Delete",
        `Please confirm you want to delete ${this.user!.fullName}`,
        ["Confirm", "Cancel"],
        async () => {
          const user = await accountStore!.deleteUser(this.user!.id)
            .catch((err: Error) => {
              notify!.show("error", "Error deleting person")
            })
          if (user) {
            this.onCancel()
            return true
          } else {
            return false
          }
        },
        () => {
          // Do nothing
        })
    }
  }

  onCancel = () => {
    ControlTower.route(`${Routes.account}/people`)
  }

  onSubmit = async () => {
    const { userId, accountStore, userStore, notify } = this.props

    const values = this.values

    if (userId) {
      Tracking.event({action: 'UpdateUser'})
      const input: UpdateUserInput = {
        id: userId!,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email.toLowerCase(),
        phone: values.phone,
        role: UserRole.Sponsor,
        active: values.active
      }
      switch (values.userRole) {
        case "Owner":
          input.role = UserRole.Owner
          break
        case "Admin":
          input.role = UserRole.Admin
          break
      }

      let user = undefined
      if (userId === userStore!.user!.id) {
        user = await userStore!.updateUser(input)
          .catch((err: Error) => {
            notify!.show("error", "Unable to update user")
          })
        if (user) {
          accountStore!.updateUserCache(user)
        }
      } else {
        user = await accountStore!.updateUser(input)
          .catch((err: Error) => {
            notify!.show("error", "Unable to update user")
          })
      }

      if (user) {
        notify!.show("success", "User updated!")

        this.onCancel()
      }
    } else {
      // New user
      Tracking.event({action: 'CreateUser'})
      const input: CreateUserInput = {
        accountId: accountStore!.account!.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email.toLowerCase(),
        phone: values.phone,
        active: values.active,
        role: UserRole[values.userRole]
      }

      const user = await accountStore!.createUser(input)
        .catch((err: Error) => {
          notify!.show("error", err.message)
        })

      if (user) {
        notify!.show("success", "User added!")
        // userStore!.createActivity(ActivityType.UserCreate, user.id)
        // Add registrations

        this.onCancel()
      }
    }

  }

  private updateEmail = async () => {
    const { userId, userStore, notify } = this.props

    const input: UpdateUserInput = {
      id: userId!,
      email: this.values.email.toLowerCase(),
    }

    await userStore!.updateUser(input)
        .catch((err: Error) => {
          notify!.show("error", "Unable to update user email")
        })
  }
}

export default withTheme((withStyles(styles)(UserEditPage)))