import * as React from "react";
import {navigate, RouteComponentProps} from "@reach/router";
import {inject} from "mobx-react";
import NotFoundPage from "../pages/NotFoundPage";
import SignInPage from "../pages/signIn/SignInPage";
import UserStore from "../stores/UserStore";
import LoadingPage from "../pages/LoadingPage";
import Tracking from "./Tracking";
import AccountStore from "../stores/AccountStore";

export const Routes = {
  index: "/index.html",
  notFound: "/404",
  signin: "/signin",
  signout: "/signout",
  signup: "/signup",
  accountCampaigns: "/account/campaigns",
  accountUsers: "/account/users",
  account: "/account",
  accountEdit: "/accountEdit",
  campaignEdit: "/campaignEdit",
  campaign: "/campaign",
  board: "/board",
  share: "/share",
  register: "/register",
  about: "/about",
  home: "/", // Must be last
}

/**
 * undefined -> allows unauthenticated access
 * isAuthenticated -> requires authentication
 * isOwner -> requires owner role
 * isAdmin -> requires admin role
 */
const RouteRequirements = {
  account: { isAuthenticated: true, isOwner: true },
  accountEdit: { isAuthenticated: true, isOwner: true },
  accountCampaigns: { isAuthenticated: true, isOwner: true },
  accountUsers: { isAuthenticated: true, isOwner: true },
  campaignEdit: { isAuthenticated: true, isOwner: true },
}

interface IControlTowerProps {
  userStore?: UserStore
  accountStore?: AccountStore
  children?: any
}

@inject("userStore", "accountStore")
class ControlTower extends React.Component<IControlTowerProps & RouteComponentProps> {

  private lastNavigatedRoute = "";

  static route = (to: string, options?: any) => {
    if (to.startsWith("http")) {
      window.location.href = to
    } else {
      navigate(to, options)
    }
  }

  static back = () => {
    window.history.back()
  }

  static open = (to: string, target: string = "_blank") => {
    window.open(to, target)
  }

  static get currentRoute(): string {
    return window.location.pathname
  }

  render() {
    const route = "/" + this.props["*"]

    const { userStore } = this.props

   if (userStore && userStore!.isLoading) {
      console.log(`Routing ${route} to LoadingPage`)
      return <LoadingPage userStore={userStore} route={route}/>
    } else if (userStore && !userStore.isAuthenticated && this.routeRequiresLogin(route)) {
      console.log(`Routing ${route} to SignInPage for authentication`)
      return <SignInPage route={route}/>
    } else if (userStore && this.routeRequiresAdmin(route) && !userStore.isAdmin) {
      console.log(`Route ${route} requires admin`)
      return <NotFoundPage/>
   } else if (userStore && this.routeRequiresOwner(route) && !userStore.isOwner) {
     console.log(`Route ${route} requires owner`)
     return <NotFoundPage/>
    } else if (this.routeExists(route)) {
      // this.loadAuthenticatedUser()
      console.log(`Routing to ${route}`)
      this.lastNavigatedRoute = route;
      Tracking.pageView(route)
      return (<React.Fragment>{this.props.children}</React.Fragment>)
    } else {
      console.log(`Route ${route} not found`)
      this.lastNavigatedRoute = Routes.notFound;
      return <NotFoundPage/>
    }
  }

  private routeExists= (route: string): boolean => {
    if (route === Routes.home) {
      return true
    } else {
      const routeKey = Object.keys(Routes).find(key => route.startsWith(Routes[key]))
      if (routeKey) {
          return true
      }
    }

    return false
  }

  private routeRequiresLogin = (route: string): boolean => {
    // Check if the route has any requirements
    const routeKey = Object.keys(Routes).find(key => route.startsWith(Routes[key]))
    if (routeKey) {
      const routeRequirements = RouteRequirements[routeKey]
      if (routeRequirements) {
        // console.log(`${route} requires login`)
        return true
      }
    }

    return false
  }

  private routeRequiresAdmin = (route: string): boolean => {
    // Check if the route has any requirements
    const routeKey = Object.keys(Routes).find(key => route.startsWith(Routes[key]))
    if (routeKey) {
      const routeRequirements = RouteRequirements[routeKey]
      if (routeRequirements && routeRequirements.isAdmin) {
        return true
      }
    }

    return false
  }

  private routeRequiresOwner = (route: string): boolean => {
    // Check if the route has any requirements
    const routeKey = Object.keys(Routes).find(key => route.startsWith(Routes[key]))
    if (routeKey) {
      const routeRequirements = RouteRequirements[routeKey]
      if (routeRequirements && routeRequirements.isOwner) {
        return true
      }
    }

    return false
  }

  private loadAuthenticatedUser = async () => {
    const { userStore, accountStore } = this.props

    const cognitoUser = await userStore!.currentAuthenticatedUser()
    if (cognitoUser) {
      console.log("initializing current user")
      // Load and initialize User
      await userStore!.initSession(cognitoUser)
        .then(async (result: any) => {
          console.log("Reloaded user from cache")
          const account = userStore!.user!.account
          if (!account) {
            await accountStore!.loadAccount(userStore!.user!.accountId)
          }

        })
        .catch(async (reason: any) => {
          console.log("Unable to load user from cache")
        })
    }
  }
}

export default ControlTower