import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { RouteComponentProps } from '@reach/router'
import DialogButton from '../form/DialogButton'
import {inject, observer} from "mobx-react";
import Alert from "./Alert";

const styles = (theme: Theme) => createStyles({
  dialogActions: {
    justifyContent: "center",
    paddingBottom: "16px"
  }
})

interface IAlertDialog {
  alert?: Alert
}

@inject("alert")
@observer
class AlertDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAlertDialog> {

  onClose = () => {
    if (this.props.alert) {
      this.props.alert.close();
    }
  };

  onSubmit = (event: any) => {
    if (this.props.alert) {
      this.props.alert.close()
    }
  }

  render() {
    const { classes, alert } = this.props

    let dialog = null
    if (alert && alert.open) {
      dialog = <Dialog
        id="alertDialog"
        open={alert.open}
        onClose={this.onClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-title">
        <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {alert.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <DialogButton variant="primary" onClick={this.onSubmit}>
            Ok
          </DialogButton>
        </DialogActions>
      </Dialog>
    }

    return (dialog)
  }
}

export default withStyles(styles)(AlertDialog)
