import React from "react";
import {
  Button,
  Collapse,
  createStyles, List,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  WithStyles,
  WithTheme
} from "@material-ui/core";
import {withStyles, withTheme} from "@material-ui/core/styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuHeading from "./MenuHeading";
import {ExpandLess, ExpandMore} from "@material-ui/icons";

const styles = (theme: Theme) => createStyles({
  container : {
    display: "inline-block"
  },
  containedButton: {
    padding: "2px 0px 2px 4px"
  },
  buttonLabel: {
    paddingLeft: 4,
    paddingRight: 4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  menuHeading: {
    fontWeight: 800,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 8,
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.action.active
    },
  },
  menuRootItem: {
    paddingTop: 3,
    paddingBottom: 3,
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.action.active
    },
  },
  menuItem: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 24,
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.action.active
    },
    // '&:focus': {
    //   color: '#fff',
    //   backgroundColor: theme.palette.action.active
    // },
    // '&:active': {
    //   color: '#fff',
    //   backgroundColor: theme.palette.action.active
    // }
  },
  listItemText: {
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.action.active
    },
    '&:focus': {
      color: '#fff',
      backgroundColor: theme.palette.action.active
    },
    '&:active': {
      color: '#fff',
      backgroundColor: theme.palette.action.active
    }
  }

})

export interface IMenuButtonProps {
  color?: "inherit" | "default" | "primary" | "secondary" | undefined
  className?: string
  value?: string
  children?: any
  disabled?: boolean
}

export class MenuButton extends React.Component<WithStyles<typeof styles> & WithTheme & IMenuButtonProps> {
  state = {
    anchorEl: null,
    expandedHeading: null
  }

  render() {
    const { color, className, classes, value, children, disabled } = this.props
    const useColor = color === undefined ? "primary" : color

    return (
      <div className={classes.container}>
        <Button variant="contained" size="small" color={useColor}
                className={className}
                classes={{contained: classes.containedButton, label: classes.buttonLabel}}
                onClick={this.onClick}
                disabled={disabled !== undefined ? disabled : false}
        >
          {value} <ArrowDropDownIcon />
        </Button>
        <Menu
          keepMounted
          open={Boolean(this.state.anchorEl)}
          disableBackdropClick={false}
          onClose={() => this.setState({anchorEl: null}) }
          anchorEl={this.state.anchorEl}
          style={{ marginTop: 40 }}>
          {children.map((item: any, i: number) => {
            if (item.type === MenuItem) {
              return <MenuItem key={i}
                               className={ classes.menuRootItem }
                               onClick={() => {this.onClickMenuItem(item)}}
              >
                {item.props.children}
              </MenuItem>
            } else if (item.type === MenuHeading) {
              if (item.props.children) {
                return (
                  <div key={i}>
                    <MenuItem
                      onClick={() => this.onClickMenuHeading(item)}
                      className={ classes.menuHeading }
                    >
                      <ListItemText
                        primary={item.props.label}
                        primaryTypographyProps={{ color: 'inherit', variant: 'h6' }}
                        className={ classes.menuHeading }
                      />
                      {this.isExpandedMenuHeading(item) ? <ExpandLess /> : <ExpandMore />}
                    </MenuItem>
                    <Collapse
                      in={this.isExpandedMenuHeading(item)}
                      timeout="auto"
                      unmountOnExit
                      key={`${i}c`}
                    >
                      <List component="span" disablePadding>
                        {item.props.children.map((child: any, j: number) => {
                          if (child.type === MenuItem) {
                            return <MenuItem key={j}
                                             className={ classes.menuItem }
                                             onClick={() => {this.onClickMenuItem(child)}}>
                              {child.props.children}
                            </MenuItem>
                          } else {
                            return null
                          }
                        })}
                      </List>
                    </Collapse>
                  </div>
                )
              } else {
                return (
                  <MenuItem
                    className={ classes.menuHeading }
                  >
                    <ListItemText
                      primary={item.props.label}
                      primaryTypographyProps={{ color: 'inherit', variant: 'h5' }}
                      className={ classes.menuHeading }
                    />
                  </MenuItem>
                  )
              }
            } else {
              return null
            }})
          }
        </Menu>
      </div>
    )
  }

  onClick = (e: React.MouseEvent) => {
    this.setState({ anchorEl: e.currentTarget})
  }

  onClickMenuItem = (item: any) => {
    this.setState({anchorEl: null})
    if (item.props.onClick) {
      item.props.onClick()
    }
  }

  onClickMenuHeading = (heading: any) => {
    console.log(`onClickMenuHeading(${heading.props.label})`)
    if (this.isExpandedMenuHeading(heading)) {
      console.log(`Collapsing '${heading.props.label}'`)
      this.setState({expandedHeading: null})
    } else {
      console.log(`Expanding '${heading.props.label}'`)
      this.setState({expandedHeading: heading})
    }
  }

  isExpandedMenuHeading = (heading: any): boolean => {
    if (this.state.expandedHeading &&
      this.state.expandedHeading === heading) {
      return true
    }
    return false
  }
}

export default withTheme((withStyles(styles)(MenuButton)))
