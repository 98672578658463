import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import CampaignStore from "../../stores/CampaignStore";
import {makeObservable, observable, when} from "mobx";
import {Grid, isWidthUp, withWidth, WithWidth} from "@material-ui/core";
import {Board} from "../../model/Board";
import BINKOBoard from "./BINKOBoard";
import ControlTower, {Routes} from "../../components/ControlTower";
import DialogButton from "../../components/form/DialogButton";
import {numberToMoneyFormat} from "../../stores/StoreUtilities";
import UserStore from "../../stores/UserStore";
import Visible from "../../components/Visible";
import parseHTML from "html-react-parser";
import Campaign from "../../model/Campaign";
import TitleBar from "../../components/TitleBar";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import DonationForm from "../donation/DonationForm";

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    width: "100%",
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(1)
  },
  dialogPaper: {
    display: 'flex',
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: 'flex-start',
    justifySelf: "flex-star",
    width: '100%',
    maxWidth: 460,
    padding: theme.spacing(1),
    margin: theme.spacing(0),
    marginTop: theme.spacing(0)
  },
  content: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    width: "100%",
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(1)
  },
  boardItem: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      width: "calc(100vw)",
      maxWidth: "calc(100vw)",
      paddingLeft: 0,
      paddingRight: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      width: "100%",
      maxWidth: 600,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
  },
  board: {
    // display: "flex",
    // flexGrow: 1,
    maxWidth: 600,
  },
  alias: {
    color: theme.palette.secondary.light,
    fontSize: 16,
    fontWeight: 600,
    padding: "0px 8px 8px 0px"
  },
  campaignTitle: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "center",
    width: "100%",
    height: 40,
    color: theme.palette.text.secondary,
    maxWidth: 500,
  },
  instructions: {
    maxWidth: 460,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 0,
    marginTop: 0
  },
  media: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    padding: 0,
  },
  actionButtons: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(1),
  },
  emph: {
    fontSize: 16,
    fontWeight: 800,
    color: theme.palette.primary.main
  },
  sponsor: {
    textAlign: "center"
  }
})

interface IBoardPageProps {
  boardId?: string
  campaignStore?: CampaignStore
  s3UrlCacheStore?: S3UrlCacheStore
  userStore?: UserStore
  progress?: Progress
}

@inject("userStore", "campaignStore", "s3UrlCacheStore", "progress")
@observer
class BoardPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IBoardPageProps & WithTheme & WithWidth> {

  @observable isLoading = true
  @observable board?: Board
  @observable campaign?: Campaign
  @observable canEdit = false
  @observable coverImage?: string
  @observable donationForm?: any

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount () {
    const { userStore, campaignStore, s3UrlCacheStore, progress } = this.props
    this.isLoading = true
    progress!.show("BoardPage")
    when (
      () => !userStore!.isLoading && !campaignStore!.isLoading,
      async () => {
        this.board = await this.loadBoard()
        if (this.board) {
          this.campaign = this.board.campaign
          if (userStore!.user && (userStore!.user.isOwner ||
                                 (userStore!.user!.id === this.board.sponsorId))) {
            this.canEdit = true
          }
          if (this.board.coverImage) {
            s3UrlCacheStore!.get(this.board.coverImage)
              .then((url: string | undefined) => {
                console.log(`coverImage = ${url}`)
                this.coverImage = url
              })
              .catch((err: Error) => {
                console.log("Error loading board cover image")
              })
          }
          this.isLoading = false
        }
        progress!.hide("BoardPage")
      }
    )
  }

  render() {
    const { classes, width } = this.props

    if (!this.board) {
      return null
    }

    let board = this.board
    let campaignTitle = this.campaign?.title
    let title = ""
    let alias = ""

    if (board) {
      title = `${board.name}`
      if (this.campaign && this.campaign!.alias) {
        alias = `#${this.campaign!.alias}`
        if (board.alias) {
          alias += `/${board.alias}`
        }
      }
    }

    let notice
    if (this.board.sponsorMatch) {
      notice = <React.Fragment>
        <p className={classes.emph}>{numberToMoneyFormat(this.board.totalAmount)} raised!</p>
        <p>Matching {numberToMoneyFormat(this.board.matchedAmount)} of {numberToMoneyFormat(this.board.sponsorMatchLimit)} by {this.board.sponsorName}!</p>
      </React.Fragment>
    } else {
      notice = <React.Fragment>
        <p className={classes.emph}>{numberToMoneyFormat(this.board.totalAmount)} raised!</p>
        {this.board.sponsorName &&
          <p>Sponsored by {this.board.sponsorName}</p>
        }
      </React.Fragment>
    }

    return (
      <Page title={title}>
        <MarginRow>
          <div className={classes.root}>
              <Grid container className={classes.content} direction="row" spacing={1}>
                <Grid item xs={12} sm={6}>
                  {(!this.donationForm && isWidthUp('sm', width)) &&
                    <div className={classes.dialogPaper}>
                      <div className={classes.alias}>{alias}</div>
                      <div className={classes.campaignTitle}>{campaignTitle}</div>
                      <TitleBar title={title} className={classes.titleBar}/>
                      <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.instructions}>
                          {this.renderCustom()}
                        </Grid>
                      </Grid>
                    </div>
                  }
                </Grid>
                <Grid item xs={12} sm={6}>
                  {!this.donationForm &&
                    <Grid container direction="column" spacing={1}>
                      <Grid item className={classes.boardItem}>
                        <BINKOBoard board={board} className={classes.boardItem} onDonate={this.onDonate}/>
                      </Grid>
                      <Grid item className={classes.sponsor}>
                        {notice}
                      </Grid>
                      <Grid item xs={12} className={classes.actionButtons}>
                        <DialogButton variant="secondary" fullWidth onClick={this.onShare}>
                          Share
                        </DialogButton>
                      </Grid>
                      <Visible if={this.canEdit}>
                        <Grid item xs={12} className={classes.actionButtons}>
                          <DialogButton variant="primary" fullWidth onClick={this.onEdit}>
                            Edit
                          </DialogButton>
                        </Grid>
                      </Visible>
                      <Grid item xs={12} className={classes.actionButtons}>
                        <DialogButton variant="tertiary" fullWidth onClick={this.onBack}>
                          Back
                        </DialogButton>
                      </Grid>
                    </Grid>
                  }
                  {this.donationForm}
                </Grid>
              </Grid>
          </div>
        </MarginRow>
      </Page>
    )
  }

  renderCustom() {
    let custom = null
    if (this.campaign) {
      custom = parseHTML(this.campaign.getCustom("playInstructions"))
    }

    return (custom)
  }

  loadBoard = async () => {
    const { campaignStore, boardId } = this.props
    let board
    if (boardId) {
      board = await campaignStore!.getBoard(boardId)
      if (board) {
        board.campaign = await campaignStore!.getCampaignOnly(board.campaignId)
      }
    }
    return board
  }

  loadCampaign = async (campaignId: string) => {
    const { campaignStore } = this.props
    let campaign
    if (campaignId) {
      campaign = await campaignStore!.getCampaignOnly(campaignId)
    }
    return campaign
  }

  onDonate = (row: number, col: number, amount: number, freq: string, other: string = "") => {
    const { boardId } = this.props
    console.log(`onDonate(${row}, ${col}, ${amount}, ${freq}`)
    const spaceId = `${row},${col}`
    const search = {search: `space=${spaceId}&amount=${amount}&freq=${freq}&other=${other}`}
    this.donationForm = <DonationForm boardId={boardId} location={search} onCancel={this.onCancel}/>
    // ControlTower.route(`${Routes.board}/${boardId}/donate?space=${spaceId}&amount=${amount}&freq=${freq}`)
  }

  onEdit = () => {
    if (this.board) {
      ControlTower.route(`${Routes.campaign}/${this.campaign!.alias}/boardEdit/${this.board!.id}`)
    }
  }

  onShare = () => {
    const route = (this.board!.alias) ? `${Routes.share}/${this.campaign!.alias}/${this.board!.alias}` : `${Routes.share}/${this.campaign!.alias}/${this.board!.id}`
    ControlTower.route(route)
  }

  onCancel = () => {
    this.donationForm = undefined
  }

  onBack = () => {
    ControlTower.route(`${Routes.campaign}/${this.campaign!.alias}/boards`)
  }

}
export default withTheme(withStyles(styles)(withWidth()(BoardPage)))