import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {addSeconds, format} from "date-fns";
import { v4 as uuid } from 'uuid';

export function createUUID() {
  return uuid()
}

export function isUUID(s: string) {
  return /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i.test(s)
}
export function getISODateFromDate (date: Date): string {
  const adjDate = new Date(date)
  // Adjust by time zone offset to make sure the date string doesn't shift
  adjDate.setMinutes(-adjDate.getTimezoneOffset())
  return adjDate.toISOString().substr(0, 10)
}

export function getISODateTimeFromDate(date: Date) : string {
  return date.toISOString()
}

export function getISODateToday() : string {
  const date = new Date()
  return getISODateFromDate(date)
}

export function getISODateTime() : string {
  const date = new Date()
  return date.toISOString()
}

// export function getExpireDate(minutes: number) : string {
//   const date = new Date()
//   const expireDate = addMinutes(date, minutes)
//   return getISODateTimeFromDate(expireDate)
// }
//
export function isoToLocalDate(isoDate: string) {
  const date = new Date(isoDate)
  date.setMinutes(date.getTimezoneOffset())
  return date
}

export function isoToLocalDateTime(isoDateTime: string) {
  const date = new Date(isoDateTime)
  return date
}

export function secondsToDuration(seconds: number) {
  let result
  if (!seconds) {
    result = "0:00"
  } else {
    const time = addSeconds(new Date(0), seconds)
    if (seconds > 3600) {
      result = format(time, "h:m:ss")
    } else {
      result = format(time, "m:ss")
    }
  }
  return result
}

export function getErrorMessage(err: any) {
  if (err.response && err.response.data && err.response.data.details && err.response.data.details.raw && err.response.data.details.raw.message) {
    return err.response.data.details.raw.message
  } else if (err.response && err.response.data && err.response.data.message) {
    return err.response.data.message
  } else if (err.message) {
    return err.message
  } else {
    return "Unknown error"
  }
}

export function timestampToISOString(timestamp: number): string {
  if (timestamp) {
    const date = new Date(timestamp*1000)
    return date.toISOString()
  } else {
    return ""
  }
}

export function undefineEmptyStrings(map: object) {
  Object.keys(map).forEach(key => {
    const value = map[key]
    if (typeof value === "string" && value.length === 0) {
      map[key] = undefined
    }
  })
}

export function phoneToE164Format(phone: string): string | null {
  const phoneNumber = parsePhoneNumberFromString(phone, "US")
  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.number.toString()
  } else {
    return null
  }
}

export function phoneToNationalFormat(phone: string): string {
  if (phone) {
    const phoneNumber = parsePhoneNumberFromString(phone, "US")
    if (phoneNumber && phoneNumber.isValid()) {
      return phoneNumber.formatNational()
    }
  }

  return phone
}

export function numberToMoneyFormat(value: number, minimumFractionDigits: number = 2, useGrouping: boolean = true) : string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    useGrouping
  })

  if (value === -0.00) {
    value = 0.00
  }
  return formatter.format(value)
}

export function parseMoney(text: string) {
  return Number(text.replace(/[^0-9\.-]+/g,""))
}

export function humanizeString (input: string): string {
  if (typeof input !== 'string') {
    throw new TypeError('Expected a string');
  }

  input = decamelize(input, ' ');
  input = titleCase(input)

  return input;
}

export function decamelize(text: string, separator: string = '_'): string {
  return text
    .replace(/([\p{Lowercase_Letter}\d])(\p{Uppercase_Letter})/gu, `$1${separator}$2`)
    .replace(/(\p{Uppercase_Letter}+)(\p{Uppercase_Letter}\p{Lowercase_Letter}+)/gu, `$1${separator}$2`)
    .toLowerCase();
}

export function titleCase(str: string): string {
  return str.toLowerCase().split(' ').map(function(word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}
