/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://bzmphaxgrfb4rgfbpr4cauw4n4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-6lusu3frybhchpibqoq3nj4kv4",
    "aws_cloud_logic_custom": [
        {
            "name": "PaymentAPI",
            "endpoint": "https://3a14atrvn0.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:5ff56258-3888-4d6f-b350-7dc8f8b289c3",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_A6MZYFFWR",
    "aws_user_pools_web_client_id": "4fp2bmsom8m8k6rcr64fprgsau",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "givinci-storage84910-main",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
