import {makeObservable, observable} from "mobx";
import GivinciAPI from "../apis/GivinciAPI";
import * as APITypes from "../API"
import {Account} from "../model/Account";
import {createUUID, phoneToE164Format} from "./StoreUtilities";
import User from "../model/User";
import Tracking from "../components/Tracking";
import ControlTower, {Routes} from "../components/ControlTower";
import Campaign from "../model/Campaign";
import {Board} from "../model/Board";

class AccountStore {
  givinciAPI: GivinciAPI

  @observable account?: Account
  @observable isLoading = true

  constructor(options: any) {
    makeObservable(this)
    this.givinciAPI = (options && options.givinciAPI) ? options.givinciAPI : null
  }

  async init(account: Account) {
    this.account = account
    this.isLoading = false
  }

  async loadAccount(accountId: string) {
    if (!this.account || this.account.id !== accountId) {
      console.log(`loadingAccount(${accountId})`)
      this.isLoading = true
      const accountData = await this.givinciAPI.getAccount(accountId)
        .catch((err: Error) => {
          console.log(`getAccount error: ${err.message}`)
          ControlTower.route(Routes.notFound)
        })

      if (accountData) {
        const account = new Account(accountData)
        await this.init(account)
        return account
      }
    } else {
      return this.account
    }
  }

  listAccounts = async (): Promise<Account[]> => {
    let accounts: Account[] = []

    const data = await this.givinciAPI.listAccounts()
    if (data && data.items) {
      accounts = data.items.map((item: any) => {
        return new Account(item)
      })
    }

    return accounts
  }

  getAccount = async (accountId: string): Promise<Account | undefined> => {
    let account: Account | undefined

    if (accountId === this.account!.id) {
      account = this.account
    }

    if (!account) {
      const data = await this.givinciAPI.getAccount(accountId)
      if (data) {
        account = new Account(data)
      }
    }

    return account
  }

  async createAccount(input:  APITypes.CreateAccountInput) {
    const account = await this.givinciAPI!.createAccount(input)
    if (account) {
      Tracking.event({action: "Create Account"})
      this.account = new Account(account)
      this.isLoading = false
      return this.account
    } else {
      return null
    }
  }

  async updateAccount(input:  APITypes.UpdateAccountInput) {
    const result = await this.givinciAPI!.updateAccount(input)
    if (result) {
      Tracking.event({action: "Update Account"})
      const account = new Account(result)
      if (account.id === this.account!.id) {
        if (!account.users || account.users.length === 0) {
          account.users = this.account!.users
        }
        if (!account.campaigns || account.campaigns.length === 0) {
          account.campaigns = this.account!.campaigns
        }
        this.account = account
      }
      return account
    } else {
      return null
    }
  }

  getOrgTypes = () => {
    return ([
      "Individual",
      "Non-Profit",
      "Company"
    ])
  }

  listUsers = async (accountId: string): Promise<User[]> => {
    let users: User[] = []

    if (accountId) {
      let account = await this.getAccount(accountId)

      if (account) {
        if (account.users.length === 0) {
          const data = await this.givinciAPI.getAccountUsers(accountId)
          if (data) {
            await account.loadUsers(data.users!.items!)
          }
        }

        users = [...account.users]
      }
    }

    return users
  }

  getUser = async (userId: string, accountId?: string): Promise<User | undefined> => {
    let user : User | undefined

    if (accountId) {
      let account = await this.getAccount(accountId)
      if (account) {
        user = account.users.find((u: User) => u.id === userId)
      }
    }

    if (!user) {
      const data = await this.givinciAPI.getUser(userId)
      if (data) {
        user = new User(data)
      }
    }

    return user
  }

  findUser = async (email: string): Promise<User | undefined> => {
    let user
    if (this.account) {
      const users = await this.listUsers(this.account!.id)
      if (users && users.length > 0) {
        const search = email.toLowerCase()
        user = users.find((u: User) => {
          return u.email === search
        })
      }
    }
    return user
  }

  async updateUser(input: APITypes.UpdateUserInput) {
    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }
    const update = await this.givinciAPI!.updateUser(input)
    if (update) {
       const user = new User(update)
       // Update list
      this.updateUserCache(user)
       return user
    } else {
      return null
    }
  }

  updateUserCache(user: User) {
    const index = this.account!.users.findIndex((u: User) => { return u.id === user.id})
    if (index >= 0) {
      this.account!.users[index] = user
    } else {
      this.account!.users.push(user)
    }
  }

  async createUser(input: APITypes.CreateUserInput) {
    // Verify email is not in use
    const existingUser = await this.findUser(input.email)
    if (existingUser) {
      throw Error("This email is already in use")
    }

    if (!input.id) {
      input.id = createUUID()
    }

    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }

    let data
    if (this.account) {
      data = await this.givinciAPI!.createUser(input)
    } else {
      data = await this.givinciAPI!.createInactiveUser(input)
    }

    if (data) {
      Tracking.event({action: "Create User"})
      const user = new User(data)
      if (this.account) {
        this.account!.users.push(user)
      }
      return user
    } else {
      return null
    }
  }

  async deleteUser(userId: string) {
    const result = await this.givinciAPI!.deleteUser(userId)
    if (result) {
      const user = new User(result)
      // Remove from list
      const index = this.account!.users.findIndex((u: User) => u.id === userId)
      if (index >= 0) {
        this.account!.users.splice(index, 1)
      }
      return user
    } else {
      return null
    }
  }

  listCampaigns = async (): Promise<Campaign[]> => {

    if (this.account!.campaigns) {
      return [...this.account!.campaigns]
    } else {
      return []
    }
  }

  getCampaign = async (campaignId: string): Promise<Campaign | undefined> => {
    let campaign: Campaign | undefined

    const data = await this.givinciAPI.getCampaign(campaignId)
    if (data) {
      campaign = new Campaign(data)
    }

    return campaign
  }

  async createCampaign(input:  APITypes.CreateCampaignInput) {
    const data = await this.givinciAPI!.createCampaign(input)
    if (data) {
      Tracking.event({action: "Create Campaign"})
      let campaign = new Campaign(data)
      this.account!.campaigns.push(campaign)
      this.isLoading = false
      return campaign
    } else {
      return null
    }
  }

  async updateCampaign(input: APITypes.UpdateCampaignInput) {
    const update = await this.givinciAPI!.updateCampaign(input)
    if (update) {
      const campaign = new Campaign(update)
      // Update list
      const index = this.account!.campaigns.findIndex((c: Campaign) => { return c.id === input.id})
      if (index >= 0) {
        this.account!.campaigns[index] = campaign
      }
      return campaign
    } else {
      return null
    }
  }

  async deleteBoard(boardId: string) {
    const result = await this.givinciAPI!.deleteBoard(boardId)
    if (result) {
      const board = new Board(result)
      return board
    } else {
      return null
    }
  }

}

export default AccountStore