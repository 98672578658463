import * as APITypes from "../API"
import {GRAPHQL_AUTH_MODE, GraphQLResult} from '@aws-amplify/api/lib/types/index'
import {API, Auth, graphqlOperation} from "aws-amplify"
import {print as gqlToString} from "graphql"
import Logger from "../components/Logger";
import * as CustomQueries from './CustomQueries'
import * as CustomMutations from './CustomMutations'
import {getErrorMessage} from "../stores/StoreUtilities";
import ControlTower, {Routes} from "../components/ControlTower";

class GivinciAPI {

  // Account methods

  async listAccounts(filter?: APITypes.ModelAccountFilterInput) {
    const query = gqlToString(CustomQueries.listAccounts)
    const variables = {"filter": filter, "limit": 10000}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.listAccounts error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListAccountsQuery && (data as APITypes.ListAccountsQuery).listAccounts) {
      return (data as APITypes.ListAccountsQuery).listAccounts
    } else {
      return null
    }
  }

  async getAccount(id: string) {
    const query = gqlToString(CustomQueries.getAccount)
    const variables = {"id": id}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.getAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetAccountQuery && (data as APITypes.GetAccountQuery).getAccount) {
      return (data as APITypes.GetAccountQuery).getAccount
    } else {
      return null
    }
  }

  async getAccountUsers(id: string) {
    const query = gqlToString(CustomQueries.getAccountUsers)
    const variables = {"id": id}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.getAccountUsers error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetAccountQuery && (data as APITypes.GetAccountQuery).getAccount) {
      return (data as APITypes.GetAccountQuery).getAccount
    } else {
      return null
    }
  }

  async createAccount(input: APITypes.CreateAccountInput) {
    const query = gqlToString(CustomMutations.createAccount)
    const variables = {input}
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateAccountMutation && (data as APITypes.CreateAccountMutation).createAccount) {
        const accountData = (data as APITypes.CreateAccountMutation).createAccount
        return accountData
      } else {
        throw new Error(`Call to create account does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GivinciAPI.createAccount error", getErrorMessage(e), input)
      return null
    }
  }

  async updateAccount(input: APITypes.UpdateAccountInput) {
    const query = gqlToString(CustomMutations.updateAccount)
    const variables = {"input": input}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.updateAccount error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateAccountMutation) {
      return (data as APITypes.UpdateAccountMutation).updateAccount
    } else {
      return null
    }
  }

  async deleteAccount(id: string) {
    const query = gqlToString(CustomMutations.deleteAccount)
    const input: APITypes.DeleteAccountInput = {
      id
    }
    const data = await this.makeQuery(query, {input})
      .catch(err => {
        Logger.error("GivinciAPI.deleteAccount error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteAccountMutation).deleteAccount
    }
    return null
  }

  // User methods

  async getUser (userId: string) {
    const query = gqlToString(CustomQueries.getUser)
    const variables = { "id": userId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.getUser error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetUserQuery && (data as APITypes.GetUserQuery).getUser) {
      return (data as APITypes.GetUserQuery).getUser
    } else {
      return null
    }
  }

  async createUser (input: APITypes.CreateUserInput) {
    const query = gqlToString(CustomMutations.createUser)
    const variables = {input}
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateUserMutation && (data as APITypes.CreateUserMutation).createUser) {
        const userData = (data as APITypes.CreateUserMutation).createUser
        return userData
      } else {
        throw new Error(`Call to create user does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GivinciAPI.createUser error", getErrorMessage(e), input)
      return null
    }
  }

  async createInactiveUser (input: APITypes.CreateUserInput) {
    input.active = false
    const query = gqlToString(CustomMutations.createUser)
    const variables = {input}
    try {
      const data = await this.makeAPIKeyQuery(query, variables)

      if (data as APITypes.CreateUserMutation && (data as APITypes.CreateUserMutation).createUser) {
        const userData = (data as APITypes.CreateUserMutation).createUser
        return userData
      } else {
        throw new Error(`Call to create user does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GivinciAPI.createUser error", getErrorMessage(e), input)
      return null
    }
  }

  async updateUser(input: APITypes.UpdateUserInput) {
    if (input.email) {
      input.email = input.email.toLowerCase()
    }
    const query = gqlToString(CustomMutations.updateUser)
    const variables = {"input": input}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.updateUser error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateUserMutation) {
      return (data as APITypes.UpdateUserMutation).updateUser
    } else {
      return null
    }
  }

  async deleteUser(id: string) {
    const query = gqlToString(CustomMutations.deleteUser)
    const input: APITypes.DeleteUserInput = {
      id
    }
    const data = await this.makeQuery(query, {input})
      .catch(err => {
        Logger.error("GivinciAPI.deleteUser error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteUserMutation).deleteUser
    }
    return null
  }

  // Campaign methods

  async listCampaigns(filter?: APITypes.ModelCampaignFilterInput) {
    const query = gqlToString(CustomQueries.listCampaigns)
    const variables = {"filter": filter, "limit": 10000}
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.listCampaigns error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListCampaignsQuery && (data as APITypes.ListCampaignsQuery).listCampaigns) {
      return (data as APITypes.ListCampaignsQuery).listCampaigns
    } else {
      return null
    }
  }

  async getCampaign (campaignId: string) {
    const query = gqlToString(CustomQueries.getCampaign)
    const variables = { "id": campaignId }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.getCampaign error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetCampaignQuery && (data as APITypes.GetCampaignQuery).getCampaign) {
      return (data as APITypes.GetCampaignQuery).getCampaign
    } else {
      return null
    }
  }

  async getCampaignByAlias(alias: string) {
    const query = gqlToString(CustomQueries.listCampaignsByAlias)
    const variables = {"alias": alias.toLowerCase()}
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.getCampaignsByAlias error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListCampaignsByAliasQuery &&
      (data as APITypes.ListCampaignsByAliasQuery).listCampaignsByAlias &&
      (data as APITypes.ListCampaignsByAliasQuery).listCampaignsByAlias!.items &&
      (data as APITypes.ListCampaignsByAliasQuery).listCampaignsByAlias!.items!.length > 0) {

      return (data as APITypes.ListCampaignsByAliasQuery).listCampaignsByAlias!.items![0]
    } else {
      return null
    }
  }

  async getCampaignOnly (campaignId: string) {
    const query = gqlToString(CustomQueries.getCampaignOnly)
    const variables = { "id": campaignId }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.getCampaignOnly error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetCampaignQuery && (data as APITypes.GetCampaignQuery).getCampaign) {
      return (data as APITypes.GetCampaignQuery).getCampaign
    } else {
      return null
    }
  }

  async getCampaignOnlyByAlias(alias: string) {
    const query = gqlToString(CustomQueries.listCampaignsOnlyByAlias)
    const variables = {"alias": alias.toLowerCase()}
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.getCampaignOnlyByAlias error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListCampaignsByAliasQuery &&
      (data as APITypes.ListCampaignsByAliasQuery).listCampaignsByAlias &&
      (data as APITypes.ListCampaignsByAliasQuery).listCampaignsByAlias!.items &&
      (data as APITypes.ListCampaignsByAliasQuery).listCampaignsByAlias!.items!.length > 0) {

      return (data as APITypes.ListCampaignsByAliasQuery).listCampaignsByAlias!.items![0]
    } else {
      return null
    }
  }

  async createCampaign (input: APITypes.CreateCampaignInput) {
    const query = gqlToString(CustomMutations.createCampaign)
    const variables = {input}
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateCampaignMutation && (data as APITypes.CreateCampaignMutation).createCampaign) {
        const campaignData = (data as APITypes.CreateCampaignMutation).createCampaign
        return campaignData
      } else {
        throw new Error(`Call to create campaign does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GivinciAPI.createCampaign error", getErrorMessage(e), input)
      return null
    }
  }

  async updateCampaign(input: APITypes.UpdateCampaignInput) {
    const query = gqlToString(CustomMutations.updateCampaign)
    const variables = {"input": input}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.updateCampaign error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateCampaignMutation) {
      return (data as APITypes.UpdateCampaignMutation).updateCampaign
    } else {
      return null
    }
  }

  // Board methods

  async getBoard (boardId: string) {
    const query = gqlToString(CustomQueries.getBoard)
    const variables = { "id": boardId }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.getBoard error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetBoardQuery && (data as APITypes.GetBoardQuery).getBoard) {
      return (data as APITypes.GetBoardQuery).getBoard
    } else {
      return null
    }
  }

  async createBoard (input: APITypes.CreateBoardInput) {
    const query = gqlToString(CustomMutations.createBoard)
    const variables = {input}
    try {
      const data = await this.makeAPIKeyQuery(query, variables)

      if (data as APITypes.CreateBoardMutation && (data as APITypes.CreateBoardMutation).createBoard) {
        const boardData = (data as APITypes.CreateBoardMutation).createBoard
        return boardData
      } else {
        throw new Error(`Call to create board does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GivinciAPI.createBoard error", getErrorMessage(e), input)
      return null
    }
  }

  async updateBoard(input: APITypes.UpdateBoardInput) {
    const query = gqlToString(CustomMutations.updateBoard)
    const variables = {"input": input}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.updateBoard error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateBoardMutation) {
      return (data as APITypes.UpdateBoardMutation).updateBoard
    } else {
      return null
    }
  }

  async deleteBoard(id: string) {
    const query = gqlToString(CustomMutations.deleteBoard)
    const input: APITypes.DeleteBoardInput = {
      id
    }
    const data = await this.makeQuery(query, {input})
      .catch(err => {
        Logger.error("GivinciAPI.deleteBoard error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteBoardMutation).deleteBoard
    }
    return null
  }

  // Donation methods

  async getDonation (donationId: string) {
    const query = gqlToString(CustomQueries.getDonation)
    const variables = { "id": donationId }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.getDonation error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetDonationQuery && (data as APITypes.GetDonationQuery).getDonation) {
      return (data as APITypes.GetDonationQuery).getDonation
    } else {
      return null
    }
  }

  async createDonation (input: APITypes.CreateDonationInput) {
    const query = gqlToString(CustomMutations.createDonation)
    const variables = {input}
    try {
      const data = await this.makeAPIKeyQuery(query, variables)

      if (data as APITypes.CreateDonationMutation && (data as APITypes.CreateDonationMutation).createDonation) {
        const donationData = (data as APITypes.CreateDonationMutation).createDonation
        return donationData
      } else {
        throw new Error(`Call to create donation does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GivinciAPI.createDonation error", getErrorMessage(e), input)
      return null
    }
  }

  async updateDonation(input: APITypes.UpdateDonationInput) {
    const query = gqlToString(CustomMutations.updateDonation)
    const variables = {"input": input}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GivinciAPI.updateDonation error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateDonationMutation) {
      return (data as APITypes.UpdateDonationMutation).updateDonation
    } else {
      return null
    }
  }

  async deleteDonation(id: string) {
    const query = gqlToString(CustomMutations.deleteDonation)
    const input: APITypes.DeleteDonationInput = {
      id
    }
    const data = await this.makeQuery(query, {input})
      .catch(err => {
        Logger.error("GivinciAPI.deleteDonation error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteDonationMutation).deleteDonation
    }
    return null
  }
  
  // Helper methods

  async makeQuery(query: string, variables?: {}) {
    try {
      // await this.checkAuthentication()
      const operation = graphqlOperation(query, variables)
      // console.log(JSON.stringify(operation))
      const result = await API.graphql(operation)
      if (result as GraphQLResult) {
        const data = (result as GraphQLResult).data
        return data
      } else {
        return null
      }
    } catch (err: any) {
      // console.log("makeQuery error", err)
      if (err instanceof Error) {
        throw err
      } else if (err.errors && err.errors.length > 0) {
        throw new Error(err.errors[0].message)
      } else {
        throw new Error(`Unknown error: ${err}`)
      }
    }
  }

  async makeAPIKeyQuery(query: string, variables?: {}) {
    try {
      // await this.checkAuthentication()
      const result = await API.graphql({
        query: query,
        variables,
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      })
      if (result as GraphQLResult) {
        const data = (result as GraphQLResult).data
        return data
      } else {
        return null
      }
    } catch (err: any) {
      // console.log("makeQuery error", err)
      if (err.message) {
        throw err
      } else if (err.errors && err.errors.length > 0) {
        throw new Error(err.errors[0].message)
      } else {
        throw new Error(`Unknown error: ${err}`)
      }
    }
  }


  async checkAuthentication() {
    try{
      const cognitoUser = await Auth.currentAuthenticatedUser()
      if (cognitoUser) {
        return
      }
    } catch (err) {
      // Logger.debug("checkAuthentication err: " + err.message)
    }

    ControlTower.route(Routes.signout)
  }


}

export default GivinciAPI