import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import { Grid, Paper} from "@material-ui/core";
import ControlTower, {Routes} from "../../components/ControlTower";
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import CampaignStore from "../../stores/CampaignStore";
import {makeObservable, observable} from "mobx";
import Campaign from "../../model/Campaign";
import {Board} from "../../model/Board";
import BINKOBoard from "../boards/BINKOBoard";
import DialogButton from "../../components/form/DialogButton";

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: 'center',
    alignContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  dialogPaper: {
    display: 'flex',
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: theme.spacing(0.5)
  },
  content: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: 'flex-start',
    width: "100%",
    marginTop: theme.spacing(0.5),
    textAlign: "left",
    fontSize: 16
  },
  boards: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    margin: "8px 0 8px 0",
  },
  boardItem: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "center",
    justifyContent: "center",
    alignItems: "flex-start",
    alignContent: "flex-start",
    minHeight: 350,
    maxHeight: 350,
    // border: "1px solid silver",
  },
  board: {
    transform: "scale(0.45, 0.45)",
    transformOrigin: "50% 0%",
    textAlign: "center",
    padding: 0
  },
  boardLink: {
    textDecoration: "none"
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(2),
  },
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  newButton: {
    maxWidth: 360
  }
})

interface IAccountCampaignBoardsProps {
  campaign: Campaign
  campaignStore?: CampaignStore
  progress?: Progress
}

@inject("campaignStore", "progress")
@observer
class AccountCampaignBoards extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountCampaignBoardsProps & WithTheme> {

  @observable campaign?: Campaign

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount () {
    this.campaign = await this.loadCampaign()
  }

  render() {
    const { classes } = this.props

    if (!this.campaign) {
      return null
    }

    return (
      <Paper className={classes.dialogPaper}>
        <Grid container className={classes.root} direction="column">
          <Grid item xs={12} className={classes.content}>
            <p>Select a board to edit or create a new board.</p>
          </Grid>
        </Grid>
        <Grid container className={classes.boards} direction="row" spacing={1}>
          {this.campaign.boards.map((board: Board) =>
            <Grid item md={3} sm={4} xs={6} className={classes.boardItem} spacing={0}>
              <div className={classes.board}>
                <a href="# " className={classes.boardLink}
                   onClick={(event) => this.onClickBoard(board.id)}>
                  <BINKOBoard board={board} legend/>
                </a>
              </div>
            </Grid>
          )}
        </Grid>
        <Grid container className={classes.actionButtons} spacing={2}>
          <Grid item xs={12} className={classes.newButton}>
            <DialogButton variant="primary" fullWidth onClick={this.onNew}>
              New Board
            </DialogButton>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  loadCampaign = async () => {
    const { campaign } = this.props
    if (campaign) {
      campaign.boards.sort((a: Board, b: Board) => a.name.localeCompare(b.name))
    }
    return campaign
  }

  onNew = () => {
    const { campaign } = this.props
    ControlTower.route(`${Routes.account}/campaign/${campaign.id}/boardEdit`)
  }

  onClickBoard = (boardId: string) => {
    const {campaign} = this.props
    ControlTower.route(`${Routes.account}/campaign/${campaign.id}/boardEdit/${boardId}`)
  }

  onBack = () => {
    const { campaign } = this.props
    ControlTower.route(`${Routes.account}/campaign/${campaign.id}/boards`)
  }
}

export default withTheme((withStyles(styles)(AccountCampaignBoards)))