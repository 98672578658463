/* tslint:disable */
import gql from "graphql-tag";

export const listAccounts = /* GraphQL */ gql`
    query ListAccounts(
        $filter: ModelAccountFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                createdAt
                updatedAt
                ownerId
                name
                active
                about
                address
                city
                state
                postalCode
                orgType
                webSite
                stripeId
                paymentProvider
                paymentOptions
            }
            nextToken
        }
    }
`;

export const getAccount = /* GraphQL */ gql`
    query GetAccount($id: ID!) {
        getAccount(id: $id) {
            id
            createdAt
            updatedAt
            ownerId
            name
            active
            about
            address
            city
            state
            postalCode
            orgType
            webSite
            stripeId
            paymentProvider
            paymentOptions
            users {
                items {
                    id
                    createdAt
                    updatedAt
                    active
                    accountId
                    firstName
                    lastName
                    email
                    phone
                    role
                }
                nextToken
            }
            campaigns {
                items {
                    id
                    createdAt
                    updatedAt
                    accountId
                    title
                    alias
                    description
                    city
                    state
                    campaignType
                    goalAmount
                    donatedAmount
                    publishAt
                    startAt
                    endAt
                    layout
                    custom
                    coverUrl
                    videoUrl
                    shareUrl
                    contactInfo
                    paymentInfo
                    boards {
                        items {
                            id
                            createdAt
                            updatedAt
                            accountId
                            campaignId
                            name
                            alias
                            sponsorId
                            sponsorName
                            sponsorEmail
                            sponsorMatch
                            sponsorMatchLimit
                            backgroundColor
                            backgroundImage
                            coverImage
                            layout
                        }
                        nextToken
                    }
                    donations {
                        items {
                            id
                            createdAt
                            updatedAt
                            accountId
                            campaignId
                            boardId
                            spaceId
                            amount
                            frequency
                            other
                            name
                            email
                            message
                            isAnonymous
                            isExistingDonor
                            paymentStatus
                        }
                        nextToken
                    }
                }
                nextToken
            }
        }
    }
`;

export const getAccountUsers = /* GraphQL */ gql`
    query GetAccount($id: ID!) {
        getAccount(id: $id) {
            id
            users (limit: 1000){
                items {
                    id
                    createdAt
                    updatedAt
                    active
                    accountId
                    firstName
                    lastName
                    email
                    phone
                    role
                }
                nextToken
            }
        }
    }
`;

export const getUser = /* GraphQL */ gql`
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            createdAt
            updatedAt
            active
            accountId
            firstName
            lastName
            email
            phone
            role
        }
    }
`;

export const listCampaigns = /* GraphQL */ gql`
    query ListCampaigns(
        $filter: ModelCampaignFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                createdAt
                updatedAt
                accountId
                title
                alias
                description
                city
                state
                campaignType
                goalAmount
                donatedAmount
                publishAt
                startAt
                endAt
                layout
                custom
                coverUrl
                videoUrl
                shareUrl
                contactInfo
                paymentInfo
                boards {
                    items {
                        id
                        createdAt
                        updatedAt
                        accountId
                        campaignId
                        name
                        alias
                        sponsorId
                        sponsorName
                        sponsorEmail
                        sponsorMatch
                        sponsorMatchLimit
                        backgroundColor
                        backgroundImage
                        coverImage
                        layout
                    }
                    nextToken
                }
                donations {
                    items {
                        id
                        createdAt
                        updatedAt
                        accountId
                        campaignId
                        boardId
                        spaceId
                        amount
                        frequency
                        other
                        name
                        email
                        message
                        isAnonymous
                        isExistingDonor
                        paymentStatus
                    }
                    nextToken
                }
            }
            nextToken
        }
    }
`;

export const listCampaignsByAlias = /* GraphQL */ gql`
    query ListCampaignsByAlias(
        $alias: String
        $sortDirection: ModelSortDirection
        $filter: ModelCampaignFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listCampaignsByAlias(
            alias: $alias
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                createdAt
                updatedAt
                accountId
                title
                alias
                description
                city
                state
                campaignType
                goalAmount
                donatedAmount
                publishAt
                startAt
                endAt
                layout
                custom
                coverUrl
                videoUrl
                shareUrl
                contactInfo
                paymentInfo
                boards {
                    items {
                        id
                        createdAt
                        updatedAt
                        accountId
                        campaignId
                        name
                        alias
                        sponsorId
                        sponsorName
                        sponsorEmail
                        sponsorMatch
                        sponsorMatchLimit
                        backgroundColor
                        backgroundImage
                        coverImage
                        layout
                    }
                    nextToken
                }
                donations {
                    items {
                        id
                        createdAt
                        updatedAt
                        accountId
                        campaignId
                        boardId
                        spaceId
                        amount
                        frequency
                        other
                        name
                        email
                        message
                        isAnonymous
                        isExistingDonor
                        paymentStatus
                    }
                    nextToken
                }
            }
            nextToken
        }
    }
`;
export const listCampaignsOnlyByAlias = /* GraphQL */ gql`
    query ListCampaignsByAlias(
        $alias: String
        $sortDirection: ModelSortDirection
        $filter: ModelCampaignFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listCampaignsByAlias(
            alias: $alias
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                createdAt
                updatedAt
                accountId
                title
                alias
                description
                city
                state
                campaignType
                goalAmount
                donatedAmount
                publishAt
                startAt
                endAt
                layout
                custom
                coverUrl
                videoUrl
                shareUrl
                contactInfo
                paymentInfo
                boards {
                    nextToken
                }
                donations {
                    nextToken
                }
            }
            nextToken
        }
    }
`;
export const getCampaign = /* GraphQL */ gql`
    query GetCampaign($id: ID!) {
        getCampaign(id: $id) {
            id
            createdAt
            updatedAt
            accountId
            title
            alias
            description
            city
            state
            campaignType
            goalAmount
            donatedAmount
            publishAt
            startAt
            endAt
            layout
            custom
            coverUrl
            videoUrl
            shareUrl
            contactInfo
            paymentInfo
            boards {
                items {
                    id
                    createdAt
                    updatedAt
                    accountId
                    campaignId
                    name
                    alias
                    sponsorId
                    sponsorName
                    sponsorEmail
                    sponsorMatch
                    sponsorMatchLimit
                    backgroundColor
                    backgroundImage
                    coverImage
                    layout
                }
                nextToken
            }
            donations {
                items {
                    id
                    createdAt
                    updatedAt
                    accountId
                    campaignId
                    boardId
                    spaceId
                    amount
                    frequency
                    other
                    name
                    email
                    message
                    isAnonymous
                    isExistingDonor
                    paymentStatus
                }
                nextToken
            }
        }
    }
`;

export const getCampaignOnly = /* GraphQL */ gql`
    query GetCampaign($id: ID!) {
        getCampaign(id: $id) {
            id
            createdAt
            updatedAt
            accountId
            title
            alias
            description
            city
            state
            campaignType
            goalAmount
            donatedAmount
            publishAt
            startAt
            endAt
            layout
            custom
            coverUrl
            videoUrl
            shareUrl
            contactInfo
            paymentInfo
        }
    }
`;

export const getBoard = /* GraphQL */ gql`
    query GetBoard($id: ID!) {
        getBoard(id: $id) {
            id
            createdAt
            updatedAt
            accountId
            campaignId
            name
            alias
            sponsorId
            sponsorName
            sponsorEmail
            sponsorMatch
            sponsorMatchLimit
            layout
            backgroundColor
            backgroundImage
            coverImage
            donations {
                items {
                    id
                    createdAt
                    updatedAt
                    accountId
                    campaignId
                    boardId
                    spaceId
                    amount
                    frequency
                    other
                    name
                    email
                    message
                    isAnonymous
                    isExistingDonor
                    paymentStatus
                }
                nextToken
            }
        }
    }
`;

export const getDonation = /* GraphQL */ gql`
    query GetDonation($id: ID!) {
        getDonation(id: $id) {
            id
            createdAt
            updatedAt
            accountId
            campaignId
            boardId
            spaceId
            amount
            frequency
            other
            name
            email
            message
            isAnonymous
            isExistingDonor
            paymentStatus
            board {
                id
                createdAt
                updatedAt
                accountId
                campaignId
                name
                alias
                sponsorId
                sponsorName
                sponsorEmail
                sponsorMatch
                sponsorMatchLimit
                backgroundColor
                backgroundImage
                coverImage
                layout
            }
        }
    }
`;