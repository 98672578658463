import * as React from 'react'
import {
  createStyles,
  Icon,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  Typography,
  Button
} from "@material-ui/core";
import {blueGrey} from "@material-ui/core/colors";

const styles = (theme: Theme) => createStyles({
  primaryButton: {
    width: 24,
    height: 24,
    padding: "0 0 2px 0",
    margin: 0,
    minWidth: 54,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.dark,
    },
    "&:active": {
      background: theme.palette.primary.light,
    },
  },
  secondaryButton: {
    width: 24,
    height: 24,
    padding: "0 0 2px 0",
    margin: 0,
    minWidth: 54,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.light,
    },
    "&:active": {
      background: theme.palette.secondary.dark,
    },
  },
  tertieryButton: {
    width: 24,
    height: 24,
    padding: "0 0 2px 0",
    margin: 0,
    minWidth: 54,
    backgroundColor: blueGrey[500],
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: blueGrey[700],
    },
    "&:active": {
      backgroundColor: blueGrey[300],
    },
  },
  icon: {
    color: theme.palette.secondary.contrastText
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    paddingTop: 2,
    paddingLeft: 0,
    paddingRight: 0,
    // color: theme.palette.secondary.contrastText,
    // textTransform: "uppercase"
  }
})

interface ITitleButtonProps {
  title?: string
  icon?: string
  onClick?: any
  variant?: 'primary' | 'secondary' | 'tertiary'
  className?: string
}

class TitleButton extends React.Component<WithStyles<typeof styles> & ITitleButtonProps & WithTheme> {

  onClick = (event: any) => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    const { classes, icon, title, onClick, variant } = this.props

    let vari: "contained" | "outlined" | "text" = "contained"
    let className = classes.primaryButton
    if (variant === "secondary") {
      vari = "contained"
      className = classes.secondaryButton
    } else if (variant === "tertiary") {
      vari = "text"
      className = classes.tertieryButton
    }

    if (this.props.className) {
      className = this.props.className
    }

    return (
      <Button size="small"
              variant={vari}
              onClick={onClick}
              className={className}>
        { icon && <Icon className={classes.icon} title={title}>{icon}</Icon> }
        <Typography className={classes.label}>{title}</Typography>
      </Button>
    )
  }
}

export default withTheme((withStyles(styles)(TitleButton)))