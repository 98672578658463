import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import CampaignStore from "../../stores/CampaignStore";
import {makeObservable, observable, when} from "mobx";
import Campaign from "../../model/Campaign";
import TitleBar from "../../components/TitleBar";
import ReactPlayer from "react-player";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Hidden,
  LinearProgress,
  Paper,
  Typography
} from "@material-ui/core";
import {getISODateToday, isoToLocalDate, numberToMoneyFormat} from "../../stores/StoreUtilities";
import ControlTower, {Routes} from "../../components/ControlTower";
import {Donation} from "../../model/Donation";
import DialogButton from "../../components/form/DialogButton";
import parseHTML from 'html-react-parser';
import {format} from "date-fns";
import UserStore from "../../stores/UserStore";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import {CampaignType, CreateBoardInput} from "../../API";
import {Board} from "../../model/Board";
import BINKOBoard from "../boards/BINKOBoard";
import Visible from "../../components/Visible";
import DonationForm from "../donation/DonationForm";
import Notify from "../../components/notify/Notify";
import DonationThankYouDialog from "../donation/DonationThankYouDialog";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  slogan: {
    color: theme.palette.text.secondary
  },
  content: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: 'center',
    width: "100%",
    margin: 0
  },
  statusContent: {
    width: "100%",
    margin: "8px 0",
    padding: 0
  },
  campaigns: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: 'flex-start',
    width: "100%",
    margin: theme.spacing(1)
  },
  appName: {
    color: theme.palette.primary.main
  },
  startButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 350,
    fontSize: 20
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  media: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vw * (9/16))',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(50vw * (9/16))',
      maxHeight: 277 // 259
    },
    maxHeight: 540,
    padding: 0,
  },
  coverImage: {
    width: "100%"
  },
  donationCard: {

  },
  description: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingTop: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
      padding: 0
    },
  },
  alias: {
    color: theme.palette.secondary.light,
    fontSize: 16,
    fontWeight: 600,
    padding: "4px 8px 0 8px"
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 8px 0px 8px",
  },
  location: {
    color: theme.palette.primary.light,
    fontSize: 16,
    fontWeight: 600,
    padding: "4px 8px 0 8px"
  },
  amountRaised: {
    fontSize: 24,
    fontWeight: 800,
  },
  status: {
    fontSize: 16,
    fontWeight: 200,
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
  },
  progressBar: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    justifyContent: "center",
    padding: "4px 8px 4px",
  },
  linearProgressDeterminateColor: {
    backgroundColor: "rgba(255, 255, 255, 0.25)"
  },
  linearProgressColor: {
    backgroundColor: theme.palette.primary.main
  },
  linearProgress: {
    color: theme.palette.primary.main,
    width: "100%",
    height: 4,
  },
  actionButtons: {
    padding: theme.spacing(1)
  },
  donations: {
    padding: theme.spacing(1),
    // maxHeight: 400,
    // overflowY: "scroll"
  },
  donation: {
    padding: "4px 2px"
  },
  donorName: {
    fontWeight: 800,
    fontSize: 16
  },
  donorMessage: {
    fontWeight: 400,
    fontSize: 14,
    maxHeight: 50,
    lineHeight: 1.2,
    overflow: "hidden"
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  titleBarXs: {
    paddingBottom: 4
  },
  dates: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    textAlign: "center"
  },
  boardItem: {
    marginTop: theme.spacing(2),
    width: "100%",
    maxWidth: 600,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  board: {
    // display: "flex",
    // flexGrow: 1,
    maxWidth: 600,
  },
  emph: {
    fontSize: 16,
    fontWeight: 800,
    color: theme.palette.primary.main
  },
  sponsor: {
    textAlign: "center"
  }
})

interface ICampaignPageProps {
  campaignId?: string
  boardId?: string
  campaignStore?: CampaignStore
  s3UrlCacheStore?: S3UrlCacheStore
  userStore?: UserStore
  progress?: Progress
  notify?: Notify
  location?: any
}

@inject("campaignStore", "userStore", "s3UrlCacheStore", "progress", "notify")
@observer
class CampaignPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICampaignPageProps & WithTheme> {

  @observable isLoading = true
  @observable campaign?: Campaign
  @observable board?: Board
  @observable player?: ReactPlayer
  @observable coverImageUrl?: string
  @observable donationForm?: any
  @observable canEdit = false
  @observable donationId: string | null = null
  @observable donationStatus: string | null = null
  @observable thankYouDialog?: any
  disablePlay: Boolean = false
  disableMessage = ""

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount () {
    const { campaignStore, s3UrlCacheStore, userStore, progress, boardId } = this.props
    this.isLoading = true
    progress!.show("CampaignPage")
    when (
      () => !campaignStore!.isLoading,
      async () => {
        const campaign = await this.loadCampaign()
        if (campaign && boardId) {
          this.board = campaign.getBoard(boardId)
          if (this.board) {
            if (this.board.coverImage) {
              const url = await s3UrlCacheStore!.get(this.board.coverImage)
                .catch((err: Error) => {
                  console.log("Error loading cover image")
                })

              if (url) {
                this.coverImageUrl = url
              }
            }
            if (userStore!.user && (userStore!.user.isOwner ||
              (userStore!.user!.id === this.board.sponsorId))) {
              this.canEdit = true
            }
          }
        }

        if (!this.coverImageUrl && campaign && !campaign.videoUrl && campaign.coverUrl) {
          const coverUrl = campaign.coverUrl
          const url = await s3UrlCacheStore!.get(coverUrl)
            .catch((err: Error) => {
              console.log("Error loading cover image")
              this.campaign = campaign
            })

          if (url) {
            this.coverImageUrl = url
            this.campaign = campaign
          }
        } else {
          this.campaign = campaign
        }

        const searchParams = new URLSearchParams(this.props.location.search)
        this.donationId = searchParams.get("donationId")
        this.donationStatus = searchParams.get("status")
        if (this.donationId && this.donationStatus) {
          console.log(`donationId=${this.donationId}, status=${this.donationStatus}`)
          if (this.donationStatus === "cancel") {
            this.onDonationCancel(this.donationId)
          } else {
            this.onDonationSuccess(this.donationId)
          }

        }

        this.isLoading = false
        progress!.hide("CampaignPage")
      }
    )
  }

  ref = (player: ReactPlayer) => {
    this.player = player
  }

  render() {
    const { classes, boardId } = this.props

    if (!this.campaign) {
      return null
    }

    let campaign = this.campaign
    let alias = ""
    let title = ""
    let location = ""
    let notice

    if (campaign) {
      alias = '#' + campaign.alias
      if (boardId) {
        alias += `/${boardId}`
      }
      title = campaign.title
      location = (campaign.city && campaign.state) ? `${campaign.city}, ${campaign.state}` : ""

      if (this.board) {
        title += ` / ${this.board.name}`

        if (this.board.sponsorMatch) {
          notice = <React.Fragment>
            <p className={classes.emph}>{numberToMoneyFormat(this.board.totalAmount)} raised!</p>
            <p>Matching {numberToMoneyFormat(this.board.matchedAmount)} of {numberToMoneyFormat(this.board.sponsorMatchLimit)} by {this.board.sponsorName}!</p>
          </React.Fragment>
        } else {
          notice = <React.Fragment>
            <p className={classes.emph}>{numberToMoneyFormat(this.board.totalAmount)} raised!</p>
            {this.board.sponsorName &&
              <p>Sponsored by {this.board.sponsorName}</p>
            }
          </React.Fragment>
        }
      }
    }


    return (
      <Page title={title} hideFooter={true}>
        <MarginRow>
          <div className={classes.root}>
            <Hidden xsDown>
              <Grid container className={classes.content} direction="row" spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.alias}>{alias}</div>
                  <TitleBar title={title} className={classes.titleBar}/>
                  <div className={classes.location}>{location}</div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.media}>
                  {campaign && campaign.videoUrl && !this.coverImageUrl &&
                    <ReactPlayer
                      url={campaign.videoUrl}
                      ref={this.ref}
                      controls={true}
                      playsinline={true}
                      width="100%"
                      height="100%"
                    />
                  }
                  {this.coverImageUrl &&
                    <img className={classes.coverImage}
                         src={this.coverImageUrl}
                         alt={title}>
                    </img>
                    // <CardMedia className={classes.media}
                    //            image={this.coverImageUrl}
                    //            title={title}>
                    // </CardMedia>
                  }
                  {this.board && this.renderStatus()}
                  { this.renderCustom() }
                </Grid>

                {!this.board && !this.donationForm &&
                  <Grid item xs={12} sm={6} className={classes.donationCard}>
                    {campaign &&
                      <Paper>
                        <Card className={classes.card}>
                          { this.renderStatusButtons() }
                          { this.renderDonations() }
                        </Card>
                      </Paper>
                    }
                  </Grid>
                }

                {this.board && !this.donationForm &&
                  <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <BINKOBoard board={this.board} className={classes.board} onDonate={this.onDonate}/>
                      </Grid>
                      {notice &&
                        <Grid item className={classes.sponsor}>
                          {notice}
                        </Grid>
                      }
                      <Grid item xs={12} className={classes.actionButtons}>
                        <DialogButton variant="secondary" fullWidth onClick={this.onShare}>
                          Share
                        </DialogButton>
                      </Grid>
                      <Visible if={this.canEdit}>
                        <Grid item xs={12} className={classes.actionButtons}>
                          <DialogButton variant="primary" fullWidth onClick={this.onEdit}>
                            Edit
                          </DialogButton>
                        </Grid>
                      </Visible>
                      <Grid item xs={12} className={classes.actionButtons}>
                        <DialogButton variant="tertiary" fullWidth onClick={this.onBack}>
                          Back
                        </DialogButton>
                      </Grid>
                    </Grid>
                  </Grid>
                }

                {this.donationForm &&
                  <Grid item xs={12} sm={6}>
                    {this.donationForm}
                  </Grid>
                }
              </Grid>
            </Hidden>

            <Hidden smUp>
              <Grid container className={classes.content} direction="row" spacing={0}>
                <Grid item xs={12} className={classes.media}>
                  {campaign && campaign.videoUrl && !this.coverImageUrl &&
                    <ReactPlayer
                      url={campaign.videoUrl}
                      ref={this.ref}
                      controls={true}
                      playsinline={true}
                      width="100%"
                      height="100%"
                    />
                  }
                  {this.coverImageUrl &&
                  <CardMedia className={classes.media}
                             image={this.coverImageUrl}
                             title={title}>
                  </CardMedia>
                  }
                </Grid>
                <Grid item xs={12} className={classes.titleBarXs}>
                  <div className={classes.alias}>{alias}</div>
                  <TitleBar title={title} variant="titleXs" className={classes.titleBar}/>
                  <div className={classes.location}>{location}</div>
                </Grid>
                <Grid item xs={12}>
                  {this.renderStatusButtons()}
                  {this.renderCustom()}
                  {this.renderDonations()}
                </Grid>
              </Grid>
            </Hidden>
          </div>
          {this.thankYouDialog}
        </MarginRow>
      </Page>
    )
  }

  renderCustom() {
    const { classes } = this.props

    let overview = null
    if (this.campaign) {
      if (this.campaign.custom && this.campaign.custom.overview) {
        overview = parseHTML(this.campaign.custom.overview)
      } else {
        overview = this.campaign.description
      }
    }

    return (
      <div className={classes.description}>
        {overview}
      </div>
    )
  }

  onEditorChange = () => {
    return
  }

  renderStatusButtons() {
    const {classes, userStore} = this.props

    if (!this.campaign) {
      return null
    }

    const campaign = this.campaign

    let status
    let progress = campaign.goalAmount ? (campaign.totalAmount / campaign.goalAmount) * 100.0 : 0

    if (campaign) {
      status =
        <React.Fragment><span className={classes.amountRaised}>{`${numberToMoneyFormat(campaign.totalAmount, 0)}`}</span>&nbsp;{`raised of ${numberToMoneyFormat(campaign.goalAmount, 0)} goal`}</React.Fragment>
    }

    let disablePlay = false
    let playLabel = campaign.campaignType === CampaignType.NoGame ? `Donate!` : `Play!`
    let dates = ""
    const today = getISODateToday()
    if (campaign.startAt) {
      if (campaign.startAt > today) {
        const startAtDate = isoToLocalDate(campaign.startAt)
        dates = `Starting ${format(startAtDate, "MMMM d, yyyy")}`
        disablePlay = false
      } else if (campaign.endAt) {
        const endAtDate = isoToLocalDate(campaign.endAt)
        if (campaign.endAt >= today) {
          dates = `Ending ${format(endAtDate, "MMMM d, yyyy")}`
        } else {
          dates = `Ended ${format(endAtDate, "MMMM d, yyyy")}`
          disablePlay = false
        }
      }
    }

    if (userStore!.isAuthenticated && userStore!.user!.accountId === campaign.accountId) {
      disablePlay = false
    }

    this.disablePlay = disablePlay
    if (disablePlay) {
      this.disableMessage = dates
    } else {
      this.disableMessage = ""
    }

    return (
      <CardContent className={classes.content}>
        <Hidden xsDown>
          <Typography className={classes.status}>
            {status}
          </Typography>
        </Hidden>
        <div className={classes.progressBar}>
          <LinearProgress className={classes.linearProgress}
                          classes={{
                            barColorPrimary: classes.linearProgressColor,
                            determinate: classes.linearProgressDeterminateColor
                          }}
                          variant="determinate" value={progress}/>
        </div>
        <Hidden smUp>
          <Typography className={classes.status}>
            {status}
          </Typography>
        </Hidden>
        <div className={classes.actionButtons}>
          <DialogButton variant="primary" fullWidth onClick={this.onPlay} disabled={disablePlay}>
            {playLabel}
          </DialogButton>
        </div>
        <Typography className={classes.dates}>
          {dates}
        </Typography>
        <div className={classes.actionButtons}>
         <DialogButton variant="secondary" fullWidth onClick={this.onShare}>
            Share
          </DialogButton>
        </div>
      </CardContent>
    )
  }

  renderStatus() {
    // This renders only the status and no buttons
    const {classes, userStore} = this.props

    if (!this.campaign) {
      return null
    }

    const campaign = this.campaign

    let status
    let progress = campaign.goalAmount ? (campaign.totalAmount / campaign.goalAmount) * 100.0 : 0

    if (campaign) {
      status =
        <React.Fragment><span className={classes.amountRaised}>{`${numberToMoneyFormat(campaign.totalAmount, 0)}`}</span>&nbsp;{`raised of ${numberToMoneyFormat(campaign.goalAmount, 0)} goal`}</React.Fragment>
    }

    let disablePlay = false
    let dates = ""
    const today = getISODateToday()
    if (campaign.startAt) {
      if (campaign.startAt > today) {
        const startAtDate = isoToLocalDate(campaign.startAt)
        dates = `Starting ${format(startAtDate, "MMMM d, yyyy")}`
        disablePlay = true
      } else if (campaign.endAt) {
        const endAtDate = isoToLocalDate(campaign.endAt)
        if (campaign.endAt >= today) {
          dates = `Ending ${format(endAtDate, "MMMM d, yyyy")}`
        } else {
          dates = `Ended ${format(endAtDate, "MMMM d, yyyy")}`
          disablePlay = true
        }
      }
    }

    if (userStore!.isAuthenticated && userStore!.user!.accountId === campaign.accountId) {
      disablePlay = false
    }

    this.disablePlay = disablePlay
    if (disablePlay) {
      this.disableMessage = dates
    } else {
      this.disableMessage = ""
    }

    return (
      <div className={classes.statusContent}>
        <Typography className={classes.status}>
          {status}
        </Typography>
        <div className={classes.progressBar}>
          <LinearProgress className={classes.linearProgress}
                          classes={{
                            barColorPrimary: classes.linearProgressColor,
                            determinate: classes.linearProgressDeterminateColor
                          }}
                          variant="determinate" value={progress}/>
        </div>
        <Typography className={classes.dates}>
          {dates}
        </Typography>
      </div>
    )
  }

  renderDonations() {
    const { classes } = this.props

    if (!this.campaign!.donations || !this.campaign!.donations.length) {
      return null
    }

    return (
      <div className={classes.donations}>
        <Typography className={classes.subtitle}>Donors</Typography>
        <Grid container direction="column">
          {this.campaign!.donations.map((d: Donation) =>
            <Grid item className={classes.donation}>
              <div className={classes.donorName}>{d.name}</div>
              <div className={classes.donorMessage}>{d.message}</div>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }

  loadCampaign = async () => {
    const { campaignStore, campaignId } = this.props
    let campaign
    if (campaignId) {
      campaign = await campaignStore!.getCampaign(campaignId)
      if (campaign && campaign.donations) {
        // Sort donations in descending order
        campaign.donations.sort((a: Donation, b: Donation) => b.createdAt.localeCompare(a.createdAt))
      }
    }
    return campaign
  }

  onPlay = async () => {
    const { campaignId, boardId } = this.props
    if (this.campaign!.campaignType === CampaignType.NoGame) {
      const boardId = await this.getNoBoardId()
      if (boardId) {
        ControlTower.route(`${Routes.board}/${boardId}/donate`)
      }
    } else {
      if (boardId) {
        const board = this.campaign!.boards.find((b: Board) => b.alias === boardId || b.id === boardId)
        if (board) {
          ControlTower.route(`${Routes.board}/${board.id}`)
          return
        }
      }
      ControlTower.route(`${Routes.campaign}/${campaignId}/boards`)
    }
  }

  onDonate = (row: number, col: number, amount: number, freq: string, other: string = "") => {
    const {notify} = this.props

    if (this.disablePlay) {
      notify!.show("warning", this.disableMessage)
      return
    }
    const spaceId = `${row},${col}`
    const search = {search: `space=${spaceId}&amount=${amount}&freq=${freq}&other=${other}`}
    this.donationForm = <DonationForm boardId={this.board!.id} location={search} onCancel={this.onCancel}/>
  }

  onCancel = () => {
    this.donationForm = undefined
  }

  onEdit = () => {
    if (this.board) {
      ControlTower.route(`${Routes.campaign}/${this.campaign!.alias}/boardEdit/${this.board!.id}`)
    }
  }

  onShare = () => {
    const { campaignId } = this.props
    if (this.board) {
      const route = (this.board!.alias) ? `${Routes.share}/${this.campaign!.alias}/${this.board!.alias}` : `${Routes.share}/${this.campaign!.alias}/${this.board!.id}`
      ControlTower.route(route)
    } else {
      ControlTower.route(`${Routes.share}/${campaignId}`)
    }
  }

  onBack = () => {
    if (this.board) {
      ControlTower.route(`${Routes.campaign}/${this.campaign!.alias}/boards`)
    } else {
      ControlTower.route(Routes.home)
    }
  }

  getNoBoardId = async () => {
    const { campaignStore } = this.props

    let boardId
    if (this.campaign!.boards && this.campaign!.boards.length > 0) {
      boardId = this.campaign!.boards[0].id
    } else {
      const input: CreateBoardInput = {
        accountId: this.campaign!.accountId,
        campaignId: this.campaign!.id,
        name: "None",
        backgroundColor: "#003d5c"
      }

      const board = await campaignStore!.createBoard(input)
        .catch((err: Error) => {
          // TODO
        })

      if (board) {
        console.log(`Created None Board ${board.id}`)
        boardId = board.id
      }
    }

    return boardId
  }

  onDonationSuccess = async (donationId: string) => {
    const { campaignStore } = this.props

    const donation = await campaignStore!.getDonation(donationId)
    if (donation) {
      this.thankYouDialog = <DonationThankYouDialog donation={donation} onClose={this.onCloseThankYouDialog}/>
    }
  }

  onCloseThankYouDialog = () => {
    this.donationStatus = null
    this.donationId = null
    this.thankYouDialog = undefined
  }

  onDonationCancel = async (donationId: string) => {
    // Delete is currently not allowed for donors. unpaid status will keep it from being displayed.
    // const donation = await campaignStore!.deleteDonation(donationId)
    // if (donation) {
    //   console.log(`donation cancelled`)
    // }
    // Clear regardless of result
    this.donationId = null
    this.donationStatus = null
  }

}
export default withTheme((withStyles(styles)(CampaignPage)))