import * as React from 'react'
import {createStyles, withStyles, Theme, WithStyles} from '@material-ui/core/styles'
import {Grid, Hidden} from "@material-ui/core";
import { Link } from '@reach/router'
import MarginRow from "../page/MarginRow";
import ControlTower from "../ControlTower";
import config from 'react-global-configuration';
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";

const styles = (theme: Theme) => createStyles({
  container: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    // backgroundColor: theme.palette.background.default,
    borderTop: "1px solid",
    borderTopColor: theme.palette.divider,
  },
  links: {
    flexGrow: 1,
    textAlign: "center",
    padding: "8px 8px 8px 8px"
  },
  linkText: {
    color: theme.palette.text.primary,
    fontSize: 13,
    fontWeight: 400,
    letterSpacing: 0.5,
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
  },
  copyright: {
    flexGrow: 1,
    textAlign: "center",
    padding: "0px 8px 8px 8px"
  }
})

interface IFooterProps {
  privacyPolicy?: PrivacyPolicy
  termsOfService?: TermsOfService
}

@inject("privacyPolicy", "termsOfService")
@observer
class Footer extends React.Component<WithStyles<typeof styles> & IFooterProps> {

  @observable showPrivacyPolicy: boolean = false
  @observable showTermsOfUse: boolean = false

  render() {
    const {classes} = this.props
    const version = config.get("version")

    return (
      <div className={classes.container}>
        <MarginRow>
          <Grid container direction="row">
            <Grid item xs={12} className={classes.links}>
              <Link to="." className={classes.linkText} onClick={this.onClickContact}>
                <Hidden xsDown>Contact&nbsp;Us</Hidden>
                <Hidden smUp>Contact</Hidden>
              </Link>
              <Link to="." className={classes.linkText} onClick={this.onClickPrivacyPolicy}>
                <Hidden xsDown>Privacy&nbsp;Policy</Hidden>
                <Hidden smUp>Privacy</Hidden>
              </Link>
              <Link to="." className={classes.linkText} onClick={this.onClickTermsOfService}>
                <Hidden xsDown>Terms&nbsp;of&nbsp;Use</Hidden>
                <Hidden smUp>Terms</Hidden>
              </Link>
              <span className={classes.linkText}>
                <Hidden xsDown>Version&nbsp;{version}</Hidden>
                <Hidden smUp>v{version}</Hidden>
              </span>
              <Link to="." className={classes.linkText} onClick={this.onClickCopyright}>
                <Hidden xsDown>&copy;&nbsp;2020-2022&nbsp;Purposeful&nbsp;Software&nbsp;LLC</Hidden>
                <Hidden smUp>&copy;&nbsp;Purposeful</Hidden>
              </Link>
              {/*<span className={classes.copyright}>*/}
              {/*  <Hidden xsDown>&copy;&nbsp;2020&nbsp;Heroic&nbsp;Development&nbsp;LLC</Hidden>*/}
              {/*  <Hidden smUp>&copy;&nbsp;2020</Hidden>*/}
              {/*</span>*/}
            </Grid>
          </Grid>
        </MarginRow>
      </div>
    )
  }

  onClickContact = () => {
    ControlTower.open(`mailto:info@purposefulsoftware.com`, "_blank")
  }

  onClickPrivacyPolicy = () => {
    const { privacyPolicy } = this.props
    if (privacyPolicy) {
      privacyPolicy.show()
    }
  }

  onClickTermsOfService = () => {
    const { termsOfService } = this.props
    if (termsOfService) {
      termsOfService.show()
    }
  }

  onClickCopyright = () => {
    ControlTower.open(`http://www.purposefulsoftware.com`, "_blank")
  }
}

export default withStyles(styles)(Footer)