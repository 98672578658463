import * as React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import {blueGrey} from "@material-ui/core/colors";

const styles = (theme: Theme) => createStyles({
    primaryDialogButton: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      color: theme.palette.primary.contrastText,
      fontSize: 20,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
      "&:disabled": {
        backgroundColor: theme.palette.primary.dark,
      }
    },
    secondaryDialogButton: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      borderColor: theme.palette.secondary.main,
      borderWidth: 1,
      fontSize: 20,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      "&:disabled": {
        backgroundColor: theme.palette.secondary.dark,
      }
    },
    tertiaryDialogButton: {
      backgroundColor: blueGrey[500],
      color: theme.palette.text.primary,
      borderColor: blueGrey[500],
      borderWidth: 1,
      fontSize: 20,
      "&:hover": {
        backgroundColor: blueGrey[700],
      }
    },
    textDialogButton: {
      color: theme.palette.secondary.light,
      borderWidth: 0,
      borderStyle: "none",
      fontSize: 20,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      }
    }

})

interface IDialogButtonProps {
    onClick?: any
    type?: string, 
    variant?: any
    disabled?: boolean
    fullWidth?: boolean
    width?: any
    customClassName?: string
    id?: string
}
  
class DialogButton extends React.Component<WithStyles<typeof styles> & IDialogButtonProps> {
      render() {
        const { classes, onClick, disabled, fullWidth, width } = this.props

        const typeProp = this.props.type || "button"
        const variantProp = this.props.variant || "contained"
        const childrenProp = this.props.children || 'Button'

        let variant: any
        let className: any = this.props.customClassName ? this.props.customClassName : null
        let color: any
        let type: any
        let style = width ? {width: width} : { }

        switch (variantProp) {
            case "primary":
                variant = "contained"
                className = className ? className : classes.primaryDialogButton
                color = "primary"
                type = this.props.type || "submit"
                break
            case "secondary":
                variant = "contained"
                className = className ? className : classes.secondaryDialogButton
                color = "primary"
                type = typeProp
                break;
            case "tertiary":
                variant = "contained"
                className =  className ? className : classes.tertiaryDialogButton
                color = "primary"
                type = typeProp
                break;
            case "text":
                variant = "text"
                className =  className ? className : classes.textDialogButton
                color = "secondary"
                type = typeProp
                break;

            default:
                variant = variantProp
                className = className ? className : classes.primaryDialogButton
                color = "primary"
                type = typeProp
        }
    
        return (
            <Button type={type} 
                className={className}
                style={style}
                size="small"
                variant={variant} 
                color={color} 
                onClick={onClick}
                disabled={disabled}
                fullWidth={fullWidth}
                id={this.props.id}
            >
               {childrenProp}
            </Button>
        )
    }
}

export default withStyles(styles)(DialogButton)
