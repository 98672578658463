import {observable} from "mobx";
import Tracking from "../Tracking";

class TermsOfService {

  @observable open: boolean = false

  onClose: any

  show = (onClose?: any) => {
    this.open = true
    this.onClose = onClose
    Tracking.modalView("TermsOfServiceDialog")
  }

  close = () => {
    this.open = false
    if (this.onClose) {
      this.onClose()
    }
  }
}

export default TermsOfService