import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles, DialogActions, DialogContent, DialogContentText, FormGroup,
  Grid,
  Paper,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import {inject, observer} from "mobx-react";
import AccountStore from "../../stores/AccountStore";
import {observable, when} from "mobx";
import Progress from "../../components/Progress";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import DialogButton from "../../components/form/DialogButton";
import ControlTower, {Routes} from "../../components/ControlTower";
import {
  UpdateAccountInput,
} from "../../API";
import Notify from "../../components/notify/Notify";
import Account from "../../model/Account";
import Tracking from "../../components/Tracking";
import UserStore from "../../stores/UserStore";
import FormGroupSpacer from "../../components/form/FormGroupSpacer";
import StateSelector from "../../components/form/StateSelector";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  message: {
    color: theme.palette.error.main,
    marginTop: 10
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: 4
    }
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
  checkboxLabel: {
    color: theme.palette.text.secondary,
    marginLeft: 0,
  },
  resendButton: {
    color: theme.palette.secondary.light,
    fontSize: 16,
    paddingLeft: 0,
  },
  radioGroup: {
    margin: 0,
    padding: 0
  },
  formGroupField: {
    flexGrow: 1,
  },
  stateSelector: {
    minWidth: 60,
    paddingTop: 5,
  },
  postalCodeField: {
    flexGrow: 0,
    width: 100,
    paddingTop: 5
  },
})

interface IAccountEditPageProps {
  accountId?: string
  accountStore?: AccountStore
  userStore?: UserStore
  progress?: Progress
  notify?: Notify
}

@inject("accountStore", "progress", "notify")
@observer
class AccountEditPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountEditPageProps & WithTheme> {

  @observable isLoading = true
  @observable account?: Account
  @observable values = {
    name: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    orgType: "",
    webSite: "",
    about: "",
    active: true
  }
  @observable message = ""

  componentDidMount () {
    const { accountId, accountStore, progress} = this.props
    this.isLoading = true
    when(
      () => !accountStore!.isLoading,
      async () => {
        if (accountId) {
          progress!.show("AccountEditPage")
          this.account = await accountStore!.getAccount(accountId!)
          if (this.account) {
            this.values = {
              name: this.account.name,
              address: this.account.address,
              city: this.account.city,
              state: this.account.state,
              postalCode: this.account.postalCode,
              orgType: this.account.orgType,
              webSite: this.account.webSite,
              about: this.account.about,
              active: this.account.active
            }
          }
          this.isLoading = false
          progress!.hide("AccountEditPage")
        } else {
          this.isLoading = false
          progress!.hide("AccountEditPage")
        }
      }
    )
  }


  render() {
    const { classes, accountStore } = this.props

    const title = "Edit Account"

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container className={classes.root} direction="column">
            <TitleBar title={title} className={classes.titleBar}>
            </TitleBar>
            <Paper className={classes.dialogPaper}>
              <FormValidator onSubmit={this.onSubmit} autoComplete="off"
                             name="AccountEditForm" id="AccountEditForm" className={classes.form}>
                <DialogContent className={classes.dialogContent}>
                  <TextFieldValidator
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Organization Name"
                    type="text"
                    variant="standard"
                    validators={{required:true}}
                    onChange={this.onChange}
                    value={this.values.name}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="orgType"
                    label="Organization Type"
                    type="text"
                    variant="standard"
                    validators={{required:false}}
                    onChange={this.onChange}
                    fullWidth
                    autocompleteOptions={{
                      value: this.values.orgType,
                      options: accountStore!.getOrgTypes()
                    }}
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="address"
                    label="Address"
                    variant="standard"
                    placeholder=""
                    type="text"
                    validators={{required:false}}
                    value={this.values.address}
                    onChange={this.onChange}
                    fullWidth
                  />
                  <FormGroup row >
                    <TextFieldValidator
                      margin="dense"
                      name="city"
                      label="City"
                      variant="standard"
                      placeholder=""
                      type="text"
                      validators={{required:true}}
                      value={this.values.city}
                      onChange={this.onChange}
                      className={classes.formGroupField}
                    />
                    <FormGroupSpacer />
                    <StateSelector
                      isRequired={true}
                      value={this.values.state}
                      onChange={this.onChange}
                      className={classes.stateSelector}
                    />
                    <FormGroupSpacer />
                    <TextFieldValidator
                      type="text"
                      validators={{ required: false, isPostalCode: null }}
                      name="postalCode"
                      label="ZIP"
                      value={this.values.postalCode}
                      onChange={this.onChange}
                      className={classes.postalCodeField}
                    />
                  </FormGroup>
                  <TextFieldValidator
                    margin="dense"
                    name="webSite"
                    label="Web Site"
                    type="text"
                    variant="standard"
                    validators={{required:false}}
                    onChange={this.onChange}
                    value={this.values.webSite}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="about"
                    label="About The Organization"
                    type="text"
                    variant="standard"
                    validators={{required: false}}
                    onChange={this.onChange}
                    value={this.values.about}
                    fullWidth
                    multiline
                    rows={4}
                  />
                  <DialogContentText className={classes.message}>
                    {this.message}
                  </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <DialogButton variant="primary" fullWidth>
                    Save
                  </DialogButton>
                  <DialogButton variant="tertiary" fullWidth onClick={this.onCancel}>
                    Cancel
                  </DialogButton>
                </DialogActions>
              </FormValidator>
            </Paper>
          </Grid>

        </MarginRow>
      </Page>
    )
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  onCancel = () => {
    ControlTower.route(`${Routes.account}/info`)
  }

  onSubmit = async () => {
    const { accountId, accountStore, notify } = this.props

    const values = this.values

    if (accountId) {
      Tracking.event({action: 'UpdateAccount'})
      const input: UpdateAccountInput = {
        id: accountId!,
        name: values.name,
        about: values.about,
        address: values.address,
        city: values.city,
        state: values.state,
        postalCode: values.postalCode,
        orgType: values.orgType,
        webSite: values.webSite,
        active: values.active
      }
      let account = await accountStore!.updateAccount(input)
        .catch((err: Error) => {
          notify!.show("error", "Unable to update account")
        })

      if (account) {
        notify!.show("success", "Account updated!")

        this.onCancel()
      }
    } else {
      // New Account (later)
    }

  }

}

export default withTheme((withStyles(styles)(AccountEditPage)))