import * as React from 'react'
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import Header from "./Header";
import NotifyBar from "../notify/NotifyBar";
import ConfirmDialog from "../confirm/ConfirmDialog";
import AlertDialog from "../alert/AlertDialog";
import Footer from "./Footer";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    minHeight: "100vh",
    // overflow: "hidden"
  },
  contentStyle: {
    display: "flex",
    flex: "auto",
    flexGrow: 1,
    // minHeight: "100%",
    // overflow: "scroll"
  },
  header: {
    flex: "none",
    height: 56
  },
  footer: {
    flex: "none",
    height: 40,
    width: "100%",
    alignSelf: "bottom",
    marginTop: theme.spacing(1)
  }
})

interface IPageProps {
  hideHeader?: boolean
  hideFooter?: boolean
  title?: string
}

class Page extends React.Component<WithStyles<typeof styles> & IPageProps> {

  componentDidMount() {
    if (this.props.title) {
      document.title = `FunGives - ${this.props.title}`
    } else {
      document.title = "FunGives"
    }
  }


  componentDidUpdate(prevProps: any) {
    if (prevProps.title !== this.props.title) {
      this.componentDidMount()
    }
  }

  render() {
    const { classes, hideHeader, hideFooter } = this.props

    return (
      <div className={classes.rootStyle}>
        {!hideHeader &&
          <div className={classes.header}>
            <Header />
          </div>
        }
        <AlertDialog />
        <ConfirmDialog />
        <NotifyBar />
        <div className={classes.contentStyle}>
          {this.props.children}
        </div>
        {!hideFooter &&
          <div className={classes.footer}>
            <Footer />
          </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Page)