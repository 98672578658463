import * as React from "react";
import {inject} from "mobx-react";
import Checkbox, {CheckboxProps} from "@material-ui/core/Checkbox";
import {createStyles, FormControl, FormHelperText, Theme, withStyles, WithStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  error: {
    color: theme.palette.error.main,
    width: 48,
    height: 30
  },
  noError: {
    width: 48
  }
})

interface ICheckboxValidatorProps {
  onChange?: React.EventHandler<any>
  formvalidator?: any
}

type CheckboxValidatorProps = CheckboxProps | ICheckboxValidatorProps

@inject("formvalidator")
class CheckboxValidator extends React.Component<WithStyles<typeof styles> & CheckboxValidatorProps> {

  state = {
    checked: false,
    isValid: true // Start valid until onChange() or validate() event
  }

  componentDidMount() {
    const {formvalidator} = this.props as ICheckboxValidatorProps
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.currentTarget;
    const { props } = this
    const { onChange } = props as ICheckboxValidatorProps

    this.setState({checked, isValid:checked})

    if (onChange) {
      onChange(event);
    }
  }

  validate = (value: boolean): boolean => {
      // Ignore value because only checked matters
      this.setState({isValid:this.state.checked})
      return this.state.checked
  }

    render() {
    const {
      onChange,
      classes,
      ...rest
    } = this.props

    const className = (this.state.isValid) ? classes.noError : classes.error

    return (
      <FormControl margin="dense">
        <Checkbox
          {...rest}
          className={className}
          onChange={this.onChange}
        />
        <FormHelperText error={!this.state.isValid}>
          {this.state.isValid ? "" : "Required"}
        </FormHelperText>
      </FormControl>    )
  }

}

export default withStyles(styles)(CheckboxValidator)