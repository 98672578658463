import ReactGA from 'react-ga4';
import {Logger} from "./Logger";

interface ITrackingEventArgs {
  category?: string
  action?: string
  value?: number
  label?: string
}

class Tracking {

  static initialized = false
  static UUIDPattern = new RegExp(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/)


  static initialize(trackingId: string, options: any) {
    if (options) {
      ReactGA.initialize([
        {
          trackingId: trackingId,
          gaOptions: options
        }])
    } else {
      ReactGA.initialize(trackingId)
    }
    this.initialized = true
    Logger.debug(`Tracking.initialize: ${trackingId} options: ${JSON.stringify(options)}`)
  }

  static set(args: any) {
    if (this.initialized) {
      ReactGA.set(args)
      Logger.debug(`Tracking.set: ${JSON.stringify(args)}`)
    }
  }

  static pageView(path: string) {
    if (this.initialized) {
      const cleaned = Tracking.cleanPath(path)
      ReactGA.send({hitType: "pageview", page: cleaned})
      Logger.debug(`Tracking.pageView: ${cleaned}`)
    }
  }

  static modalView(name: string) {
    if (this.initialized) {
      // ReactGA.modalview(name)
      Logger.debug(`Tracking.modalview: ${name}`)
    }
  }

  static event(args: ITrackingEventArgs) {
    if (this.initialized) {
      const eventArgs = {
        category: args.category || Tracking.cleanPath(window.location.pathname),
        action: args.action || "",
        value: args.value,
        label: args.label
      }
      ReactGA.event(eventArgs)
      Logger.debug(`Tracking.event: ${JSON.stringify(eventArgs)}`)
    }
  }

  static cleanPath(path: string) {
    // Clean out the UUIDs from the path for analytic reporting
    const cleaned = path.replace(Tracking.UUIDPattern, "uuid")
    return cleaned
  }

}

export default Tracking