/* tslint:disable */
import gql from "graphql-tag";

export const createAccount = /* GraphQL */ gql`
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ownerId
      name
      about
      address
      city
      state
      postalCode
      orgType
      webSite
      stripeId
      paymentProvider
      paymentOptions
      active
    }
  }
`;

export const updateAccount = /* GraphQL */ gql`
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ownerId
      name
      about
      address
      city
      state
      postalCode
      orgType
      webSite
      stripeId
      paymentProvider
      paymentOptions
      active
    }
  }
`;

export const deleteAccount = /* GraphQL */ gql`
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ownerId
      name
      about
      address
      city
      state
      postalCode
      orgType
      webSite
      stripeId
      paymentProvider
      paymentOptions
      active
    }
  }
`;

export const createUser = /* GraphQL */ gql`
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      active
      accountId
      firstName
      lastName
      email
      phone
      role
    }
  }
`;

export const updateUser = /* GraphQL */ gql`
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      active
      accountId
      firstName
      lastName
      email
      phone
      role
    }
  }
`;

export const deleteUser = /* GraphQL */ gql`
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      active
      accountId
      firstName
      lastName
      email
      phone
      role
    }
  }
`;

export const createCampaign = /* GraphQL */ gql`
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      accountId
      title
      alias
      description
      city
      state
      campaignType
      goalAmount
      donatedAmount
      publishAt  
      startAt
      endAt
      layout
      custom
      coverUrl
      videoUrl
      shareUrl
      contactInfo
      paymentInfo
    }
  }
`;
export const updateCampaign = /* GraphQL */ gql`
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      accountId
      title
      alias  
      description
      city
      state
      campaignType
      goalAmount
      donatedAmount
      publishAt
      startAt
      endAt
      layout
      custom
      coverUrl
      videoUrl
      shareUrl
      contactInfo
      paymentInfo
    }
  }
`;
export const deleteCampaign = /* GraphQL */ gql`
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      accountId
      title
      alias
      description
      city
      state
      campaignType
      goalAmount
      donatedAmount
      publishAt
      startAt
      endAt
      layout
      custom
      coverUrl
      videoUrl
      shareUrl
      contactInfo
      paymentInfo
    }
  }
`;

export const createBoard = /* GraphQL */ gql`
  mutation CreateBoard(
    $input: CreateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    createBoard(input: $input, condition: $condition) {
        id
        createdAt
        updatedAt
        accountId
        campaignId
        name
        alias
        sponsorId
        sponsorName
        sponsorEmail
        sponsorMatch
        sponsorMatchLimit
        layout
        backgroundColor
        backgroundImage
        coverImage
    }
  }
`;
export const updateBoard = /* GraphQL */ gql`
  mutation UpdateBoard(
    $input: UpdateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    updateBoard(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      accountId
      campaignId
      name
      alias  
      sponsorId
      sponsorName
      sponsorEmail
      sponsorMatch
      sponsorMatchLimit
      layout
      backgroundColor
      backgroundImage
      coverImage
    }
  }
`;
export const deleteBoard = /* GraphQL */ gql`
  mutation DeleteBoard(
    $input: DeleteBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    deleteBoard(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      accountId
      campaignId
      name
      alias  
      sponsorId
      sponsorName
      sponsorEmail
      sponsorMatch
      sponsorMatchLimit
      layout
      backgroundColor
      backgroundImage
      coverImage  
    }
  }
`;

export const createDonation = /* GraphQL */ gql`
  mutation CreateDonation(
    $input: CreateDonationInput!
    $condition: ModelDonationConditionInput
  ) {
    createDonation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      accountId
      campaignId
      boardId
      spaceId
      amount
      frequency
      other
      name
      email
      message
      isAnonymous
      isExistingDonor  
      paymentStatus  
    }
  }
`;
export const updateDonation = /* GraphQL */ gql`
  mutation UpdateDonation(
    $input: UpdateDonationInput!
    $condition: ModelDonationConditionInput
  ) {
    updateDonation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      accountId
      campaignId
      boardId
      spaceId
      amount
      frequency
      other  
      name
      email
      message
      isAnonymous
      isExistingDonor
      paymentStatus  
    }
  }
`;
export const deleteDonation = /* GraphQL */ gql`
  mutation DeleteDonation(
    $input: DeleteDonationInput!
    $condition: ModelDonationConditionInput
  ) {
    deleteDonation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      accountId
      campaignId
      boardId
      spaceId
      amount
      frequency
      other
      name
      email
      message
      isAnonymous
      isExistingDonor
      paymentStatus  
    }
  }
`;