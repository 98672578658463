import React from "react";
import {createStyles, Theme, WithStyles, WithTheme} from "@material-ui/core";
import {withStyles, withTheme} from "@material-ui/core/styles";

const styles = (theme: Theme) => createStyles({
  formGroupSpacing: {
    flexGrow: 0,
    width: 10,
  }
})

export class FormGroupSpacer extends React.Component<WithStyles<typeof styles> & WithTheme> {

  render() {
    const { classes } = this.props
    return (
      <div className={classes.formGroupSpacing}/>
    )
  }
}

export default withTheme((withStyles(styles)(FormGroupSpacer)))