import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles,
  DialogActions,
  DialogContent,
  Grid,
  Paper, Select,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import {inject, observer} from "mobx-react";
import {makeObservable, observable, when} from "mobx";
import Campaign from "../../model/Campaign";
import Progress from "../../components/Progress";
import UserStore from "../../stores/UserStore";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Tracking from "../../components/Tracking";
import {CampaignType, CreateCampaignInput, PaymentProvider, UpdateAccountInput, UpdateCampaignInput} from "../../API";
import ControlTower, {Routes} from "../../components/ControlTower";
import Notify from "../../components/notify/Notify";
import DialogButton from "../../components/form/DialogButton";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import ProgressButton from "../../components/form/ProgressButton";
import {createUUID, getErrorMessage, getISODateFromDate, isoToLocalDate} from "../../stores/StoreUtilities";
import AccountStore from "../../stores/AccountStore";
import {format, parse} from "date-fns";
import Account from "../../model/Account";
import User from "../../model/User";
import config from "react-global-configuration";
import PaymentAPI from "../../apis/PaymentAPI";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  content: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: 4
    }
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  divLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1)
  },
  fieldGroup: {
    marginTop: theme.spacing(1),
    marginBOttom: theme.spacing(1)
  },
  selectField: {
    width: "100%",
  },
  fileInput: {
    width: 340
  },
  campaignCard: {
    display: "flex",
    justifyContent: "center"
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 800,
  },
  media: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vw * (9/16))',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(50vw * (9/16))',
      maxHeight: 277 // 259
    },
    maxHeight: 540,
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  description: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingTop: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
      padding: 0
    },
    borderBottom: "1px solid rgba(255,255,255,0.7)",
    marginBottom: theme.spacing(1)
  },
})

interface ICampaignPaymentSettingsProps {
  campaignId?: string
  campaign?: Campaign
  userStore?: UserStore
  accountStore?: AccountStore
  s3UrlCacheStore?: S3UrlCacheStore
  paymentAPI?: PaymentAPI
  progress?: Progress
  notify?: Notify
  onSave?: any
}

@inject("userStore", "accountStore", "paymentAPI", "progress", "notify", "s3UrlCacheStore")
@observer
class CampaignPaymentSettings extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICampaignPaymentSettingsProps & WithTheme> {

  @observable isLoading = true
  @observable account?: Account
  @observable user?: User
  @observable campaign?: Campaign
  @observable preview?: Campaign
  @observable previewUrl? : string
  @observable values = {
    campaignId: "",
    title: "",
    campaignType: "BINKO",
    goalAmount: 0,
    startAt: "",
    endAt: "",
    paymentSource: "Stripe",
    monthlyGivingUrl: "",
    oneTimeGivingUrl: ""
  }
  @observable isValid: boolean = true
  @observable isProcessing = false
  @observable progressValue: number = 0
  @observable progressText: string = ""
  @observable isStripeSetup: boolean | undefined = true

  stripeAccount?: any

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    const { campaign, campaignId, userStore, accountStore, progress} = this.props
    this.isLoading = true
    progress!.show("CampaignPaymentSettings")
    when(
      () => !userStore!.isLoading,
      async () => {
        this.account = accountStore!.account
        this.user = userStore!.user

        this.isStripeSetup = await this.checkStripeSetup()

        let useCampaign
        if (campaign) {
          useCampaign = campaign
        } else if (campaignId) {
          useCampaign = await accountStore!.getCampaign(campaignId!)
        }

        if (useCampaign) {
          this.values = {
            campaignId: useCampaign.id,
            title: useCampaign.title,
            campaignType: useCampaign.campaignType ? useCampaign.campaignType.toString() : "BINKO",
            goalAmount: useCampaign.goalAmount,
            startAt: useCampaign.startAt ? format(isoToLocalDate(useCampaign.startAt), "M/d/yyyy") : "",
            endAt: useCampaign.endAt ? format(isoToLocalDate(useCampaign.endAt), "M/d/yyyy") : "",
            paymentSource: useCampaign.paymentInfo ? useCampaign.paymentInfo.paymentSource : "",
            monthlyGivingUrl: useCampaign.paymentInfo ? useCampaign.paymentInfo.monthlyGivingUrl : "",
            oneTimeGivingUrl: useCampaign.paymentInfo ? useCampaign.paymentInfo.oneTimeGivingUrl : ""
          }
          this.campaign = useCampaign
        } else {
          this.values.campaignId = createUUID()
          this.values.paymentSource = accountStore!.account!.paymentProvider ? accountStore!.account!.paymentProvider : ""
          this.values.monthlyGivingUrl = this.account!.paymentOptions ? this.account!.paymentOptions.monthlyGivingUrl : ""
          this.values.oneTimeGivingUrl = this.account!.paymentOptions ? this.account!.paymentOptions.oneTimeGivingUrl : ""
        }
        this.isLoading = false
        progress!.hide("CampaignPaymentSettings")
      }
    )
  }

  render() {
    const { classes, campaign } = this.props

    const title = "Payment Settings"

    if (this.isLoading) {
      return null
    }

    const content =
      <Grid container className={classes.root} direction="column">
        <Paper className={classes.dialogPaper}>
          <TitleBar title={title} className={classes.titleBar}>
          </TitleBar>
          <FormValidator onSubmit={this.onSubmit} autoComplete="off"
                         name="CampaignEditForm" id="CampaignEditForm" className={classes.form}>
            <DialogContent className={classes.dialogContent}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <label className={classes.fieldLabel}>
                    Payment Source
                  </label>
                  <Select
                    native
                    name="paymentSource"
                    value={this.values.paymentSource}
                    onChange={this.onChange}
                    inputProps={{
                      name: 'paymentSource',
                      id: 'paymentSource',
                    }}
                    className={classes.selectField}
                  >
                    <option value="">None</option>
                    <option value="Stripe">Stripe</option>
                    <option value="YoungLife">Young Life Giving</option>
                  </Select>
                </Grid>
                {this.values.paymentSource === "YoungLife" &&
                  <Grid item xs={12} sm={6}>
                    <TextFieldValidator
                      margin="dense"
                      name="monthlyGivingUrl"
                      label="Monthly Giving URL"
                      type="text"
                      variant="standard"
                      validators={{required: false}}
                      onChange={this.onChange}
                      value={this.values.monthlyGivingUrl}
                      fullWidth
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="oneTimeGivingUrl"
                      label="One Time Giving URL"
                      type="text"
                      variant="standard"
                      validators={{required: false}}
                      onChange={this.onChange}
                      value={this.values.oneTimeGivingUrl}
                      fullWidth
                    />
                  </Grid>
                }
                {this.values.paymentSource === "Stripe" &&
                  <Grid item xs={12} sm={6} style={{textAlign:"center"}}>
                    {this.values.paymentSource === "Stripe" && !this.isStripeSetup &&
                      <DialogButton variant="secondary" type="button" onClick={this.onClickSetupStripe}>
                        Setup Stripe Account
                      </DialogButton>
                    }
                    {this.values.paymentSource === "Stripe" && this.isStripeSetup &&
                      <DialogButton variant="secondary" type="button" onClick={this.onClickViewStripe}>
                        View Stripe Account
                      </DialogButton>
                    }
                  </Grid>
                }
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12} >
                  <ProgressButton variant="contained" color="primary" fullWidth
                                  type="submit" processing={this.isProcessing}
                                  onClick={this.onSubmit}>
                    Save
                  </ProgressButton>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <DialogButton variant="tertiary" fullWidth onClick={this.onCancel}>
                    Cancel
                  </DialogButton>
                </Grid>
              </Grid>
            </DialogActions>
          </FormValidator>
        </Paper>
      </Grid>

    if (campaign) {
      return content
    } else {
      return (
        <Page title={title}>
          <MarginRow>
            {content}
          </MarginRow>
        </Page>
      )
    }
  }


  onChange = (event: any) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  onCardAction = (campaign: Campaign) => {
    // Ignore for now
  }

  checkStripeSetup = async () => {
    const { paymentAPI, notify } = this.props

    let isSetup = false

    if (this.account && this.account!.stripeId !== null && this.account!.stripeId !== "") {
      const stripeAccount = await paymentAPI!.getAccount(this.account!.stripeId)
        .catch((error: any) => {
          notify!.show("error", getErrorMessage(error))
        })

      if (stripeAccount) {
        console.log(`stripeAccount = ${JSON.stringify(stripeAccount)}`)
        this.stripeAccount = stripeAccount
        isSetup = stripeAccount.charges_enabled
      }
    }

    return isSetup
  }

  onClickSetupStripe = async () => {
    const { paymentAPI, accountStore, notify } = this.props

    if (!this.stripeAccount) {
      this.stripeAccount = await paymentAPI!.createAccount(this.user!, this.account!)
        .catch((error: any) => {
          notify!.show("error", getErrorMessage(error))
        })

      if (this.stripeAccount) {
        const input: UpdateAccountInput = {
          id: this.account!.id,
          paymentProvider: PaymentProvider.Stripe,
          stripeId: this.stripeAccount.id
        }

        accountStore!.updateAccount(input)
          .catch((error: any) => {
            notify!.show("error", getErrorMessage(error))
            return
          })

        // Update Campaign
        const update: UpdateCampaignInput = {
          id: this.campaign!.id,
          paymentInfo: JSON.stringify({
            paymentSource: "Stripe",
            stripeId: this.stripeAccount.id,
            monthlyGivingUrl: this.values.monthlyGivingUrl,
            oneTimeGivingUrl: this.values.oneTimeGivingUrl
          })
        }

        await accountStore!.updateCampaign(update)
          .catch((err: Error) => {
            this.isProcessing = false
            notify!.show("error", "Unable to update fundraiser payment settings")
          })
      }
    }

    if (this.stripeAccount) {
      const homeUrl = config.get("homeUrl")
      const refreshUrl = `${homeUrl}${Routes.account}/campaign/${this.campaign!.id}/payment`
      const returnUrl = `${homeUrl}${Routes.account}/campaign/${this.campaign!.id}/payment`
      const accountUrls = await paymentAPI!.createAccountLinks(this.stripeAccount.id, refreshUrl, returnUrl)
        .catch((error: any) => {
          notify!.show("error", getErrorMessage(error))
        })

      if (accountUrls) {
        ControlTower.open(accountUrls.url, "_self")
      }
    }
  }

  onClickViewStripe = async () => {
    const { paymentAPI, notify } = this.props

    if (this.stripeAccount) {
      const loginLink = await paymentAPI!.createLoginLink(this.stripeAccount.id)
        .catch((error: any) => {
          notify!.show("error", getErrorMessage(error))
        })

      if (loginLink) {
        ControlTower.open(loginLink.url, "_blank")
      }
    }
  }

  onSubmit = async () => {
    const { campaign, accountStore, notify, onSave } = this.props

    if (!this.isValid) {
      return
    }

    const values = this.values
    const startAt = getISODateFromDate(parse(values.startAt, 'M/d/yyyy', new Date()))
    const endAt = getISODateFromDate(parse(values.endAt, 'M/d/yyyy', new Date()))

    if (campaign) {
      Tracking.event({action: 'UpdateCampaign'})
      this.isProcessing = true

      const input: UpdateCampaignInput = {
        id: values.campaignId,
        paymentInfo: JSON.stringify({
          paymentSource: values.paymentSource,
          stripeId: this.account!.stripeId,
          monthlyGivingUrl: values.monthlyGivingUrl,
          oneTimeGivingUrl: values.oneTimeGivingUrl
        })
      }

      const update = await accountStore!.updateCampaign(input)
        .catch((err: Error) => {
          this.isProcessing = false
          notify!.show("error", "Unable to update fundraiser")
        })

      if (update) {
        this.isProcessing = false
        notify!.show("success", "Fundraiser updated!")
        if (onSave) {
          onSave(update)
        }
        this.onCancel()
      }
    } else {
      this.isProcessing = true

      if (values.campaignId === "") {
        values.campaignId = createUUID()
      }

      const input: CreateCampaignInput = {
          id: values.campaignId,
          accountId: accountStore!.account!.id,
          campaignType: CampaignType[values.campaignType],
          goalAmount: 0,
          donatedAmount: 0,
          startAt: startAt,
          endAt: endAt,
          title: values.title,
          paymentInfo: JSON.stringify({
          paymentSource: values.paymentSource,
          stripeId: this.account!.stripeId,
          monthlyGivingUrl: values.monthlyGivingUrl,
          oneTimeGivingUrl: values.oneTimeGivingUrl
        })
      }

      const campaign = await accountStore!.createCampaign(input)
        .catch((err: Error) => {
          this.isProcessing = false
          notify!.show("error", "Unable to create fundraiser")
        })

      if (campaign) {
        this.isProcessing = false
        notify!.show("success", "Fundraiser created!")
        if (onSave) {
          onSave(campaign)
        }
        ControlTower.route(`${Routes.account}/campaign/${campaign.id}/payment`)
      }
    }

  }

  onCancel = () => {
    const { campaign } = this.props
    if (campaign) {
      // Editing campaign settings
      ControlTower.route(`${Routes.account}/campaign/${campaign.id}`)
    } else {
      // Creating new campaign
      ControlTower.route(`${Routes.accountCampaigns}`)
    }
  }
}

export default withTheme((withStyles(styles)(CampaignPaymentSettings)))