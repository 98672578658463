import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import CampaignStore from "../../stores/CampaignStore";
import {makeObservable, observable} from "mobx";
import Campaign from "../../model/Campaign";
import {Grid} from "@material-ui/core";
import ControlTower, {Routes} from "../../components/ControlTower";
import {Donation} from "../../model/Donation";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import MaterialTable from "material-table";
import {format} from "date-fns";
import {isoToLocalDateTime, numberToMoneyFormat} from "../../stores/StoreUtilities";
import {Board} from "../../model/Board";
import {CreateBoardInput} from "../../API";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  slogan: {
    color: theme.palette.text.secondary
  },
  content: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: 'center',
    width: "100%",
    margin: 0
  },
  table: {

  },
  location: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 400,
    padding: "4px 8px 0 8px"
  },

})

interface IAccountCampaignDonationsProps {
  campaign: Campaign
  userStore?: UserStore
  accountStore?: AccountStore
  campaignStore?: CampaignStore
  progress?: Progress
}

@inject("userStore", "accountStore", "campaignStore", "progress")
@observer
class AccountCampaignDonations extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountCampaignDonationsProps & WithTheme> {

  @observable campaign?: Campaign
  @observable data: any[] = []


  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount () {
    this.campaign = await this.loadCampaign()
  }

  render() {
    const { classes } = this.props

    if (!this.campaign) {
      return null
    }

    return (
      <div className={classes.root}>
          <Grid container className={classes.content} direction="row" spacing={2}>
            <Grid item xs={12} className={classes.table}>
              <MaterialTable
                title="Donation Report"
                columns={[
                  { title: 'Date', field: 'createdAt', type: 'date' },
                  { title: 'Donor', field: 'name'},
                  { title: 'Email', field: 'email'},
                  { title: 'Amount', field: 'amount'},
                  { title: 'Frequency', field: 'frequency'},
                  { title: 'Other', field: 'other'},
                  { title: 'Board', field: 'board'}
                ]}
                data={this.data}
                options={{
                  toolbar: true,
                  exportButton: true,
                  showTitle: true,
                  search: false,
                  sorting: true,
                  filtering: true,
                  padding: "dense",
                  pageSize: 100,
                  pageSizeOptions: [10,25,50,100,200,500],
                  maxBodyHeight: "calc(100vh - 360px)"
                }}
                actions={[
                  {
                    icon: 'edit',
                    tooltip: 'Edit Donation',
                    onClick: (event, rowData) => this.onEdit(event, rowData)
                  },
                  {
                    icon: 'add',
                    tooltip: 'Add Donation',
                    isFreeAction: true,
                    onClick: (event) => this.onAdd()
                  }
                ]}
              />
            </Grid>
          </Grid>
      </div>
    )
  }

  loadCampaign = async () => {
    const { campaign } = this.props
    if (campaign) {
      const data: any[] = []
      if (campaign.boards) {
        campaign.boards.forEach((board: Board) => {
          board.donations.forEach((donation: Donation) => {
            data.push({
              id: donation.id,
              boardId: donation.boardId,
              createdAt: format(isoToLocalDateTime(donation.createdAt), 'MM/dd/yy HH:mm'),
              name: donation.name,
              email: donation.email,
              amount: numberToMoneyFormat(donation.amount),
              frequency: donation.frequency.toString(),
              other: donation.other,
              board: board.name
            })
          })
        })

        data.sort((a: any, b: any) => b.createdAt.localeCompare(a.createdAt))
      }
      this.data = data
    }
    return campaign
  }

  onEdit = (event: any, donation: any) => {
    console.log(`donation: ${JSON.stringify(donation)}`)
    ControlTower.route(`${Routes.board}/${donation.boardId}/${donation.id}`)
  }

  onAdd = async () =>{
    const boardId = await this.getNoBoardId()
    if (boardId) {
      ControlTower.route(`${Routes.board}/${boardId}/add`)
    }

  }

  onClose = () => {
    ControlTower.route(`${Routes.account}/campaigns`)
  }

  getNoBoardId = async () => {
    const { campaignStore } = this.props

    let boardId
    if (this.campaign!.boards && this.campaign!.boards.length > 0) {
      boardId = this.campaign!.boards[0].id
    } else {
      const input: CreateBoardInput = {
        accountId: this.campaign!.accountId,
        campaignId: this.campaign!.id,
        name: "None",
        backgroundColor: "#003d5c"
      }

      const board = await campaignStore!.createBoard(input)
        .catch((err: Error) => {
          // TODO
        })

      if (board) {
        console.log(`Created None Board ${board.id}`)
        boardId = board.id
      }
    }

    return boardId
  }
}
export default withTheme((withStyles(styles)(AccountCampaignDonations)))