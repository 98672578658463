import {
  createStyles, FormControl, FormHelperText, InputLabel,
  Select,
  Theme, WithStyles,
  withStyles
} from '@material-ui/core';
import { inject } from "mobx-react";
import * as React from 'react';

export const usaStates = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
];

const styles = (theme: Theme) => createStyles({

})

interface IStateSelectorProps {
    formvalidator?: any
    onChange?: any
    value?: string
    className?: string
    isRequired?: boolean
    name?: string
}

@inject("formvalidator")
class StateSelector extends React.Component<WithStyles<typeof styles> & IStateSelectorProps> {

    state = {
      value: '',
      isValid: true,
    };

  componentDidMount() {
    const {formvalidator, value} = this.props as IStateSelectorProps
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }

    this.setState({value})
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.value !== prevProps.value) {
      this.setState({value: this.props.value})
    }
  }

  handleChange = (event: any) => {
        this.setState({ value: event.target.value });
        this.validate(event.target.value)
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    };

    validate = (value: string): boolean => {
      const isRequired = this.props.isRequired ?? true
      const isValid = !isRequired || (usaStates.indexOf(value) >= 0)
      this.setState({isValid})
      return isValid
    }

    render() {
        const { className } = this.props

        return <FormControl
            className={className}>
            <InputLabel htmlFor="state-required">State</InputLabel>
            <Select
                native
                value={this.state.value}
                onChange={this.handleChange}
                inputProps={{
                    name: this.props.name ?? 'state',
                    id: 'state-required',
                }}
            >
                <option value="" />
                {usaStates.map((usaState) => <option key={usaState} value={usaState}>{usaState}</option>)}
            </Select>
            <FormHelperText error={!this.state.isValid}>
              {this.state.isValid ? "" : "Required"}
            </FormHelperText>
        </FormControl>
    }
}

export default withStyles(styles)(StateSelector)
