import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import DialogButton from "../../components/form/DialogButton";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import ControlTower, {Routes} from "../../components/ControlTower";
import Notify from "../../components/notify/Notify";
import UserStore, {UserStoreConstants} from "../../stores/UserStore";
import User from "../../model/User";
import {UpdateUserInput, UserRole} from "../../API";
import Visible from "../../components/Visible";
import AccountStore from "../../stores/AccountStore";
import ProgressButton from "../../components/form/ProgressButton";
import {createUUID} from "../../stores/StoreUtilities";
import SignInHero from "../../images/StockHero2.jpg";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tracking from "../../components/Tracking";
import LogoImage from "../../images/FunGivesLogoLg.png";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  dialogHero: {
    width: "auto",
    height: '250px',
    paddingTop: 60,
    backgroundImage: `url(${SignInHero})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  titleBackground: {
    width: "100%",
  },
  appTitle: {
    paddingTop: 16,
    textAlign: "center",
  },
  message: {
    color: theme.palette.error.main,
    fontSize: 16,
    marginTop: theme.spacing(1)
  },
  instructions: {
    color: theme.palette.text.secondary,
    fontSize: 16,
  },
  dialogPaper: {
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      marginTop: 'calc(50vh - 350px)',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 'calc(50vh - 325px)',
    },
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  dialogContent: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: 4
    }
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  dialogTitle: {
    // paddingLeft: 20,
    // paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
    // paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  label: {
    color: theme.palette.text.secondary,
    marginLeft: 0,
  }
})

interface IRegisterPageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  notify?: Notify
  accountId?: string
  userId?: string
}

@inject("userStore", "accountStore", "notify" /*, "privacyPolicy", "termsOfService" */)
@observer
class RegisterPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IRegisterPageProps & WithTheme> {

  @observable values = {
    email: "",
    password: "",
    phone: "",
    userId: "",
    accountId: "",
    role: UserRole.Sponsor,
    verificationCode: "",
  }
  @observable user?: User
  @observable isVerifying: boolean = false
  @observable isProcessing: boolean = false
  @observable message = ""

  componentDidMount () {
    const { accountId, userId } = this.props
    if (accountId) {
      this.values.accountId = accountId
    } else {
      this.values.accountId = createUUID()
    }

    if (userId) {
      this.values.userId = userId
    } else {
      this.values.userId = createUUID()
    }
  }

    render() {
    const { classes } = this.props

    const title = "Register"

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container className={classes.root} direction="column">
            <Paper className={classes.dialogPaper}>
              <div className={classes.titleBackground}>
                <div className={classes.appTitle}>
                  <img src={LogoImage} style={{height: 84}} alt="Logo"/>
                </div>
              </div>
              <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="signupForm" id="signupForm">
                <DialogTitle
                  id="login-dialog-title"
                  className={ classes.dialogTitle }
                >
                  Registration
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  <Visible if={!this.isVerifying}>
                    <DialogContentText className={classes.instructions}>
                      Please enter your registered email address and a create a password to complete your registration.
                    </DialogContentText>
                    <TextFieldValidator
                      margin="dense"
                      name="email"
                      id="EmailInput"
                      label="Email"
                      variant="standard"
                      placeholder=""
                      type="text"
                      validators={{required:true}}
                      value={this.values.email}
                      onChange={this.onChange}
                      fullWidth
                      autoCorrect="off"
                      autoCapitalize="off"
                      autoComplete="on"
                    />
                    <TextFieldValidator
                      margin="dense"
                      name="password"
                      label="Create Password"
                      type="password"
                      variant="standard"
                      validators={{required:true, isStrongPassword:3}}
                      value={this.values.password}
                      onChange={this.onChange}
                      fullWidth
                    />
                  </Visible>

                  <DialogContentText className={classes.message}>
                    {this.message}
                  </DialogContentText>

                  <Visible if={this.isVerifying}>
                    <Grid item className={classes.message}>
                      <Typography variant="body2" className={classes.instructions}>
                        Please check your email for a verification code.
                      </Typography>
                    </Grid>
                    <TextFieldValidator
                      margin="dense"
                      name="verificationCode"
                      label="Verification Code"
                      type="text"
                      variant="standard"
                      value={this.values.verificationCode}
                      validators={{required:true, matches:"^\\d{6}$"}}
                      onChange={this.onChange}
                      fullWidth
                    />
                    <DialogButton variant="text" onClick={this.onResendCode}>
                      Resend verification code
                    </DialogButton>
                  </Visible>

                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <ProgressButton variant="contained" color="primary" fullWidth
                                  type="submit" processing={this.isProcessing}>
                    { this.isVerifying ? "Verify" : "Next" }
                  </ProgressButton>
                </DialogActions>
                <DialogActions className={classes.dialogActions}>
                  <DialogButton variant="tertiary" fullWidth onClick={this.onCancel}>
                    Cancel
                  </DialogButton>
                </DialogActions>
              </FormValidator>
            </Paper>
          </Grid>
        </MarginRow>
      </Page>
    )
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  onCancel = () => {
    ControlTower.back()
  }

  onSubmit = async () => {
    const {accountId, userStore, accountStore, notify} = this.props
    const values = this.values

    if (!this.isVerifying) {
      this.isProcessing = true

      // Sign-up user
      const signupResult = await userStore!.signUp(values.userId, values.password, values.email, values.phone,
                                                   values.accountId, values.role)
        .catch(async (err: Error) => {
          let message
          if (err.name === UserStoreConstants.USERNAME_EXISTS_EXCEPTION) {
            // Try resending confirmation code
            await userStore!.resendSignUp(this.values.userId)
              .then((result: any) => {
                this.isVerifying = true
              })
              .catch((err: Error) => {
                message = err.message
              })
          } else {
            message = `Unable to create user. ${err.message}`
          }
          if (message) {
            notify!.show("error", message)
            Tracking.event({action: 'RegisterError', label: message})
          }
          this.isProcessing = false
        })
      if (signupResult) {
        this.isVerifying = true
      }
      this.isProcessing = false
    } else if (this.isVerifying) {
      this.isProcessing = true

      const verification = await userStore!.confirmSignUp(values.userId, values.verificationCode)
        .catch((err: Error) => {
          notify!.show("error", "Unable to verify account")
          Tracking.event({action: 'RegisterVerifyError', label: err.message})
          this.isProcessing = false
        })

      if (verification === "SUCCESS") {
        // Sign in
        let user = await userStore!.signIn(values.userId, values.password)
          .catch((err: Error) => {
            if (err.message !== UserStoreConstants.USER_NOT_FOUND) {
              notify!.show("error", "Unable to signin")
              this.isProcessing = false
            }
          })

        if (!user && accountId) {
          notify!.show("error", "A user with this email was not found for this account.  Please contact the account user or administrator.")
          Tracking.event({action: 'RegisterError', label: 'User not found'})
          this.isProcessing = false
          return
        }

        if (user && accountId && user.accountId !== accountId) {
          notify!.show("error", "A user with this email is already registered for another account.  Please contact the account user or administrator.")
          Tracking.event({action: 'RegisterError', label: 'Email already registered'})
          this.isProcessing = false
          return
        }

        if (user && user.email !== values.email.toLowerCase()) {
          notify!.show("error", "The email address does not match the invitation.  Please contact the account user or administrator.")
          Tracking.event({action: 'RegisterError', label: "Email doesn't match invitation"})
          this.isProcessing = false
          return
        }

        if (user) {
          // Update User to active
          const updateUserInput: UpdateUserInput = {
            id: user.id,
            active: true
          }

          user = await userStore!.updateUser(updateUserInput)
            .catch((err: Error) => {
              notify!.show("error", "Unable to update user")
              Tracking.event({action: 'RegisterError', label: `Unable to update user: ${err.message}`})
              this.isProcessing = false
            })

          if (user.isAdmin || user.isOwner) {
            // Sign-in again to authenticate with new role
            user = await userStore!.signIn(values.userId, values.password)
            if (user) {
              await accountStore!.loadAccount(userStore!.user!.accountId)
            }
          }

          // Initialize accountStore for new user
          if (user.account) {
            await accountStore!.init(user.account)
          } else {
            console.log("user.account is undefined!")
          }

          this.isProcessing = false
          notify!.show("success", "Welcome to FunGives!")
          Tracking.event({action: 'RegisterSuccess'})
          // userStore!.createActivity(ActivityType.UserRegister, user.id)
          if (user.role === UserRole.Owner) {
            ControlTower.route(`${Routes.account}`)
          } else if (user.role === UserRole.Admin) {
            ControlTower.route(Routes.home)
          } else {
            ControlTower.route(Routes.home)
          }
        }
      }
    }
  }

  onResendCode = () => {
    const { userStore, notify} = this.props
    Tracking.event({action: 'RegisterResendCode'})
    userStore!.resendSignUp(this.values.userId)
      .then((result: any) => {
        notify!.show("success", "Verification code resent")
      })
      .catch((err: Error) => {
        notify!.show("error", "Unable to resend verification code")
      })
  }

}

export default withTheme((withStyles(styles)(RegisterPage)))