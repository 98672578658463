import * as React from 'react'
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MarginRow from "./MarginRow";
import {Button, Grid, Hidden, LinearProgress} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import ControlTower, {Routes} from "../../components/ControlTower";
import SimpleMenu from "./SimpleMenu"
import UserStore from "../../stores/UserStore";
import Progress from "../Progress";
import Tracking from "../Tracking";
import LogoImage from "../../images/FunGivesLogo.png"

const styles = (theme: Theme) => createStyles({
  appBar: {
    top: 0,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    height: 56,
    maxHeight: 56,
  },
  toolBar: {
    minHeight: 56,
    height: 56,
    // textAlign: "center"
  },
  left: {
    textAlign: "left",
    paddingLeft: theme.spacing(0.5)
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
    paddingTop: 8,
    // paddingRight: 24
  },
  linkStyle: {
    marginBottom: '-5px',
    marginLeft: 5
  },
  button: {
    margin: theme.spacing(1),
    color: theme.palette.background.paper,
    fontWeight: 600,
    padding: 0,
  },
  activeButton: {
    margin: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.background.paper,
    borderBottom: "3px solid",
    borderBottomColor: theme.palette.primary.main,
    borderRadius: 0,
    padding: 0,
  },
  input: {
    display: 'none',
  },
  logo: {
    paddingTop: 7,
  },
  icon: {
    height: 20,
    width: 20,
  },
  progressWrapper: {
    flexGrow: 1
  },
  progress: {
    height: 3,
    backgroundColor: theme.palette.secondary.main
  },
})

interface IHeaderProps {
  userStore?: UserStore
  progress?: Progress
}

@inject("userStore", "progress")
@observer
class Header extends React.Component<WithStyles<typeof styles> & IHeaderProps> {

  render() {
    const {classes, /*, userStore, */  progress } = this.props

    return (
      <AppBar position="fixed" className={classes.appBar}>
        <MarginRow>
          <Toolbar className={classes.toolBar} disableGutters={ true }>
            <Grid container>
              <Grid item xs={6} className={classes.left}>
                <div className={classes.logo}>
                  <a href="# " onClick={this.onClickHome}>
                    <img src={LogoImage} alt="Logo"/>
                  </a>
                </div>
              </Grid>
              <Grid item xs={6} className={classes.right}>
                <Hidden xsDown>
                  {this.renderMenuItems()}
                </Hidden>
                <Hidden smUp>
                  <SimpleMenu />
                </Hidden>
              </Grid>
            </Grid>
          </Toolbar>
        </MarginRow>
        <div className={classes.progressWrapper}>
          { <LinearProgress hidden={ !progress!.isVisible } className={ classes.progress } /> }
        </div>
      </AppBar>

    )
  }

  renderMenuItems = () => {
    const {
      classes,
      userStore
    } = this.props

    // if (!userStore || !userStore.isAuthenticated) {
    //   return null
    // }

    const currentPath = ControlTower.currentRoute
    const isAuthenticated = userStore!.isAuthenticated
    const isOwner = userStore!.isOwner

    const menuItems = []

    menuItems.push(
      <Button
        key="0"
        color="primary"
        aria-label="Home"
        className={(currentPath === Routes.home) ? classes.activeButton : classes.button}
        onClick={this.onClickHome}
      >
        Home
      </Button>
    )

    menuItems.push(
      <Button
        key="1"
        color="primary"
        aria-label="About"
        className={(currentPath === Routes.about) ? classes.activeButton : classes.button}
        onClick={this.onClickAbout}
      >
        About
      </Button>
    )

    if (!isAuthenticated) {
      menuItems.push(
        <Button
          key="2"
          color="primary"
          aria-label="Sign In"
          className={classes.button}
          onClick={this.onClickSignIn}
        >
          Sign In
        </Button>
      )
    } else {
      if (isOwner) {
        menuItems.push(
          <Button
            key="2"
            color="primary"
            className={(currentPath.startsWith(Routes.account)) ? classes.activeButton : classes.button}
            aria-label="Account"
            onClick={this.onClickAccount }
          >
            Account
          </Button>
        )
      }
      menuItems.push(
        <Button
          key="3"
          color="primary"
          className={classes.button}
          aria-label="Sign Out"
          onClick={this.onClickSignOut }
        >
          Sign Out
        </Button>
      )
    }


    return menuItems
  }

  onClickSignIn = async (event: any) => {
    const { userStore } = this.props

    await userStore!.signOut()
    ControlTower.route(Routes.signin)
  }

  onClickAbout = (event: any) => {
    ControlTower.route(Routes.about)
  }

  onClickHome = (event: any) => {
    ControlTower.route(Routes.home)
  }

  onClickAccount = (event: any) => {
    ControlTower.route(Routes.account)
  }

  onClickSignOut = async (event: any) => {
    Tracking.event({action: "SignOut"})
    await this.props.userStore!.signOut()
    ControlTower.route(Routes.home)
  }
}

export default withStyles(styles)(Header)