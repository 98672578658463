import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import {Grid} from "@material-ui/core";
import SignInDialog from "../../components/auth/SignInDialog";
import UserStore from "../../stores/UserStore";
import {inject, observer} from "mobx-react";
import ControlTower, {Routes} from "../../components/ControlTower";
import config from 'react-global-configuration';
import Progress from "../../components/Progress";
import Logger from "../../components/Logger";
import AccountStore from "../../stores/AccountStore";
import {makeObservable, observable, when} from "mobx";
import MarginRow from "../../components/page/MarginRow";
import Page from "../../components/page/Page";
import PasswordAssistDialog from "../../components/auth/PasswordAssistDialog";
import Notify from "../../components/notify/Notify";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    width: "100%",
    flex: "auto",
    overflowY: "auto",
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  signInDialog: {
  },

})

interface ISignInPageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
  notify?: Notify
  route?: string
}

@inject("userStore", "accountStore", "progress", "notify")
@observer
class SignInPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ISignInPageProps & WithTheme> {

  @observable isLoading = true
  @observable showPasswordAssist: boolean = false

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount () {
    const { userStore, accountStore, progress, route } = this.props
    console.log("SignInPage.componentDidMount")
    when(
      () => !userStore!.isLoading && !userStore!.isAuthenticated,
      async () => {
        console.log("SignInPage looking for current authenticated user")
        userStore!.isLoading = true
        this.isLoading = true
        const cognitoUser = await userStore!.currentAuthenticatedUser()
        if (cognitoUser) {
          progress!.show("SignInPage")
          console.log("initializing current user")
          // Load and initialize User
          await userStore!.initSession(cognitoUser)
            .then(async (result: any) => {
              console.log("Reloaded user from cache")
              const account = userStore!.user!.account
              if (!account) {
                await accountStore!.loadAccount(userStore!.user!.accountId)
              }
              this.isLoading = false
              progress!.hide("SignInPage")

              if (route) {
                ControlTower.route(route)
              } else {
                ControlTower.route(Routes.accountCampaigns)
              }

              // if (userStore!.isAdmin) {
              //   ControlTower.route(Routes.accounts)
              // }
            })
            .catch(async (reason: any) => {
              this.isLoading = false
              console.log("Unable to load user from cache")
              progress!.hide("SignInPage")
            })
        } else {
          console.log("No existing authenticated user found")
          userStore!.isLoading = false
          this.isLoading = false
        }
        Logger.info("App started")
      }
    )

    // when(
    //   () => !userStore!.isLoading && !accountStore!.isLoading,
    //   async () => {
    //     console.log("SignInPage completed loading user")
    //     this.isLoading = false
    //     progress!.hide("SignInPage")
    //     if (this.props.route) {
    //       console.log(`Signin routing to ${this.props.route}`)
    //       ControlTower.route(this.props.route)
    //     } else {
    //       console.log(`Signin routing to ${Routes.home}`)
    //       ControlTower.route(Routes.home)
    //     }
    //
    //   }
    // )

  }

  render() {
    const { classes, userStore } = this.props
    console.log("SignInPage.render")

    const isAuthenticated = userStore!.isAuthenticated
    console.log(`SignInPage.isAuthenticated = ${isAuthenticated === true}`)

    let title = "Sign In"
    if (isAuthenticated) {
      title = (this.showPasswordAssist) ? "Password Assist" : "My Classes"
    }

    return (
      <Page title={title}>
        <MarginRow>
            <Grid container className={classes.root} direction="column">
              {!this.isLoading && !isAuthenticated && !this.showPasswordAssist &&
                  <Grid item className={classes.signInDialog}>
                    <SignInDialog
                      onSignIn={this.onSignIn}
                      onClose={this.onSignInClose}
                      onShowPasswordAssist={this.onShowPasswordAssist}
                    />
                  </Grid>
                }
                {this.showPasswordAssist &&
                  <Grid item className={classes.signInDialog}>
                    <PasswordAssistDialog
                      onSignIn={this.onSignIn}
                      onClose={() => {this.showPasswordAssist = false}}
                    />
                  </Grid>
                }
            </Grid>
        </MarginRow>
      </Page>
    )
  }

  onSignIn = async () => {
    const { userStore, accountStore, notify } = this.props

    this.showPasswordAssist = false

    let account = userStore!.user!.account
    if (!account) {
      account = await accountStore!.loadAccount(userStore!.user!.accountId)
      if (account && !account.active) {
        notify!.show("error", "This account has not been activated yet.")
        account = undefined
        await userStore!.signOut()
      }
    }

    if (account) {
      if (userStore!.isOwner) {
        ControlTower.route(`${Routes.account}`)
      } else {
        ControlTower.route(Routes.home)
      }
    }

  }

  onSignInClose = () => {
    window.location.href = config.get('homeUrl')
  }

  onShowPasswordAssist = () => {
    this.showPasswordAssist = true
  }

}

export default withTheme((withStyles(styles)(SignInPage)))