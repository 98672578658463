import * as React from 'react'
import {RouteComponentProps} from '@reach/router'
import {createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import CampaignStore from "../../stores/CampaignStore";
import {makeObservable, observable, when} from "mobx";
import Campaign from "../../model/Campaign";
import TitleBar from "../../components/TitleBar";
import {Grid, Tab, Tabs} from "@material-ui/core";
import ControlTower, {Routes} from "../../components/ControlTower";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import AccountCampaignDonations from "./AccountCampaignDonations";
import NavigationBar from "../../components/NavigationBar";
import AccountCampaignBoards from "./AccountCampaignBoards";
import CampaignEditPage from "./CampaignEditPage";
import {CampaignType} from "../../API";
import CampaignCustomSettings from "./CampaignCustomSettings";
import CampaignPaymentSettings from "./CampaignPaymentSettings";
import CampaignGameSettings from "./CampaignGameSettings";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: 'center',
    width: "100%",
    margin: 0
  },
  navigation: {
  },
  navigationBar: {
    display: "flex",
    flex: "none",
    flexDirection: "row",
    height: 30,
    width: "100%",
    maxWidth: 960,
    paddingRight: theme.spacing(1)
  },
  location: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 400,
    padding: "4px 8px 0 8px"
  },
  tabs: {

  },
  tab: {
    fontSize: 15,
    fontWeight: 600,
  },
  hiddenTab: {
    fontSize: 15,
    fontWeight: 600,
    display: "none"
  },

})

interface IAccountCampaignPageProps {
  campaignId?: string
  tab?: string
  userStore?: UserStore
  accountStore?: AccountStore
  campaignStore?: CampaignStore
  progress?: Progress
}

@inject("userStore", "accountStore", "campaignStore", "progress")
@observer
class AccountCampaignPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountCampaignPageProps & WithTheme> {

  tabNames: string[] = ["game", "boards", "settings", "customize", "payment", "donations"]

  @observable isLoading = true
  @observable campaign?: Campaign
  @observable data: any[] = []

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount () {
    const { userStore, accountStore, progress } = this.props
    this.isLoading = true
    progress!.show("AccountCampaignPage")
    when (
      () => !userStore!.isLoading && !accountStore!.isLoading,
      async () => {
        this.campaign = await this.loadCampaign()
        this.isLoading = false
        progress!.hide("AccountCampaignPage")
      }
    )
  }

  handleChange = (event: any, value: any) => {
    const { campaignId } = this.props
    // Navigate to tab to allow a direct url to each tab
    const tabName = this.tabNames[value]
    ControlTower.route(`${Routes.account}/campaign/${campaignId}/${tabName}`)
  }

  render() {
    const { classes } = this.props

    if (!this.campaign) {
      return null
    }

    let campaign = this.campaign
    let title = ""
    let location = ""

    if (campaign) {
      title = campaign.title
      location = (campaign.city && campaign.state) ? `${campaign.city}, ${campaign.state}` : ""
    }

    const tabName = (this.props.tab !== undefined) ? this.props.tab : this.tabNames[0]
    let tabValue = this.tabNames.findIndex(name => tabName === name)

    const showBoards = this.campaign.campaignType !== CampaignType.NoGame
    if (!showBoards && tabValue <= 0) {
      tabValue = 1
    }

    return (
      <Page title={title}>
        <MarginRow>
          <div className={ classes.root}>
            <Grid container className={classes.navigation}>
              <div className={classes.navigationBar}>
                <NavigationBar title="Account" onBack={this.onBack}/>
              </div>
              <Grid item xs={12}>
                <TitleBar title={title}>
                </TitleBar>
                <div className={classes.location}>{location}</div>
              </Grid>
              <Tabs
                value={tabValue}
                onChange={this.handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="secondary"
                className={ classes.tabs }
              >
                <Tab className={classes.tab} label="Game"/>
                {showBoards && <Tab className={classes.tab} label="Boards"/> }
                {!showBoards && <Tab className={classes.hiddenTab} label="Boards"/> }
                <Tab className={classes.tab} label="Settings"/>
                <Tab className={classes.tab} label="Customize"/>
                <Tab className={classes.tab} label="Payment"/>
                <Tab className={classes.tab} label="Donations" />
              </Tabs>
            </Grid>
            <Grid container className={classes.content}>
              {tabValue === 0 && <CampaignGameSettings campaign={this.campaign} onSave={this.onSave}/>}
              {tabValue === 1 && <AccountCampaignBoards campaign={this.campaign}/>}
              {tabValue === 2 && <CampaignEditPage campaign={this.campaign} onSave={this.onSave}/>}
              {tabValue === 3 && <CampaignCustomSettings campaign={this.campaign} onSave={this.onSave}/>}
              {tabValue === 4 && <CampaignPaymentSettings campaign={this.campaign} onSave={this.onSave}/>}
              {tabValue === 5 && <AccountCampaignDonations campaign={this.campaign}/>}
            </Grid>
          </div>
        </MarginRow>
      </Page>
    )
  }

  loadCampaign = async () => {
    const { campaignStore, campaignId } = this.props
    let campaign
    if (campaignId) {
      campaign = await campaignStore!.getCampaign(campaignId)
    }
    return campaign
  }

  onSave = async (campaign : Campaign) => {
    this.campaign = await this.loadCampaign()
  }

  onBack = () => {
    ControlTower.route(Routes.accountCampaigns)
  }
}
export default withTheme((withStyles(styles)(AccountCampaignPage)))