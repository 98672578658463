import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Progress from "./components/Progress";
import {Provider} from "mobx-react";
import Logger from "./components/Logger";
import GivinciAPI from "./apis/GivinciAPI";
import UserStore from "./stores/UserStore";
import AccountStore from "./stores/AccountStore";
import TermsOfService from "./components/page/TermsOfService";
import PrivacyPolicy from "./components/page/PrivacyPolicy";
import Notify from "./components/notify/Notify";
import Alert from "./components/alert/Alert";
import Confirm from "./components/confirm/Confirm";
import S3UrlCacheStore from "./stores/S3UrlCacheStore";
import CampaignStore from "./stores/CampaignStore";
import config from "react-global-configuration";
import Tracking from "./components/Tracking";
import PaymentAPI from "./apis/PaymentAPI";

Logger.init()

const givinciAPI = new GivinciAPI()
const paymentAPI = new PaymentAPI()

const userStore = new UserStore( {
  givinciAPI
})

const accountStore = new AccountStore( {
  givinciAPI
})

const campaignStore = new CampaignStore( {
  givinciAPI
})

const resources = {
  confirm: new Confirm(),
  notify: new Notify(),
  alert: new Alert(),
  progress: new Progress(),
  privacyPolicy: new PrivacyPolicy(),
  termsOfService: new TermsOfService(),
  s3UrlCacheStore: new S3UrlCacheStore(),
  userStore,
  accountStore,
  campaignStore,
  paymentAPI
}

const trackingId = config.get("google.trackingId")
if (trackingId && trackingId !== "" && trackingId !== "NONE") {
  Tracking.initialize(trackingId, config.get("google.options"))
}

ReactDOM.render(
  <React.StrictMode>
    <Provider {...resources}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
