import {DonationFrequency} from "../API";
import {Board} from "./Board";

export class Donation {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  campaignId: string
  boardId: string
  board?: Board
  spaceId: string
  amount: number
  frequency: DonationFrequency
  other: string
  name: string
  email: string
  message: string
  isAnonymous: boolean
  isExistingDonor: boolean
  paymentStatus: string

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.campaignId = data.campaignId
    this.boardId = data.boardId
    if (data.board) {
      this.board = new Board(data.board)
    }
    this.spaceId = data.spaceId
    this.amount = data.amount
    this.frequency = data.frequency
    this.other = data.other
    this.name = data.name
    this.email = data.email
    this.message = data.message
    this.isAnonymous = data.isAnonymous
    this.isExistingDonor = data.isExistingDonor
    this.paymentStatus = data.paymentStatus ?? "paid"
  }
}