import * as React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import {Dialog, Grid} from "@material-ui/core";
import {Donation} from "../../model/Donation";
import DialogButton from "../../components/form/DialogButton";
import ControlTower, {Routes} from "../../components/ControlTower";

const styles = (theme: Theme) => createStyles({
  message: {
    color: 'red',
    marginTop: 10
  },
  dialogPaper: {
    // minWidth: 350,
    // width: "100%",
    maxWidth: 480,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 'calc(50vh - 325px)',
    },
    marginLeft: 10,
    marginRight: 10
  },
  dialogContent: {
    padding: "0 20px 10px 20px",
  },
  dialogActions: {
    justifyContent: "flex-start",
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16
  },
  dialogTitle: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
})

interface IDonationThankYouDialogProps {
  donation: Donation
  onClose: any
}

class DonationThankYouDialog extends React.Component<WithStyles<typeof styles> & IDonationThankYouDialogProps> {

  render() {
    const { classes, donation } = this.props

    let amount = donation.amount > 0 ? `$${donation.amount}` : ""
    let other = donation.other ?? ""
    let frequency
    switch (donation.frequency) {
      case "Monthly":
        frequency = "monthly"
        break
      case "Increase":
        frequency = "monthly increase"
        break;
      case "Other":
        frequency = "one time"
        break;
      default:
        frequency = "one time"
        break
    }

    let message = `Thank you for your ${amount} ${other} ${frequency} donation!  We hope you will share this fundraiser with others by clicking the Share button!`

    return (
      <Dialog
        id="confirmDialog"
        open={true}
        onClose={this.props.onClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        aria-labelledby="confirm-title">
        <DialogTitle
          id="thank-you-dialog-title" className={ classes.dialogTitle}>
          Thank You!
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} >
              <DialogButton variant="secondary" fullWidth onClick={this.onShare}>
                Share
              </DialogButton>
            </Grid>
            <Grid item sm={6} xs={12}>
              <DialogButton variant="tertiary" fullWidth onClick={this.props.onClose}>
                Close
              </DialogButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }

  onShare = () => {
    const { donation } = this.props

    const returnTo = `${Routes.campaign}/${donation.campaignId}/${donation.boardId}`
    ControlTower.route(`${Routes.share}/${donation.campaignId}?return=${returnTo}`)
  }

}

export default withStyles(styles)(DonationThankYouDialog)