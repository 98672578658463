import * as React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { RouteComponentProps } from '@reach/router'
import {inject, observer} from "mobx-react";
import {makeObservable, observable} from "mobx";
import {Paper, TextField} from "@material-ui/core";
import DialogButton from "../form/DialogButton";
import FormValidator from "../form/FormValidator";
import SignInHero from "../../images/StockHero2.jpg";
import UserStore, {UserStoreConstants} from "../../stores/UserStore";
import ControlTower, {Routes} from "../ControlTower";
import TextFieldValidator from "../form/TextFieldValidator";
import ProgressButton from "../form/ProgressButton";
import Tracking from "../Tracking";
import LogoImage from "../../images/FunGivesLogoLg.png";

const styles = (theme: Theme) => createStyles({
  message: {
    color: theme.palette.error.light,
    fontWeight: 600,
    marginTop: 10
  },
  dialogPaper: {
    maxWidth: 480,
    [theme.breakpoints.down('sm')]: {
      marginTop: 'calc(50vh - 350px)',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 'calc(50vh - 325px)',
    },
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  dialogImage: {
    height: 'auto',
    width: '100%'
  },
  dialogHero: {
    width: "auto",
    height: '100px',
    paddingTop: 60,
    backgroundImage: `url(${SignInHero})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  titleBackground: {
    width: "100%",
  },
  dialogContent: {
    padding: "0 20px 10px 20px",
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // paddingBottom: 0,
  },
  dialogTitle: {
    color: theme.palette.text.secondary,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  heroIcon: {
    color: theme.palette.secondary.main
  },
  appTitle: {
    paddingTop: 16,
    textAlign: "center",
  }
})

interface ISignInDialogProps {
  onSignIn: any,
  onClose: any,
  onShowPasswordAssist: any
  userStore?: UserStore
  // confirm?: Confirm
}

@inject("userStore")
@observer
class SignInDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ISignInDialogProps> {

  @observable values = {
    username: "",
    password: "",
    newPassword: "",
    userId: "",
  }
  @observable message = ""
  @observable newPasswordRequired = false
  @observable isProcessing = false

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  onClose = () => {
    if (this.props.onClose) {
      this.message = ""
      this.props.onClose()
    }
  };

  onSignIn = () => {
    this.props.onSignIn()
  }

  onSignUp = () => {
    ControlTower.route(Routes.signup)
  }

  onSubmit = async () => {
    const { userStore } = this.props
    this.message = ""
    this.isProcessing = true

    const password = this.values.password
    userStore!.signIn(this.values.username, password)
      .then((account: any) => {
        if (account.challengeName === "NEW_PASSWORD_REQUIRED") {
          if (this.values.newPassword) {
            userStore!.completeNewPassword(account, this.values.newPassword)
              .then((data: any) => {
                // Login with the new password
                userStore!.signIn(this.values.username, this.values.newPassword)
                  .then((account2: any) => {
                    this.isProcessing = false
                    if (this.props.onSignIn) {
                      this.message = "";
                      this.onSignIn()
                    }
                  })
                  .catch((err: any) => {
                    this.message = err.message
                    this.isProcessing = false
                  });
              })
              .catch((err: any) => {
                this.message = err.message
                this.isProcessing = false
              });
          } else {
            // Prompt for new password
            this.newPasswordRequired = true
            this.message = "A new password is required"
            this.isProcessing = false
          }
        }
        else {
          Tracking.event({action: "SignIn"})
          this.isProcessing = false
          if (this.props.onSignIn) {
            this.message = "";
            this.onSignIn()
          }
        }
      })
      .catch((err: any) => {
        this.isProcessing = false
        Tracking.event({action: "SignInError", label: err.message})
        if (err.code === UserStoreConstants.USER_NOT_FOUND_EXCEPTION) {
          // Don't show "User not found" message to prevent phishing for valid usernames
          this.message = "Incorrect username or password."
        } else if (err.message === UserStoreConstants.USER_NOT_CONFIRMED ||
          err.message === UserStoreConstants.EMAIL_VERIFICATION_PENDING ||
          err.message === UserStoreConstants.PHONE_VERIFICATION_PENDING) {
          // Signup is incomplete
          this.message = "Please complete your sign up."
        } else {
          this.message = err.message;
        }
      });

  }

  onForgotPassword = () => {
    if (this.props.onShowPasswordAssist) {
      Tracking.event({action: "PasswordAssist"})
      this.message = "";
      this.props.onShowPasswordAssist()
    }
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  render() {
    const {classes} = this.props

    return (
      <Paper className={classes.dialogPaper}>
        <div className={classes.titleBackground}>
          <div className={classes.appTitle}>
            <img src={LogoImage} style={{height: 84}} alt="Logo"/>
          </div>
        </div>
        <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="loginForm" id="loginForm">
          <DialogTitle
            id="login-dialog-title"
            className={ classes.dialogTitle }
          >
            Sign In
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <TextFieldValidator
              autoFocus
              margin="dense"
              name="username"
              id="EmailInput"
              label="Email"
              type="text"
              variant="standard"
              validators={{required:true, isEmail:true}}
              value={this.values.username}
              onChange={this.onChange}
              fullWidth
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="on"
            />
            <TextField
              margin="dense"
              name="password"
              id="passwordInput"
              label="Password"
              type="password"
              variant="standard"
              // validators={{required:true}}
              value={this.values.password}
              onChange={this.onChange}
              fullWidth
              autoComplete="off"
            />
            {this.newPasswordRequired &&
            <TextField
              margin="dense"
              name="newPassword"
              label="New Password"
              type="password"
              variant="standard"
              // validators={{required:true, isStrongPassword:3}}
              value={this.values.newPassword}
              onChange={this.onChange}
              fullWidth
              helperText="Please enter a new password of at least 8 characters with at least one digit or symbol."
            />
            }
            <DialogContentText className={classes.message}>
              {this.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {/*<DialogButton variant="primary" fullWidth={true}>*/}
            {/*  Sign In*/}
            {/*</DialogButton>*/}
            <ProgressButton variant="contained" color="primary" fullWidth={true}
                            type="submit" processing={this.isProcessing}
            >Sign In</ProgressButton>
          </DialogActions>
          <DialogActions className={classes.dialogActions}>
            <DialogButton variant="tertiary" fullWidth={true} onClick={this.onForgotPassword}>
              Password&nbsp;Assistance
            </DialogButton>
          </DialogActions>
          <DialogActions className={classes.dialogActions}>
            <DialogButton variant="secondary" fullWidth={true} onClick={this.onSignUp}>
              Create&nbsp;New&nbsp;Account
            </DialogButton>
          </DialogActions>
        </FormValidator>
      </Paper>
    )
  }
}

export default withStyles(styles)(SignInDialog)
