import * as React from "react";
import {Board} from "../../model/Board";
import {createStyles, fade, Theme, withStyles, WithStyles} from "@material-ui/core";
import {numberToMoneyFormat} from "../../stores/StoreUtilities";
import {Donation} from "../../model/Donation";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import BingoBackground from "../../images/backgrounds/BingoBackground.png"

const styles = (theme: Theme) => createStyles({
  root: {
    padding: 0,
  },
  background: {
    paddingTop: 240,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingRight: 20,
    width: 472,
  },
  table: {
    borderColor: theme.palette.text.primary,
    borderWidth: 1,
    borderStyle: "solid",
    width: "100%",
    height: "100%",
  },
  th: {
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    borderWidth: 1,
    borderStyle: "solid",
    color: theme.palette.text.primary,
    textAlign: "center",
    fontSize: 48,
    fontWeight: 800,
    padding: 0
  },
  tdOpaque: {
    color: theme.palette.common.black,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: 0,
    border: "1px solid silver",
  },
  tdTranslucent: {
    color: theme.palette.common.black,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    padding: 0,
  },
  tdTransparent: {
    color: theme.palette.text.secondary,
    backgroundColor: "transparent",
    padding: 0,
  },
  amount: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    padding: "10px 4px 0",
    lineHeight: 1.0
  },
  freq: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
    padding: "0 4px 4px",
    whiteSpace: "nowrap"
  },
  other: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: "center",
    padding: "4px 2px 4px",
    textSizeAdjust: "auto",
    lineHeight: 1.2
  },
  fill: {
    backgroundColor: fade(theme.palette.secondary.main, 0.5),
    borderWidth: 2,
    borderColor: theme.palette.common.white,
    borderStyle: "solid",
    borderRadius: "50%"
  },
  nofill: {

  },
  link: {
    color: theme.palette.common.black,
    textDecoration: "none"
  },
  boardName: {
    color: theme.palette.text.primary,
    fontSize: 30,
    fontWeight: 600,
    padding: 0,
    paddingTop: theme.spacing(1),
    margin: "4px 0",
    textAlign: "center",
    border: "0px",
    lineHeight: 1.3
  },

})

interface IBINKOBoardProps {
  board: Board
  className?: string
  onDonate?: any
  preview?: boolean
  legend?: boolean
  s3UrlCacheStore?: S3UrlCacheStore
}

@inject("s3UrlCacheStore")
@observer
class BINKOBoard extends React.Component<WithStyles<typeof styles> & IBINKOBoardProps> {

  @observable backgroundColor?: string
  @observable backgroundImage?: string
  @observable backgroundUrl?: string

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  paymentSource = ""

  componentDidMount() {
    const { s3UrlCacheStore, board } = this.props

    if (board) {
      if (board.backgroundImage) {
        this.backgroundImage = board.backgroundImage
      } else if (board.campaign && board.campaign.custom && board.campaign.custom.backgroundImage) {
        this.backgroundImage = board.campaign.custom.backgroundImage
      } else {
        this.backgroundImage = undefined
      }

      if (board.backgroundColor) {
        this.backgroundColor = board.backgroundColor
      } else if (board.campaign && board.campaign.custom && board.campaign.custom.backgroundColor) {
        this.backgroundColor = board.campaign.custom.backgroundColor
      } else {
        this.backgroundColor = undefined
      }

      if (this.backgroundImage) {
        s3UrlCacheStore!.get(this.backgroundImage)
          .then((url: string | undefined) => {
            console.log(`backgroundImage = ${url}`)
            this.backgroundUrl = url
          })
          .catch((err: Error) => {
            console.log("Error loading board background image")
            this.backgroundUrl = `url(${BingoBackground})`
          })
      } else {
        this.backgroundUrl = BingoBackground
      }

      if (board.campaign) {
        this.paymentSource = board.campaign.getPaymentSource()
      }
    }

  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.board !== this.props.board) {
      this.componentDidMount()
    }
  }

  render() {
    const { board, classes, className, legend } = this.props

    const layout = this.getLayout()
    const rootClassName = className ? className : classes.root

    let backgroundStyle: any = {
      backgroundImage: `url(${BingoBackground})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center top",
      maxWidth: "calc(100vw)",
    }
    let tableStyle: any = {}
    if (this.backgroundUrl) {
      backgroundStyle.backgroundImage = `url(${this.backgroundUrl})`
    }
    if (this.backgroundColor) {
      backgroundStyle.backgroundColor = this.backgroundColor
    }

    return (
      <div className={rootClassName}>
        <div className={classes.background} style={backgroundStyle}>
          <table className={classes.table} style={tableStyle}>
            <tbody>
            {layout.rows.map((row: any, rowIndex: number) =>
              <tr key={rowIndex}>
                {row.cols.map((col:any, colIndex: number) =>
                   this.renderSpace(col, rowIndex, colIndex)
                )}
              </tr>
            )}
            </tbody>
          </table>
        </div>
        {legend &&
          <div className={classes.boardName}>{board.name}<br/>{numberToMoneyFormat(board.totalAmount, 0, false)}</div>
        }
      </div>
    )
  }

  renderSpace(col: any, rowIndex: number, colIndex: number) {
    const { classes, onDonate, board } = this.props

    let fill = false
    let donation: Donation | undefined
    let amount

    if (this.paymentSource === "Stripe" && col.freq === "Monthly") {
      col.freq = "Once"
    }

    let freq = col.freq
    let other = col.other

    if (board.donations) {
      const spaceId = `${rowIndex},${colIndex}`
      donation = board.donations.find((d: Donation) => d.spaceId === spaceId)
      if (donation) {
        fill = true
        amount = numberToMoneyFormat(donation.amount, 0, false)
        freq = donation.frequency.toString()
      }
    }

    const fillClassName = fill ? classes.fill : classes.nofill
    let tdClassName = classes.tdOpaque
    if (!amount) {
      if (col.amount === 0) {
        amount = col.freq === "Once" ? "One Time" : "Monthly"
        freq = "Gift"
      } else {
        amount = numberToMoneyFormat(col.amount, 0, false)
      }
      if (amount.length <= 3) {
        amount = amount + "+"
      }
    }

    if (freq === "One Time" || freq === "Once") {
      freq = "Gift"
    }

    if (!fill && onDonate) {
      return (
        <td className={tdClassName} key={colIndex}>
          <a href="# " className={classes.link}
             onClick={(event) => {onDonate(rowIndex, colIndex, col.amount, col.freq, col.other)}}>
            {!other &&
              <div className={fillClassName}>
                <div className={classes.amount}>{amount}</div>
                <div className={classes.freq}>{freq}</div>
              </div>
            }
            {other &&
              <div className={fillClassName}>
                <div className={classes.other}>{other}</div>
                {col.amount !== 0 &&
                  <div className={classes.freq}>{amount}</div>
                }
              </div>
            }
          </a>
        </td>
      )
    } else {
      return (
        <td className={tdClassName} key={colIndex}>
          {!other &&
            <div className={fillClassName}>
              <div className={classes.amount}>{amount}</div>
              <div className={classes.freq}>{freq}</div>
            </div>
          }
          {other &&
            <div className={fillClassName}>
              <div className={classes.other}>{other}</div>
              {donation && donation!.amount !== 0 &&
                <div className={classes.freq}>{amount}</div>
              }
            </div>
          }
        </td>
      )
    }

  }

  getLayout() {
    const { board } = this.props

    let layout = null

    if (board.layout) {
      layout = board.layout
    } else if (board.campaign) {
      if (board.campaign.layout) {
        layout = board.campaign.layout
      } else {
        layout = board.campaign.getDefaultLayout()
      }
    }

    return layout
  }



}

export default withStyles(styles)(BINKOBoard)