import * as React from 'react'
import {
  Card,
  CardContent,
  createStyles,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {makeObservable, observable, when} from "mobx";
import User from "../../model/User";
import Progress from "../../components/Progress";
import TitleBar from "../../components/TitleBar";
import DialogButton from "../../components/form/DialogButton";
import PaymentAPI from "../../apis/PaymentAPI";
import Notify from "../../components/notify/Notify";
import {getErrorMessage} from "../../stores/StoreUtilities";
import {PaymentProvider, UpdateAccountInput} from "../../API";
import config from "react-global-configuration";
import ControlTower from "../../components/ControlTower";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    justifyContent: 'top',
    alignItems: 'center',
    width: "100%",
    maxWidth: 960,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1)
  },
  card: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: "column",
    width: '100%',
    marginTop: theme.spacing(1)
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  container: {
    padding: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1)
  },
  fieldValue: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
    minHeight: 20
  },
  fieldLink: {
    color: theme.palette.secondary.light
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
  buttons: {
    margin: theme.spacing(2),
    textAlign: "center"
  }
})

interface IAccountPaymentsProps {
  userStore?: UserStore
  accountStore?: AccountStore
  paymentAPI?: PaymentAPI
  progress?: Progress
  notify?: Notify
}

@inject("userStore", "accountStore", "paymentAPI", "progress", "notify")
@observer
class AccountPayments extends React.Component<WithStyles<typeof styles> & IAccountPaymentsProps & WithTheme> {

  @observable account?: Account
  @observable user?: User
  @observable isLoading = true
  @observable isStripeSetup: boolean | undefined = true
  @observable values = {
    paymentProvider: "Stripe",
    oneTimeGivingUrl: "",
    monthlyGivingUrl: ""
  }

  stripeAccount?: any

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    const { progress } = this.props
    this.isLoading = true
    progress!.show("AccountPayment")
    const { userStore, accountStore} = this.props
    when (
      () => !userStore!.isLoading && !accountStore!.isLoading,
      async () => {
        this.account = accountStore!.account
        this.user = userStore!.user
        if (this.account!.stripeId) {
          // Default to Stripe
          this.values.paymentProvider = PaymentProvider.Stripe
        } else {
          this.values.paymentProvider = this.account!.paymentProvider ? this.account!.paymentProvider.toString() : ""
        }
        this.isStripeSetup = await this.checkStripeSetup()
        this.values.monthlyGivingUrl = this.account!.paymentOptions ? this.account!.paymentOptions.monthlyGivingUrl : ""
        this.values.oneTimeGivingUrl = this.account!.paymentOptions ? this.account!.paymentOptions.oneTimeGivingUrl : ""
        progress!.hide("AccountPayment")
        this.isLoading = false
      }
    )
  }

  render() {
    const { classes } = this.props

    if (this.isLoading || !this.account) {
      return null
    }

    const title = "Payments"
    const paymentProviderisabled = false

    return (
      <Grid container direction="row" className={classes.root}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <TitleBar title={title} className={classes.titleBar}>
              </TitleBar>

              <FormValidator autoComplete="off"
                             name="PaymentEditForm" id="PaymentEditForm" className={classes.form}>
                <Grid container direction="column" spacing={1} className={classes.container}>
                  <Grid item>
                    <div className={classes.label}>Payment Provider</div>
                    <RadioGroup aria-label="paymentProvider" name="paymentProvider" value={this.values.paymentProvider} onChange={this.onChange} row>
                      <FormControlLabel
                        value="Stripe"
                        control={<Radio color="secondary" disabled={paymentProviderisabled}/>}
                        label="Stripe"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="YoungLife"
                        control={<Radio color="secondary" disabled={paymentProviderisabled}/>}
                        label="YL Giving"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </Grid>
                  {this.values.paymentProvider === "Stripe" &&
                    <Grid item>
                      <Typography variant="body1">
                        FunGives uses Stripe.com to make sure you get paid on time and to
                        keep your personal bank and details secure.
                      </Typography>
                    </Grid>
                  }
                  {this.values.paymentProvider === "Stripe" && !this.isStripeSetup &&
                    <Grid item className={classes.buttons}>
                      <DialogButton variant="secondary" type="button" onClick={this.onClickSetupStripe}>
                        Setup Stripe Account
                      </DialogButton>
                    </Grid>
                  }
                  {this.values.paymentProvider === "Stripe" && this.isStripeSetup &&
                    <Grid item className={classes.buttons}>
                      <DialogButton variant="secondary" type="button" onClick={this.onClickViewStripe}>
                        View Stripe Account
                      </DialogButton>
                    </Grid>
                  }
                  {this.values.paymentProvider === "YoungLife" &&
                    [
                      <Grid item>
                        <Typography variant="body1">
                          FunGives supports donating through the Young Life Giving platform.
                        </Typography>
                      </Grid>,
                      <Grid item>
                        <TextFieldValidator
                          margin="dense"
                          name="monthlyGivingUrl"
                          label="Monthly Giving URL"
                          type="text"
                          variant="standard"
                          validators={{required:false}}
                          onChange={this.onChange}
                          value={this.values.monthlyGivingUrl}
                          fullWidth
                        />
                        <TextFieldValidator
                          margin="dense"
                          name="oneTimeGivingUrl"
                          label="One Time Giving URL"
                          type="text"
                          variant="standard"
                          validators={{required:false}}
                          onChange={this.onChange}
                          value={this.values.oneTimeGivingUrl}
                          fullWidth
                        />
                      </Grid>,
                      <Grid item className={classes.buttons}>
                        <DialogButton variant="primary" type="button" onClick={this.onClickSave}>
                        Save
                        </DialogButton>
                      </Grid>
                    ]
                  }
                </Grid>
              </FormValidator>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }

  checkStripeSetup = async () => {
    const { paymentAPI, notify } = this.props

    let isSetup = false

    if (this.account && this.account!.stripeId !== null && this.account!.stripeId !== "") {
      const stripeAccount = await paymentAPI!.getAccount(this.account!.stripeId)
        .catch((error: any) => {
          notify!.show("error", getErrorMessage(error))
        })

      if (stripeAccount) {
        console.log(`stripeAccount = ${JSON.stringify(stripeAccount)}`)
        this.stripeAccount = stripeAccount
        isSetup = stripeAccount.charges_enabled
      }
    }

    return isSetup
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  onClickSetupStripe = async () => {
    const { paymentAPI, accountStore, notify } = this.props

    if (!this.stripeAccount) {
      this.stripeAccount = await paymentAPI!.createAccount(this.user!, this.account!)
        .catch((error: any) => {
          notify!.show("error", getErrorMessage(error))
        })

      if (this.stripeAccount) {
        const input: UpdateAccountInput = {
          id: this.account!.id,
          paymentProvider: PaymentProvider.Stripe,
          stripeId: this.stripeAccount.id
        }

        const account = await accountStore!.updateAccount(input)
          .catch((error: any) => {
            notify!.show("error", getErrorMessage(error))
          })

        if (account) {
          this.account = account
        }
      }
    }

    if (this.stripeAccount) {
      const homeUrl = config.get("homeUrl")
      const refreshUrl = `${homeUrl}/account/payment`
      const returnUrl = `${homeUrl}/account/payment`
      const accountUrls = await paymentAPI!.createAccountLinks(this.stripeAccount.id, refreshUrl, returnUrl)
        .catch((error: any) => {
          notify!.show("error", getErrorMessage(error))
        })

      if (accountUrls) {
        ControlTower.open(accountUrls.url, "_self")
      }
    }
  }

  onClickSave = async () => {
    const { accountStore, notify } = this.props

    if (this.values.paymentProvider) {

      const paymentOptions = JSON.stringify(
        {
          monthlyGivingUrl: this.values.monthlyGivingUrl,
          oneTimeGivingUrl: this.values.oneTimeGivingUrl
        }
      )

      const input: UpdateAccountInput = {
        id: this.account!.id,
        paymentProvider: PaymentProvider[this.values.paymentProvider],
        paymentOptions: paymentOptions
      }

      accountStore!.updateAccount(input)
        .catch((error: any) => {
          notify!.show("error", getErrorMessage(error))
        })
    }
  }

  onClickViewStripe = async () => {
    const { paymentAPI, notify } = this.props

    if (this.stripeAccount) {
      const loginLink = await paymentAPI!.createLoginLink(this.stripeAccount.id)
        .catch((error: any) => {
          notify!.show("error", getErrorMessage(error))
        })

      if (loginLink) {
        ControlTower.open(loginLink.url, "_blank")
      }
    }
  }

}

export default withTheme((withStyles(styles)(AccountPayments)))