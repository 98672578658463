import { Storage } from 'aws-amplify';
import CacheStore from "./CacheStore";

const S3CACHE_EXPIRE_SECS = 3600

class S3UrlCacheStore extends CacheStore<string> {

  constructor () {
    super(S3CACHE_EXPIRE_SECS, S3UrlCacheStore.s3Resolver)
  }

  static s3Resolver = async (key: string): Promise<string | undefined> => {
    const url = await Storage.get(key, {expires: S3CACHE_EXPIRE_SECS})
    if (url as string) {
      return (url as string)
    } else {
      return undefined
    }
  }

  removeKey = (key: string) => {
    this.remove(key)
  }
}

export default S3UrlCacheStore