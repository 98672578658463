import * as React from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import {observer} from "mobx-react";
import {ColorResult, SwatchesPicker} from 'react-color'
import {makeObservable, observable} from "mobx";

const styles = (theme: Theme) => createStyles({
})

interface IColorPickerProps {
  color?: string
  onChange?: any
}

@observer
class ColorPicker extends React.Component<WithStyles<typeof styles> & IColorPickerProps> {
  @observable displayColorPicker = false
  @observable color = { rgb: {r: 255, g: 255, b: 255, a: 1}} as ColorResult

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount() {
    const { color } = this.props
    if (color) {
      this.color = this.toColorResult(color) as ColorResult
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.color !== this.props.color && this.props.color) {
      this.color = this.toColorResult(this.props.color) as ColorResult
    }
  }
  handleClick = () => {
    this.displayColorPicker = !this.displayColorPicker
  };

  handleClose = () => {
    this.displayColorPicker = false
  };

  handleChange = (color: ColorResult) => {
    this.color = color

    if (this.props.onChange) {
      this.props.onChange(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
    }
    this.displayColorPicker = false
  };

  render() {

    const styles: any = {
        color: {
          width: 94,
          height: 20,
          borderRadius: 1,
          background: `rgba(${ this.color.rgb.r }, ${ this.color.rgb.g }, ${ this.color.rgb.b }, ${ this.color.rgb.a })`,
        },
        swatch: {
          padding: 2,
          background: '#fff',
          borderRadius: 1,
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
    }

    return (
      <div>
        <div style={ styles.swatch } onClick={ this.handleClick }>
          <div style={ styles.color } />
        </div>
        { this.displayColorPicker ? <div style={ styles.popover }>
          <div style={styles.cover} onClick={ this.handleClose }/>
          <SwatchesPicker color={ this.color.rgb } onChange={ this.handleChange } />
        </div> : null }

      </div>
    )
  }

  toColorResult = (color: string) => {
    let result

    const standardColors = [
      { name: "#003d5c", rgb: {r: 0, g: 61, b: 92, a: 1} },
      { name: "black", rgb: {r: 0, g: 0, b: 0, a: 1} },
      { name: "cornflowerblue", rgb: {r: 100, g: 149, b: 237, a: 1} },
      { name: "darkcyan", rgb: {r: 0, g: 139, b: 139, a: 1} },
      { name: "crimson", rgb: {r: 220, g: 20, b: 60, a: 1} },
      { name: "gold", rgb: {r: 255, g: 215, b: 0, a: 1} },
      { name: "darkgreen", rgb: {r: 0, g: 100, b: 0, a: 1} },
      { name: "dimgrey", rgb: {r: 105, g: 105, b: 105, a: 1} },
      { name: "goldenrod", rgb: {r: 218, g: 165, b: 32, a: 1} },
      { name: "darkorange", rgb: {r: 255, g: 140, b: 0, a: 1} },
      { name: "magenta", rgb: {r: 255, g: 0, b: 255, a: 1} },
      { name: "navy", rgb: {r: 0, g: 0, b: 128, a: 1} },
      { name: "darkolivegreen", rgb: {r: 85, g: 107, b: 47, a: 1} },
      { name: "deeppink", rgb: {r: 0, g: 0, b: 0, a: 1} },
      { name: "rebeccapurple", rgb: {r: 102, g: 51, b: 153, a: 1} },
      { name: "#dd0000", rgb: {r: 221, g: 0, b: 0, a: 1} },
      { name: "sienna", rgb: {r: 160, g: 82, b: 45, a: 1} },
      { name: "teal", rgb: {r: 0, g: 128, b: 128, a: 1} },
      { name: "turquoise", rgb: {r: 64, g: 224, b: 208, a: 1} },
      { name: "violet", rgb: {r: 238, g: 130, b: 238, a: 1} },
    ]
    const regex = /rgba\(\s*(-?\d+|-?\d*\.\d+(?=%))(%?)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*.\d+)\s*\)/
    const found = color.match(regex)
    if (found && found.length === 8) {
     result = { rgb: {r: parseInt(found[1]), g: parseInt(found[3]), b: parseInt(found[5]), a: parseFloat(found[7])}} as ColorResult
    } else {
      const match = standardColors.find((item: any) => item.name === color)
      if (match) {
        result = { rgb: match.rgb }
      } else {
        result = { rgb: {r: 0, g: 0, b: 0, a: 1}} as ColorResult
      }
    }
    return result
  }

}

export default withStyles(styles)(ColorPicker)
