import * as React from "react";
import {createStyles, Grid, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 8px 0px 8px",
  },
  title: {
    color: theme.palette.text.primary,
    justifyContent: "left",
    fontSize: 24,
    fontWeight: 600,
    alignItems: "center",
    paddingTop: 0,
    lineHeight: 1.2,
  },
  titleXs: {
    color: theme.palette.text.primary,
    justifyContent: "left",
    fontSize: 18,
    fontWeight: 600,
    alignItems: "center",
    paddingTop: 0,
    lineHeight: 1.2,
    whiteSpace: "nowrap"
  },
  subtitle: {
    color: theme.palette.text.secondary,
    justifyContent: "left",
    fontSize: 20,
    fontWeight: 600,
    alignItems: "center",
    paddingTop: 0,
    lineHeight: 1.2,
  },
  actions: {
    justifyContent: "right",
    textAlign: "right",
    paddingTop: 4
  },
})

interface ITitleBarProps {
  title: string,
  className?: string
  variant?: "title" | "subtitle" | "titleXs"
  children?: any
}

class TitleBar extends React.Component<WithStyles<typeof styles> & ITitleBarProps & WithTheme> {

  render() {
    const { classes, className, variant, title, children } = this.props

    let titleClassName = classes.title
    if (variant === "subtitle") {
      titleClassName = classes.subtitle
    } else if (variant === "titleXs") {
      titleClassName = classes.titleXs
    }

    return (
      <Grid item className={className ? className : classes.titleBar}>
        <Grid container direction="row">
          <Grid item className={titleClassName} xs={9}>
            {title}
          </Grid>
          <Grid item className={classes.actions} xs={3}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withTheme((withStyles(styles)(TitleBar)))
