import React from "react";
import {createStyles, Theme, WithStyles, WithTheme} from "@material-ui/core";
import {withStyles, withTheme} from "@material-ui/core/styles";

const styles = (theme: Theme) => createStyles({
  MenuHeading: {
    height: 25
  },
  containedButton: {
    padding: "2px 4px"
  },
  buttonLabel: {
    paddingLeft: 4,
    paddingRight: 4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },

})

export interface IMenuHeadingProps {
  label?: string
  children?: any
}

export class MenuHeading extends React.Component<WithStyles<typeof styles> & WithTheme & IMenuHeadingProps> {

  render() {
    return this.props.children
  }
}

export default withTheme((withStyles(styles)(MenuHeading)))