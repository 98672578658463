import * as React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import ControlTower, {Routes} from "../ControlTower";
import UserStore from "../../stores/UserStore";
import Tracking from "../Tracking";

const styles = (theme: Theme) => createStyles({
  menuIcon: {
    color: theme.palette.secondary.main,
  },
  menu: {
    backgroundColor: theme.palette.common.white
  },
  menuItem: { // TODO: Fix pseudo selector styles
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white
    // '&:hover': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.hover
    // },
    // '&:focus': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.selected
    // },
    // '&:active': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.active
    // }
  },
  activeMenuItem: {
    fontWeight: 900,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white
    // '&:hover': {
    //   color: theme.palette.secondary.main,
    //   backgroundColor: theme.palette.action.hover
    // },
    // '&:focus': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.selected
    // },
    // '&:active': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.active
    // }
  }
})

interface ISimpleMenuProps {
  userStore?: UserStore
  // accountStore?: AccountStore
}

@inject("userStore")
@observer
class SimpleMenu extends React.Component<WithStyles<typeof styles> & ISimpleMenuProps> {

  @observable anchorEl = null
  @observable showAuth = false
  @observable isAuthenticated = false

  handleClick = (event: any) => {
    this.anchorEl = event.currentTarget
  };

  handleClose = () => {
    this.anchorEl = null
  };

  handleHome = () => {
    ControlTower.route(Routes.home)
    this.handleClose()
  }

  handleAbout = () => {
    ControlTower.route(Routes.about)
    this.handleClose()
  }

  handleSignin = async () => {
    const { userStore } = this.props

    await userStore!.signOut()
    ControlTower.route(`${Routes.signin}`)
    this.handleClose()
  }

  handleSignOut = async () => {
    Tracking.event({action: "SignOut"})
    await this.props.userStore!.signOut()
    ControlTower.route(Routes.home)
  }

  handleAccount = (event: any) => {
    ControlTower.route(Routes.account)
  }

  render() {
    const { classes, userStore } = this.props

    // const currentPath = ControlTower.currentRoute
    const isAuthenticated = userStore!.isAuthenticated
    const isOwner = userStore!.isOwner

    const menuItems = []

    menuItems.push(
      <MenuItem key="0"
                className= {classes.menuItem}
                onClick={this.handleHome}>
        Home
      </MenuItem>
    )

    menuItems.push(
      <MenuItem key="1"
                className= {classes.menuItem}
                onClick={this.handleAbout}>
        About
      </MenuItem>
    )

    if (!isAuthenticated) {
      menuItems.push(
        <MenuItem key="2"
                  className= {classes.menuItem}
                  onClick={this.handleSignin}>
          Sign In
        </MenuItem>
      )
    } else {
      if (isOwner) {
        menuItems.push(
          <MenuItem key="3"
                    className= {classes.menuItem}
                    onClick={this.handleAccount}>
            Account
          </MenuItem>
        )
      }

      menuItems.push(
          <MenuItem key="3"
                    className={classes.menuItem}
                    onClick={this.handleSignOut}>
            Sign Out
          </MenuItem>
      )
    }

    return (
      <div>
        <div>
          <IconButton
            color="inherit"
            aria-label="Menu"
            aria-owns={this.anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={this.anchorEl}
            open={Boolean(this.anchorEl)}
            onClose={this.handleClose}
            classes={{paper: classes.menu}}
          >
            { menuItems }
          </Menu>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SimpleMenu)
