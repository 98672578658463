import * as React from "react";
import {Button, createStyles, Grid, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const styles = (theme: Theme) => createStyles({
  NavigationBar: {
    color: theme.palette.text.primary,
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 32,
    padding: "6px 4px 0px 0px",
  },
  title: {
    color: theme.palette.action.active,
    justifyContent: "left",
    // fontFamily: 'Archivo',
    fontSize: 16,
    fontWeight: 600,
    alignItems: "center",
    paddingTop: 0,
    paddingLeft: 0,
    cursor: "pointer",
    textDecoration: "none"
  },
  actions: {
    justifyContent: "right",
    textAlign: "right",
    paddingTop: 2
  },
  chevron: {
    verticalAlign: "middle",
    paddingBottom: 2
  }
})

interface INavigationBarProps {
  title: string,
  className?: string
  onBack?: any
}

class NavigationBar extends React.Component<WithStyles<typeof styles> & INavigationBarProps & WithTheme> {

  render() {
    const { classes, className, title } = this.props

    return (
      <Grid item className={className ? className : classes.NavigationBar}>
        <Grid container direction="row">
          <Grid item className={classes.title} xs={9}>
            <Button variant="text" onClick={this.onBack} className={classes.title}>
              <ChevronLeftIcon className={classes.chevron}/>{title}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  onBack = () => {
    if (this.props.onBack) {
      this.props.onBack()
    }
  }
}

export default withTheme((withStyles(styles)(NavigationBar)))
