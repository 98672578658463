import * as React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Button, createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {ButtonProps} from "@material-ui/core/Button";

const styles = (theme: Theme) => createStyles({
  primaryDialogButton: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    color: theme.palette.primary.contrastText,

    fontSize: 20,

    // fontWeight: 'bold',
    // letterSpacing: 0.7,
    // marginTop: 0,
    // paddingTop: 7,
    // paddingBottom: 5,
    // marginLeft: "0px",
    // "&:hover": {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.primary.light,
    // }
  },
  progressButton: {
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

interface IProgressButtonProps {
  classes: any
  processing: boolean
  fullWidth?: boolean
  children?: any
}

export type ProgressButtonProps = ButtonProps | IProgressButtonProps

class ProgressButton extends React.Component<WithStyles<typeof styles> & ProgressButtonProps> {

  state = {
    processing: false
  }

  componentDidUpdate(prevProps: any) {
    const processing = (this.props as IProgressButtonProps).processing
    if (processing !== prevProps.processing) {
      this.setState({processing})
    }
  }

  render() {
    const {
      classes,
      processing,
      children,
      ...rest
    } = this.props as IProgressButtonProps

    return (
      <Button disabled={processing} className={classes.primaryDialogButton} size="small" {...rest}>
        {children}
        {this.state.processing && <CircularProgress size={24} className={classes.progressButton} />}
      </Button>    )
  }
}

export default withStyles(styles)(ProgressButton)
