import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { RouteComponentProps } from '@reach/router'
import DialogButton from '../../components/form/DialogButton'
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Notify from "../../components/notify/Notify";
import {DonationFrequency} from "../../API";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import Campaign from "../../model/Campaign";

const styles = (theme: Theme) => createStyles({
  dialogActions: {
    justifyContent: "center",
    paddingBottom: "16px",
    paddingLeft: 20
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },

})

interface IBoardSpaceEditDialogProps {
  row: number,
  col: number,
  amount: number
  frequency: DonationFrequency
  other: string
  campaign?: Campaign
  onChange?: any
  onClose?: any
  notify?: Notify
}

@inject("notify")
@observer
class BoardSpaceEditDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IBoardSpaceEditDialogProps> {

  @observable values = {
    frequency: "",
    amount: 0,
    other: ""
  }

  @observable paymentSource = ""
  @observable message = ""

  componentDidMount() {
    const {amount, frequency, other, campaign} = this.props

    this.values.amount = amount
    this.values.frequency = other ? "Other" : frequency
    this.values.other = other

    this.paymentSource = campaign?.getPaymentSource()
  }

  render() {
    const { classes } = this.props

    const isOther = this.values.frequency === "Other"

    return (
      <Dialog
        id="passwordResetDialog"
        open={true}
        onClose={this.onClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        aria-labelledby="board-space-edit-dialog-title">
        <FormValidator onSubmit={this.onSubmit} autoComplete="off">
          <DialogTitle id="board-space-edit-dialog-title">Edit Space</DialogTitle>
          <DialogContent>
            <DialogContentText color="error">
              {this.message}
            </DialogContentText>
            <div className={classes.label}>Type</div>
            <RadioGroup aria-label="frequency" name="frequency" value={this.values.frequency} onChange={this.onChange} row>
              <FormControlLabel
                value="Once"
                control={<Radio color="secondary"/>}
                label="One Time"
                labelPlacement="end"
              />
              {this.paymentSource === "YoungLife" &&
                <FormControlLabel
                  value="Monthly"
                  control={<Radio color="secondary"/>}
                  label="Monthly"
                  labelPlacement="end"
                />
              }
              <FormControlLabel
                value="Other"
                control={<Radio color="secondary"/>}
                label="Other"
                labelPlacement="end"
              />
            </RadioGroup>
            {isOther &&
              <TextFieldValidator
                margin="dense"
                name="other"
                label="Other Donation"
                type="text"
                validators={{required:this.values.frequency === "Other"}}
                value={this.values.other}
                onChange={this.onChange}
                fullWidth
                helperText="Other donation description"
              />
            }
            <TextFieldValidator
              margin="dense"
              name="amount"
              label="Amount"
              type="text"
              validators={{required:true, minValue:0, maxValue:10000}}
              value={this.values.amount}
              onChange={this.onChange}
              helperText={this.values.frequency === "Other" ? "0 indicates none monetary gift" : "0 allows any amount"}
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <DialogButton variant="primary" onClick={this.onSubmit} fullWidth>
              Save
            </DialogButton>
            <DialogButton variant="tertiary" fullWidth onClick={this.onClose}>
              Cancel
            </DialogButton>
          </DialogActions>
        </FormValidator>
      </Dialog>
    );
  }

  onChange = (event: any) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  onClose = () => {
    if (this.props.onClose) {
        this.message = ""
        this.props.onClose();
    }
  };

  onSubmit = () => {
    const { row, col, onChange } = this.props

    this.message = ""

    if (onChange) {
      const frequency = this.values.frequency
      const amount = parseInt(this.values.amount.toString())
      onChange(row, col, frequency, amount, this.values.other)
    }
  }

}

export default withStyles(styles)(BoardSpaceEditDialog)
