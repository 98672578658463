import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles,
  DialogActions,
  DialogContent,
  FormGroup,
  Grid,
  Paper, Select,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import {inject, observer} from "mobx-react";
import {action, makeObservable, observable, when} from "mobx";
import Campaign from "../../model/Campaign";
import Progress from "../../components/Progress";
import UserStore from "../../stores/UserStore";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Tracking from "../../components/Tracking";
import {CampaignType, CreateCampaignInput, UpdateCampaignInput} from "../../API";
import ControlTower, {Routes} from "../../components/ControlTower";
import Notify from "../../components/notify/Notify";
import DialogButton from "../../components/form/DialogButton";
import {Storage} from 'aws-amplify';
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import ProgressButton from "../../components/form/ProgressButton";
import {createUUID, getISODateFromDate, isoToLocalDate} from "../../stores/StoreUtilities";
import AccountStore from "../../stores/AccountStore";
import {format, parse} from "date-fns";
import CampaignCard from "./CampaignCard";
import ReactPlayer from "react-player";
import config from 'react-global-configuration';
import CampaignStore from "../../stores/CampaignStore";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  content: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: 4
    }
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  divLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1)
  },
  fieldGroup: {
    marginTop: theme.spacing(1),
    marginBOttom: theme.spacing(1)
  },
  selectField: {
    width: "100%",
  },
  fileInput: {
    width: 340
  },
  campaignCard: {
    display: "flex",
    justifyContent: "center"
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 800,
  },
  media: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vw * (9/16))',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(50vw * (9/16))',
      maxHeight: 277 // 259
    },
    maxHeight: 540,
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  description: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingTop: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
      padding: 0
    },
    borderBottom: "1px solid rgba(255,255,255,0.7)",
    marginBottom: theme.spacing(1)
  },
})

interface ICampaignEditPageProps {
  campaignId?: string
  campaign?: Campaign
  userStore?: UserStore
  accountStore?: AccountStore
  campaignStore?: CampaignStore
  s3UrlCacheStore?: S3UrlCacheStore
  progress?: Progress
  notify?: Notify
  onSave?: any
}

@inject("userStore", "accountStore", "campaignStore", "progress", "notify", "s3UrlCacheStore")
@observer
class CampaignEditPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICampaignEditPageProps & WithTheme> {

  @observable isLoading = true
  @observable campaign?: Campaign
  @observable preview?: Campaign
  @observable previewUrl? : string
  @observable values = {
    campaignId: "",
    title: "",
    alias: "",
    description: "",
    city: "",
    state: "",
    campaignType: "BINKO",
    goalAmount: 0,
    startAt: "",
    endAt: "",
    publishAt: "",
    coverFile: undefined,
    coverUrl: "",
    name: "BINKO",
    contactInfo: "",
    custom: {},
    customUpdate: {},
  }
  @observable player?: ReactPlayer
  @observable isValid: boolean = true
  @observable isProcessing = false
  @observable progressValue: number = 0
  @observable progressText: string = ""
  @observable shareUrl: string = config.get("shareUrl")

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    const { campaign, campaignId, userStore, accountStore, progress} = this.props
    this.isLoading = true
    progress!.show("CampaignEditPage")
    when(
      () => !userStore!.isLoading,
      async () => {
        let useCampaign
        if (campaign) {
          useCampaign = campaign
        } else if (campaignId) {
          useCampaign = await accountStore!.getCampaign(campaignId!)
        }

        if (useCampaign) {
          this.values = {
            campaignId: useCampaign.id,
            title: useCampaign.title,
            alias: useCampaign.alias,
            description: useCampaign.description,
            city: useCampaign.city,
            state: useCampaign.state,
            campaignType: useCampaign.campaignType ? useCampaign.campaignType.toString() : "BINKO",
            goalAmount: useCampaign.goalAmount,
            publishAt: useCampaign.publishAt ? format(isoToLocalDate(useCampaign.publishAt), "M/d/yyyy") : "",
            startAt: useCampaign.startAt ? format(isoToLocalDate(useCampaign.startAt), "M/d/yyyy") : "",
            endAt: useCampaign.endAt ? format(isoToLocalDate(useCampaign.endAt), "M/d/yyyy") : "",
            coverUrl: useCampaign.coverUrl,
            coverFile: undefined,
            name: useCampaign.getCustom("name"),
            contactInfo: useCampaign.contactInfo,
            custom: useCampaign.custom,
            customUpdate: {...useCampaign.custom},
          }
          if (!this.values.publishAt) {
            this.values.publishAt = this.values.startAt
          }
          if (!this.values.custom) {
            this.values.custom = this.getCustomDefault()
          }
          this.campaign = useCampaign
        } else {
          this.values.campaignId = createUUID()
          this.values.custom = this.getCustomDefault()
          this.values.customUpdate = {...this.values.custom}
        }
        this.updatePreview()
        this.isLoading = false
        progress!.hide("CampaignEditPage")
      }
    )
  }

  render() {
    const { classes, campaign } = this.props

    const title = campaign ? "Fundraiser Settings" : "Add Fundraiser"
    const minDate = format(new Date("2020-01-01"), 'M/d/yyyy')

    const content =
      <Grid container className={classes.root} direction="column">
        <Paper className={classes.dialogPaper}>
          <TitleBar title={title} className={classes.titleBar}>
          </TitleBar>
          <FormValidator onSubmit={this.onSubmit} autoComplete="off"
                         name="CampaignEditForm" id="CampaignEditForm" className={classes.form}>
            <DialogContent className={classes.dialogContent}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextFieldValidator
                    margin="dense"
                    name="title"
                    label="Title"
                    type="text"
                    variant="standard"
                    validators={{required:true}}
                    onChange={this.onChange}
                    value={this.values.title}
                    fullWidth
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="description"
                    label="Description"
                    type="text"
                    variant="standard"
                    validators={{required:true}}
                    onChange={this.onChange}
                    value={this.values.description}
                    fullWidth
                    multiline
                    rows={2}
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="contactInfo"
                    label="Contact Info"
                    type="text"
                    variant="standard"
                    validators={{required:true}}
                    onChange={this.onChange}
                    value={this.values.contactInfo}
                    fullWidth
                    helperText="Names and/or emails to contact for questions"
                    multiline
                    rows={2}
                  />
                  <Grid container className={classes.fieldGroup} spacing={1}>
                    <Grid item xs={6}>
                      <TextFieldValidator
                        margin="dense"
                        name="city"
                        label="City"
                        type="text"
                        variant="standard"
                        validators={{required:true}}
                        onChange={this.onChange}
                        value={this.values.city}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldValidator
                        margin="dense"
                        name="state"
                        label="State"
                        type="text"
                        variant="standard"
                        validators={{required:true}}
                        onChange={this.onChange}
                        value={this.values.state}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.fieldGroup} spacing={1}>
                    <Grid item xs={6}>
                      <label className={classes.fieldLabel}>
                        Campaign Type
                      </label>
                      <Select
                        native
                        name="campaignType"
                        value={this.values.campaignType}
                        onChange={this.onChange}
                        inputProps={{
                          name: 'campaignType',
                          id: 'campaignType',
                        }}
                        className={classes.selectField}
                      >
                        <option value="BINKO">BINKO</option>
                        <option value="NoGame">No Game</option>
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldValidator
                        margin="dense"
                        name="goalAmount"
                        label="Goal Amount"
                        type="text"
                        variant="standard"
                        validators={{required:true, minValue:0}}
                        onChange={this.onChange}
                        value={this.values.goalAmount}
                        fullWidth
                        placeholder=""
                      />
                    </Grid>
                  </Grid>

                  <Grid container className={classes.fieldGroup} spacing={1}>
                    <Grid item xs={6}>
                      <TextFieldValidator
                        margin="dense"
                        name="startAt"
                        label="Start Date"
                        type="text"
                        variant="standard"
                        validators={{required:true, minDate:minDate}}
                        onChange={this.onChange}
                        value={this.values.startAt}
                        fullWidth
                        placeholder="m/d/yyyy"
                        helperText="Date to enable play/donate"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldValidator
                        margin="dense"
                        name="endAt"
                        label="End Date"
                        type="text"
                        variant="standard"
                        validators={{required:true, minDate:minDate}}
                        onChange={this.onChange}
                        value={this.values.endAt}
                        fullWidth
                        placeholder="m/d/yyyy"
                        helperText="Date to disable play/donate"
                      />
                    </Grid>
                  </Grid>

                  <Grid container className={classes.fieldGroup} spacing={1}>
                    <Grid item xs={6}>
                      <div className={classes.label}>Cover Image (JPEG)</div>
                      <FormGroup row>
                        <input
                          type='file'
                          accept='image/jpeg'
                          className={classes.fileInput}
                          onChange={(e) => this.onCoverChange(e.target!.files![0])}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldValidator
                        margin="dense"
                        name="publishAt"
                        label="Publish Date"
                        type="text"
                        variant="standard"
                        validators={{required:false, minDate:minDate}}
                        onChange={this.onChange}
                        value={this.values.publishAt}
                        fullWidth
                        placeholder="m/d/yyyy"
                        helperText="Date to make public"
                      />
                    </Grid>
                  </Grid>
                  <TextFieldValidator
                    margin="dense"
                    name="alias"
                    label="Sharing Alias (4-20 characters: a-z, 0-9, -)"
                    type="text"
                    variant="standard"
                    validators={{required:true, matches:"^[a-z-0-9]{4,20}$"}}
                    onChange={this.onChange}
                    value={this.values.alias}
                    fullWidth
                    helperText={`${this.shareUrl}/${this.values.alias}`}
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <label className={classes.fieldLabel}>Preview</label>
                  <div className={classes.campaignCard}>
                    {this.preview &&
                      <CampaignCard campaign={this.preview!} previewUrl={this.previewUrl} onCardAction={this.onCardAction}/>
                    }
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12} >
                  <ProgressButton variant="contained" color="primary" fullWidth
                                  type="submit" processing={this.isProcessing}
                                  onClick={this.onSubmit}>
                    Save
                  </ProgressButton>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <DialogButton variant="tertiary" fullWidth onClick={this.onCancel}>
                    Cancel
                  </DialogButton>
                </Grid>
              </Grid>
            </DialogActions>
          </FormValidator>
        </Paper>
      </Grid>

    if (campaign) {
      return content
    } else {
      return (
        <Page title={title}>
          <MarginRow>
            {content}
          </MarginRow>
        </Page>
      )
    }
  }

  onChange = (event: any) => {
    const name = event.target.name
    if (name === "alias") {
      this.values[name] = event.target.value.toLowerCase()
    } else {
      this.values[name] = event.target.value
    }
    this.updatePreview()
  }

  @action onCoverChange = async (file: any) => {
    this.values.coverFile = file
    this.previewUrl = (window.URL ? URL : webkitURL).createObjectURL(file)
  }

  onCardAction = (campaign: Campaign) => {
    // Ignore for now
  }

  @action updatePreview = () => {
    let preview
    const values = this.values
    let startAt = ""
    let endAt = ""
    let publishAt = ""
    if (values.startAt) {
      try {
        startAt = values.startAt ? getISODateFromDate(parse(values.startAt, 'M/d/yyyy', new Date())) : ""
      } catch (error) {
        // Ignore
      }
    }
    if (values.endAt) {
      try {
        endAt = values.endAt ? getISODateFromDate(parse(values.startAt, 'M/d/yyyy', new Date())) : ""
      } catch (error) {
        // Ignore
      }
    }
    if (values.publishAt) {
      try {
        publishAt = values.publishAt ? getISODateFromDate(parse(values.publishAt, 'M/d/yyyy', new Date())) : ""
      } catch (error) {
        // Ignore
      }
    } else {
      publishAt = startAt
    }

    if (this.campaign) {
      preview = new Campaign({
        title: values.title,
        description: values.description,
        city: values.city,
        alias: values.alias,
        state: values.state,
        campaignType: CampaignType[values.campaignType],
        goalAmount: values.goalAmount,
        donatedAmount: 0,
        startAt,
        endAt,
        publishAt,
        layout: this.campaign.layout ? JSON.stringify(this.campaign.layout) : "",
        // custom: this.campaign.custom ? JSON.stringify(this.campaign.custom) : "",
        coverUrl: values.coverUrl,
        contactInfo: values.contactInfo,
        boards: this.campaign.boards,
        donations: this.campaign.donations
      })
    } else {
      preview = new Campaign({
        title: values.title,
        alias: values.alias,
        description: values.description,
        city: values.city,
        state: values.state,
        campaignType: CampaignType[values.campaignType],
        goalAmount: values.goalAmount,
        donatedAmount: 0,
        startAt,
        endAt,
        coverUrl: values.coverUrl,
        contactInfo: values.contactInfo,
      })
    }

    this.preview = preview
  }

  onSubmit = async () => {
    const { campaign, accountStore, campaignStore, notify, onSave } = this.props

    if (!this.isValid) {
      return
    }

    const values = this.values
    const startAt = getISODateFromDate(parse(values.startAt, 'M/d/yyyy', new Date()))
    const endAt = getISODateFromDate(parse(values.endAt, 'M/d/yyyy', new Date()))
    const publishAt = values.publishAt ? getISODateFromDate(parse(values.publishAt, 'M/d/yyyy', new Date())) : startAt

    if (campaign) {
      Tracking.event({action: 'UpdateCampaign'})
      this.isProcessing = true

      // Check for duplicate alias
      if (values.alias.toLowerCase() !== campaign.alias) {
        const existing = await campaignStore!.getCampaignOnly(values.alias)
        if (existing && existing.id !== campaign.id) {
          this.isProcessing = false
          notify!.show("error", `The alias (${values.alias}) is already in use.`)
          return
        }
      }

      if (values.coverFile) {
        const result = await this.uploadCover()
        if (!result) {
          this.isProcessing = false
          return
        }
      }

      const input: UpdateCampaignInput = {
        id: values.campaignId,
        title: values.title,
        alias: values.alias,
        description: values.description,
        city: values.city,
        state: values.state,
        campaignType: CampaignType[values.campaignType],
        goalAmount: values.goalAmount,
        donatedAmount: 0,
        publishAt,
        startAt,
        endAt,
        coverUrl: values.coverUrl,
        shareUrl: `${this.shareUrl}/${values.alias}`,
        contactInfo: values.contactInfo,
      }

      const update = await accountStore!.updateCampaign(input)
        .catch((err: Error) => {
          this.isProcessing = false
          notify!.show("error", "Unable to update fundraiser")
        })

      if (update) {
        this.isProcessing = false
        // userStore!.createActivity(ActivityType.CampaignEdit, campaignId)
        notify!.show("success", "Fundraiser updated!")
        if (onSave) {
          onSave(update)
        }
        this.onCancel()
      }
    } else {
      Tracking.event({action: 'CreateCampaign'})
      this.isProcessing = true

      // Check for duplicate alias
      const existing = await campaignStore!.getCampaignOnly(values.alias)
      if (existing) {
        this.isProcessing = false
        notify!.show("error", `The alias (${values.alias}) is already in use.`)
        return
      }

      if (values.campaignId === "") {
        values.campaignId = createUUID()
      }

      if (values.coverFile) {
        const result = await this.uploadCover()
        if (!result) {
          this.isProcessing = false
          return
        }
      }

      const input: CreateCampaignInput = {
        id: values.campaignId,
        accountId: accountStore!.account!.id,
        campaignType: CampaignType[values.campaignType],
        goalAmount: 0,
        donatedAmount: 0,
        startAt: startAt,
        endAt: endAt,
        title: values.title,
        alias: values.alias,
        description: values.description,
        city: values.city,
        state: values.state,
        coverUrl: values.coverUrl,
        shareUrl: `${this.shareUrl}/${values.alias}`,
        contactInfo: values.contactInfo,
      }

      const campaign = await accountStore!.createCampaign(input)
        .catch((err: Error) => {
          this.isProcessing = false
          notify!.show("error", "Unable to create fundraiser")
        })

      if (campaign) {
        this.isProcessing = false
        // userStore!.createActivity(ActivityType.CampaignCreate, useCampaign.id)
        notify!.show("success", "Fundraiser created!")
        if (onSave) {
          onSave(campaign)
        }
        ControlTower.route(`${Routes.account}/campaign/${campaign.id}/customize`)
      }
    }

  }

  onCancel = () => {
    const { campaign } = this.props
    if (campaign) {
      // Editing campaign
      ControlTower.route(`${Routes.account}/campaign/${campaign.id}`)
    } else {
      ControlTower.route(Routes.accountCampaigns)
    }
  }

  uploadCover = async () => {
    const { notify, s3UrlCacheStore } = this.props

    const key = `content/${this.values.campaignId}/cover.jpg`

    const result = await Storage.put(key, this.values.coverFile, {
      contentType: 'image/jpeg'
    })
      .catch((err: Error) => {
        notify!.show("error", "Unable to upload cover image")
      })

    if (result) {
      this.values.coverUrl = result["key"]
      s3UrlCacheStore!.remove(this.values.coverUrl)
    }
    return result
  }

  quote = (text: string): string => {
    return text.replace(/"/g, '""')
  }

  getCustomDefault = () => {
    const temp = new Campaign({})
    return ({
          overview: temp.getCustom("overview"),
          boardsInstructions: temp.getCustom("boardsInstructions"),
          newBoardInstructions: temp.getCustom("newBoardInstructions"),
          playInstructions: temp.getCustom("playInstructions"),
          donateInstructions: temp.getCustom("donateInstructions"),
          shareInstructions: temp.getCustom("shareInstructions")
    })
  }
}

export default withTheme((withStyles(styles)(CampaignEditPage)))