import {computed, makeObservable, observable} from "mobx";
import {CognitoUser, CognitoUserAttribute} from 'amazon-cognito-identity-js'
import {Auth} from 'aws-amplify'
import GivinciAPI from '../apis/GivinciAPI'
import Logger from "../components/Logger";
import User from "../model/User";
import {format, fromUnixTime, isToday} from "date-fns";
import ControlTower, {Routes} from "../components/ControlTower";
import {phoneToE164Format} from "./StoreUtilities";
import * as APITypes from "../API"
import {
  UserRole,
} from "../API"
import Tracking from "../components/Tracking";


export const UserStoreConstants = {

  USER_ALREADY_CONFIRMED: "User cannot be confirm. Current status is CONFIRMED",
  CONFIRMATION_SUCCESS: "SUCCESS",
  USER_NOT_FOUND: "User not found",
  USERNAME_EXISTS_EXCEPTION: "UsernameExistsException",
  EMAIL_EXISTS_MESSAGE: "PreSignUp failed with error Email exists.",
  USERNAME_NOT_CONFIRMED_EXCEPTION: "UserNotConfirmedException",
  USER_NOT_CONFIRMED: "User not confirmed",
  NOT_AUTHORIZED_EXCEPTION: "NotAuthorizedException",
  USER_NOT_FOUND_EXCEPTION: "UserNotFoundException",
  CODE_MISMATCH_EXEPTION: "CodeMismatchException",
  CONDITIONAL_REQUEST_FAILED: "The conditional request failed",
  EMAIL_VERIFICATION_PENDING: "Email verification pending",
  PHONE_VERIFICATION_PENDING: "Phone verification pending",
  COMPANY_EMAIL: ""
}

export interface ICognitoAttributes {
  email?: string,
  phone_number?: string,
  "custom:account"?: string,
  "custom:role"?: string
}

export const CognitoAttribute = {
  EMAIL: "email",
  PHONE_NUMBER: "phone_number",
  ACCOUNT: "custom:account",
  ROLE: "custom:role"
}

export const CognitoAttributeValue = {
  FALSE: "false",
  TRUE: "true"
}

class UserStore {
  @observable groups: string[] = []
  @observable user?: User
  @observable isLoading: boolean = false
  @observable isAdmin: boolean = false

  givinciAPI: GivinciAPI

  @observable private _cognitoUser?: CognitoUser

  get cognitoUser() {
    return this._cognitoUser
  }

  get isOwner() {
    return this.user ? this.user.isOwner : false
  }

  private _attributes: CognitoUserAttribute[] = []

  constructor(options: any) {
    makeObservable(this);
    this.givinciAPI = (options && options.givinciAPI) ? options.givinciAPI : null
  }

  signUp = async (username: string, password: string, email: string, phone: string, accountId?: string, role?: UserRole) => {
    this._cognitoUser = undefined
    this.user = undefined
    this._attributes = []

    return await new Promise<CognitoUser | any>((resolve, reject) => {
      const attributes = {}
      attributes[CognitoAttribute.EMAIL] = email.toLowerCase()
      if (phone) {
        attributes[CognitoAttribute.PHONE_NUMBER] = phoneToE164Format(phone)
      }
      if (accountId) {
        attributes[CognitoAttribute.ACCOUNT] = accountId
      }
      if (role) {
        attributes[CognitoAttribute.ROLE] = role
      }

      Auth.signUp({
        username,
        password,
        attributes,
        validationData: []  // optional
      })
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  confirmSignUp = async (username: string, code: string, options?: any): Promise<string | any> => {
    return await Auth.confirmSignUp(username, code, options)
  }

  resendSignUp = async (username: string): Promise<string> => {
    return await Auth.resendSignUp(username)
  }

  signIn = async (username: string, password: string) => {
    this._cognitoUser = undefined
    this.user = undefined
    this._attributes = []

    return await new Promise<CognitoUser | any>((resolve, reject) => {
      Auth.signIn(username.toLowerCase(), password)
        .then(async (cognitoUser: any) => {
          if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
            Logger.debug(`Auth.signIn(${username}) = ${cognitoUser.challengeName}`)
            resolve(cognitoUser)
          } else {
            Logger.debug(`Auth.signIn(${username}) success`)
            // Load and initialize User
            this.initSession(cognitoUser)
              .then(result => {
                // this.createActivity(ActivityType.UserSignIn, result.id)
                resolve(result)
              })
              .catch(async (reason: any) => {
                // await this.signInAsGuest()
                reject(reason)
              })
          }
        }).catch(err => {
        if (err.code !== UserStoreConstants.USER_NOT_FOUND_EXCEPTION &&
          err.code !== UserStoreConstants.NOT_AUTHORIZED_EXCEPTION) {
          Logger.error("Auth.SignIn error.", err)
        }
        reject(err)
      })
    })
  }

  signOut = async () => {
    return await new Promise<any>((resolve, reject) => {
      this.currentAuthenticatedUser()
        .then((cognitoUser: CognitoUser) => {
          Auth.signOut()
            .then(() => {
              Logger.debug("Auth.signOut success")
              this._cognitoUser = undefined
              this.user = undefined
              this._attributes = []
              if (this.checkInterval) {
                clearInterval(this.checkInterval)
                this.checkInterval = undefined
              }
              resolve(null)
            })
            .catch(err => {
              Logger.error("Auth.signOut error", err)
              reject(err)
            })
        })
    })
  }

  currentSession = async() => {
    Auth.currentSession()
      .then(data => {
        return data
      })
      .catch(err => {
        console.log(`Auth.currentSession err: ${JSON.stringify(err)}`)
      });
  }

  currentAuthenticatedUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser()
      .catch(err => {
        this._cognitoUser = undefined
      })
    if (cognitoUser) {
      this._cognitoUser = cognitoUser
      return cognitoUser
    } else {
      this._cognitoUser = undefined
      return null
    }
  }

  getUserAttribute = async (cognitoUser: any, name: string) => {
    const attributes = await Auth.userAttributes(cognitoUser ? cognitoUser : this._cognitoUser)
    const attribute = attributes.find(a => a.getName() === name)
    if (attribute) {
      return attribute.getValue()
    } else {
      return null
    }
  }

  getUserAttributes = async (cognitoUser?: any) => {
    return await Auth.userAttributes(cognitoUser ? cognitoUser : this._cognitoUser)
  }

  updateUserAttributes = async (cognitoUser: any, attributes: any) => {
    return await Auth.updateUserAttributes(cognitoUser ? cognitoUser : this._cognitoUser, attributes)
  }

  initSession = async (cognitoUser: CognitoUser) => {

    console.log("UserStore.initSession")
    this.isLoading = true

    return await new Promise<CognitoUser | any>( async (resolve, reject) => {
      this._cognitoUser = cognitoUser
      await this.getCurrentSessionPayload()
      const username = cognitoUser.getUsername()
      this.checkAuthentication()

      this.loadUser(username)
        .then((user: any) => {
          Tracking.set({userId: user.email})
          this.isLoading = false
          resolve(user)
        })
        .catch((err: any) => {
          this.isLoading = false
          reject(err)
        })
    })
  }

  async getCurrentSessionPayload() {
    const session = await Auth.currentSession()
    const authTimeValue = session.getIdToken().payload['auth_time']
    const authTime = fromUnixTime(authTimeValue)
    console.log(`User authenticated at ${format(authTime!, "M/d/yyyy h:mm:ss aa")}`)
    const groups = session.getIdToken().payload['cognito:groups']
    this.isAdmin = (groups && groups.indexOf("Admin") >= 0)
  }

  @computed get isAuthenticated() {
    return this._cognitoUser !== undefined && this.user !== undefined
  }

  checkInterval: any

  checkAuthentication() {
    console.log("checkAuthentication")
    Auth.currentSession()
      .then((session: any) => {
        const authTimeValue = session.getIdToken().payload['auth_time']
        const authTime = fromUnixTime(authTimeValue)
        if (!isToday(authTime)) {
          console.log("Authentication expired")
          ControlTower.route(Routes.signout)
        }
      })
      .catch((err: any) => {
      })

    if (!this.checkInterval) {
      // Check authentication every hour
      this.checkInterval = setInterval(this.checkAuthentication, 60 * 60000)
    }
  }

  completeNewPassword = async (user: string, newPassword: string) => {
    return await new Promise<any>((resolve, reject) => {
      Auth.completeNewPassword(user, newPassword, null)
        .then(data => {
          Logger.debug("Auth.completeNewPassword success")
          resolve(data)
        })
        .catch(err => {
          Logger.debug("Auth.completeNewPassword error", err)
          reject(err)
        });
    })
  }

  loadUser = async (username: string): Promise<User | undefined> => {
    Logger.debug(`UserStore.loadUser(${username})`)

    const data = await this.givinciAPI.getUser(username)
    console.log("Loaded user")

    if (data) {
      let user = new User(data)
      if (user) {
        if (user.account && !user.account!.active) {
          throw new Error("Account is not active. Please contact account owner or admin.")
        } else {
          this.user = user
          console.log("Initializing Logger")
          Logger.configureUser(this.user.id)
          Logger.info("Signed in as " + this.user.id)
          console.log("loadUser completed")
        }
      }
    } else {
      throw new Error("User not found")
    }

    return this.user
  }

  getUser = async (username: string): Promise<User | undefined> => {
    const data = await this.givinciAPI.getUser(username)
    if (data) {
      return new User(data)
    } else {
      return undefined
    }
  }

  async createUser(input:  APITypes.CreateUserInput) {
    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }
    const user = await this.givinciAPI!.createUser(input)
    if (user) {
      Tracking.event({action: "Create Account"})
    //   const attributes: ICognitoAttributes = {}
    //   let updateAttributes = false
    //   if (user.accountId) {
    //     attributes[CognitoAttribute.ACCOUNT] = user.accountId
    //     updateAttributes = true
    //   }
    //   if (user.role) {
    //     attributes[CognitoAttribute.ROLE] = user.role
    //     updateAttributes = true
    //   }
    //   if (updateAttributes) {
    //     await this.updateUserAttributes(null, attributes)
    //   }

      this.user = new User(user)
      // this.createActivity(ActivityType.UserCreate, this.user.id)
      return this.user
    } else {
      return null
    }
  }

  async updateUser(input: APITypes.UpdateUserInput) {

    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }
    const user = await this.givinciAPI!.updateUser(input)
    if (user) {
      if (user.id === this.user!.id) {
        const updatedUser = new User(user)
        // Preserve related data
        updatedUser.account = this.user!.account
        this.user = updatedUser
        // Verify custom account user attribute
        const accountValue = await this.getUserAttribute(null, CognitoAttribute.ACCOUNT)
        if (accountValue !== user.accountId) {
          const attributes: ICognitoAttributes = {}
          attributes[CognitoAttribute.ACCOUNT] = user.accountId
          console.log(`Updated custom:Account Cognito attribute: ${user.accountId}`)
          await this.updateUserAttributes(null, attributes)
        }
        const roleValue = await this.getUserAttribute(null, CognitoAttribute.ROLE)
        if (roleValue !== user.role) {
          const attributes: ICognitoAttributes = {}
          attributes[CognitoAttribute.ROLE] = user.role
          console.log(`Updated custom:Role Cognito attribute: ${user.role}`)
          await this.updateUserAttributes(null, attributes)
        }
        return this.user
      } else {
        return new User(user)
      }
    } else {
      return null
    }
  }

  async forgotPassword(userId: string) {
    await Auth.forgotPassword(userId)
    return "SUCCESS"
  }

  async forgotPasswordSubmit(userId: string, code: string, password: string) {
    await Auth.forgotPasswordSubmit(userId, code, password)
    return "SUCCESS"
  }

}

export default UserStore