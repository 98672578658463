import * as React from 'react'
import {
  createStyles,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import TitleBar from "../../components/TitleBar";
import {inject, observer} from "mobx-react";
import {action, makeObservable, observable, when} from "mobx";
import Campaign from "../../model/Campaign";
import Progress from "../../components/Progress";
import UserStore from "../../stores/UserStore";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Tracking from "../../components/Tracking";
import {UpdateCampaignInput} from "../../API";
import ControlTower, {Routes} from "../../components/ControlTower";
import Notify from "../../components/notify/Notify";
import DialogButton from "../../components/form/DialogButton";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import ProgressButton from "../../components/form/ProgressButton";
import AccountStore from "../../stores/AccountStore";
import ReactPlayer from "react-player";
import {Editor} from "@tinymce/tinymce-react";
import config from 'react-global-configuration';

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  content: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: 4
    }
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  divLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1)
  },
  fieldGroup: {
    marginTop: theme.spacing(1),
    marginBOttom: theme.spacing(1)
  },
  selectField: {
    width: "100%",
  },
  fileInput: {
    width: 340
  },
  campaignCard: {
    display: "flex",
    justifyContent: "center"
  },
  sectionTitle: {
    color: theme.palette.secondary.light,
    fontSize: 18,
    fontWeight: 800,
  },
  media: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vw * (9/16))',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(50vw * (9/16))',
      maxHeight: 277 // 259
    },
    maxHeight: 540,
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  description: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingTop: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
      padding: 0
    },
    borderBottom: "1px solid rgba(255,255,255,0.7)",
    marginBottom: theme.spacing(1)
  },
})

interface ICampaignCustomSettingsProps {
  campaignId?: string
  campaign?: Campaign
  userStore?: UserStore
  accountStore?: AccountStore
  s3UrlCacheStore?: S3UrlCacheStore
  progress?: Progress
  notify?: Notify
  onSave?: any
}

@inject("userStore", "accountStore", "progress", "notify", "s3UrlCacheStore")
@observer
class CampaignCustomSettings extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICampaignCustomSettingsProps & WithTheme> {

  @observable isLoading = true
  @observable campaign?: Campaign
  @observable preview?: Campaign
  @observable previewUrl? : string
  @observable values = {
    campaignId: "",
    title: "",
    description: "",
    city: "",
    state: "",
    campaignType: "",
    coverUrl: "",
    name: "",
    videoUrl: "",
    custom: {},
    customUpdate: {},
  }
  @observable player?: ReactPlayer
  @observable isValid: boolean = true
  @observable isProcessing = false
  @observable progressValue: number = 0
  @observable progressText: string = ""

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    const { campaign, campaignId, userStore, accountStore, progress} = this.props
    this.isLoading = true
    progress!.show("CampaignCustomSettings")
    when(
      () => !userStore!.isLoading,
      async () => {
        let useCampaign
        if (campaign) {
          useCampaign = campaign
        } else if (campaignId) {
          useCampaign = await accountStore!.getCampaign(campaignId!)
        }

        if (useCampaign) {
          this.values = {
            campaignId: useCampaign.id,
            title: useCampaign.title,
            description: useCampaign.description,
            city: useCampaign.city,
            state: useCampaign.state,
            campaignType: useCampaign.campaignType ? useCampaign.campaignType.toString() : "BINKO",
            coverUrl: useCampaign.coverUrl,
            name: useCampaign.getCustom("name"),
            videoUrl: useCampaign.videoUrl,
            custom: useCampaign.custom,
            customUpdate: {...useCampaign.custom},
          }
          if (!this.values.custom) {
            this.values.custom = this.getCustomDefault()
          }
          this.campaign = useCampaign
        }
        this.updatePreview()
        this.isLoading = false
        progress!.hide("CampaignCustomSettings")
      }
    )
  }

  ref = (player: ReactPlayer) => {
    this.player = player
  }

  render() {
    const { classes } = this.props

    const title = "Fundraiser Customizations"
    const noGame = this.values.campaignType === "NoGame"

    const content =
      <Grid container className={classes.root} direction="column">
        <Paper className={classes.dialogPaper}>
          <FormValidator onSubmit={this.onSubmit} autoComplete="off"
                         name="CampaignEditForm" id="CampaignEditForm" className={classes.form}>
            <TitleBar title={title} className={classes.titleBar}>
            </TitleBar>
            <DialogContent className={classes.dialogContent}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Grid item xs={12} className={classes.sectionTitle}>
                    Main Page
                  </Grid>
                  <TextFieldValidator
                    margin="dense"
                    name="name"
                    label="Game Name"
                    type="text"
                    variant="standard"
                    validators={{required:true, minLength:5, maxLength:5}}
                    onChange={this.onChange}
                    value={noGame ? "None " : this.values.name}
                    fullWidth
                    disabled={noGame}
                    helperText="Must be 5 characters"
                  />
                  <TextFieldValidator
                    margin="dense"
                    name="videoUrl"
                    label="Video URL"
                    type="text"
                    variant="standard"
                    validators={{required:false}}
                    onChange={this.onChange}
                    value={this.values.videoUrl}
                    fullWidth
                  />
                  <label className={classes.fieldLabel}>Preview</label>
                  {this.values.videoUrl &&
                  <div className={classes.media}>
                    <ReactPlayer
                      url={this.values.videoUrl}
                      ref={this.ref}
                      controls={true}
                      playsinline={true}
                      width="100%"
                      height="100%"
                    />
                  </div>
                  }
                  <div className={classes.divLabel}>Overview (Click to edit)</div>
                  { this.renderCustom("overview") }
                </Grid>

                <Grid item xs={12} sm={6} >
                  <Grid item xs={12} className={classes.sectionTitle}>
                    Boards Page
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.divLabel}>Boards Page Instructions (Click to edit)</div>
                    { this.renderCustom("boardsInstructions") }
                  </Grid>

                  <Grid item xs={12} className={classes.sectionTitle}>
                    New Board Page
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.divLabel}>New Board Page Instructions (Click to edit)</div>
                    { this.renderCustom("newBoardInstructions") }
                  </Grid>

                  <Grid item xs={12} className={classes.sectionTitle}>
                    Play Page
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.divLabel}>Play Page Instructions (Click to edit)</div>
                    { this.renderCustom("playInstructions") }
                  </Grid>

                  <Grid item xs={12} className={classes.sectionTitle}>
                    Donate Page
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.divLabel}>Donate Page Instructions (Click to edit)</div>
                    { this.renderCustom("donateInstructions") }
                  </Grid>

                  <Grid item xs={12} className={classes.sectionTitle}>
                    Share Page
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.divLabel}>Share Page Instructions (Click to edit)</div>
                    { this.renderCustom("shareInstructions") }
                  </Grid>
                </Grid>

              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12} >
                  <ProgressButton variant="contained" color="primary" fullWidth
                                  type="submit" processing={this.isProcessing}
                                  onClick={this.onSubmit}>
                    Save
                  </ProgressButton>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <DialogButton variant="tertiary" fullWidth onClick={this.onCancel}>
                    Cancel
                  </DialogButton>
                </Grid>
              </Grid>
            </DialogActions>
          </FormValidator>
        </Paper>
      </Grid>

    return content
  }

  renderCustom(item: string) {
    const { classes } = this.props

    const editorKey = config.get("tinymce.apiKey")

    const custom = this.values.custom as any
    const initialValue = custom[item] ? custom[item] : ""

    return (
      <div className={classes.description}>
        <Editor
          id={item}
          apiKey={editorKey}
          init={{
            plugins: 'link',
            menubar: '',
            toolbar: 'undo redo | styleselect | bold italic forecolor | link | outdent indent',
            skin: "oxide-dark",
          }}
          initialValue={initialValue}
          inline={true}
          disabled={false}
          onEditorChange={this.onEditorChange}
        />
      </div>
    )
  }

  onChange = (event: any) => {
    const name = event.target.name
    this.values[name] = event.target.value
    this.updatePreview()
  }

  onEditorChange = (content: any, editor: any) => {
    this.values.custom[editor.id] = content
  }

  @action updatePreview = () => {
    let preview
    // const values = this.values

    // TODO

    this.preview = preview
  }

  onSubmit = async () => {
    const { campaign, accountStore, notify, onSave } = this.props

    if (!this.isValid) {
      return
    }

    const values = this.values

    if (campaign) {
      Tracking.event({action: 'UpdateCampaign'})
      this.isProcessing = true

      values.custom["name"] = values.name

      const input: UpdateCampaignInput = {
        id: values.campaignId,
        videoUrl: values.videoUrl,
        custom: JSON.stringify(values.custom),
      }

      const update = await accountStore!.updateCampaign(input)
        .catch((err: Error) => {
          this.isProcessing = false
          notify!.show("error", "Unable to update fundraiser")
        })

      if (update) {
        this.isProcessing = false
        // userStore!.createActivity(ActivityType.CampaignEdit, campaignId)
        notify!.show("success", "Fundraiser updated!")
        if (onSave) {
          onSave(update)
        }
        if (!update.paymentInfo) {
          ControlTower.route(`${Routes.account}/campaign/${update.id}/payment`)
        } else {
          // Editing campaign customizations
          ControlTower.route(`${Routes.account}/campaign/${update.id}/customize`)
        }
      }
    }
  }

  onCancel = () => {
    const { campaign } = this.props
    if (campaign) {
      ControlTower.route(`${Routes.account}/campaign/${campaign.id}`)
    } else {
      // Creating new campaign
      ControlTower.route(`${Routes.accountCampaigns}`)
    }
  }

  quote = (text: string): string => {
    return text.replace(/"/g, '""')
  }

  getCustomDefault = () => {
    const temp = new Campaign({})
    return ({
          overview: temp.getCustom("overview"),
          boardsInstructions: temp.getCustom("boardsInstructions"),
          newBoardInstructions: temp.getCustom("newBoardInstructions"),
          playInstructions: temp.getCustom("playInstructions"),
          donateInstructions: temp.getCustom("donateInstructions"),
          shareInstructions: temp.getCustom("shareInstructions")
    })
  }
}

export default withTheme((withStyles(styles)(CampaignCustomSettings)))