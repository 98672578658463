import * as React from 'react'

interface IVisibleProps {
  if: boolean
  children?: any
}

class Visible extends React.Component<IVisibleProps> {

  render() {
    const condition = this.props.if // (this.props.if !== undefined) ? this.props.if : false

    if (condition) {
      return (this.props.children)
    } else {
      return null
    }
  }
}

export default Visible