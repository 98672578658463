import {observable} from "mobx";

class Alert {

  @observable open: boolean = false
  @observable title: string = ''
  @observable message: string = ''

  onClose: any

  show = (title: string, message: string, onClose?: any) => {
    this.open = true
    this.title = title
    this.message = message
    this.onClose = onClose
  }

  close = () => {
    this.open = false
    if (this.onClose) {
      this.onClose()
    }
  }
}

export default Alert