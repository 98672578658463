/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAccountInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  ownerId?: string | null,
  name: string,
  about?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  orgType?: string | null,
  webSite?: string | null,
  active?: boolean | null,
  paymentProvider?: PaymentProvider | null,
  paymentOptions?: string | null,
  stripeId?: string | null,
};

export enum PaymentProvider {
  Stripe = "Stripe",
  YoungLife = "YoungLife",
}


export type ModelAccountConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  about?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  orgType?: ModelStringInput | null,
  webSite?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  paymentProvider?: ModelPaymentProviderInput | null,
  paymentOptions?: ModelStringInput | null,
  stripeId?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelPaymentProviderInput = {
  eq?: PaymentProvider | null,
  ne?: PaymentProvider | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  ownerId?: string | null,
  name: string,
  about?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  orgType?: string | null,
  webSite?: string | null,
  active?: boolean | null,
  paymentProvider?: PaymentProvider | null,
  paymentOptions?: string | null,
  stripeId?: string | null,
  users?: ModelUserConnection | null,
  campaigns?: ModelCampaignConnection | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  active?: boolean | null,
  accountId?: string | null,
  account?: Account | null,
  firstName: string,
  lastName: string,
  email: string,
  phone?: string | null,
  role?: UserRole | null,
};

export enum UserRole {
  Admin = "Admin",
  Owner = "Owner",
  Sponsor = "Sponsor",
}


export type ModelCampaignConnection = {
  __typename: "ModelCampaignConnection",
  items:  Array<Campaign | null >,
  nextToken?: string | null,
};

export type Campaign = {
  __typename: "Campaign",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  account?: Account | null,
  title: string,
  alias?: string | null,
  description?: string | null,
  city?: string | null,
  state?: string | null,
  campaignType: CampaignType,
  goalAmount: number,
  donatedAmount: number,
  publishAt?: string | null,
  startAt: string,
  endAt: string,
  layout?: string | null,
  custom?: string | null,
  coverUrl?: string | null,
  videoUrl?: string | null,
  shareUrl?: string | null,
  contactInfo?: string | null,
  paymentInfo?: string | null,
  temp?: string | null,
  boards?: ModelBoardConnection | null,
  donations?: ModelDonationConnection | null,
};

export enum CampaignType {
  BINKO = "BINKO",
  NoGame = "NoGame",
}


export type ModelBoardConnection = {
  __typename: "ModelBoardConnection",
  items:  Array<Board | null >,
  nextToken?: string | null,
};

export type Board = {
  __typename: "Board",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  campaignId: string,
  name: string,
  alias?: string | null,
  sponsorId?: string | null,
  sponsorName?: string | null,
  sponsorEmail?: string | null,
  sponsorMatch?: boolean | null,
  sponsorMessage?: string | null,
  sponsorMatchLimit?: number | null,
  backgroundColor?: string | null,
  backgroundImage?: string | null,
  coverImage?: string | null,
  layout?: string | null,
  campaign?: Campaign | null,
  donations?: ModelDonationConnection | null,
};

export type ModelDonationConnection = {
  __typename: "ModelDonationConnection",
  items:  Array<Donation | null >,
  nextToken?: string | null,
};

export type Donation = {
  __typename: "Donation",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  campaignId: string,
  boardId: string,
  spaceId?: string | null,
  amount: number,
  frequency: DonationFrequency,
  other?: string | null,
  name: string,
  email: string,
  message?: string | null,
  isAnonymous?: boolean | null,
  isExistingDonor?: boolean | null,
  paymentStatus?: string | null,
  campaign?: Campaign | null,
  board?: Board | null,
};

export enum DonationFrequency {
  Once = "Once",
  Monthly = "Monthly",
  Increase = "Increase",
  Other = "Other",
}


export type UpdateAccountInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  ownerId?: string | null,
  name?: string | null,
  about?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  orgType?: string | null,
  webSite?: string | null,
  active?: boolean | null,
  paymentProvider?: PaymentProvider | null,
  paymentOptions?: string | null,
  stripeId?: string | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type UpdateUserInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  active?: boolean | null,
  accountId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  role?: UserRole | null,
};

export type ModelUserConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserRoleInput = {
  eq?: UserRole | null,
  ne?: UserRole | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateCampaignInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  title: string,
  alias?: string | null,
  description?: string | null,
  city?: string | null,
  state?: string | null,
  campaignType: CampaignType,
  goalAmount: number,
  donatedAmount: number,
  publishAt?: string | null,
  startAt: string,
  endAt: string,
  layout?: string | null,
  custom?: string | null,
  coverUrl?: string | null,
  videoUrl?: string | null,
  shareUrl?: string | null,
  contactInfo?: string | null,
  paymentInfo?: string | null,
  temp?: string | null,
};

export type ModelCampaignConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  description?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  campaignType?: ModelCampaignTypeInput | null,
  goalAmount?: ModelFloatInput | null,
  donatedAmount?: ModelFloatInput | null,
  publishAt?: ModelStringInput | null,
  startAt?: ModelStringInput | null,
  endAt?: ModelStringInput | null,
  layout?: ModelStringInput | null,
  custom?: ModelStringInput | null,
  coverUrl?: ModelStringInput | null,
  videoUrl?: ModelStringInput | null,
  shareUrl?: ModelStringInput | null,
  contactInfo?: ModelStringInput | null,
  paymentInfo?: ModelStringInput | null,
  temp?: ModelStringInput | null,
  and?: Array< ModelCampaignConditionInput | null > | null,
  or?: Array< ModelCampaignConditionInput | null > | null,
  not?: ModelCampaignConditionInput | null,
};

export type ModelCampaignTypeInput = {
  eq?: CampaignType | null,
  ne?: CampaignType | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateCampaignInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId?: string | null,
  title?: string | null,
  alias?: string | null,
  description?: string | null,
  city?: string | null,
  state?: string | null,
  campaignType?: CampaignType | null,
  goalAmount?: number | null,
  donatedAmount?: number | null,
  publishAt?: string | null,
  startAt?: string | null,
  endAt?: string | null,
  layout?: string | null,
  custom?: string | null,
  coverUrl?: string | null,
  videoUrl?: string | null,
  shareUrl?: string | null,
  contactInfo?: string | null,
  paymentInfo?: string | null,
  temp?: string | null,
};

export type DeleteCampaignInput = {
  id: string,
};

export type UpdateBoardInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId?: string | null,
  campaignId?: string | null,
  name?: string | null,
  alias?: string | null,
  sponsorId?: string | null,
  sponsorName?: string | null,
  sponsorEmail?: string | null,
  sponsorMatch?: boolean | null,
  sponsorMessage?: string | null,
  sponsorMatchLimit?: number | null,
  backgroundColor?: string | null,
  backgroundImage?: string | null,
  coverImage?: string | null,
  layout?: string | null,
};

export type ModelBoardConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  campaignId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  sponsorId?: ModelIDInput | null,
  sponsorName?: ModelStringInput | null,
  sponsorEmail?: ModelStringInput | null,
  sponsorMatch?: ModelBooleanInput | null,
  sponsorMessage?: ModelStringInput | null,
  sponsorMatchLimit?: ModelFloatInput | null,
  backgroundColor?: ModelStringInput | null,
  backgroundImage?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  layout?: ModelStringInput | null,
  and?: Array< ModelBoardConditionInput | null > | null,
  or?: Array< ModelBoardConditionInput | null > | null,
  not?: ModelBoardConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type DeleteBoardInput = {
  id: string,
};

export type UpdateDonationInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId?: string | null,
  campaignId?: string | null,
  boardId?: string | null,
  spaceId?: string | null,
  amount?: number | null,
  frequency?: DonationFrequency | null,
  other?: string | null,
  name?: string | null,
  email?: string | null,
  message?: string | null,
  isAnonymous?: boolean | null,
  isExistingDonor?: boolean | null,
  paymentStatus?: string | null,
};

export type ModelDonationConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  campaignId?: ModelIDInput | null,
  boardId?: ModelIDInput | null,
  spaceId?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  frequency?: ModelDonationFrequencyInput | null,
  other?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  message?: ModelStringInput | null,
  isAnonymous?: ModelBooleanInput | null,
  isExistingDonor?: ModelBooleanInput | null,
  paymentStatus?: ModelStringInput | null,
  and?: Array< ModelDonationConditionInput | null > | null,
  or?: Array< ModelDonationConditionInput | null > | null,
  not?: ModelDonationConditionInput | null,
};

export type ModelDonationFrequencyInput = {
  eq?: DonationFrequency | null,
  ne?: DonationFrequency | null,
};

export type DeleteDonationInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  active?: boolean | null,
  accountId?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  phone?: string | null,
  role?: UserRole | null,
};

export type CreateBoardInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  campaignId: string,
  name: string,
  alias?: string | null,
  sponsorId?: string | null,
  sponsorName?: string | null,
  sponsorEmail?: string | null,
  sponsorMatch?: boolean | null,
  sponsorMessage?: string | null,
  sponsorMatchLimit?: number | null,
  backgroundColor?: string | null,
  backgroundImage?: string | null,
  coverImage?: string | null,
  layout?: string | null,
};

export type CreateDonationInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  accountId: string,
  campaignId: string,
  boardId: string,
  spaceId?: string | null,
  amount: number,
  frequency: DonationFrequency,
  other?: string | null,
  name: string,
  email: string,
  message?: string | null,
  isAnonymous?: boolean | null,
  isExistingDonor?: boolean | null,
  paymentStatus?: string | null,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  about?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  orgType?: ModelStringInput | null,
  webSite?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  paymentProvider?: ModelPaymentProviderInput | null,
  paymentOptions?: ModelStringInput | null,
  stripeId?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  accountId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelCampaignFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  description?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  campaignType?: ModelCampaignTypeInput | null,
  goalAmount?: ModelFloatInput | null,
  donatedAmount?: ModelFloatInput | null,
  publishAt?: ModelStringInput | null,
  startAt?: ModelStringInput | null,
  endAt?: ModelStringInput | null,
  layout?: ModelStringInput | null,
  custom?: ModelStringInput | null,
  coverUrl?: ModelStringInput | null,
  videoUrl?: ModelStringInput | null,
  shareUrl?: ModelStringInput | null,
  contactInfo?: ModelStringInput | null,
  paymentInfo?: ModelStringInput | null,
  temp?: ModelStringInput | null,
  and?: Array< ModelCampaignFilterInput | null > | null,
  or?: Array< ModelCampaignFilterInput | null > | null,
  not?: ModelCampaignFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelBoardFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  campaignId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  sponsorId?: ModelIDInput | null,
  sponsorName?: ModelStringInput | null,
  sponsorEmail?: ModelStringInput | null,
  sponsorMatch?: ModelBooleanInput | null,
  sponsorMessage?: ModelStringInput | null,
  sponsorMatchLimit?: ModelFloatInput | null,
  backgroundColor?: ModelStringInput | null,
  backgroundImage?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  layout?: ModelStringInput | null,
  and?: Array< ModelBoardFilterInput | null > | null,
  or?: Array< ModelBoardFilterInput | null > | null,
  not?: ModelBoardFilterInput | null,
};

export type ModelDonationFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  campaignId?: ModelIDInput | null,
  boardId?: ModelIDInput | null,
  spaceId?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  frequency?: ModelDonationFrequencyInput | null,
  other?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  message?: ModelStringInput | null,
  isAnonymous?: ModelBooleanInput | null,
  isExistingDonor?: ModelBooleanInput | null,
  paymentStatus?: ModelStringInput | null,
  and?: Array< ModelDonationFilterInput | null > | null,
  or?: Array< ModelDonationFilterInput | null > | null,
  not?: ModelDonationFilterInput | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    about?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    orgType?: string | null,
    webSite?: string | null,
    active?: boolean | null,
    paymentProvider?: PaymentProvider | null,
    paymentOptions?: string | null,
    stripeId?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        accountId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelCampaignConnection",
      items:  Array< {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    about?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    orgType?: string | null,
    webSite?: string | null,
    active?: boolean | null,
    paymentProvider?: PaymentProvider | null,
    paymentOptions?: string | null,
    stripeId?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        accountId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelCampaignConnection",
      items:  Array< {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    about?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    orgType?: string | null,
    webSite?: string | null,
    active?: boolean | null,
    paymentProvider?: PaymentProvider | null,
    paymentOptions?: string | null,
    stripeId?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        accountId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelCampaignConnection",
      items:  Array< {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
  } | null,
};

export type CreateCampaignMutationVariables = {
  input: CreateCampaignInput,
  condition?: ModelCampaignConditionInput | null,
};

export type CreateCampaignMutation = {
  createCampaign?:  {
    __typename: "Campaign",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    title: string,
    alias?: string | null,
    description?: string | null,
    city?: string | null,
    state?: string | null,
    campaignType: CampaignType,
    goalAmount: number,
    donatedAmount: number,
    publishAt?: string | null,
    startAt: string,
    endAt: string,
    layout?: string | null,
    custom?: string | null,
    coverUrl?: string | null,
    videoUrl?: string | null,
    shareUrl?: string | null,
    contactInfo?: string | null,
    paymentInfo?: string | null,
    temp?: string | null,
    boards?:  {
      __typename: "ModelBoardConnection",
      items:  Array< {
        __typename: "Board",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        name: string,
        alias?: string | null,
        sponsorId?: string | null,
        sponsorName?: string | null,
        sponsorEmail?: string | null,
        sponsorMatch?: boolean | null,
        sponsorMessage?: string | null,
        sponsorMatchLimit?: number | null,
        backgroundColor?: string | null,
        backgroundImage?: string | null,
        coverImage?: string | null,
        layout?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCampaignMutationVariables = {
  input: UpdateCampaignInput,
  condition?: ModelCampaignConditionInput | null,
};

export type UpdateCampaignMutation = {
  updateCampaign?:  {
    __typename: "Campaign",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    title: string,
    alias?: string | null,
    description?: string | null,
    city?: string | null,
    state?: string | null,
    campaignType: CampaignType,
    goalAmount: number,
    donatedAmount: number,
    publishAt?: string | null,
    startAt: string,
    endAt: string,
    layout?: string | null,
    custom?: string | null,
    coverUrl?: string | null,
    videoUrl?: string | null,
    shareUrl?: string | null,
    contactInfo?: string | null,
    paymentInfo?: string | null,
    temp?: string | null,
    boards?:  {
      __typename: "ModelBoardConnection",
      items:  Array< {
        __typename: "Board",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        name: string,
        alias?: string | null,
        sponsorId?: string | null,
        sponsorName?: string | null,
        sponsorEmail?: string | null,
        sponsorMatch?: boolean | null,
        sponsorMessage?: string | null,
        sponsorMatchLimit?: number | null,
        backgroundColor?: string | null,
        backgroundImage?: string | null,
        coverImage?: string | null,
        layout?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCampaignMutationVariables = {
  input: DeleteCampaignInput,
  condition?: ModelCampaignConditionInput | null,
};

export type DeleteCampaignMutation = {
  deleteCampaign?:  {
    __typename: "Campaign",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    title: string,
    alias?: string | null,
    description?: string | null,
    city?: string | null,
    state?: string | null,
    campaignType: CampaignType,
    goalAmount: number,
    donatedAmount: number,
    publishAt?: string | null,
    startAt: string,
    endAt: string,
    layout?: string | null,
    custom?: string | null,
    coverUrl?: string | null,
    videoUrl?: string | null,
    shareUrl?: string | null,
    contactInfo?: string | null,
    paymentInfo?: string | null,
    temp?: string | null,
    boards?:  {
      __typename: "ModelBoardConnection",
      items:  Array< {
        __typename: "Board",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        name: string,
        alias?: string | null,
        sponsorId?: string | null,
        sponsorName?: string | null,
        sponsorEmail?: string | null,
        sponsorMatch?: boolean | null,
        sponsorMessage?: string | null,
        sponsorMatchLimit?: number | null,
        backgroundColor?: string | null,
        backgroundImage?: string | null,
        coverImage?: string | null,
        layout?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateBoardMutationVariables = {
  input: UpdateBoardInput,
  condition?: ModelBoardConditionInput | null,
};

export type UpdateBoardMutation = {
  updateBoard?:  {
    __typename: "Board",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    name: string,
    alias?: string | null,
    sponsorId?: string | null,
    sponsorName?: string | null,
    sponsorEmail?: string | null,
    sponsorMatch?: boolean | null,
    sponsorMessage?: string | null,
    sponsorMatchLimit?: number | null,
    backgroundColor?: string | null,
    backgroundImage?: string | null,
    coverImage?: string | null,
    layout?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteBoardMutationVariables = {
  input: DeleteBoardInput,
  condition?: ModelBoardConditionInput | null,
};

export type DeleteBoardMutation = {
  deleteBoard?:  {
    __typename: "Board",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    name: string,
    alias?: string | null,
    sponsorId?: string | null,
    sponsorName?: string | null,
    sponsorEmail?: string | null,
    sponsorMatch?: boolean | null,
    sponsorMessage?: string | null,
    sponsorMatchLimit?: number | null,
    backgroundColor?: string | null,
    backgroundImage?: string | null,
    coverImage?: string | null,
    layout?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateDonationMutationVariables = {
  input: UpdateDonationInput,
  condition?: ModelDonationConditionInput | null,
};

export type UpdateDonationMutation = {
  updateDonation?:  {
    __typename: "Donation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    boardId: string,
    spaceId?: string | null,
    amount: number,
    frequency: DonationFrequency,
    other?: string | null,
    name: string,
    email: string,
    message?: string | null,
    isAnonymous?: boolean | null,
    isExistingDonor?: boolean | null,
    paymentStatus?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    board?:  {
      __typename: "Board",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      campaignId: string,
      name: string,
      alias?: string | null,
      sponsorId?: string | null,
      sponsorName?: string | null,
      sponsorEmail?: string | null,
      sponsorMatch?: boolean | null,
      sponsorMessage?: string | null,
      sponsorMatchLimit?: number | null,
      backgroundColor?: string | null,
      backgroundImage?: string | null,
      coverImage?: string | null,
      layout?: string | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteDonationMutationVariables = {
  input: DeleteDonationInput,
  condition?: ModelDonationConditionInput | null,
};

export type DeleteDonationMutation = {
  deleteDonation?:  {
    __typename: "Donation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    boardId: string,
    spaceId?: string | null,
    amount: number,
    frequency: DonationFrequency,
    other?: string | null,
    name: string,
    email: string,
    message?: string | null,
    isAnonymous?: boolean | null,
    isExistingDonor?: boolean | null,
    paymentStatus?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    board?:  {
      __typename: "Board",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      campaignId: string,
      name: string,
      alias?: string | null,
      sponsorId?: string | null,
      sponsorName?: string | null,
      sponsorEmail?: string | null,
      sponsorMatch?: boolean | null,
      sponsorMessage?: string | null,
      sponsorMatchLimit?: number | null,
      backgroundColor?: string | null,
      backgroundImage?: string | null,
      coverImage?: string | null,
      layout?: string | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
  } | null,
};

export type CreateBoardMutationVariables = {
  input: CreateBoardInput,
  condition?: ModelBoardConditionInput | null,
};

export type CreateBoardMutation = {
  createBoard?:  {
    __typename: "Board",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    name: string,
    alias?: string | null,
    sponsorId?: string | null,
    sponsorName?: string | null,
    sponsorEmail?: string | null,
    sponsorMatch?: boolean | null,
    sponsorMessage?: string | null,
    sponsorMatchLimit?: number | null,
    backgroundColor?: string | null,
    backgroundImage?: string | null,
    coverImage?: string | null,
    layout?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateDonationMutationVariables = {
  input: CreateDonationInput,
  condition?: ModelDonationConditionInput | null,
};

export type CreateDonationMutation = {
  createDonation?:  {
    __typename: "Donation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    boardId: string,
    spaceId?: string | null,
    amount: number,
    frequency: DonationFrequency,
    other?: string | null,
    name: string,
    email: string,
    message?: string | null,
    isAnonymous?: boolean | null,
    isExistingDonor?: boolean | null,
    paymentStatus?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    board?:  {
      __typename: "Board",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      campaignId: string,
      name: string,
      alias?: string | null,
      sponsorId?: string | null,
      sponsorName?: string | null,
      sponsorEmail?: string | null,
      sponsorMatch?: boolean | null,
      sponsorMessage?: string | null,
      sponsorMatchLimit?: number | null,
      backgroundColor?: string | null,
      backgroundImage?: string | null,
      coverImage?: string | null,
      layout?: string | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    about?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    orgType?: string | null,
    webSite?: string | null,
    active?: boolean | null,
    paymentProvider?: PaymentProvider | null,
    paymentOptions?: string | null,
    stripeId?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        accountId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelCampaignConnection",
      items:  Array< {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      active?: boolean | null,
      accountId?: string | null,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      phone?: string | null,
      role?: UserRole | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCampaignQueryVariables = {
  id: string,
};

export type GetCampaignQuery = {
  getCampaign?:  {
    __typename: "Campaign",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    title: string,
    alias?: string | null,
    description?: string | null,
    city?: string | null,
    state?: string | null,
    campaignType: CampaignType,
    goalAmount: number,
    donatedAmount: number,
    publishAt?: string | null,
    startAt: string,
    endAt: string,
    layout?: string | null,
    custom?: string | null,
    coverUrl?: string | null,
    videoUrl?: string | null,
    shareUrl?: string | null,
    contactInfo?: string | null,
    paymentInfo?: string | null,
    temp?: string | null,
    boards?:  {
      __typename: "ModelBoardConnection",
      items:  Array< {
        __typename: "Board",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        name: string,
        alias?: string | null,
        sponsorId?: string | null,
        sponsorName?: string | null,
        sponsorEmail?: string | null,
        sponsorMatch?: boolean | null,
        sponsorMessage?: string | null,
        sponsorMatchLimit?: number | null,
        backgroundColor?: string | null,
        backgroundImage?: string | null,
        coverImage?: string | null,
        layout?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCampaignsQueryVariables = {
  filter?: ModelCampaignFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignsQuery = {
  listCampaigns?:  {
    __typename: "ModelCampaignConnection",
    items:  Array< {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCampaignsByAliasQueryVariables = {
  alias?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignsByAliasQuery = {
  listCampaignsByAlias?:  {
    __typename: "ModelCampaignConnection",
    items:  Array< {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBoardQueryVariables = {
  id: string,
};

export type GetBoardQuery = {
  getBoard?:  {
    __typename: "Board",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    name: string,
    alias?: string | null,
    sponsorId?: string | null,
    sponsorName?: string | null,
    sponsorEmail?: string | null,
    sponsorMatch?: boolean | null,
    sponsorMessage?: string | null,
    sponsorMatchLimit?: number | null,
    backgroundColor?: string | null,
    backgroundImage?: string | null,
    coverImage?: string | null,
    layout?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListBoardsQueryVariables = {
  filter?: ModelBoardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBoardsQuery = {
  listBoards?:  {
    __typename: "ModelBoardConnection",
    items:  Array< {
      __typename: "Board",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      campaignId: string,
      name: string,
      alias?: string | null,
      sponsorId?: string | null,
      sponsorName?: string | null,
      sponsorEmail?: string | null,
      sponsorMatch?: boolean | null,
      sponsorMessage?: string | null,
      sponsorMatchLimit?: number | null,
      backgroundColor?: string | null,
      backgroundImage?: string | null,
      coverImage?: string | null,
      layout?: string | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDonationQueryVariables = {
  id: string,
};

export type GetDonationQuery = {
  getDonation?:  {
    __typename: "Donation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    boardId: string,
    spaceId?: string | null,
    amount: number,
    frequency: DonationFrequency,
    other?: string | null,
    name: string,
    email: string,
    message?: string | null,
    isAnonymous?: boolean | null,
    isExistingDonor?: boolean | null,
    paymentStatus?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    board?:  {
      __typename: "Board",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      campaignId: string,
      name: string,
      alias?: string | null,
      sponsorId?: string | null,
      sponsorName?: string | null,
      sponsorEmail?: string | null,
      sponsorMatch?: boolean | null,
      sponsorMessage?: string | null,
      sponsorMatchLimit?: number | null,
      backgroundColor?: string | null,
      backgroundImage?: string | null,
      coverImage?: string | null,
      layout?: string | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListDonationsQueryVariables = {
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDonationsQuery = {
  listDonations?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      campaignId: string,
      boardId: string,
      spaceId?: string | null,
      amount: number,
      frequency: DonationFrequency,
      other?: string | null,
      name: string,
      email: string,
      message?: string | null,
      isAnonymous?: boolean | null,
      isExistingDonor?: boolean | null,
      paymentStatus?: string | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null,
      board?:  {
        __typename: "Board",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        name: string,
        alias?: string | null,
        sponsorId?: string | null,
        sponsorName?: string | null,
        sponsorEmail?: string | null,
        sponsorMatch?: boolean | null,
        sponsorMessage?: string | null,
        sponsorMatchLimit?: number | null,
        backgroundColor?: string | null,
        backgroundImage?: string | null,
        coverImage?: string | null,
        layout?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  ownerId?: string | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    about?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    orgType?: string | null,
    webSite?: string | null,
    active?: boolean | null,
    paymentProvider?: PaymentProvider | null,
    paymentOptions?: string | null,
    stripeId?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        accountId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelCampaignConnection",
      items:  Array< {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  ownerId?: string | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    about?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    orgType?: string | null,
    webSite?: string | null,
    active?: boolean | null,
    paymentProvider?: PaymentProvider | null,
    paymentOptions?: string | null,
    stripeId?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        accountId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelCampaignConnection",
      items:  Array< {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  ownerId?: string | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    ownerId?: string | null,
    name: string,
    about?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    postalCode?: string | null,
    orgType?: string | null,
    webSite?: string | null,
    active?: boolean | null,
    paymentProvider?: PaymentProvider | null,
    paymentOptions?: string | null,
    stripeId?: string | null,
    users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        active?: boolean | null,
        accountId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        phone?: string | null,
        role?: UserRole | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    campaigns?:  {
      __typename: "ModelCampaignConnection",
      items:  Array< {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    accountId?: string | null,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string | null,
    role?: UserRole | null,
  } | null,
};

export type OnCreateCampaignSubscription = {
  onCreateCampaign?:  {
    __typename: "Campaign",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    title: string,
    alias?: string | null,
    description?: string | null,
    city?: string | null,
    state?: string | null,
    campaignType: CampaignType,
    goalAmount: number,
    donatedAmount: number,
    publishAt?: string | null,
    startAt: string,
    endAt: string,
    layout?: string | null,
    custom?: string | null,
    coverUrl?: string | null,
    videoUrl?: string | null,
    shareUrl?: string | null,
    contactInfo?: string | null,
    paymentInfo?: string | null,
    temp?: string | null,
    boards?:  {
      __typename: "ModelBoardConnection",
      items:  Array< {
        __typename: "Board",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        name: string,
        alias?: string | null,
        sponsorId?: string | null,
        sponsorName?: string | null,
        sponsorEmail?: string | null,
        sponsorMatch?: boolean | null,
        sponsorMessage?: string | null,
        sponsorMatchLimit?: number | null,
        backgroundColor?: string | null,
        backgroundImage?: string | null,
        coverImage?: string | null,
        layout?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateCampaignSubscription = {
  onUpdateCampaign?:  {
    __typename: "Campaign",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    title: string,
    alias?: string | null,
    description?: string | null,
    city?: string | null,
    state?: string | null,
    campaignType: CampaignType,
    goalAmount: number,
    donatedAmount: number,
    publishAt?: string | null,
    startAt: string,
    endAt: string,
    layout?: string | null,
    custom?: string | null,
    coverUrl?: string | null,
    videoUrl?: string | null,
    shareUrl?: string | null,
    contactInfo?: string | null,
    paymentInfo?: string | null,
    temp?: string | null,
    boards?:  {
      __typename: "ModelBoardConnection",
      items:  Array< {
        __typename: "Board",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        name: string,
        alias?: string | null,
        sponsorId?: string | null,
        sponsorName?: string | null,
        sponsorEmail?: string | null,
        sponsorMatch?: boolean | null,
        sponsorMessage?: string | null,
        sponsorMatchLimit?: number | null,
        backgroundColor?: string | null,
        backgroundImage?: string | null,
        coverImage?: string | null,
        layout?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteCampaignSubscription = {
  onDeleteCampaign?:  {
    __typename: "Campaign",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    account?:  {
      __typename: "Account",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      ownerId?: string | null,
      name: string,
      about?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      postalCode?: string | null,
      orgType?: string | null,
      webSite?: string | null,
      active?: boolean | null,
      paymentProvider?: PaymentProvider | null,
      paymentOptions?: string | null,
      stripeId?: string | null,
      users?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      campaigns?:  {
        __typename: "ModelCampaignConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    title: string,
    alias?: string | null,
    description?: string | null,
    city?: string | null,
    state?: string | null,
    campaignType: CampaignType,
    goalAmount: number,
    donatedAmount: number,
    publishAt?: string | null,
    startAt: string,
    endAt: string,
    layout?: string | null,
    custom?: string | null,
    coverUrl?: string | null,
    videoUrl?: string | null,
    shareUrl?: string | null,
    contactInfo?: string | null,
    paymentInfo?: string | null,
    temp?: string | null,
    boards?:  {
      __typename: "ModelBoardConnection",
      items:  Array< {
        __typename: "Board",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        name: string,
        alias?: string | null,
        sponsorId?: string | null,
        sponsorName?: string | null,
        sponsorEmail?: string | null,
        sponsorMatch?: boolean | null,
        sponsorMessage?: string | null,
        sponsorMatchLimit?: number | null,
        backgroundColor?: string | null,
        backgroundImage?: string | null,
        coverImage?: string | null,
        layout?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateBoardSubscriptionVariables = {
  ownerId?: string | null,
};

export type OnCreateBoardSubscription = {
  onCreateBoard?:  {
    __typename: "Board",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    name: string,
    alias?: string | null,
    sponsorId?: string | null,
    sponsorName?: string | null,
    sponsorEmail?: string | null,
    sponsorMatch?: boolean | null,
    sponsorMessage?: string | null,
    sponsorMatchLimit?: number | null,
    backgroundColor?: string | null,
    backgroundImage?: string | null,
    coverImage?: string | null,
    layout?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateBoardSubscriptionVariables = {
  ownerId?: string | null,
};

export type OnUpdateBoardSubscription = {
  onUpdateBoard?:  {
    __typename: "Board",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    name: string,
    alias?: string | null,
    sponsorId?: string | null,
    sponsorName?: string | null,
    sponsorEmail?: string | null,
    sponsorMatch?: boolean | null,
    sponsorMessage?: string | null,
    sponsorMatchLimit?: number | null,
    backgroundColor?: string | null,
    backgroundImage?: string | null,
    coverImage?: string | null,
    layout?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteBoardSubscriptionVariables = {
  ownerId?: string | null,
};

export type OnDeleteBoardSubscription = {
  onDeleteBoard?:  {
    __typename: "Board",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    name: string,
    alias?: string | null,
    sponsorId?: string | null,
    sponsorName?: string | null,
    sponsorEmail?: string | null,
    sponsorMatch?: boolean | null,
    sponsorMessage?: string | null,
    sponsorMatchLimit?: number | null,
    backgroundColor?: string | null,
    backgroundImage?: string | null,
    coverImage?: string | null,
    layout?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        campaignId: string,
        boardId: string,
        spaceId?: string | null,
        amount: number,
        frequency: DonationFrequency,
        other?: string | null,
        name: string,
        email: string,
        message?: string | null,
        isAnonymous?: boolean | null,
        isExistingDonor?: boolean | null,
        paymentStatus?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateDonationSubscription = {
  onCreateDonation?:  {
    __typename: "Donation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    boardId: string,
    spaceId?: string | null,
    amount: number,
    frequency: DonationFrequency,
    other?: string | null,
    name: string,
    email: string,
    message?: string | null,
    isAnonymous?: boolean | null,
    isExistingDonor?: boolean | null,
    paymentStatus?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    board?:  {
      __typename: "Board",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      campaignId: string,
      name: string,
      alias?: string | null,
      sponsorId?: string | null,
      sponsorName?: string | null,
      sponsorEmail?: string | null,
      sponsorMatch?: boolean | null,
      sponsorMessage?: string | null,
      sponsorMatchLimit?: number | null,
      backgroundColor?: string | null,
      backgroundImage?: string | null,
      coverImage?: string | null,
      layout?: string | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateDonationSubscription = {
  onUpdateDonation?:  {
    __typename: "Donation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    boardId: string,
    spaceId?: string | null,
    amount: number,
    frequency: DonationFrequency,
    other?: string | null,
    name: string,
    email: string,
    message?: string | null,
    isAnonymous?: boolean | null,
    isExistingDonor?: boolean | null,
    paymentStatus?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    board?:  {
      __typename: "Board",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      campaignId: string,
      name: string,
      alias?: string | null,
      sponsorId?: string | null,
      sponsorName?: string | null,
      sponsorEmail?: string | null,
      sponsorMatch?: boolean | null,
      sponsorMessage?: string | null,
      sponsorMatchLimit?: number | null,
      backgroundColor?: string | null,
      backgroundImage?: string | null,
      coverImage?: string | null,
      layout?: string | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteDonationSubscription = {
  onDeleteDonation?:  {
    __typename: "Donation",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    accountId: string,
    campaignId: string,
    boardId: string,
    spaceId?: string | null,
    amount: number,
    frequency: DonationFrequency,
    other?: string | null,
    name: string,
    email: string,
    message?: string | null,
    isAnonymous?: boolean | null,
    isExistingDonor?: boolean | null,
    paymentStatus?: string | null,
    campaign?:  {
      __typename: "Campaign",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      account?:  {
        __typename: "Account",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        ownerId?: string | null,
        name: string,
        about?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        orgType?: string | null,
        webSite?: string | null,
        active?: boolean | null,
        paymentProvider?: PaymentProvider | null,
        paymentOptions?: string | null,
        stripeId?: string | null,
      } | null,
      title: string,
      alias?: string | null,
      description?: string | null,
      city?: string | null,
      state?: string | null,
      campaignType: CampaignType,
      goalAmount: number,
      donatedAmount: number,
      publishAt?: string | null,
      startAt: string,
      endAt: string,
      layout?: string | null,
      custom?: string | null,
      coverUrl?: string | null,
      videoUrl?: string | null,
      shareUrl?: string | null,
      contactInfo?: string | null,
      paymentInfo?: string | null,
      temp?: string | null,
      boards?:  {
        __typename: "ModelBoardConnection",
        nextToken?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    board?:  {
      __typename: "Board",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      accountId: string,
      campaignId: string,
      name: string,
      alias?: string | null,
      sponsorId?: string | null,
      sponsorName?: string | null,
      sponsorEmail?: string | null,
      sponsorMatch?: boolean | null,
      sponsorMessage?: string | null,
      sponsorMatchLimit?: number | null,
      backgroundColor?: string | null,
      backgroundImage?: string | null,
      coverImage?: string | null,
      layout?: string | null,
      campaign?:  {
        __typename: "Campaign",
        id: string,
        createdAt?: string | null,
        updatedAt?: string | null,
        accountId: string,
        title: string,
        alias?: string | null,
        description?: string | null,
        city?: string | null,
        state?: string | null,
        campaignType: CampaignType,
        goalAmount: number,
        donatedAmount: number,
        publishAt?: string | null,
        startAt: string,
        endAt: string,
        layout?: string | null,
        custom?: string | null,
        coverUrl?: string | null,
        videoUrl?: string | null,
        shareUrl?: string | null,
        contactInfo?: string | null,
        paymentInfo?: string | null,
        temp?: string | null,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};
