import Account from "./Account";
import {CampaignType, DonationFrequency} from "../API";
import {Board} from "./Board";
import {Donation} from "./Donation";
import {isUUID} from "../stores/StoreUtilities";

export class Campaign {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  account?: Account
  title: string
  alias: string
  description: string
  city: string
  state: string
  campaignType: CampaignType
  goalAmount: number
  donatedAmount: number
  publishAt: string
  startAt: string
  endAt: string
  layout: any
  custom: any
  coverUrl: string
  videoUrl: string
  shareUrl: string
  contactInfo: string
  paymentInfo: any

  boards: Board[]
  donations: Donation[]
  matchedAmount: number
  totalAmount: number

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.account = data.account ? new Account(data.account) : undefined
    this.title = data.title
    this.alias = data.alias
    this.description = data.description
    this.city = data.city
    this.state = data.state
    this.campaignType = data.campaignType
    this.goalAmount = data.goalAmount
    this.donatedAmount = data.donatedAmount
    this.publishAt = data.publishAt
    this.startAt = data.startAt
    this.endAt = data.endAt
    this.layout = this.loadLayout(data.layout)
    this.custom = data.custom ? JSON.parse(data.custom) : undefined
    this.coverUrl = data.coverUrl
    this.videoUrl = data.videoUrl
    this.shareUrl = data.shareUrl
    this.contactInfo = data.contactInfo
    this.paymentInfo = data.paymentInfo ? JSON.parse(data.paymentInfo) : undefined
    this.boards = []
    if (data.boards && data.boards.items) {
      this.loadBoards(data.boards.items)
    }
    this.donatedAmount = 0
    this.matchedAmount = 0
    this.totalAmount = 0
    this.donations = []
    if (data.donations && data.donations.items) {
      this.loadDonations(data.donations.items)
    }
  }

  getBoard(boardId: string) {
    if (isUUID(boardId)) {
      return this.boards.find((b: Board) => b.id === boardId)
    } else {
      const alias = boardId.toLowerCase()
      return this.boards.find((b: Board) => b.alias === alias)
    }
  }

  getCustom(name: string) {
    let value = ""
    if (this.custom) {
      value = this.custom[name]
    }
    if (!value) {
      switch (name) {
        case "name":
          value = "BINKO"
          break
        case "overview":
          value = `<p>Provide a detailed overview of your fundraiser and goal.</p>
                     <p><em>Edit the rest of this to fit your needs.</em></p>
                      <h3>Why Now?</h3>
                      <p>Describe why you need donor support at this time.</p>
                      <h3>How You Can Help</h3>
                      <p>Describe the ways people can help by donating, sponsoring a board, sharing with their friends, etc.</p>
                      <h3>Prizes</h3>
                      <p>Describe any prizes you plan to award and how they will be awarded.</p>
                      <h3>More Information</h3>
                      <p>Provide contact info and your website URL for more information.</p>`
          break
        case "boardsInstructions":
          value = `<p>Choose your group's board, another board or create a new board to share with your friends!</p>`
          break
        case "newBoardInstructions":
          value = `<p>Create/Edit a board for an individual sponsor, group or team to promote.</p>`
          break
        case "playInstructions":
          value = `<p>Please select a square below for the amount you want to donate or a $__ square to enter a custom amount. Help complete a board by filling all the squares.</p>
                               <p><b>We urge you to consider a sustainable monthly donation,</b> but we welcome Once gifts too!</p>`
          break
        case "donateInstructions":
          value = `<p>Thank you for your decision to donate!</p>`
          break
        case "shareInstructions":
          value = `<p>Please help us reach our goal by inviting others to play!<br/> Don't be shy! People love a good cause...</p>`
          break
      }
    }
    return value
  }

  loadLayout(layout: any) {
    if (layout) {
      let json = JSON.parse(layout)
      // Fix up freq "One Time" -> "Once
      const rows = json["rows"]
      if (rows) {
        rows.forEach((row: any) => {
          const cols = row["cols"]
          if (cols) {
            cols.forEach((col: any) => {
              if (col["freq"] === "One Time") {
                col["freq"] = "Once"
              }
            })
          }
        })
      }
      return json
    } else {
      return undefined
    }
  }

  loadBoards(items: any) {
    this.boards = items.map((item: any) => {
      const board = new Board(item)
      board.campaign = this
      return board
    })
  }

  loadDonations(items: any) {
    this.donations = []
    let donated = 0
    // Apply donations to boards and total by board
    items.forEach((item: any) => {
      const donation = new Donation(item)
      if (!donation.paymentStatus || donation.paymentStatus !== "unpaid") {
        // Find board
        const board = this.boards.find((b: Board) => b.id === donation.boardId)
        if (board) {
          board.donations.push(donation)
          if (donation.frequency === DonationFrequency.Monthly || donation.frequency === DonationFrequency.Increase) {
            donated = donation.amount * 12
          } else {
            donated = donation.amount
          }
          board.donatedAmount += donated
          board.matchableAmount += donation.amount // Only count first month donation for matching
          if (board.sponsorMatch) {
            if (board.matchableAmount <= board.sponsorMatchLimit) {
              board.matchedAmount = board.matchableAmount
            } else {
              board.matchedAmount = board.sponsorMatchLimit
            }
          }
        }
        this.donations.push(donation)
      }
    })
    // Total boards
    donated = 0
    let matched = 0
    let total = 0
    this.boards.forEach((board: Board) => {
      donated += board.donatedAmount
      matched += board.matchedAmount
      board.totalAmount = board.donatedAmount + board.matchedAmount
      total += board.totalAmount
    })
    this.donatedAmount = donated
    this.matchedAmount = matched
    this.totalAmount = total
  }

  getPaymentSource = () => {
    return (this.paymentInfo && this.paymentInfo!.paymentSource) ? this.paymentInfo!.paymentSource : undefined
  }

  getDefaultLayout = () => {
    const paymentSource = this.getPaymentSource()

    if (paymentSource === "YoungLife") {
      return (
        {
          "rows": [
            {
              "cols": [
                { "amount": 25, "freq": "Monthly"},
                { "amount": 50, "freq": "Monthly"},
                { "amount": 75, "freq": "Monthly"},
                { "amount": 100, "freq": "Monthly"},
                { "amount": 10, "freq": "Monthly"}
              ]
            },
            {
              "cols": [
                { "amount": 25, "freq": "Monthly"},
                { "amount": 50, "freq": "Monthly"},
                { "amount": 75, "freq": "Monthly"},
                { "amount": 100, "freq": "Monthly"},
                { "amount": 10, "freq": "Monthly"}
              ]
            },
            {
              "cols": [
                { "amount": 50, "freq": "Monthly"},
                { "amount": 75, "freq": "Monthly"},
                { "amount": 100, "freq": "Monthly"},
                { "amount": 10, "freq": "Monthly"},
                { "amount": 25, "freq": "Monthly"}
              ]
            },
            {
              "cols": [
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"}
              ]
            },
            {
              "cols": [
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"}
              ]
            }
          ]
        }
      )
    } else {
      return (
        {
          "rows": [
            {
              "cols": [
                { "amount": 25, "freq": "Once"},
                { "amount": 50, "freq": "Once"},
                { "amount": 75, "freq": "Once"},
                { "amount": 100, "freq": "Once"},
                { "amount": 10, "freq": "Once"}
              ]
            },
            {
              "cols": [
                { "amount": 25, "freq": "Once"},
                { "amount": 50, "freq": "Once"},
                { "amount": 75, "freq": "Once"},
                { "amount": 100, "freq": "Once"},
                { "amount": 10, "freq": "Once"}
              ]
            },
            {
              "cols": [
                { "amount": 50, "freq": "Once"},
                { "amount": 75, "freq": "Once"},
                { "amount": 100, "freq": "Once"},
                { "amount": 10, "freq": "Once"},
                { "amount": 25, "freq": "Once"}
              ]
            },
            {
              "cols": [
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"}
              ]
            },
            {
              "cols": [
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"},
                { "amount": 0, "freq": "Once"}
              ]
            }
          ]
        }
      )
    }

  }

}

export default Campaign