import * as React from 'react'
import Page from '../../components/page/Page'
import {
  Checkbox,
  createStyles,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import TitleBar from "../../components/TitleBar";
import {inject, observer} from "mobx-react";
import {action, makeObservable, observable, when} from "mobx";
import Campaign from "../../model/Campaign";
import Progress from "../../components/Progress";
import UserStore from "../../stores/UserStore";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Tracking from "../../components/Tracking";
import {CreateBoardInput, CreateUserInput, DonationFrequency, UpdateBoardInput, UserRole} from "../../API";
import ControlTower, {Routes} from "../../components/ControlTower";
import Notify from "../../components/notify/Notify";
import DialogButton from "../../components/form/DialogButton";
import ProgressButton from "../../components/form/ProgressButton";
import CampaignStore from "../../stores/CampaignStore";
import {Board} from "../../model/Board";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import {Storage} from 'aws-amplify';
import {createUUID} from "../../stores/StoreUtilities";
import BINKOBoard from "./BINKOBoard";
import AccountStore from "../../stores/AccountStore";
import parseHTML from "html-react-parser";
import config from "react-global-configuration";
import ColorPicker from "../../components/form/ColorPicker";
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import IconButton from "@material-ui/core/IconButton";
import BoardCellEditDialog from "./BoardSpaceEditDialog";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  content: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: 4
    }
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  fieldGroup: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectField: {
    width: "100%",
  },
  fileInput: {
    width: 340
  },
  boardItem: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "center",
    justifyContent: "center",
    alignItems: "flex-start",
    alignContent: "flex-start",
    width: "100%",
    // maxHeight: 480,
    padding: theme.spacing(1.5),
    // margin: "0 8px",
    // paddingTop: 0,
  },
  board: {
    transform: "scale(0.95, 0.95)",
    transformOrigin: "50% 0%",
    textAlign: "center",
    padding: "0 16px"
  },
  matchLimit: {
    maxWidth: 100
  },
  coverImage: {
    height: 72
  },
  deleteIcon: {
    fontSize: 24,
  },
  deleteBtn: {
    color: theme.palette.text.secondary,
    display: 'inline-block',
    position: "relative",
    top: -6,
    cursor: 'pointer',
  }
})

interface IBoardEditPageProps {
  campaignId?: string
  boardId?: string
  userStore?: UserStore
  accountStore?: AccountStore
  campaignStore?: CampaignStore
  s3UrlCacheStore?: S3UrlCacheStore
  progress?: Progress
  notify?: Notify
}

@inject("userStore", "accountStore", "campaignStore", "s3UrlCacheStore", "progress", "notify")
@observer
class BoardEditPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IBoardEditPageProps & WithTheme> {

  @observable isLoading = true
  @observable campaign?: Campaign
  @observable board?: Board
  @observable values = {
    boardId: "",
    name: "",
    alias: "",
    sponsorId: "",
    sponsorName: "",
    sponsorEmail: "",
    sponsorMatch: false,
    sponsorMatchLimit: 0,
    backgroundColor: "",
    backgroundFile: undefined,
    backgroundImage: "",
    coverImage: "",
    coverFile: undefined,
    layout: undefined,
    saveLayout: false
  }
  @observable isValid: boolean = true
  @observable isProcessing = false
  @observable progressValue: number = 0
  @observable progressText: string = ""
  @observable coverImageUrl?: string
  @observable backgroundImageUrl?: string
  @observable previewBoard?: Board
  @observable shareUrl: string = config.get("shareUrl")
  @observable boardCellEditDialog: any

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    const { campaignId, boardId, userStore, campaignStore, s3UrlCacheStore, progress} = this.props
    this.isLoading = true
    progress!.show("BoardEditPage")
    when(
      () => !userStore!.isLoading && !campaignStore!.isLoading,
      async () => {
        if (campaignId) {
          const campaign = await campaignStore!.getCampaign(campaignId)

          if (campaign) {
            this.campaign = campaign
          }
          // TODO: Handle error if campaign not loaded
        }

        if (boardId) {
          const board = await campaignStore!.getBoard(boardId!)
          if (board) {
            this.values = {
              boardId: board.id,
              name: board.name,
              alias: board.alias,
              sponsorId: board.sponsorId,
              sponsorName: board.sponsorName,
              sponsorEmail: board.sponsorEmail,
              sponsorMatch: board.sponsorMatch,
              sponsorMatchLimit: board.sponsorMatchLimit,
              backgroundColor: board.backgroundColor,
              backgroundImage: board.backgroundImage,
              backgroundFile: undefined,
              coverImage: board.coverImage,
              coverFile: undefined,
              layout: this.getLayout(this.campaign!, board),
              saveLayout: false
            }

            if (!board.backgroundColor) {
              if (this.campaign && this.campaign.custom && this.campaign.custom.backgroundColor) {
                this.values.backgroundColor = this.campaign.custom.backgroundColor
              }
            }

            this.board = board

            if (board.coverImage) {
              const url = await s3UrlCacheStore!.get(board.coverImage)
                .catch((err: Error) => {
                  console.log("Error loading board cover image")
                })
              if (url) {
                this.coverImageUrl = url
              }
            }

            if (board.backgroundImage) {
              const url = await s3UrlCacheStore!.get(board.backgroundImage)
                .catch((err: Error) => {
                  console.log("Error loading board background image")
                })
              if (url) {
                this.backgroundImageUrl = url
              }
            }
          }
        } else {
          this.values.boardId = createUUID()
          this.values.layout = this.getLayout(this.campaign!)
          this.values.alias = `b${this.campaign!.boards.length + 1}`
          if (this.campaign && this.campaign.custom && this.campaign.custom.backgroundColor) {
            this.values.backgroundColor = this.campaign.custom.backgroundColor
          }
        }
        this.updatePreview()
        this.isLoading = false
        progress!.hide("BoardEditPage")
      }
    )
  }

  render() {
    const { classes, boardId, userStore } = this.props

    const isOwner = userStore!.isOwner
    const title = boardId ? "Edit Board" : "New Board"

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container className={classes.root} direction="column">
            <TitleBar title={title} className={classes.titleBar}>
            </TitleBar>
            <Paper className={classes.dialogPaper}>
              <FormValidator onSubmit={this.onSubmit} autoComplete="off"
                             name="BoardEditForm" id="BoardEditForm" className={classes.form}>
                <DialogContent className={classes.dialogContent}>
                  <Grid container  direction="row" spacing={1}>
                    <Grid item xs={12}>
                      {this.renderCustom()}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldValidator
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Board or Team Name"
                        type="text"
                        variant="standard"
                        validators={{required:true}}
                        onChange={this.onChange}
                        value={this.values.name}
                        fullWidth
                      />
                      <TextFieldValidator
                        margin="dense"
                        name="alias"
                        label="Board Sharing Alias (2-20 characters: a-z, 0-9, -)"
                        type="text"
                        variant="standard"
                        validators={{required:true, matches:"^[a-z-0-9]{2,20}$"}}
                        onChange={this.onChange}
                        value={this.values.alias}
                        fullWidth
                        helperText={`${this.shareUrl}/${this.campaign?.alias}/${this.values.alias}`}
                      />
                      <TextFieldValidator
                        margin="dense"
                        name="sponsorName"
                        label="Sponsor Name(s)"
                        type="text"
                        variant="standard"
                        validators={{required:true}}
                        onChange={this.onChange}
                        value={this.values.sponsorName}
                        fullWidth
                      />
                      <TextFieldValidator
                        margin="dense"
                        name="sponsorEmail"
                        label="Sponsor Email"
                        type="text"
                        variant="standard"
                        validators={{required:true, isEmail:true}}
                        onChange={this.onChange}
                        value={this.values.sponsorEmail}
                        disabled={!userStore!.isOwner && boardId !== undefined}
                        fullWidth
                      />
                      <FormGroup row>
                        <FormControlLabel
                          value="sponsorMatch"
                          control={
                            <Checkbox color="secondary" name="sponsorMatch" id="sponsorMatch"
                                      checked={this.values.sponsorMatch}
                                      onChange={this.onChange}/>}
                          label="Sponsor match up to $"
                          labelPlacement="end"
                          className={classes.label}
                        />
                        <TextFieldValidator
                          margin="dense"
                          name="sponsorMatchLimit"
                          label="Limit"
                          type="text"
                          variant="standard"
                          validators={{required:false, minValue: 0}}
                          onChange={this.onChange}
                          value={this.values.sponsorMatchLimit}
                          className={classes.matchLimit}
                        />
                      </FormGroup>

                      <div className={classes.label}>Cover Photo Override</div>
                      {this.coverImageUrl &&
                        <div>
                          <img src={this.coverImageUrl} alt="Cover" className={classes.coverImage}/>
                          <IconButton
                            key="delete"
                            aria-label="Delete Cover"
                            color="inherit"
                            className={classes.deleteBtn}
                            onClick={this.onDeleteCover}
                          >
                            <DeleteIcon className={classes.deleteIcon} />
                          </IconButton>
                        </div>
                      }
                      <FormGroup row>
                        <input
                          type='file'
                          accept='image/jpeg, image/png'
                          className={classes.fileInput}
                          onChange={(e) => this.onCoverFileChange(e.target!.files![0])}
                        />
                      </FormGroup>

                      <div className={classes.fieldGroup}>
                        <label className={classes.fieldLabel}>
                          Board Color
                        </label>
                        <ColorPicker color={this.values.backgroundColor} onChange={this.onChangeBackgroundColor}/>
                      </div>
                      {isOwner &&
                        <React.Fragment>
                          <div className={classes.label}>Board Background Override (944 x 1338 pixels)</div>
                          {this.backgroundImageUrl &&
                            <div>
                              <img src={this.backgroundImageUrl} alt="Background" className={classes.coverImage}/>
                              <IconButton
                                key="delete"
                                aria-label="Delete Background"
                                color="inherit"
                                className={classes.deleteBtn}
                                onClick={this.onDeleteBackground}
                              >
                                <DeleteIcon className={classes.deleteIcon} />
                              </IconButton>
                            </div>
                          }
                        </React.Fragment>
                      }
                      <FormGroup row>
                        <input
                          type='file'
                          accept='image/jpeg, image/png'
                          className={classes.fileInput}
                          onChange={(e) => this.onBackgroundFileChange(e.target!.files![0])}
                        />
                      </FormGroup>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.fieldLabel}>Click a space to customize.</div>
                      <div className={classes.boardItem}>
                      { this.renderPreview() }
                      </div>
                    </Grid>
                  </Grid>


                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12} >
                      <ProgressButton variant="contained" color="primary" fullWidth
                                      type="submit" processing={this.isProcessing}
                                      onClick={this.onSubmit}>
                        Save
                      </ProgressButton>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <DialogButton variant="tertiary" fullWidth onClick={this.onCancel}>
                        Cancel
                      </DialogButton>
                    </Grid>
                  </Grid>
                </DialogActions>
              </FormValidator>
            </Paper>
          </Grid>
        </MarginRow>
        {this.boardCellEditDialog}
      </Page>
    )
  }

  renderCustom() {
    let custom = null
    if (this.campaign) {
      custom = parseHTML(this.campaign.getCustom("newBoardInstructions"))
    }

    return (custom)
  }

  getLayout(campaign: Campaign, board?: Board) {

    if (board && board.layout) {
      return board.layout
    } else if (campaign.layout) {
      return campaign.layout
    } else {
      return campaign.getDefaultLayout()
    }

  }

  onChange = (event: any) => {
    const name = event.target.name
    if (name === "alias") {
      this.values[name] = event.target.value.toLowerCase()
    } else if (name === "sponsorMatch") {
      this.values![name] = event.target.checked
    } else {
      this.values[name] = event.target.value
      this.updatePreview()
    }
  }

  onChangeBackgroundColor = (color: string) => {
    this.values.backgroundColor = color
    this.updatePreview()
  }

  @action onCoverFileChange = async (file: any) => {
    this.values.coverFile = file
    await this.uploadCover()
  }

  onDeleteCover = async () => {
    const { notify, s3UrlCacheStore } = this.props

    const result = await Storage.remove(this.values.coverImage)
      .catch((err: Error) => {
        notify!.show("error", "Unable to delete cover image")
      })

    if (result) {
      s3UrlCacheStore!.remove(this.values.coverImage)
      this.values.coverImage = ""
      this.coverImageUrl = undefined
    }
  }

  @action onBackgroundFileChange = async (file: any) => {
    this.values.backgroundFile = file
    const result = await this.uploadBackground()
    if (result) {
      this.updatePreview()
    }
  }

  onDeleteBackground = async () => {
    const { notify, s3UrlCacheStore } = this.props

    const result = await Storage.remove(this.values.backgroundImage)
      .catch((err: Error) => {
        notify!.show("error", "Unable to delete background image")
      })

    if (result) {
      s3UrlCacheStore!.remove(this.values.backgroundImage)
      this.values.backgroundImage = ""
      this.backgroundImageUrl = undefined
      this.updatePreview()
    }
  }

  onSubmit = async () => {
    const { boardId, campaignStore, accountStore, notify } = this.props

    if (!this.isValid) {
      return
    }

    const values = this.values

    if (boardId) {
      Tracking.event({action: 'UpdateBoard'})
      this.isProcessing = true

      if (values.alias && values.alias !== this.board!.alias) {
        const board = this.campaign!.boards.find((b: Board) => b.alias === values.alias)
        if (board && board.id !== boardId) {
          this.isProcessing = false
          notify!.show("error", `The alias (${values.alias}) is already in use for this fundraiser`)
          return
        }
      }

      // if (values.coverFile) {
      //   const result = await this.uploadCover()
      //   if (!result) {
      //     this.isProcessing = false
      //     return
      //   }
      // }
      //
      // if (values.backgroundFile) {
      //   const result = await this.uploadBackground()
      //   if (!result) {
      //     this.isProcessing = false
      //     return
      //   }
      // }

      let sponsor
      if (values.sponsorEmail) {
        sponsor = await accountStore!.findUser(values.sponsorEmail)
        if (sponsor) {
          values.sponsorId = sponsor.id
        } else {
          values.sponsorId = createUUID()
        }
      }

      const input: UpdateBoardInput = {
        id: boardId,
        name: values.name,
        alias: values.alias,
        sponsorId: values.sponsorId,
        sponsorName: values.sponsorName,
        sponsorEmail: values.sponsorEmail && values.sponsorEmail.length > 0 ? values.sponsorEmail : null,
        sponsorMatch: values.sponsorMatch,
        sponsorMatchLimit: values.sponsorMatchLimit,
        backgroundColor: !this.campaign!.custom || values.backgroundColor !== this.campaign!.custom.backgroundColor ? values.backgroundColor : "",
        backgroundImage: values.backgroundImage,
        coverImage: values.coverImage,
      }

      if (values.saveLayout && values.layout) {
        input.layout = JSON.stringify(values.layout)
      }

      const update = await campaignStore!.updateBoard(input)
        .catch((err: Error) => {
          this.isProcessing = false
          notify!.show("error", "Unable to update board")
        })

      if (update) {
        if (!sponsor && values.sponsorEmail && values.sponsorEmail.length > 0) {
          await this.createBoardSponsor(update)
        }
        this.isProcessing = false
        // userStore!.createActivity(ActivityType.CampaignEdit, campaignId)
        notify!.show("success", "Board updated!")
        this.onCancel()
      }
    } else {
      Tracking.event({action: 'CreateBoard'})
      this.isProcessing = true

      if (values.alias) {
        const board = this.campaign!.boards.find((b: Board) => b.alias === values.alias)
        if (board) {
          this.isProcessing = false
          notify!.show("error", `The alias (${values.alias}) is already in use for this fundraiser`)
          return
        }
      }

      if (values.boardId === "") {
        values.boardId = createUUID()
      }

      // if (values.coverFile) {
      //   const result = await this.uploadCover()
      //   if (!result) {
      //     this.isProcessing = false
      //     return
      //   }
      // }
      //
      // if (values.backgroundFile) {
      //   const result = await this.uploadBackground()
      //   if (!result) {
      //     this.isProcessing = false
      //     return
      //   }
      // }

      let sponsor = await accountStore!.findUser(values.sponsorEmail)
      if (sponsor) {
        values.sponsorId = sponsor.id
      } else {
        values.sponsorId = createUUID()
      }

      const input: CreateBoardInput = {
        id: values.boardId,
        accountId: this.campaign!.accountId,
        campaignId: this.campaign!.id,
        name: values.name,
        alias: values.alias,
        sponsorId: values.sponsorId,
        sponsorName: values.sponsorName,
        sponsorEmail: values.sponsorEmail && values.sponsorEmail.length > 0 ? values.sponsorEmail : null,
        sponsorMatch: values.sponsorMatch,
        sponsorMatchLimit: values.sponsorMatchLimit,
        backgroundColor: !this.campaign!.custom || values.backgroundColor !== this.campaign!.custom.backgroundColor ? values.backgroundColor : "",
        backgroundImage: values.backgroundImage,
        coverImage: values.coverImage,
      }

      if (values.saveLayout && values.layout) {
        input.layout = JSON.stringify(values.layout)
      }

      const board = await campaignStore!.createBoard(input)
        .catch((err: Error) => {
          this.isProcessing = false
          notify!.show("error", "Unable to create board")
        })

      if (board) {
        if (!sponsor && board.sponsorEmail) {
          await this.createBoardSponsor(board)
        }
        this.isProcessing = false
        // userStore!.createActivity(ActivityType.CampaignCreate, campaign.id)
        // TODO: Notify them that an invitation has been sent
        const message = (!sponsor) ? "Board created and a registration email sent to the sponsor for updating board!" : "Board created!"
        notify!.show("success", message)
        this.onCancel()
      }
    }

  }

  createBoardSponsor = async (board: Board) => {
    const { userStore, accountStore } = this.props

    let user

    if (userStore!.isOwner) {
      // Look for existing user
      user = await accountStore!.findUser(board.sponsorEmail)
      if (user) {
        return user
      }
    }

    if (!user) {
      let firstName
      let lastName
      const lastSpace = board.sponsorName.lastIndexOf(" ")
      if (lastSpace > 0) {
        firstName = board.sponsorName.substr(0, lastSpace)
        lastName = board.sponsorName.substr(lastSpace + 1)
      } else {
        firstName = board.sponsorName
        lastName = ""
      }

      const input: CreateUserInput = {
        id: board.sponsorId,
        active: false,
        accountId: board.accountId,
        firstName: firstName,
        lastName: lastName,
        email: board.sponsorEmail,
        role: UserRole.Sponsor
      }

      return accountStore!.createUser(input)
    }
  }

  uploadCover = async () => {
    const { notify, s3UrlCacheStore } = this.props


    const contentType = this.values.coverFile!["type"]
    const ext = (contentType === "image/jpeg") ? "jpg" : "png"
    const key = `content/${this.campaign!.id}/${this.values.boardId}.${ext}`

    const result = await Storage.put(key, this.values.coverFile, {
      contentType: contentType
    })
      .catch((err: Error) => {
        notify!.show("error", "Unable to upload cover image")
      })

    if (result) {
      this.values.coverImage = result["key"]
      s3UrlCacheStore!.remove(this.values.coverImage)
      s3UrlCacheStore!.get(this.values.coverImage)
        .then((url: string | undefined) => {
          this.coverImageUrl = url
        })
        .catch((err: Error) => {
          console.log("Error loading bpard cover image")
        })
    }
    return result
  }

  uploadBackground = async () => {
    const { notify, s3UrlCacheStore } = this.props

    const contentType = this.values.backgroundFile!["type"]
    const ext = (contentType === "image/jpeg") ? "jpg" : "png"
    const key = `content/${this.campaign!.id}/${this.values.boardId}/background.${ext}`
    console.log(`uploadBackground to: ${key}`)

    const result = await Storage.put(key, this.values.backgroundFile, {
      contentType: contentType
    })
      .catch((err: Error) => {
        notify!.show("error", "Unable to upload background image")
      })

    if (result) {
      this.values.backgroundImage = result["key"]
      s3UrlCacheStore!.remove(this.values.backgroundImage)
      s3UrlCacheStore!.get(this.values.backgroundImage)
        .then((url: string | undefined) => {
          this.backgroundImageUrl = url
        })
        .catch((err: Error) => {
          console.log("Error loading bpard background image")
        })
    }
    return result
  }

  onCancel = () => {
    const { campaignId } = this.props
    if (ControlTower.currentRoute.startsWith(Routes.account)) {
      ControlTower.route(`${Routes.account}/campaign/${campaignId}/boards`)
    } else {
      ControlTower.route(`${Routes.campaign}/${campaignId}/boards`)
    }
  }

  @action updatePreview = () => {
    console.log(`updatePreview backgroundColor = ${this.values.backgroundColor}`)
    this.previewBoard = new Board({
      id: this.values.boardId,
      campaignId: this.campaign!.id,
      campaign: this.campaign,
      name: this.values.name,
      sponsorName: this.values.sponsorName,
      sponsorEmail:  this.values.sponsorName,
      sponsorMatch:  this.values.sponsorMatch,
      sponsorMatchLimit: this.values.sponsorMatchLimit,
      backgroundColor: this.values.backgroundColor,
      backgroundImage: this.values.backgroundImage,
    })
    this.previewBoard.layout = this.values.layout
  }

  renderPreview() {
    const { classes } = this.props

    if (this.previewBoard) {
      return (
        <BINKOBoard board={this.previewBoard} preview={true} className={classes.board} onDonate={this.onDonate}/>
      )
    }
  }

  onDonate = (row: number, col: number, amount: number, freq: string, other: string) => {
    this.boardCellEditDialog = <BoardCellEditDialog row={row} col={col} amount={amount} frequency={DonationFrequency[freq]} other={other}
                                                     onChange={this.onChangeBoardCell} onClose={this.onCloseBoardCellEditDialog}
                                                     campaign={this.campaign}/>
  }

  onChangeBoardCell = (row: number, col: number, freq: string, amount: number, other: string) => {
    // Save values
    if (!this.values.layout) {
      this.values.layout = this.getLayout(this.campaign!, this.board)
    }
    if (this.values.layout) {
      let r = this.values.layout!["rows"][row]
      let cell = r["cols"][col] as any
      cell["amount"] = amount
      cell["freq"] = freq
      cell["other"] = other
      this.values.saveLayout = true
      this.updatePreview()
    }
    this.boardCellEditDialog = undefined
  }

  onCloseBoardCellEditDialog = () => {
    this.boardCellEditDialog = undefined
  }
}

export default withTheme((withStyles(styles)(BoardEditPage)))