import * as React from 'react'
import {
  createStyles,
  DialogActions,
  DialogContent,
  FormGroup,
  Grid, Paper,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import {inject, observer} from "mobx-react";
import {action, makeObservable, observable, when} from "mobx";
import Campaign from "../../model/Campaign";
import Progress from "../../components/Progress";
import UserStore from "../../stores/UserStore";
import FormValidator from "../../components/form/FormValidator";
import Tracking from "../../components/Tracking";
import {
  DonationFrequency,
  UpdateCampaignInput,
} from "../../API";
import ControlTower, {Routes} from "../../components/ControlTower";
import Notify from "../../components/notify/Notify";
import DialogButton from "../../components/form/DialogButton";
import ProgressButton from "../../components/form/ProgressButton";
import CampaignStore from "../../stores/CampaignStore";
import {Board} from "../../model/Board";
import S3UrlCacheStore from "../../stores/S3UrlCacheStore";
import {Storage} from 'aws-amplify';
import BINKOBoard from "../boards/BINKOBoard";
import AccountStore from "../../stores/AccountStore";
import ColorPicker from "../../components/form/ColorPicker";
import TitleBar from "../../components/TitleBar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import BoardCellEditDialog from "../boards/BoardSpaceEditDialog";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  content: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  instructions: {
    maxWidth: 460,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 0,
    marginTop: 0
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: 4
    }
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  fieldGroup: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectField: {
    width: "100%",
  },
  fileInput: {
    width: 340
  },
  boardItem: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "center",
    justifyContent: "center",
    alignItems: "flex-start",
    alignContent: "flex-start",
    maxHeight: 620,
    padding: theme.spacing(1.5),
    // paddingTop: 0,
  },
  board: {
    transform: "scale(0.95, 0.95)",
    transformOrigin: "50% 0%",
    textAlign: "center",
    padding: 0
  },
  matchLimit: {
    maxWidth: 100
  },
  coverImage: {
    height: 72
  },
  deleteIcon: {
    fontSize: 24,
  },
  deleteBtn: {
    color: theme.palette.text.secondary,
    display: 'inline-block',
    position: "relative",
    top: -6,
    cursor: 'pointer',
  }
})

interface ICampaignGameSettingsProps {
  campaign?: Campaign
  userStore?: UserStore
  accountStore?: AccountStore
  campaignStore?: CampaignStore
  s3UrlCacheStore?: S3UrlCacheStore
  progress?: Progress
  notify?: Notify
  onSave?: any
}

@inject("userStore", "accountStore", "campaignStore", "s3UrlCacheStore", "progress", "notify")
@observer
class CampaignGameSettings extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICampaignGameSettingsProps & WithTheme> {

  @observable isLoading = true
  @observable campaign?: Campaign
  @observable values = {
    backgroundColor: "",
    backgroundFile: undefined,
    backgroundImage: "",
    custom: {},
    layout: undefined,
    saveLayout: false
  }
  @observable isValid: boolean = true
  @observable isProcessing = false
  @observable progressValue: number = 0
  @observable progressText: string = ""
  @observable backgroundImageUrl?: string
  @observable previewBoard?: Board
  @observable boardCellEditDialog: any

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    const { campaign, userStore, campaignStore, s3UrlCacheStore, progress} = this.props
    this.isLoading = true
    progress!.show("GameSettings")
    when(
      () => !userStore!.isLoading && !campaignStore!.isLoading,
      async () => {
        if (campaign) {
          this.campaign = campaign
          this.values = {
            backgroundColor: campaign.custom && campaign.custom!.backgroundColor ? campaign.custom.backgroundColor : "rgb(255,255,255,1)",
            backgroundFile: undefined,
            backgroundImage: campaign.custom ? campaign.custom!.backgroundImage : "",
            custom: campaign.custom ?? {},
            layout: campaign.layout ? campaign.layout : campaign.getDefaultLayout(),
            saveLayout: false
          }
          if (this.values.backgroundImage) {
            const url = await s3UrlCacheStore!.get(this.values.backgroundImage)
              .catch((err: Error) => {
                console.log("Error loading board background image")
              })
            if (url) {
              this.backgroundImageUrl = url
            }
          }

          this.updatePreview()
          this.isLoading = false
          progress!.hide("GameSettings")
        }
      }
    )
  }

  render() {
    const { classes } = this.props

    const title = "Game Settings"

    return (
      <div className={classes.root}>
        <Paper className={classes.dialogPaper}>
          <FormValidator onSubmit={this.onSubmit} autoComplete="off"
                         name="GameEditForm" id="GameEditForm" className={classes.form}>
            <TitleBar title={title} className={classes.titleBar}>
            </TitleBar>
            <DialogContent className={classes.dialogContent}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.fieldGroup}>
                    <label className={classes.fieldLabel}>
                      Background Color
                    </label>
                    <ColorPicker color={this.values.backgroundColor} onChange={this.onChangeBackgroundColor}/>
                  </div>

                  <div className={classes.label}>Board Background (.png or .jpg)</div>
                  {this.backgroundImageUrl &&
                    <div>
                      <img src={this.backgroundImageUrl} alt="Background" className={classes.coverImage}/>
                      <IconButton
                        key="delete"
                        aria-label="Delete Background"
                        color="inherit"
                        className={classes.deleteBtn}
                        onClick={this.onDeleteBackground}
                      >
                        <DeleteIcon className={classes.deleteIcon} />
                      </IconButton>
                    </div>
                  }
                  <FormGroup row>
                    <input
                      type='file'
                      accept='image/jpeg, image/png'
                      className={classes.fileInput}
                      onChange={(e) => this.onBackgroundFileChange(e.target!.files![0])}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.fieldLabel}>Click a space to customize the default game board.</div>
                  <div className={classes.boardItem}>
                  { this.renderPreview() }
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12} >
                  <ProgressButton variant="contained" color="primary" fullWidth
                                  type="submit" processing={this.isProcessing}
                                  onClick={this.onSubmit}>
                    Save
                  </ProgressButton>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <DialogButton variant="tertiary" fullWidth onClick={this.onCancel}>
                    Cancel
                  </DialogButton>
                </Grid>
              </Grid>
            </DialogActions>
          </FormValidator>
        </Paper>
        {this.boardCellEditDialog}
      </div>
    )
  }

  onChange = (event: any) => {
    const name = event.target.name
    this.values[name] = event.target.value
    this.updatePreview()
  }

  onChangeBackgroundColor = (color: string) => {
    this.values.backgroundColor = color
    this.updatePreview()
  }

  @action onBackgroundFileChange = async (file: any) => {
    this.values.backgroundFile = file
    const result = await this.uploadBackground()
    if (result) {
      this.updatePreview()
    }
  }

  onDeleteBackground = async () => {
    const { notify, s3UrlCacheStore } = this.props

    const result = await Storage.remove(this.values.backgroundImage)
      .catch((err: Error) => {
        notify!.show("error", "Unable to delete background image")
      })

    if (result) {
      await s3UrlCacheStore!.remove(this.values.backgroundImage)
      this.values.backgroundImage = ""
      this.backgroundImageUrl = undefined
      this.updatePreview()
    }
  }

  onSubmit = async () => {
    const { accountStore, notify, onSave } = this.props

    if (!this.isValid) {
      return
    }

    const values = this.values

    Tracking.event({action: 'UpdateGame'})
    this.isProcessing = true

    if (values.backgroundFile) {
      const result = await this.uploadBackground()
      if (!result) {
        this.isProcessing = false
        return
      }
    }

    values.custom["backgroundColor"] = values.backgroundColor
    values.custom["backgroundImage"] = values.backgroundImage
    
    const input: UpdateCampaignInput = {
      id: this.campaign!.id,
      custom: JSON.stringify(values.custom)
    }
    if (values.saveLayout && values.layout) {
      input.layout = JSON.stringify(values.layout)
    }

    const update = await accountStore!.updateCampaign(input)
      .catch((err: Error) => {
        this.isProcessing = false
        notify!.show("error", "Unable to update game")
      })
    
    if (update) {
      this.isProcessing = false
      notify!.show("success", "Game updated!")
      if (onSave) {
        onSave(update)
      }
    }

  }

  uploadBackground = async () => {
    const { notify, s3UrlCacheStore } = this.props

    const contentType = this.values.backgroundFile!["type"]
    const ext = (contentType === "image/jpeg") ? "jpg" : "png"
    const key = `content/${this.campaign!.id}/background.${ext}`

    const result = await Storage.put(key, this.values.backgroundFile, {
      contentType: contentType
    })
      .catch((err: Error) => {
        notify!.show("error", "Unable to upload background image")
      })

    if (result) {
      this.values.backgroundImage = result["key"]
      s3UrlCacheStore!.remove(this.values.backgroundImage)
      s3UrlCacheStore!.get(this.values.backgroundImage)
        .then((url: string | undefined) => {
          this.backgroundImageUrl = url
        })
        .catch((err: Error) => {
          console.log("Error loading bpard background image")
        })
    }
    return result
  }

  onCancel = () => {
    ControlTower.route(`${Routes.account}/campaign/${this.campaign!.id}`)
  }

  @action updatePreview = () => {
    this.previewBoard = new Board({
      id: "preview",
      campaignId: this.campaign!.id,
      // campaign: this.campaign,
      name: "Preview",
      sponsorName: "Sponsor Name",
      sponsorEmail:  "",
      sponsorMatch:  false,
      sponsorMatchLimit: 0,
      backgroundColor: this.values.backgroundColor,
      backgroundImage: this.values.backgroundImage,
    })
    this.previewBoard.layout = this.values.layout
  }

  renderPreview() {
    const { classes } = this.props

    if (this.previewBoard) {
      return (
        <BINKOBoard board={this.previewBoard} preview={true} className={classes.board} legend onDonate={this.onDonate}/>
      )
    }
  }

  onDonate = (row: number, col: number, amount: number, freq: string, other: string) => {
    this.boardCellEditDialog = <BoardCellEditDialog row={row} col={col} amount={amount} frequency={DonationFrequency[freq]} other={other}
                                                    campaign={this.campaign}
                                                    onChange={this.onChangeBoardCell} onClose={this.onCloseBoardCellEditDialog}/>
  }

  onChangeBoardCell = (row: number, col: number, freq: string, amount: number, other: string) => {
    // Save values
    if (!this.values.layout) {
      // @ts-ignore
      this.values.layout = this.campaign!.getDefaultLayout()
    }
    if (this.values.layout) {
      let r = this.values.layout!["rows"][row]
      let cell = r["cols"][col] as any
      cell["amount"] = amount
      cell["freq"] = freq
      cell["other"] = other
      this.values.saveLayout = true
      this.updatePreview()
    }
    this.boardCellEditDialog = undefined
  }

  onCloseBoardCellEditDialog = () => {
    this.boardCellEditDialog = undefined
  }
}

export default withTheme((withStyles(styles)(CampaignGameSettings)))