import * as React from 'react'
import {createStyles, Grid, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import TitleBar from "../../components/TitleBar";
import TitleButton from "../../components/TitleButton";
import Campaign from "../../model/Campaign";
import {makeObservable, observable, when} from "mobx";
import Progress from "../../components/Progress";
import Notify from "../../components/notify/Notify";
import UserStore from "../../stores/UserStore";
import {inject, observer} from "mobx-react";
import CampaignCard from "./CampaignCard";
import AccountStore from "../../stores/AccountStore";
import ControlTower, {Routes} from "../../components/ControlTower";
import {getISODateToday} from "../../stores/StoreUtilities";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  titleBar: {
    marginTop: theme.spacing(1),
    width: "100%"
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    justifyContent: 'flex-start',
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },

})

interface IAccountCampaignsProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
  notify?: Notify
}

@inject("userStore", "accountStore", "progress", "notify")
@observer
class AccountCampaigns extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountCampaignsProps & WithTheme> {

  @observable isLoading = true
  @observable active: Campaign[] = []
  @observable completed: Campaign[] = []

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount () {
    const { userStore, accountStore, progress } = this.props
    this.isLoading = true
    progress!.show("AccountCampaigns")
    when (
      () => !userStore!.isLoading && !accountStore!.isLoading,
      async () => {
        await this.loadCampaigns()
        this.isLoading = false
        progress!.hide("AccountCampaigns")
      }
    )
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.titleBar}>
          <TitleBar title="Active Fundraisers" variant="subtitle">
            <TitleButton title="+ Add" onClick={this.onAddCampaign}/>
          </TitleBar>
        </div>

        {this.active.length > 0 &&
          <Grid container className={classes.content} direction="row" spacing={2}>
            {this.active.map((c: Campaign) => {
              return (
                <Grid item sm={6} xs={12} className={classes.card} key={c.id}>
                  <CampaignCard campaign={c} onEdit={this.onEdit} onCardAction={this.onCardAction}/>
                </Grid>
              )
            })}
          </Grid>
        }

        <div className={classes.titleBar}>
          <TitleBar title="Completed Fundraisers" variant="subtitle">
          </TitleBar>
        </div>

        {this.completed.length > 0 &&
        <Grid container className={classes.content} direction="row" spacing={2}>
          {this.completed.map((c: Campaign) => {
            return (
              <Grid item sm={6} xs={12} className={classes.card} key={c.id}>
                <CampaignCard campaign={c} onEdit={this.onEdit} onCardAction={this.onCardAction}/>
              </Grid>
            )
          })}
        </Grid>
        }
      </div>
    )
  }

  onAddCampaign = () => {
    ControlTower.route(`${Routes.campaignEdit}`)
  }

  onEdit = (campaign: Campaign) => {
    ControlTower.route(`${Routes.account}/campaign/${campaign.id}`)
  }

  onCardAction = (campaign: Campaign) => {
    ControlTower.route(`${Routes.campaign}/${campaign.alias}`)
  }

  loadCampaigns = async () => {
    const { accountStore } = this.props
    const campaigns = await accountStore!.listCampaigns()
    if (campaigns) {
      campaigns.sort((a: Campaign, b: Campaign) => a.startAt.localeCompare(b.startAt))
      const active: Campaign[] = []
      const completed: Campaign[] = []
      const today = getISODateToday()

      campaigns.forEach((c: Campaign) => {
        if (c.endAt >= today) {
          active.push(c)
        } else {
          completed.push(c)
        }
      })
      this.active = active
      this.completed = completed
    } else {
      this.active = []
      this.completed = []
    }
  }
}

export default withTheme((withStyles(styles)(AccountCampaigns)))