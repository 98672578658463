import GivinciAPI from "../apis/GivinciAPI";
import Campaign from "../model/Campaign";
import {Board} from "../model/Board";
import * as APITypes from "../API";
import Tracking from "../components/Tracking";
import {Donation} from "../model/Donation";
import {isUUID} from "./StoreUtilities";

class CampaignStore {
  givinciAPI: GivinciAPI

  isLoading = false

  constructor(options: any) {
    this.givinciAPI = (options && options.givinciAPI) ? options.givinciAPI : null
  }

  listCampaigns = async (filter?: APITypes.ModelCampaignFilterInput): Promise<Campaign[]> => {
    let campaings: Campaign[] = []

    const data = await this.givinciAPI.listCampaigns(filter)
    if (data && data.items) {
      campaings = data.items.map((item: any) => {
        return new Campaign(item)
      })
    }

    return campaings
  }

  getCampaign = async (campaignId: string): Promise<Campaign | undefined> => {
    let campaign: Campaign | undefined

    if (isUUID(campaignId)) {
      const data = await this.givinciAPI.getCampaign(campaignId)
      if (data) {
        campaign = new Campaign(data)
      }
    } else {
      const data = await this.givinciAPI.getCampaignByAlias(campaignId)
      if (data) {
        campaign = new Campaign(data)
      }
    }

    return campaign
  }

  getCampaignOnly = async (campaignId: string): Promise<Campaign | undefined> => {
    let campaign: Campaign | undefined

    if (isUUID(campaignId)) {
      const data = await this.givinciAPI.getCampaignOnly(campaignId)
      if (data) {
        campaign = new Campaign(data)
      }
    } else {
      const data = await this.givinciAPI.getCampaignOnlyByAlias(campaignId)
      if (data) {
        campaign = new Campaign(data)
      }
    }

    return campaign
  }

  // Board methods

  getBoard = async (boardId: string): Promise<Board | undefined> => {
    let board: Board | undefined
    const data = await this.givinciAPI.getBoard(boardId)
    if (data) {
      board = new Board(data)
    }
    return board
  }

  async createBoard(input:  APITypes.CreateBoardInput) {
    const data = await this.givinciAPI!.createBoard(input)
    if (data) {
      Tracking.event({action: "Create Board"})
      let board = new Board(data)
      this.isLoading = false
      return board
    } else {
      return null
    }
  }

  async updateBoard(input: APITypes.UpdateBoardInput) {
    const update = await this.givinciAPI!.updateBoard(input)
    if (update) {
      const board = new Board(update)
      return board
    } else {
      return null
    }
  }

  // Donation methods

  getDonation = async (donationId: string): Promise<Donation | undefined> => {
    let donation: Donation | undefined

    const data = await this.givinciAPI.getDonation(donationId)
    if (data) {
      donation = new Donation(data)
    }

    return donation
  }

  async createDonation(input:  APITypes.CreateDonationInput) {
    const data = await this.givinciAPI!.createDonation(input)
    if (data) {
      Tracking.event({action: "Create Donation"})
      let donation = new Donation(data)
      this.isLoading = false
      return donation
    } else {
      return null
    }
  }

  async updateDonation(input: APITypes.UpdateDonationInput) {
    const update = await this.givinciAPI!.updateDonation(input)
    if (update) {
      const donation = new Donation(update)
      return donation
    } else {
      return null
    }
  }

  async deleteDonation(donationId: string) {
    const result = await this.givinciAPI!.deleteDonation(donationId)
    if (result) {
      const donation = new Donation(result)
      return donation
    } else {
      return null
    }
  }

}

export default CampaignStore