import * as React from 'react'
import Page from '../../components/page/Page'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import DonationForm from "./DonationForm";

const styles = (theme: Theme) => createStyles({
})

interface IDonationPageProps {
  donationId?: string
  boardId?: string
  location?: any
}

class DonationPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IDonationPageProps & WithTheme> {

  render() {
    const { boardId, donationId, location } = this.props

    const title = donationId ? "Edit Donation" : "Donate"

    return (
      <Page title={title}>
        <MarginRow>
          <DonationForm donationId={donationId} boardId={boardId} location={location}/>
        </MarginRow>
      </Page>
    )
  }
}

export default withTheme((withStyles(styles)(DonationPage)))