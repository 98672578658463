import * as React from 'react'
import {
  Checkbox,
  createStyles,
  DialogActions,
  DialogContent, DialogContentText, FormControlLabel,
  Grid, InputAdornment, MenuItem,
  Paper, Radio, RadioGroup,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import TitleBar from "../../components/TitleBar";
import {inject, observer} from "mobx-react";
import {makeObservable, observable, when} from "mobx";
import Progress from "../../components/Progress";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Tracking from "../../components/Tracking";
import {
  CampaignType,
  CreateDonationInput,
  DonationFrequency,
  UpdateDonationInput
} from "../../API";
import ControlTower, {Routes} from "../../components/ControlTower";
import Notify from "../../components/notify/Notify";
import DialogButton from "../../components/form/DialogButton";
import ProgressButton from "../../components/form/ProgressButton";
import CampaignStore from "../../stores/CampaignStore";
import {Board} from "../../model/Board";
import {Donation} from "../../model/Donation";
import Confirm from "../../components/confirm/Confirm";
import parseHTML from "html-react-parser";
import Campaign from "../../model/Campaign";
import PaymentAPI, {CheckoutSession, LineItem} from "../../apis/PaymentAPI";
import config from "react-global-configuration";
import {getErrorMessage, getISODateToday, numberToMoneyFormat, parseMoney} from "../../stores/StoreUtilities";
import MenuButton from "../../components/controls/MenuButton";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'top',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "center",
    width: "100%",
    height: 40,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    maxWidth: 500,
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  content: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  dialogPaper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 460,
    marginTop: theme.spacing(0)
  },
  form: {
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: 4
    }
  },
  dialogActions: {
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  fieldGroup: {
    margin: theme.spacing(1),
  },
  selectField: {
    width: "100%",
  },
  fileInput: {
    width: 340
  },
  instructions: {
    // [theme.breakpoints.down('sm')]: {
    //   padding: theme.spacing(1),
    //   paddingTop: 0
    // },
    // [theme.breakpoints.up('sm')]: {
    //   marginTop: theme.spacing(1),
    //   padding: 0
    // },
  },
  tipButton: {
    marginLeft: 4,
    marginBottom: 6,
    paddingBottom: 0,
  },
  readonly: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: theme.palette.text.primary
    }
  },
  helperText: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3
  }
})

interface IDonationFormProps {
  donationId?: string
  boardId?: string
  onCancel?: any
  campaignStore?: CampaignStore
  paymentAPI?: PaymentAPI
  progress?: Progress
  notify?: Notify
  confirm?: Confirm
  location?: any
}

@inject("campaignStore", "paymentAPI", "progress", "notify", "confirm")
@observer
class DonationForm extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IDonationFormProps & WithTheme> {

  @observable isLoading = true
  @observable donation?: Donation
  @observable board?: Board
  @observable campaign?: Campaign
  @observable values = {
    donationId: "",
    spaceId: "",
    amount: "$0.00",
    includeFees: true,
    fees: "$0.00",
    includeTip: true,
    tipPercent: 5,
    tip: "$0.00",
    frequency: "Once",
    other: "",
    name: "",
    email: "",
    message: "",
    isAnonymous: false
  }
  @observable minAmount = 0
  @observable amountDisabled = false
  @observable oneTimeDisabled = false
  @observable monthlyDisabled = false
  @observable isValid: boolean = true
  @observable isProcessing = false
  @observable progressValue: number = 0
  @observable progressText: string = ""
  @observable isAdminAdd: boolean = false
  @observable paymentSource: string = ""
  @observable isActive: boolean = true

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    const { donationId, boardId, campaignStore, progress} = this.props
    this.isLoading = true
    progress!.show("DonationForm")
    when(
      () => !campaignStore!.isLoading,
      async () => {
        this.isAdminAdd = ControlTower.currentRoute.endsWith("add")

        if (donationId) {
          const donation = await campaignStore!.getDonation(donationId)
          if (donation) {
            this.donation = donation
            this.board = donation.board
            if (this.board) {
              this.campaign = await campaignStore?.getCampaignOnly(this.board.campaignId)
            }
            this.values = {
              donationId: donation.id,
              spaceId: donation.spaceId,
              amount: numberToMoneyFormat(donation.amount),
              includeFees: false,
              fees: "$0.00",
              includeTip: false,
              tipPercent: 0,
              tip: "$0.00",
              frequency: donation.frequency.toString(),
              other: donation.other,
              name: donation.name,
              email: donation.email,
              message: donation.message,
              isAnonymous: donation.isAnonymous
            }
          }
          // TODO: Handle error if donation not loaded
        } else if (boardId) {
          const board = await campaignStore!.getBoard(boardId)
          if (board) {
            this.campaign = await campaignStore?.getCampaignOnly(board.campaignId)
            this.board = board
            // TODO: Handle error if board not loaded
          }

          if (this.campaign) {
            this.paymentSource = this.campaign!.paymentInfo?.paymentSource ?? ""
            const today = getISODateToday()
            this.isActive = (donationId !== undefined || this.isAdminAdd) ||
              (this.campaign.startAt <= today && this.campaign.endAt >= today)
          }

          const searchParams = new URLSearchParams(this.props.location.search)
          const freq = searchParams.get("freq")
          const spaceId = searchParams.get("space")
          if (spaceId) {
            this.values.spaceId = spaceId
          }
          const amountParam = searchParams.get("amount")
          if (amountParam) {
            const amount = parseFloat(amountParam)
            this.values.amount = numberToMoneyFormat(amount)
            this.minAmount = Math.max(amount, 10)
            if (amount > 0) {
              this.amountDisabled = true
            }
          }

          if (freq === "Once" && this.values.amount === "$0.00") {
            this.values.amount = "$50.00" // Default to $50 instead of zero
          }

          this.computeFees()

          if (freq === "Monthly") {
            this.values.frequency = freq
            this.oneTimeDisabled = true
          } else {
            this.values.frequency = "Once"
            this.monthlyDisabled = true
          }
          const other = searchParams.get("other")
          if (other) {
            this.values.frequency = "Other"
            this.values.other = other
            this.minAmount = 0
          }
        }

        this.isLoading = false
        progress!.hide("DonationForm")
      }
    )
  }

  render() {
    const { classes, donationId } = this.props

    if (this.isLoading) {
      return null
    }

    const title = donationId ? "Edit Donation" : "Donate"

    let message = ""
    if (donationId || this.isAdminAdd) {
      message = ""
    } else if (!this.isActive) {
      message = "This fundraiser is not currently active."
    } else if (this.values.frequency === DonationFrequency.Increase) {
      message = "When you press Next, the additional amount will be added to your existing recurring donation."
    } else if (this.paymentSource === "YoungLife") {
      message = "When you press Next, you will be redirected to the Young Life Giving page to complete your donation.  Be sure to come back and share this with your friends!"
    } else if (this.paymentSource === "Stripe") {
      message = "When you press Next, you will be redirected to Stripe to complete your donation."
    }

    const tipPercent = this.values.tipPercent > 0 ? `${this.values.tipPercent}%` : "Other"

    return (
      <Grid container className={classes.root} direction="column">
        <Paper className={classes.dialogPaper}>
          <TitleBar title={title} className={classes.titleBar}>
          </TitleBar>
          <FormValidator onSubmit={this.onSubmit} autoComplete="off"
                         name="DonationForm" id="DonationForm" className={classes.form}>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText>
                {this.renderCustom()}
              </DialogContentText>

              <div className={classes.label}>Type</div>
              {!this.values.other &&
                <RadioGroup aria-label="frequency" name="frequency" value={this.values.frequency} onChange={this.onChange} row>
                  <FormControlLabel
                    value="Once"
                    control={<Radio color="secondary"/>}
                    label="One Time"
                    labelPlacement="end"
                    disabled={this.oneTimeDisabled}
                  />
                  {this.paymentSource === "YoungLife" &&
                    [<FormControlLabel
                      value="Monthly"
                      control={<Radio color="secondary"/>}
                      label="Monthly"
                      labelPlacement="end"
                      disabled={this.monthlyDisabled}
                    />,
                    <FormControlLabel
                    value="Increase"
                    control={<Radio color="secondary"/>}
                    label="Increase Monthly"
                    labelPlacement="end"
                    disabled={this.monthlyDisabled}
                    />]
                  }
                </RadioGroup>
              }
              {this.values.other &&
                <RadioGroup aria-label="frequency" name="frequency" value={this.values.frequency} onChange={this.onChange} row>
                  <FormControlLabel
                    value="Other"
                    control={<Radio color="secondary"/>}
                    label={this.values.other}
                    labelPlacement="end"
                  />
                </RadioGroup>
              }

              {(!this.values.other || this.values.amount > "$0.00") &&
                <React.Fragment>
                  <TextFieldValidator
                    autoFocus={!this.amountDisabled}
                    margin="dense"
                    name="amount"
                    label={this.values.frequency === DonationFrequency.Increase ? "Additional Monthly Donation Amount (existing donors)" : "Donation Amount"}
                    type="text"
                    variant="standard"
                    validators={{required: true}}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    value={this.values.amount}
                    fullWidth
                    helperText="You may increase this if desired!"
                  />
                  {this.paymentSource === "Stripe" &&
                    <Grid container alignContent="center" alignItems="center" direction="row" xs={12}>
                      <Grid item xs="auto">
                        <Checkbox name="includeFees" checked={this.values.includeFees} onChange={this.onChange}/>
                      </Grid>
                      <Grid item xs>
                        <TextFieldValidator
                          disabled={!this.values.includeFees}
                          margin="dense"
                          name="fees"
                          label="Optional: Cover Credit Card Fees"
                          type="text"
                          variant="standard"
                          validators={{required: false, isCurrency: true}}
                          value={this.values.fees}
                          helperText="Covering credit card fees allows the full amount of your donation to go to the organization."
                          FormHelperTextProps={{
                            className: classes.helperText
                          }}
                        />
                      </Grid>
                    </Grid>
                  }
                  {this.paymentSource === "Stripe" &&
                    <Grid container alignContent="center" alignItems="center" direction="row" xs={12}>
                      <Grid item xs="auto">
                        <Checkbox name="includeTip" checked={this.values.includeTip} onChange={this.onChange}/>
                      </Grid>
                      <Grid item xs>
                        <TextFieldValidator
                          disabled={!this.values.includeTip}
                          margin="dense"
                          name="tip"
                          label="Optional: Tip to support FunGives"
                          type="text"
                          variant="standard"
                          validators={{required: false, isCurrency: true}}
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          value={this.values.tip}
                          helperText="FunGives is entirely funded by tips from donors like you. We don't charge any fees for use and appreciate your support!"
                          FormHelperTextProps={{
                            className: classes.helperText
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <MenuButton color="secondary" className={classes.tipButton} value={tipPercent}>
                                  <MenuItem onClick={() => this.onChangeTipPercent(2)}>2%</MenuItem>
                                  <MenuItem onClick={() => this.onChangeTipPercent(3)}>3%</MenuItem>
                                  <MenuItem onClick={() => this.onChangeTipPercent(5)}>5%</MenuItem>
                                  <MenuItem onClick={() => this.onChangeTipPercent(10)}>10%</MenuItem>
                                  <MenuItem onClick={() => this.onChangeTipPercent(15)}>15%</MenuItem>
                                  <MenuItem onClick={() => this.onChangeTipPercent(0)}>Other</MenuItem>
                                </MenuButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  }
                </React.Fragment>

              }
              <TextFieldValidator
                autoFocus = {this.amountDisabled}
                margin="dense"
                name="name"
                label="Donor Name(s)"
                type="text"
                variant="standard"
                validators={{required:true}}
                onChange={this.onChange}
                value={this.values.name}
                fullWidth
              />
              <TextFieldValidator
                margin="dense"
                name="email"
                label="Email Address"
                type="text"
                variant="standard"
                validators={{required:true, isEmail:true}}
                onChange={this.onChange}
                value={this.values.email}
                fullWidth
              />
              <br/><br/>
              <TextFieldValidator
                margin="dense"
                name="message"
                label="Public Donor Message (optional)"
                type="text"
                variant="outlined"
                multiline
                rows={4}
                validators={{required:false, maxLength:250}}
                onChange={this.onChange}
                value={this.values.message}
                fullWidth
              />
              <div className={classes.instructions}>
                <p>{message}</p>
              </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12} >
                  <ProgressButton variant="contained" color="primary" fullWidth
                                  type="submit" processing={this.isProcessing}
                                  onClick={this.onSubmit}
                                  disabled={!this.isActive}>
                    {donationId || this.isAdminAdd ? 'Save' : 'Next'}
                  </ProgressButton>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <DialogButton variant="tertiary" onClick={this.onCancel} fullWidth>
                    Back
                  </DialogButton>
                </Grid>
                {donationId &&
                  <Grid item sm={6} xs={12}>
                    <DialogButton variant="secondary" onClick={this.onDelete} fullWidth>
                      Delete
                    </DialogButton>
                  </Grid>
                }
              </Grid>
            </DialogActions>
          </FormValidator>
        </Paper>
      </Grid>
    )
  }

  renderCustom() {
    const { donationId } = this.props
    let custom = null
    if (donationId) {
      custom = <p>Update or delete this donation.</p>
    } else if (this.isAdminAdd) {
      custom = <p>Add a new donation.</p>
    } else if (this.campaign) {
      custom = parseHTML(this.campaign.getCustom("donateInstructions"))
    }

    return (custom)
  }

  computeFees = () => {
    if (this.paymentSource === "Stripe") {
      this.values.fees = this.values.includeFees ? numberToMoneyFormat(Math.round((parseMoney(this.values.amount) * 0.029 + 0.30) * 100) / 100) : "$0.00"

      if (!this.values.includeTip) {
        this.values.tip = "$0.00"
      } else if (this.values.tipPercent > 0) {
        this.values.tip = numberToMoneyFormat(Math.round(parseMoney(this.values.amount) * this.values.tipPercent) / 100)
      }
    }
  }

  onChangeTipPercent = (value: number) => {
    this.values.tipPercent = value
    this.computeFees()
  }

  onChange = (event: any) => {
    const name = event.target.name
    if (name === "includeFees") {
      this.values[name] = event.target.checked
      this.computeFees()
    } else if (name === "includeTip") {
      this.values[name] = event.target.checked
      this.computeFees()
    } else if (name === "amount") {
      this.values[name] = event.target.value
      this.computeFees()
    } else if (name === "tip") {
      this.values[name] = event.target.value
      this.values.tipPercent = 0 // Other
    } else {
      this.values[name] = event.target.value
    }
  }

  onBlur = (event: any) => {
    const name = event.target.name
    if (name === "tip" || name === "amount") {
      this.values[name] = numberToMoneyFormat(parseMoney(event.target.value))
    }
  }

  onSubmit = async () => {
    const { donationId, boardId, campaignStore, notify, confirm } = this.props

    if (!this.isValid) {
      return
    }

    const values = this.values
    const amount = parseMoney(values.amount)

    // TODO: Figure out why validation isn't catching this
    if (!values.name || !values.email || (values.frequency === "Once" && amount <= 0)) {
      return
    }

    if (!this.campaign!.paymentInfo ||
      (this.paymentSource === "Stripe" && !this.campaign!.paymentInfo.stripeId) ||
      (this.paymentSource === "YoungLife" &&
      ((values.frequency === "Monthly" && !this.campaign!.paymentInfo.monthlyGivingUrl) ||
       (values.frequency === "Once" && !this.campaign!.paymentInfo.oneTimeGivingUrl)))) {
      notify!.show("error", "No payment source has been configured.")
      return
    }

    if (donationId) {
      Tracking.event({action: 'UpdateDonation'})
      this.isProcessing = true

      const input: UpdateDonationInput = {
        id: donationId,
        accountId: this.donation!.accountId,
        amount: amount,
        frequency: DonationFrequency[values.frequency],
        other: values.other,
        name: values.name,
        email: values.email,
        message: values.message,
        isAnonymous: values.isAnonymous
      }

      this.isProcessing = true

      const update = await campaignStore!.updateDonation(input)
        .catch((err: Error) => {
          this.isProcessing = false
          notify!.show("error", "Unable to update donation")
        })

      if (update) {
        this.isProcessing = false
        notify!.show("success", "Donation updated!")
        this.onCancel()
      }
    } else {
      Tracking.event({action: 'CreateDonation'})

      const input: CreateDonationInput = {
        accountId: this.board!.accountId,
        campaignId: this.board!.campaignId,
        boardId: boardId!,
        spaceId: values.spaceId,
        amount: amount,
        frequency: DonationFrequency[values.frequency],
        other: values.other,
        name: values.name,
        email: values.email,
        message: values.message,
        isAnonymous: values.isAnonymous,
        paymentStatus: amount > 0 ? "unpaid" : "paid"
      }

      this.isProcessing = true

      const donation = await campaignStore!.createDonation(input)
        .catch((err: Error) => {
          this.isProcessing = false
          notify!.show("error", "Unable to create donation")
        })

      this.isProcessing = false

      if (donation) {

        if (this.isAdminAdd) {
          notify!.show("success", "Donation added!")
          // Go to donations page
          this.onCancel()
          return
        }

        if (values.frequency !== DonationFrequency.Increase && amount > 0) {
          if (this.paymentSource === "YoungLife") {
            confirm!.show("Notice", `You will now be sent to the Young Life Giving site to complete your donation.  Please enter your $${values.amount} donation amount there under Other.`, ["Ok", "Cancel"],
              async (): Promise<any> => {

                // Mark as paid
                const input: UpdateDonationInput = {
                  id: donation.id,
                  paymentStatus: "paid"
                }
                await campaignStore!.updateDonation(input)
                  .catch((err: Error) => {
                    // notify!.show("error", "Unable to update donation")
                  })

                this.onNext()

                return true;
              });
          } else if (this.paymentSource === "Stripe") {
            await this.checkoutWithStripe(donation)
          }
        } else {
          notify!.show("success", "Thanks for your donation!")
          // Go to share page
          ControlTower.route(`${Routes.share}/${this.campaign!.id}`)
        }
      }
    }

  }

  checkoutWithStripe = async (donation: Donation) => {
    const { paymentAPI, notify } = this.props

    const homeUrl = config.get("homeUrl")
    const returnUrl = `${homeUrl}/campaign/${this.campaign!.alias ?? this.campaign!.id}/${this.board!.alias ?? this.board!.id}`

    const amount = donation.amount * 100
    const fees = this.values.includeFees ? parseMoney(this.values.fees) * 100 : 0  // 2.9% + 0.30 fees
    const tip = this.values.includeTip ? parseMoney(this.values.tip) * 100 : 0
    // Always pass along the transaction fees
    const applicationFee = parseMoney(this.values.fees) * 100 + tip

    let session = new CheckoutSession()
    session.client_reference_id = donation.id
    session.customer_email = donation.email
    let name = `Donation to ${this.campaign!.title}`
    session.line_items.push(new LineItem(name, amount, 1))
    if (fees > 0) {
      session.line_items.push(new LineItem("Transaction Fee", fees, 1))
    }
    if (tip > 0) {
      session.line_items.push(new LineItem("Tip", tip, 1))
    }
    session.success_url = `${returnUrl}?donationId=${donation.id}&status=success`
    session.cancel_url = `${returnUrl}?donationId=${donation.id}&status=cancel`
    session.payment_intent_data = {
      application_fee_amount: applicationFee,
      description: "Transaction Fees + Tip",
      transfer_data: {
        destination: this.campaign!.paymentInfo!.stripeId,
      }
    }
    this.isProcessing = true

    const checkoutSession = await paymentAPI!.createCheckoutSession(session, this.campaign!.paymentInfo!.stripeId)
      .catch (error => {
        notify!.show("error", getErrorMessage(error))
      })

    this.isProcessing = false

    if (checkoutSession) {
      console.log(`checkoutSession: ${JSON.stringify(checkoutSession, null, 2)}`)
      ControlTower.open(checkoutSession.url, "_self")
    }
  }

  onDelete = () => {
    const { donationId, confirm, campaignStore, notify } = this.props

    confirm!.show("Confirm", "Click Ok to permanently delete this donation.", ["Ok", "Cancel"],
      async (): Promise<any> => {

        const result = await campaignStore!.deleteDonation(donationId!)
          .catch((err: Error) => {
            this.isProcessing = false
            notify!.show("error", "Unable to delete donation")
          })

        if (result) {
          notify!.show("success", "Donation deleted!")
          this.onCancel()
        }

        return true;
      });


  }

  onCancel = () => {
    const { boardId, donationId, onCancel } = this.props

    if (onCancel) {
      onCancel()
    } else {
      if (donationId || this.isAdminAdd) {
        ControlTower.route(`${Routes.account}/campaign/${this.campaign!.id}/donations`)
      } else if (this.campaign!.campaignType === CampaignType.NoGame) {
        ControlTower.route(`${Routes.campaign}/${this.campaign!.id}`)
      } else {
        ControlTower.route(`${Routes.board}/${boardId}`)
      }
    }
  }

  onNext = () => {
    let url
    if (this.values.frequency === "Monthly") {
      url = this.campaign!.paymentInfo.monthlyGivingUrl
    } else {
      url = this.campaign!.paymentInfo.oneTimeGivingUrl
    }
    // ControlTower.route(`${Routes.campaign}/${this.board!.campaignId}/share`)
    ControlTower.open(url, "_self")
  }

}

export default withTheme((withStyles(styles)(DonationForm)))