import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme } from '@material-ui/core/styles'
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import {Grid, isWidthDown, Paper, withWidth, WithWidth} from "@material-ui/core";
import ControlTower, {Routes} from "../../components/ControlTower";
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import CampaignStore from "../../stores/CampaignStore";
import {makeObservable, observable, when} from "mobx";
import Campaign from "../../model/Campaign";
import {Board} from "../../model/Board";
import BINKOBoard from "./BINKOBoard";
import DialogButton from "../../components/form/DialogButton";
import parseHTML from "html-react-parser";
import TitleBar from "../../components/TitleBar";
import {getISODateToday} from "../../stores/StoreUtilities";

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: 'center',
    alignContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  dialogPaper: {
    display: 'flex',
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: theme.spacing(2)
  },
  content: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: 'flex-start',
    width: "100%",
    marginTop: theme.spacing(0.5),
    textAlign: "left",
    fontSize: 16
  },
  alias: {
    color: theme.palette.secondary.light,
    fontSize: 16,
    fontWeight: 600,
    padding: "4px 8px 0 8px"
  },
  location: {
    color: theme.palette.primary.light,
    fontSize: 16,
    fontWeight: 600,
    padding: "4px 8px 0 8px"
  },
  boards: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    margin: "8px 0 8px 0",
  },
  boardItem: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "center",
    justifyContent: "center",
    alignItems: "flex-start",
    alignContent: "flex-start",
    minHeight: 350,
    maxHeight: 350,
    // border: "1px solid silver",
  },
  board: {
    transform: "scale(0.45, 0.45)",
    transformOrigin: "50% 0%",
    textAlign: "center",
    padding: 0,
  },
  boardLink: {
    textDecoration: "none"
  },
  actionButtons: {
    // display: "flex",
    // flexDirection: "row",
    // flexGrow: 1,
    // justifyContent: "space-around",
    // alignItems: "center",
    // textAlign: "center",
    padding: theme.spacing(2),
    paddingTop: 0
  },
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // backgroundColor: "#808080"
  },
})

interface IBoardsPageProps {
  campaignId?: string
  campaignStore?: CampaignStore
  progress?: Progress
}

@inject("campaignStore", "progress")
@observer
class BoardsPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IBoardsPageProps & WithTheme & WithWidth> {

  @observable isLoading = true
  @observable campaign?: Campaign
  @observable isActive: boolean = true

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount () {
    const { campaignStore, progress } = this.props
    this.isLoading = true
    progress!.show("BoardsPage")
    when (
      () => !campaignStore!.isLoading,
      async () => {
        this.campaign = await this.loadCampaign()
        if (this.campaign) {
          const today = getISODateToday()
          this.isActive = (this.campaign.startAt <= today && this.campaign.endAt >= today)
        }
        this.isLoading = false
        progress!.hide("BoardsPage")
      }
    )
  }

  render() {
    const { classes } = this.props

    if (!this.campaign) {
      return null
    }

    const campaign = this.campaign
    const alias = '#' + campaign.alias
    const title = this.campaign ? this.campaign.title : "Boards"
    const location = (campaign.city && campaign.state) ? `${campaign.city}, ${campaign.state}` : ""

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container className={classes.content} direction="row" spacing={2}>
            <Grid item xs={12}>
              <div className={classes.alias}>{alias}</div>
              <TitleBar title={title}/>
              <div className={classes.location}>{location}</div>
            </Grid>
          </Grid>
          <Paper className={classes.dialogPaper}>
            <Grid container className={classes.root} direction="column">
              <Grid item xs={12} className={classes.content}>
                {this.renderCustom()}
              </Grid>
            </Grid>
            <Grid container className={classes.boards} direction="row" spacing={1}>
              {campaign.boards.map((board: Board) =>
                <Grid item md={3} sm={4} xs={6} className={classes.boardItem} spacing={0}>
                  <div className={classes.board}>
                    <a href="# " className={classes.boardLink}
                       onClick={(event) => this.onClickBoard(board)}>
                      <BINKOBoard board={board} legend/>
                    </a>
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid container className={classes.actionButtons} spacing={2}>
              <Grid item sm={6} xs={12} >
                <DialogButton variant="primary" fullWidth onClick={this.onNew} disabled={!this.isActive}>
                  New Board
                </DialogButton>
              </Grid>
              <Grid item sm={6} xs={12}>
                <DialogButton variant="tertiary" fullWidth onClick={this.onBack}>
                  Back
                </DialogButton>
              </Grid>
            </Grid>
          </Paper>
        </MarginRow>
      </Page>
    )
  }

  renderCustom() {
    let custom = null
    if (this.campaign) {
      custom = parseHTML(this.campaign.getCustom("boardsInstructions"))
    }

    return (custom)
  }

  loadCampaign = async () => {
    const { campaignStore, campaignId } = this.props
    let campaign
    if (campaignId) {
      campaign = await campaignStore!.getCampaign(campaignId)
    }
    if (campaign) {
      campaign.boards.sort((a: Board, b: Board) => a.name.localeCompare(b.name))
    }
    return campaign
  }

  onNew = () => {
    const { campaignId } = this.props
    ControlTower.route(`${Routes.campaign}/${campaignId}/boardEdit`)
  }

  onClickBoard = (board: Board) => {
    const { width } = this.props
    if (isWidthDown('xs', width)) {
      ControlTower.route(`${Routes.board}/${board.id}`)
    } else {
      ControlTower.route(`${Routes.campaign}/${this.campaign!.alias ?? this.campaign!.id}/${board.alias ?? board.id}`)
    }
  }

  onBack = () => {
    const { campaignId } = this.props
    ControlTower.route(`${Routes.campaign}/${campaignId}`)
  }
}

export default withTheme((withStyles(styles)(withWidth()(BoardsPage))))