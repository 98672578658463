import * as React from 'react'
import Page from '../../components/page/Page'
import {createStyles, Grid, Tab, Tabs, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Account from "../../model/Account"
import {makeObservable, observable, when} from "mobx";
import User from "../../model/User";
import Progress from "../../components/Progress";
import ControlTower, {Routes} from "../../components/ControlTower";
import AccountInfo from "./AccountInfo";
import AccountUsers from "./AccountUsers";
import TitleBar from "../../components/TitleBar";
import AccountCampaigns from "./AccountCampaigns";
import AccountPayments from "./AccountPayments";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    width: "100%",
    justifyContent: 'top',
    alignItems: 'center',
    overflow: "hidden"
  },
  navigation: {
  },
  content: {
    overflow: "hidden"
  },
  tabs: {

  },
  tab: {
    fontSize: 15,
    fontWeight: 600,
  },
  hiddenTab: {
    display: "none"
  }
})

interface IAccountPageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
  tab?: string
}

@inject("userStore", "accountStore", "progress")
@observer
class AccountPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountPageProps & WithTheme> {

  tabNames: string[] = ["campaigns", "people", "info", "payments"]

  @observable account?: Account
  @observable user?: User
  @observable isLoading = true

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount () {
    const { userStore, accountStore, progress } = this.props

    this.isLoading = true
    progress!.show("AccountPage")
    when(
      () => !userStore!.isLoading && !accountStore!.isLoading,
      () => {
        this.account = accountStore!.account
        this.user = userStore!.user
        this.isLoading = false
        progress!.hide("AccountPage")
      }
    )
  }

  handleChange = (event: any, value: any) => {
    // Navigate to tab to allow a direct url to each tab
    const tabName = this.tabNames[value]
     ControlTower.route(`${Routes.account}/${tabName}`)
  }

  render() {
    const { classes } = this.props

    if (!this.account) {
      return null
    }

    const title = this.isLoading ? "Account" : this.account!.name

    const tabName = (this.props.tab !== undefined) ? this.props.tab : this.tabNames[0]
    let tabValue = this.tabNames.findIndex(name => tabName === name)
    if (tabValue < 0) {
      tabValue = 0
    }

    // const isAdmin = userStore!.isAdmin
    // const isOwner = isAdmin || userStore!.isOwner

    return (
      <Page title={title}>
        <MarginRow>
          <div className={ classes.root}>
            <Grid container className={classes.navigation}>
              <TitleBar title={title}>
              </TitleBar>
              <Tabs
                value={tabValue}
                onChange={this.handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="secondary"
                className={ classes.tabs }
              >
                <Tab className={classes.tab} label="Fundraisers"/>
                <Tab className={classes.tab} label="People" />
                <Tab className={classes.tab} label="Information"/>
                <Tab className={classes.tab} label="Payments"/>
              </Tabs>
            </Grid>
            <Grid container className={classes.content}>
              {tabValue === 0 && <AccountCampaigns />}
              {tabValue === 1 && <AccountUsers />}
              {tabValue === 2 && <AccountInfo />}
              {tabValue === 3 && <AccountPayments />}
            </Grid>
          </div>
        </MarginRow>
      </Page>
    )
  }

}

export default withTheme((withStyles(styles)(AccountPage)))