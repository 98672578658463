const AppConfig = {
  env: process.env.REACT_APP_ENV,
  homeUrl: process.env.REACT_APP_HOME_URL,
  shareUrl: process.env.REACT_APP_SHARE_URL,
  nodeEnv: process.env.NODE_ENV,
  version: process.env.REACT_APP_VERSION,
  commitId: process.env.REACT_APP_COMMIT_ID,
  build: process.env.REACT_APP_JOB_ID,
  rollbar: {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN
  },
  google: {
    trackingId: process.env.REACT_APP_GOOGLE_TRACKING_ID,
    options: {
    }
  },
  tinymce: {
    apiKey: process.env.REACT_APP_TINYMCE_API_KEY
  },
  branch: process.env.REACT_APP_BRANCH
};

export default AppConfig;