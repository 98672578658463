import * as React from "react";
import {
  Card,
  CardContent,
  createStyles, Grid,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import TitleBar from "../../components/TitleBar";
import Account from "../../model/Account"
import TitleButton from "../../components/TitleButton";
import {observable} from "mobx";
import {observer} from "mobx-react";
import ControlTower, {Routes} from "../../components/ControlTower";

const styles = (theme: Theme) => createStyles({
  card: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: "column",
    width: '100%',
    marginTop: theme.spacing(1)
  },
  titleBar: {
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  content: {
    justifyContent: 'flex-start',
    padding: theme.spacing(1)
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1)
  },
  fieldValue: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
    minHeight: 20
  },
  fieldLink: {
    color: theme.palette.secondary.light
  }
})

interface IAccountCardProps {
  account: Account
}

@observer
class AccountCard extends React.Component<WithStyles<typeof styles> & IAccountCardProps> {

  @observable isEditing = false


  render() {
    const { classes, account } = this.props

    const title = account.name ?? "Account"

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <TitleBar title={title} className={classes.titleBar}>
            <TitleButton title="Edit" variant="secondary" onClick={this.onEditAccount}/>
          </TitleBar>
          <Grid container direction="column" className={classes.content}>
            <Grid item>
              <div className={classes.fieldLabel}>Organization Type</div>
              <div className={classes.fieldValue}>{account.orgType}</div>
            </Grid>
            <Grid item>
              <div className={classes.fieldLabel}>Address</div>
              {account.address &&
                <div className={classes.fieldValue}>{account.address}</div>
              }
              <div className={classes.fieldValue}>
                {account.city} {account.state} {account.postalCode}
              </div>
            </Grid>
            <Grid item>
              <div className={classes.fieldLabel}>Web Site</div>
              {account.webSite &&
                <a href={account.webSite} target="_blank" rel="noopener noreferrer">
                  <div className={classes.fieldLink}>{account.webSite}</div>
                </a>
              }
              {!account.webSite &&
                <div className={classes.fieldValue}>{account.webSite}</div>
              }
            </Grid>
            <Grid item>
              <div className={classes.fieldLabel}>About</div>
              <div className={classes.fieldValue}>{account.about}</div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  onEditAccount = () => {
    const { account } = this.props
    ControlTower.route(`${Routes.accountEdit}/${account.id}`)
  }
}

export default withStyles(styles)(AccountCard)
