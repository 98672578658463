import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import {inject, observer} from "mobx-react";
import Progress from "../../components/Progress";
import CampaignStore from "../../stores/CampaignStore";
import {makeObservable, observable, when} from "mobx";
import Campaign from "../../model/Campaign";
import TitleBar from "../../components/TitleBar";
import {Grid, IconButton, Paper, TextField} from "@material-ui/core";
import {Donation} from "../../model/Donation";
import TextAppIcon from "../../images/TextAppIcon.png"
import CopyLinkIcon from "../../images/CopyLinkIcon.png"
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton, WhatsappIcon, WhatsappShareButton
} from "react-share";
import DialogButton from "../../components/form/DialogButton";
import Notify from "../../components/notify/Notify";
import ControlTower from "../../components/ControlTower";
import Tracking from "../../components/Tracking";
import parseHTML from "html-react-parser";
import config from 'react-global-configuration';
import {Board} from "../../model/Board";

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(1)
  },
  dialogPaper: {
    display: 'flex',
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: 'flex-start',
    justifySelf: "center",
    width: '100%',
    maxWidth: 460,
    margin: theme.spacing(0),
    marginTop: theme.spacing(0.5)
  },
  content: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: 'center',
    width: "100%",
    padding: theme.spacing(1)
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    padding: theme.spacing(1)
  },
  instructions: {
    padding: theme.spacing(1),
  },
  shareButtons: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    padding: "8px 0"
  },
  shareItem: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // border: "1px solid yellow"
  },
  shareButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  shareIcon: {
    display: "flex",
    paddingBottom: theme.spacing(1)
  },
  shareLabel: {
    display: "flex",
    flexGrow: 1,
    fontSize: 16,
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
  appIcon: {
    width: 64,
    height: 64
  },
  shareUrl: {
    padding: theme.spacing(1),
  },
  actionButtons: {
    padding: theme.spacing(1)
  },
})

interface ICampaignSharePageProps {
  campaignId?: string
  boardId?: string
  campaignStore?: CampaignStore
  progress?: Progress
  notify?: Notify
  location?: any
}

@inject("campaignStore", "progress", "notify")
@observer
class CampaignSharePage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICampaignSharePageProps & WithTheme> {

  @observable isLoading = true
  @observable campaign?: Campaign
  @observable board?: Board

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount () {
    const { campaignStore, boardId, progress } = this.props
    this.isLoading = true
    progress!.show("CampaignSharePage")
    when (
      () => !campaignStore!.isLoading,
      async () => {
        this.campaign = await this.loadCampaign()
        if (boardId) {
          this.board = this.campaign?.getBoard(boardId)
        }
        this.isLoading = false
        progress!.hide("CampaignSharePage")
      }
    )
  }

  render() {
    const { classes, boardId } = this.props

    if (!this.campaign) {
      return null
    }

    let boardAlias
    if (this.board && this.board.alias) {
      boardAlias = this.board.alias
    } else {
      boardAlias = boardId
    }

    const shareUrl = (boardId) ? `${config.get("shareUrl")}/${this.campaign.alias}/${boardAlias}` :
                                 `${config.get("shareUrl")}/${this.campaign.alias}`
    let campaign = this.campaign
    let title = ""
    let name = "BINKO"
    // TODO: Get default URL from environment

    if (campaign) {
      title = `Share ${campaign.title}!`
      name = campaign.getCustom("name")
    }
    let subject = `Play ${name} for ${campaign.title}`

    const textUrl = encodeURI(`sms://?&body=${subject} at ${shareUrl}`)

    return (
      <Page title={title}>
        <MarginRow>
          <div className={classes.root}>
            <Paper className={classes.dialogPaper}>
              <Grid container className={classes.content} direction="row" spacing={0}>
                <Grid item xs={12}>
                  <TitleBar title={title}/>
                </Grid>
                <Grid item xs={12} className={classes.instructions}>
                  {this.renderCustom()}
                </Grid>
                <Grid container direction="row" spacing={3} className={classes.shareButtons}>
                  <Grid item xs={6} className={classes.shareItem}>
                    <FacebookShareButton
                      url={shareUrl}
                      quote={subject}
                      className={classes.shareButton}
                    >
                      <div className={classes.shareIcon}><FacebookIcon size={64} round/></div>
                      <div className={classes.shareLabel}>Facebook</div>
                    </FacebookShareButton>
                  </Grid>

                  <Grid item xs={6} className={classes.shareItem}>
                    <TwitterShareButton
                      url={shareUrl}
                      title={subject}
                      className={classes.shareButton}
                    >
                      <div className={classes.shareIcon}><TwitterIcon size={64} round/></div>
                      <div className={classes.shareLabel}>Twitter</div>
                    </TwitterShareButton>
                  </Grid>

                  <Grid item xs={6} className={classes.shareItem}>
                    <EmailShareButton
                      url={shareUrl}
                      subject={subject}
                      body={`You're invited to play BINKO to help ${campaign.title}. Go to: `}
                      className={classes.shareButton}
                    >
                      <div className={classes.shareIcon}><EmailIcon size={64} round/></div>
                      <div className={classes.shareLabel}>Email</div>
                    </EmailShareButton>
                  </Grid>

                  <Grid item xs={6} className={classes.shareItem}>
                    <a
                      href={textUrl}
                      className={classes.shareLabel}
                    >
                      <div className={classes.shareButton}>
                        <div className={classes.shareIcon}><img src={TextAppIcon} className={classes.appIcon} alt="Text Message"/></div>
                        <div className={classes.shareLabel}>Text</div>
                      </div>
                    </a>
                  </Grid>

                  <Grid item xs={6} className={classes.shareItem}>
                    <CopyToClipboard
                      text={shareUrl}
                      onCopy={this.onCopyLink}>
                      <IconButton
                        color="inherit"
                        aria-label="Copy Link"
                      >
                        <div className={classes.shareButton}>
                          <div className={classes.shareIcon}><img src={CopyLinkIcon} className={classes.appIcon} alt="Copy Link"/></div>
                          <div className={classes.shareLabel}>Copy Link</div>
                        </div>
                      </IconButton>
                    </CopyToClipboard>
                  </Grid>

                  <Grid item xs={6} className={classes.shareItem}>
                    <WhatsappShareButton
                      url={shareUrl}
                      title={subject}
                      separator=":: "
                      className={classes.shareButton}
                    >
                      <div className={classes.shareIcon}><WhatsappIcon size={64} round/></div>
                      <div className={classes.shareLabel}>WhatsApp</div>
                    </WhatsappShareButton>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.shareUrl}>
                  <TextField
                    margin="dense"
                    name="shareUrl"
                    label="Share Link"
                    type="text"
                    variant="standard"
                    value={shareUrl}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} className={classes.actionButtons}>
                  <DialogButton variant="tertiary" onClick={this.onBack} fullWidth>
                    Back
                  </DialogButton>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </MarginRow>
      </Page>
    )
  }

  renderCustom() {
    let custom = null
    if (this.campaign) {
      custom = parseHTML(this.campaign.getCustom("shareInstructions"))
    }

    return (custom)
  }

  loadCampaign = async () => {
    const { campaignStore, campaignId } = this.props
    let campaign
    if (campaignId) {
      campaign = await campaignStore!.getCampaign(campaignId)
      if (campaign && campaign.donations) {
        campaign.donations.sort((a: Donation, b: Donation) => a.createdAt.localeCompare(b.createdAt))
      }
    }
    return campaign
  }

  onTextShare = () => {
    console.log("onTextShare")
    // TODO
  }

  onCopyLink = () => {
    this.props.notify!.show('success', 'Copied link to clipboard.')
    Tracking.event({action: "Share Link"})
  }


  onBack = () => {
    const searchParams = new URLSearchParams(this.props.location.search)
    const returnTo = searchParams.get("return")
    if (returnTo) {
      ControlTower.route(returnTo)
    } else {
      ControlTower.back()
    }
  }
}
export default withTheme((withStyles(styles)(CampaignSharePage)))