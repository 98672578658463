import { API } from "aws-amplify"
import {getErrorMessage} from "../stores/StoreUtilities";
import Account from "../model/Account";
import {Logger} from "../components/Logger";
import User from "../model/User";

export class LineItem {
  name: string
  amount: number
  currency: string
  quantity: number

  constructor(name: string, amount: number, quantity: number) {
    this.name = name
    this.amount = amount
    this.currency = 'usd'
    this.quantity = quantity
  }
}

export class CheckoutSession {
  client_reference_id: string = ""
  customer_email: string = ""
  payment_method_types: string[] = ["card"] // , "us_bank_account" (later)
  line_items: LineItem[] = []
  mode: string = "payment"
  success_url: string = ""
  cancel_url: string = ""
  payment_intent_data = {
    application_fee_amount: 0,
    description: "",
    transfer_data: {
      destination: "string",
    }
  }
}

class PaymentAPI {

  createAccount = async (user: User, account: Account) => {
    const init = {
      body: {
        user: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone
        },
        account: {
          id: account.id,
          name: account.name,
          address: account.address,
          city: account.city,
          state: account.state,
          postalCode: account.postalCode,
          orgType: account.orgType,
          webSite: account.webSite,
          about: account.about
        }
      }
    }
    try {
      const response = await API.post("PaymentAPI", "/accounts", init)
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.debug("PaymentAPI.createInvoice error", message, init.body)
      throw new Error(message)
    }
  }

  getAccount = async (id: string) => {
    try {
      const response = await API.get("PaymentAPI", `/accounts/${id}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("PaymentAPI.getAccount error", message)
      throw new Error(message)
    }
  }

  createAccountLinks = async (stripeId: string, refreshUrl : string, returnUrl: string) => {
    const init = {
      body: {
        account: stripeId,
        refresh_url: refreshUrl,
        return_url: returnUrl
      }
    }
    try {
      const response = await API.post("PaymentAPI", "/accountLinks", init)
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.debug("PaymentAPI.createAccountLinks error", message, init.body)
      throw new Error(message)
    }
  }

  createLoginLink = async (stripeId: string) => {
    const init = {
    }
    try {
      const response = await API.post("PaymentAPI", `/loginLink/${stripeId}`, init)
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.debug("PaymentAPI.createLoginLink error", message)
      throw new Error(message)
    }
  }

  createCheckoutSession = async (session: CheckoutSession, stripeId: string) => {
    const init = {
      body: {
        session: session,
        // stripeId: stripeId
      }
    }
    try {
      const response = await API.post("PaymentAPI", "/checkout/sessions", init)
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.debug("PaymentAPI.createCheckoutSession error", message, init.body)
      throw new Error(message)
    }
  }
}

export default PaymentAPI