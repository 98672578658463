import {Account} from "./Account";
import {UserRole} from "../API";


class User {
  id: string
  createdAt: string
  updatedAt: string
  active: boolean
  accountId: string
  account?: Account
  firstName: string
  lastName: string
  email: string
  phone: string
  role: UserRole

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.active = data.active
    this.accountId = data.accountId
    this.account = data.account ? new Account(data.account) : undefined
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.email = data.email
    this.phone = data.phone
    this.role = data.role
  }

  get fullName(): string {
    return `${this.lastName}, ${this.firstName}`
  }

  get isActive(): boolean {
    return this.active
  }

  get isAdmin(): boolean {
    return this.role === UserRole.Admin
  }

  get isOwner(): boolean {
    return this.role === UserRole.Owner
  }

  get isSponsor(): boolean {
    return this.role === UserRole.Sponsor
  }
}

export default User