import * as React from 'react'
import Page from '../components/page/Page'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import {inject} from "mobx-react";
import UserStore from "../stores/UserStore";
import ControlTower, {Routes} from "../components/ControlTower";
import Notify from "../components/notify/Notify";

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },

})

interface ISignOutPageProps {
  userStore?: UserStore
  notify?: Notify
}

@inject("userStore", "notify")
class SignOutPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ISignOutPageProps & WithTheme> {

  async componentDidMount() {
    const { userStore, notify } = this.props

    notify!.close()
    userStore!.signOut()
    ControlTower.route(Routes.home)
  }

  render() {
    const { classes } = this.props

    return (
      <Page classes={{rootStyle: classes.rootStyle}}>
      </Page>
    )
  }
}

export default withTheme((withStyles(styles)(SignOutPage)))