import * as React from "react";
import {
  Card, CardActionArea,
  CardContent,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import User from "../../model/User";
import {phoneToNationalFormat} from "../../stores/StoreUtilities";
import TitleButton from "../../components/TitleButton";
import ControlTower, {Routes} from "../../components/ControlTower";


const styles = (theme: Theme) => createStyles({
  card: {
    width: '100%',
    height: 80,
    padding: 0
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: "5px 10px",
    display: "flex"
  },
  title: {
    color: theme.palette.secondary.light,
    fontSize: 16,
    fontWeight: 800,
    maxHeight: 24,
    overflow: "hidden",
  },
  titleButtons: {
    textAlign: "right",
  },
  owner: {
    fontSize: 14,
    fontWeight: 400,
  },
  details: {
    fontSize: 14,
    fontWeight: 400,
    maxHeight: 24,
    overflow: "hidden",
  },
  role: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    textAlign: "right"
  },
  actionArea: {
    color: theme.palette.secondary.main
  },

})

interface IUserCardProps {
  user: User
  onCardAction?: any
}

@inject('user')
@observer
class UserCard extends React.Component<WithStyles<typeof styles> & IUserCardProps> {

  render() {
    const { classes, user, onCardAction } = this.props
    const active = user.active

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Grid container justify={'space-between'} direction="column">
            <Grid container justify={'space-between'} direction="row">
              <Grid item xs={8}>
                {onCardAction &&
                <CardActionArea onClick={this.onCardAction} className={classes.actionArea}>
                  <Typography className={classes.title}>
                    {user.lastName}, {user.firstName}
                  </Typography>
                </CardActionArea>
                }
                {!onCardAction &&
                  <Typography className={classes.title}>
                    {user.lastName}, {user.firstName}
                  </Typography>
                }
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.titleButtons}>
                  <TitleButton title="Edit" variant="secondary" onClick={this.onEdit}/>
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify={'space-between'} direction="row">
              <Grid item xs={8}>
                <Typography className={classes.details}>
                  {user.email}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.role}>
                  {active ? "Active" : "Inactive"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={6}>
                <Typography className={classes.details}>
                  {phoneToNationalFormat(user.phone)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.role}>
                  {user.role}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  onEdit = () => {
    const { user } = this.props
    ControlTower.route(`${Routes.account}/userEdit/${user.id}`)
  }

  onCardAction = () => {
    if (this.props.onCardAction) {
      this.props.onCardAction(this.props.user)
    }
  }

}

export default withStyles(styles)(UserCard)
