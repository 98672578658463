import Campaign from "./Campaign";
import {Donation} from "./Donation";
import {DonationFrequency} from "../API";

export class Board {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  campaignId: string
  campaign?: Campaign
  name: string
  alias: string
  sponsorId: string
  sponsorName: string
  sponsorEmail: string
  sponsorMatch: boolean
  sponsorMatchLimit: number
  backgroundColor: string
  backgroundImage: string
  coverImage: string
  layout: any
  donations: Donation[]
  donatedAmount: number
  matchableAmount: number
  matchedAmount: number
  totalAmount: number

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.campaignId = data.campaignId
    this.campaign = data.campaign
    this.name = data.name
    this.alias = data.alias
    this.sponsorId = data.sponsorId
    this.sponsorName = data.sponsorName
    this.sponsorEmail = data.sponsorEmail
    this.sponsorMatch = data.sponsorMatch
    this.sponsorMatchLimit = data.sponsorMatchLimit
    this.backgroundColor = data.backgroundColor
    this.backgroundImage = data.backgroundImage
    this.coverImage = data.coverImage
    this.layout = this.loadLayout(data.layout)
    this.donatedAmount = 0
    this.matchableAmount = 0
    this.matchedAmount = 0
    this.totalAmount = 0
    this.donations = []
    if (data.donations && data.donations.items) {
      this.loadDonations(data.donations.items)
    }
  }

  loadLayout(layout: any) {
    if (layout) {
      let json = JSON.parse(layout)
      // Fix up freq "One Time" -> "Once
      const rows = json["rows"]
      if (rows) {
        rows.forEach((row: any) => {
          const cols = row["cols"]
          if (cols) {
            cols.forEach((col: any) => {
              if (col["freq"] === "One Time") {
                col["freq"] = "Once"
              }
            })
          }
        })
      }
      return json
    } else {
      return undefined
    }
  }

  loadDonations(items: any) {
    this.donations = []
    items.forEach((item: any) => {
      const donation = new Donation(item)
      if (!donation.paymentStatus || donation.paymentStatus !== "unpaid") {
        let donated
        if (donation.frequency === DonationFrequency.Monthly) {
          donated = donation.amount * 12
        } else {
          donated = donation.amount
        }
        this.donatedAmount += donated
        this.matchableAmount += donation.amount
        if (this.sponsorMatch) {
          if (this.matchableAmount <= this.sponsorMatchLimit) {
            this.matchedAmount = this.matchableAmount
          } else {
            this.matchedAmount = this.sponsorMatchLimit
          }
        }
        this.totalAmount = this.donatedAmount + this.matchedAmount

        this.donations.push(donation)
      }
    })
  }
}